import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { SelectivityPartAddDialogService } from './selectivity-part-add-dialog.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-selectivity-part-add-dialog',
  templateUrl: './selectivity-part-add-dialog.component.html',
  styleUrls: ['./selectivity-part-add-dialog.component.css']
})
export class SelectivityPartAddDialogComponent implements OnInit {

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  [x: string]: any;
  //to main page
  message = "Records Added Successfully";
  index: any;
  errorMessage = "";

  //ngModelValues
  namc: any = "";
  family: any = "";
  grpi: any = "";
  selectivity: any = "";
  partNumber: any = "";
  parentPart: any = "";
  completePart: any = "";
  selectivityRuleVIn: any = "";
  selectivityRuleRep: any = "";
  extTimeIn: any = "";
  extTimeOut: any = "";
  comments: any = "";
  hidedata: any = "true";
  //respone after inserting a record
  response: any;

  //list
  namcLst: string[] = [];
  familyLst: string[] = [];
  grpiLst: string[] = [];
  parentPartLst: string[] = [];
  partNumberLst: string[] = [];
  selectivityRuleRepLst: string[] = [];
  selectivityRuleVinLst: string[] = [];
  selectivityLst: string[] = [];
  completePartRoutingLst: string[] = [];
  spinnerMessage = "Please Wait...";
  constructor(private SelectivityPartAddDialogService: SelectivityPartAddDialogService, public dialogRef: MatDialogRef<SelectivityPartAddDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    //to get namc
    this.SpinnerService.show();
    this.SelectivityPartAddDialogService.getNamcSpr().subscribe(data => {
      console.log(data);
      this.namcLst = data.payload;
      this.SpinnerService.hide();
    });

    // to get family
    // this.getFamilyByNamcSpr();

    // to get selectivity
    this.SelectivityPartAddDialogService.getSelectivityByFamily(this.family).subscribe(data => {
      console.log(data);
      this.selectivityLst = data.payload;
    });

    // to get selectivityRuleVin
    this.SelectivityPartAddDialogService.getSelectivityRuleVin().subscribe(data => {
      console.log(data);
      this.selectivityRuleVinLst = data.payload;
    });


    // to get selectivityRuleRep
    this.SelectivityPartAddDialogService.getSelectivityRuleRep().subscribe(data => {
      console.log(data);
      this.selectivityRuleRepLst = data.payload;
    });


    // to get completePartRouting
    this.SelectivityPartAddDialogService.getCompletePartRouting(this.selectivity).subscribe(data => {
      console.log(data);
      this.completePartRoutingLst = data.payload;
    });
  }

  getFamilyByNamcSpr() {
    if (this.namc == undefined) {
      this.namc = ""
    }
    this.SpinnerService.show();
    this.SelectivityPartAddDialogService.getFamilyByNamcSpr(this.namc).subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.familyLst = data.payload;
      console.log(this.hidedata);
      this.family = ""
    });
  }


  getSelectivityByFam() {
    if (this.family == undefined) {
      this.family = ""
    }
    this.SelectivityPartAddDialogService.getSelectivityByFamily(this.family).subscribe(data => {
      console.log(data);
      this.selectivityLst = data.payload;
      this.selectivity = ""
    });
  }
  getcompByNamc() {
    if (this.namc == undefined) {
      this.namc = ""
    }
    this.SelectivityPartAddDialogService.getCompletePartRouting(this.namc).subscribe(data => {
      console.log(data);
      this.completePartRoutingLst = data.payload;
      this.completePart = ""
    });
  }

  //closealert
  closeAlert() {
    this.errorMessage = "";
  }

  updateSelPartRule() {
    let fileName= localStorage.getItem('Filename')
    console.log(this.namc, this.family, this.selectivity, this.partNumber, this.completePart,
      this.selectivityRuleVIn, this.selectivityRuleRep, this.extTimeIn);

    if (!this.namc || !this.family || !this.selectivity || !this.partNumber
      || !this.completePart || !this.selectivityRuleVIn || !this.selectivityRuleRep || !this.extTimeIn || !this.grpi) {

      this.errorMessage = "Please enter all mandatory field";

    }
    else if (this.extTimeIn && this.extTimeOut && this.pipe.transform(this.extTimeIn, 'yyyy-MM-dd') > this.pipe.transform(this.extTimeOut, 'yyyy-MM-dd')) {

      this.errorMessage = "Selectivity Rule Time Out should be greater than or equal to Selectivity Rule Time In";

    }

    else {

      this.errorMessage = "";
      if (this.extTimeOut == "") {
        this.extTimeOut = "9999-12-31"
      }
      let Grpibody = {
        "grpiCD": this.grpi,
        "family": this.family,
        "screenNo":"NBPC_2230",
        "fileName" :fileName
      }
      this.SpinnerService.show();
      this.SelectivityPartAddDialogService.CheckGrpi(Grpibody).subscribe(data => {
        this.response = data.payload;
        this.SpinnerService.hide();
        if (this.response[0].result == 'success') {
          this.checkpartnumber();
        } else if (this.response[0].result == 'Missing_grpi_cd') {
          // this.dialogRef.close(this.response[0].result);
          this.errorMessage = 'Missing Grpi Code!';
        } else if (this.response[0].result == 'Invalid GRPI CODE') {
          // this.dialogRef.close(this.response[0].result);
          this.errorMessage = 'GRPI <' + this.grpi + '> Invalid for the family <' + this.family + '>';
        }
      }, err => {
        this.SpinnerService.hide();
      });
    }
  }


  cancel(): void {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  grpiEnable() {
    this.hidedata = "false";
  }

  checkpartnumber() {
    let partNobody = {
      "partNo": this.partNumber,
    }
    this.SpinnerService.show();
    this.SelectivityPartAddDialogService.CheckpartNo(partNobody).subscribe(data => {
      this.response = data.payload;
      this.SpinnerService.hide();
      if (this.response[0].result == 'success') {
        if (this.parentPart != '') {
          this.checkparentPartNo();
        } else {
          this.checkaddslctvTypExists();
        }

      } else if (this.response[0].result == 'Missing_Part_Number') {
        // this.dialogRef.close(this.response[0].result);
        this.errorMessage = 'Missing Part Number!';
      }
      else if (this.response[0].result == 'Invalid Part Num') {
        // this.dialogRef.close(this.response[0].result);
        this.errorMessage = 'PartNumber <' + this.partNumber + '> Invalid';
      }
    }, err => {
      this.SpinnerService.hide();
    });
  }
  checkparentPartNo() {
    let fileName= localStorage.getItem('Filename')
    let partNobody = {
      "parentPartNo": this.parentPart,
    }
    this.SpinnerService.show();
    this.SelectivityPartAddDialogService.CheckParentpartNo(partNobody).subscribe(data => {
      this.response = data.payload;
      this.SpinnerService.hide();
      if (this.response[0].result == 'success') {
        this.checkaddslctvTypExists();
      } else if (this.response[0].result == 'AlreadyExists') {
        // this.dialogRef.close(this.response[0].result);
        this.errorMessage = 'Data Entered already Exists!';
      } else if (this.response[0].result == 'Invalid Parent part number') {
        // this.dialogRef.close(this.response[0].result);
        this.errorMessage = 'Parent PartNumber <' + this.parentPart + '> Invalid';
      }
    }, err => {
      this.SpinnerService.hide();
    });
  }

  checkaddslctvTypExists() {
    let slctvTypbody = {
      "slctvTypExists": this.selectivity,
    }
    this.SpinnerService.show();
    this.SelectivityPartAddDialogService.Checkselectivity(slctvTypbody).subscribe(data => {
      this.response = data.payload;
      this.SpinnerService.hide();
      if (this.response[0].result == 'success') {
        this.checkaddExist();
      } else if (this.response[0].result == 'AlMissing_slctv_typ_existsreadyExists') {
        // this.dialogRef.close(this.response[0].result);
        this.errorMessage = 'Missing Selectivity type Exists!';
      } else if (this.response[0].result == 'Invalid Selectivity type Already Exists') {
        // this.dialogRef.close(this.response[0].result);
        this.errorMessage = 'Invalid Selectivity type Already Exists!';
      }
    }, err => {
      this.SpinnerService.hide();
    });
  }

  checkaddExist() {
    let fileName= localStorage.getItem('Filename')
    let indateFormat = ''; let outdateFormat = '';
    if (this.extTimeIn != "") {
      let d1 = moment(this.extTimeIn).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.extTimeIn = date
    }
    if (this.extTimeOut != "") {
      let d1 = moment(this.extTimeOut).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.extTimeOut = date
    }
    let checkAddExistsbody = {
      'namc': this.namc,
      'family': this.family,
      'slctvTyp': this.selectivity,
      'partNo': this.partNumber,
      'parPartNo': this.parentPart,
      'partSrcRtgId': this.completePart,
      // 'rep_slctv_rule_typ_id': this.selectivityRuleVIn,
      // 'vin_slctv_rule_typ_id': this.selectivityRuleRep,
      'timeIn': indateFormat,
      'timeOut': outdateFormat,
      // 'comment': this.comments,
      'grpiCD': this.grpi,
      "screenNo":"NBPC_2230",
      "fileName" :fileName
    }
    this.SpinnerService.show();
    this.SelectivityPartAddDialogService.checkaddExists(checkAddExistsbody).subscribe(data => {
      this.response = data.payload;
      this.SpinnerService.hide();
      if (this.response[0].result == 'success') {
        this.selectiviyAdd();
      } else if (this.response[0].result == 'Already Exists') {
        // this.dialogRef.close(this.response[0].result);
        this.errorMessage = 'Data Already Exists!';
      }
    }, err => {
      this.SpinnerService.hide();
    });
  }

  selectiviyAdd() {
    let fileName= localStorage.getItem('Filename')
    let indateFormat = ''; let outdateFormat = '';
    if (this.extTimeIn != "") {
      let d1 = moment(this.extTimeIn).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.extTimeIn = date
    }
    if (this.extTimeOut != "") {
      let d1 = moment(this.extTimeOut).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.extTimeOut = date
    }
    let user = localStorage.getItem('workDayId');
    let cmts;
    if (this.comments!= undefined) {
      cmts = this.comments.replaceAll("'", "''");
    } else {
      cmts = this.comments
    }
    let body = {
      'namc': this.namc,
      'family': this.family,
      'slctvtyp': this.selectivity,
      'partNo': this.partNumber,
      'parentPartNo': this.parentPart,
      'cmp_rtg_cd': this.completePart,
      'rep_slctv_rule_typ_id': this.selectivityRuleRep,
      'vin_slctv_rule_typ_id': this.selectivityRuleVIn,
      'timeIn': indateFormat,
      'timeOut': outdateFormat,
      'comment': cmts,
      'grpiCD': this.grpi,
      "screenNo":"NBPC_2230",
      "fileName" :fileName
      
    }
    this.SpinnerService.show();
    this.SelectivityPartAddDialogService.addSelectivityPart(body).subscribe(data => {
      this.response = data.payload;
      this.SpinnerService.hide();
      if (this.response[0].result == 'success') {
        this.dialogRef.close(this.response[0].result);
      } else if (this.response[0].result == 'AlreadyExists') {
        // this.dialogRef.close(this.response[0].result);
        this.errorMessage = 'Data Entered already Exists!';
      }
    }, err => {
      this.SpinnerService.hide();
    });

  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'extTimeIn') {
      this.extTimeIn = "";
    } else if (name == 'extTimeOut') {
      this.extTimeOut = "";
    }
  }
}



