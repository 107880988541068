<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <h2 mat-dialog-title cdkDragHandle >Manual Job Trigger <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
    </h2>
    
    <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
      {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
    </div>
    <mat-dialog-content class="mat-typography">
      <div class="container">
        <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are mandatory</span>
        <!-- row1 -->
        <div class="row rowClass">
          <div class="col">
            <div class="row">
              <div class="col-4"> <label>Job Name<span class="text-danger">*</span>:</label></div>
              <div class=col-8><input type="text" [(ngModel)]="eParameterName" class="inputtext" disabled></div>
            </div>
          </div>
          
        <div class="col">
            <div class="row">
              <div class="col-4"> <label style="margin-top: 10px">Remark:</label></div>
              <div class=col-8><input type="text" [(ngModel)]="eParameterValue" class="inputtext"></div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <span class="text-danger" style="margin-left: 10px;">{{jobTime}}</span>
    <mat-dialog-actions align="center">
      <button mat-button cdkFocusInitial (click)="uploadParm()">Trigger</button>
    
      <button mat-button (click)="cancel()">Cancel</button>
    </mat-dialog-actions>
    </ng-container>
    <ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
    
      <p style="color: white">{{spinnerMessage}} </p>
    
    </ngx-spinner>