import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MajorPartTypeComponent } from '../major-part-type/major-part-type.component';
import { MajorPartTypeAddDialogComponent } from '../major-part-type-add-dialog/major-part-type-add-dialog.component';
import { MajorPartTypeEditDialogComponent } from '../major-part-type-edit-dialog/major-part-type-edit-dialog.component';
import {ReferenceModule} from '../reference.module';


@NgModule({
  declarations: [
    MajorPartTypeComponent,
    MajorPartTypeAddDialogComponent,
    MajorPartTypeEditDialogComponent,
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class MajPartTypModule { }
