import { Component, OnInit } from '@angular/core';
import { DialogData } from '../dialog-data/dialog-data';
import { NgxSpinnerService } from "ngx-spinner";  

@Component({
  selector: 'app-rep-vehicle-view-dialog',
  templateUrl: './rep-vehicle-view-dialog.component.html',
  styleUrls: ['./rep-vehicle-view-dialog.component.css']
})
export class RepVehicleViewDialogComponent implements OnInit {

  vMonthlyRep:any;
  vCompBom:any;
  vModelNumber:any;
  vOption:any;
  vSapMatId:any;
  vNamc:any;
  vFamily:any;
  vKatashiki:any;
  vCustomerId:any;
  vDest:any;
  vHikiate:any;
  vSP:any;
  vPcSPec:any;
  vCompPcSpec:any;
  vNetSpec:any;
  vHundPercentSpec:any;
  vCompHundPercentSpec:any;
  vExtractionTimeIn:any;
  vExtractionTimeOut:any;
  vComments:any;

  spinnerMessage="Please Wait...";

  constructor(public data: DialogData,private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.vMonthlyRep=this.data.monthlyRep;
    this.vCompBom=this.data.compBom;
    this.vModelNumber=this.data.modelNumber;
    this.vOption=this.data.option;
    this.vSapMatId=this.data.sapMatId;
    this.vNamc=this.data.namc;
    this.vFamily=this.data.family;
    this.vKatashiki=this.data.katashiki;
    this.vCustomerId=this.data.customerId;
    this.vDest=this.data.dest;
    this.vHikiate=this.data.hikiate;
    this.vSP=this.data.sp;
    this.vPcSPec=this.data.pcSpec;
    this.vCompPcSpec=this.data.compPCSpec;
    this.vNetSpec=this.data.netSpec;
    this.vHundPercentSpec=this.data.hundrentPerSpec;
    this.vCompHundPercentSpec=this.data.compHundPerSpec;
    this.vExtractionTimeIn=this.data.extractionTimeIn;
    this.vExtractionTimeOut=this.data.extractionTimeOut;
    this.vComments=this.data.comments;
  }

}
