import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartSourceRoutingComponent } from '../part-source-routing/part-source-routing.component'
import { PartSourceRoutingUploadDialogComponent } from '../part-source-routing-upload-dialog/part-source-routing-upload-dialog.component';
import { PartSourceRoutingEditDialogComponent } from '../part-source-routing-edit-dialog/part-source-routing-edit-dialog.component';
import { PartSourceRoutingViewDialogComponent } from '../part-source-routing-view-dialog/part-source-routing-view-dialog.component';

import {ReferenceModule} from '../reference.module';


@NgModule({
  declarations: [
    PartSourceRoutingComponent,
    PartSourceRoutingUploadDialogComponent,
    PartSourceRoutingEditDialogComponent,
    PartSourceRoutingViewDialogComponent,
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class PartSrcRtgModule { }
