import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import * as print from 'print-js';
import { ProductWithinVersion } from '../dialog-data/product-within-version';
import { GenerateRepVerSumDialogService } from './generate-rep-ver-sum-dialog.service'
import { MatTableDataSource } from '@angular/material/table';
import { ExportToExcelService } from '../common/export-to-excel.service';
// import * as $ from 'jquery';
// import * as  jqGrid  from 'jquery-grid'
declare var require


// var $ = require('jquery');
// require('jqgrid')($)




@Component({
  selector: 'app-generate-rep-ver-sum-dialog',
  templateUrl: './generate-rep-ver-sum-dialog.component.html',
  styleUrls: ['./generate-rep-ver-sum-dialog.component.css']
})
export class GenerateRepVerSumDialogComponent implements OnInit {
  verName: any;
  productTyp: any;
  spans: any[];
  fileName: string = "Rep Version Summary"
  sheetName = ['Rep Version Summary'];
  exportData = [];
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: ProductWithinVersion,
    public dialogRef: MatDialogRef<GenerateRepVerSumDialogComponent>,
    private generateRepVerService: GenerateRepVerSumDialogService,
    private SpinnerService: NgxSpinnerService, private exportService: ExportToExcelService,) { }

  //ngx-datatable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = false;
  @ViewChild('genRepVer') table;
  limitSelected: any = 5;
  gridList: any = []
  veridlist: any
  showdata: any = true;
  displayedColumns: string[] = ['version_name', 'prod_stat_typ_cd', 'bom_proc_stat_typ_desc', 'namc_nm', 'count']
  dataSource = new MatTableDataSource<any>(this.gridList);
  columns = [
    { name: 'version', prop: 'version_name' }, { name: 'status', prop: 'prod_stat_typ_cd' },
    { name: 'statusDesc', prop: 'bom_proc_stat_typ_desc' }, { name: 'namc', prop: 'namc_nm' },
    { name: 'total', prop: 'count' }
  ];
  ColumnMode = ColumnMode;


  ngOnInit(): void {
    console.log("data", this.dialogData);
    let arrayvalue: any;
    arrayvalue = this.dialogData.versionName;
    console.log(arrayvalue.length, arrayvalue);
    let str = '';
    for (let i = 0; i < arrayvalue.length; i++) {
      if (str == '') {
        str = "'" + arrayvalue[i] + "'"
      } else {
        str += ',' + "'" + arrayvalue[i] + "'";
      }
      this.veridlist = str;
    }
    console.log(this.veridlist)
    this.verName = this.veridlist
    this.productTyp = this.dialogData.productType
    this.gridDetails();
    // this.getcount1();
    // this.getcount2();
    // this.getcount3();

  }

  gridDetails() {
    let fileName= localStorage.getItem('Filename')
    let flg = this.productTyp == "onHold" ? 'Y' : undefined
    this.SpinnerService.show()

    let body = {
      "versionNm": this.verName,
      "on_hold_flg": flg,
      "screenNo":"NBPC_10115",
      "fileName" :fileName
    }
    this.generateRepVerService.getRPSummarygrid(body).subscribe(res => {
      console.log("res from grid", res.payload);
      this.showdata = true
      this.rows = res.payload
      this.gridList = res.payload;
      this.formSpan();
      this.dataSource = new MatTableDataSource<any>(this.gridList);
      this.SpinnerService.hide()
    }, err => {
      this.rows = []
      this.showdata = false
      this.SpinnerService.hide()
      console.log("err", err);
      // this.dialogRef.close("Data not found");
    });

  }

  getcount1() {
    let fileName= localStorage.getItem('Filename')
    this.SpinnerService.show()
    let flg = this.productTyp == "onHold" ? 'Y' : undefined
    let body = {
      "versionNm": this.verName,
      "on_hold_flg": flg,
      "screenNo":"NBPC_10115",
      "fileName" :fileName
    }
    this.generateRepVerService.getRPSummarycount1(body).subscribe(res => {
      console.log("res from grid", res.payload);
      this.SpinnerService.hide()
    })
  }

  getcount2() {
    let fileName= localStorage.getItem('Filename')
    this.SpinnerService.show()
    let flg = this.productTyp == "onHold" ? 'Y' : undefined

    let body = {
      "versionNm": this.verName,
      "on_hold_flg": flg,
      "screenNo":"NBPC_10115",
      "fileName" :fileName

    }
    this.generateRepVerService.getRPSummarycount2(body).subscribe(res => {
      console.log("res from grid", res.payload);
      this.SpinnerService.hide()
    })
  }
  getcount3() {
    let fileName= localStorage.getItem('Filename')
    this.SpinnerService.show()
    let flg = this.productTyp == "onHold" ? 'Y' : undefined

    let body = {
      "versionNm": this.verName,
      "on_hold_flg": flg,
      "screenNo":"NBPC_10115",
      "fileName" :fileName

    }
    this.generateRepVerService.getRPSummarycount3(body).subscribe(res => {
      console.log("res from grid", res.payload);
      this.SpinnerService.hide()
    })
  }


  formSpan() {


    this.spans = [];
    let data = []
    console.log("data in before", data);
    let Obj = {
      "version_name": '',
      "prod_stat_typ_cd": '',
      'namc_nm': '',
      "bom_proc_stat_typ_desc": '',
      "count": ''
    }
    data.push(Obj)

    let rowCnt = 0;
    let finalCnt = 0;
    let statusCnt = 0;
    let descCnt = 0;
    let versiontotal = 0;
    for (let i = 0; i < this.gridList.length; i++) {
      if (i == 0) {
        data[rowCnt].version_name = this.gridList[i].version_name;
        data[rowCnt].prod_stat_typ_cd = this.gridList[i].prod_stat_typ_cd
        data[rowCnt].bom_proc_stat_typ_desc = this.gridList[i].bom_proc_stat_typ_desc
        data[rowCnt].namc_nm = this.gridList[i].namc_nm
        data[rowCnt].count = this.gridList[i].count
        descCnt += Number(this.gridList[i].count)
        statusCnt += Number(this.gridList[i].count)
        finalCnt += Number(this.gridList[i].count)
        versiontotal += Number(this.gridList[i].count)
        rowCnt++;
        console.log("data in 165", data);
      }
      else {
        if (this.gridList[i - 1].version_name != this.gridList[i].version_name) {
          console.log("rowCnt 170", rowCnt);
          let Obj1 = {
            "version_name": '',
            "prod_stat_typ_cd": '',
            'namc_nm': '',
            "bom_proc_stat_typ_desc": this.gridList[i - 1].bom_proc_stat_typ_desc + ' Total',
            "count": descCnt
          }
          data.push(Obj1)
          descCnt = 0;
          rowCnt++;
          let Obj = {
            "version_name": '',
            "prod_stat_typ_cd": this.gridList[i - 1].prod_stat_typ_cd + ' Total',
            'namc_nm': '',
            "bom_proc_stat_typ_desc": '',
            "count": statusCnt
          }
          data.push(Obj)
          statusCnt = 0;
          descCnt = 0;
          rowCnt++;
          let Obj4 = {
            "version_name": this.gridList[i - 1].version_name + ' Total',
            "prod_stat_typ_cd": '',
            'namc_nm': '',
            "bom_proc_stat_typ_desc": '',
            "count": versiontotal
          }
          data.push(Obj4)
          descCnt = 0;
          statusCnt = 0;
          versiontotal = 0;
          rowCnt++;
          let Obj2 = {
            "version_name": '',
            "prod_stat_typ_cd": '',
            'namc_nm': this.gridList[i].namc_nm,
            "bom_proc_stat_typ_desc": '',
            "count": this.gridList[i].count
          }
          data.push(Obj2)
          data[rowCnt].version_name = this.gridList[i].version_name;
          data[rowCnt].prod_stat_typ_cd = this.gridList[i].prod_stat_typ_cd
          data[rowCnt].bom_proc_stat_typ_desc = this.gridList[i].bom_proc_stat_typ_desc
          data[rowCnt].namc_nm = this.gridList[i].namc_nm
          data[rowCnt].count = this.gridList[i].count
          descCnt += Number(this.gridList[i].count)
          statusCnt += Number(this.gridList[i].count)
          finalCnt += Number(this.gridList[i].count)
          versiontotal += Number(this.gridList[i].count)
          rowCnt++;
          console.log("data in 189", data);
        }


        else if (this.gridList[i - 1].bom_proc_stat_typ_desc == this.gridList[i].bom_proc_stat_typ_desc) {
          console.log("rowCnt 170", rowCnt);
          let Obj1 = {
            "version_name": '',
            "prod_stat_typ_cd": '',
            'namc_nm': this.gridList[i].namc_nm,
            "bom_proc_stat_typ_desc": '',
            "count": this.gridList[i].count
          }
          data.push(Obj1)
          descCnt += Number(this.gridList[i].count)
          statusCnt += Number(this.gridList[i].count)
          finalCnt += Number(this.gridList[i].count)
          versiontotal += Number(this.gridList[i].count)
          rowCnt++;
          console.log("data in 178", data);
        }
        else if (this.gridList[i - 1].prod_stat_typ_cd == this.gridList[i].prod_stat_typ_cd) {
          console.log("rowCnt 179", rowCnt);
          let Obj1 = {
            "version_name": '',
            "prod_stat_typ_cd": '',
            'namc_nm': '',
            "bom_proc_stat_typ_desc": this.gridList[i - 1].bom_proc_stat_typ_desc + ' Total',
            "count": descCnt
          }
          data.push(Obj1)
          descCnt = 0;
          rowCnt++;

          let Obj2 = {
            "version_name": '',
            "prod_stat_typ_cd": '',
            'namc_nm': this.gridList[i].namc_nm,
            "bom_proc_stat_typ_desc": this.gridList[i].bom_proc_stat_typ_desc,
            "count": this.gridList[i].count
          }
          data.push(Obj2)
          descCnt += Number(this.gridList[i].count)
          statusCnt += Number(this.gridList[i].count)
          finalCnt += Number(this.gridList[i].count)
          versiontotal += Number(this.gridList[i].count)
          rowCnt++;
          console.log("data in 193", data);
        }

        else {
          // write obj
          let Obj1 = {
            "version_name": '',
            "prod_stat_typ_cd": '',
            'namc_nm': '',
            "bom_proc_stat_typ_desc": this.gridList[i - 1].bom_proc_stat_typ_desc + ' Total',
            "count": descCnt
          }
          data.push(Obj1)
          descCnt = 0;
          rowCnt++;
          console.log("rowCnt else", rowCnt);
          console.log("data in 197", data);
          let Obj = {
            "version_name": '',
            "prod_stat_typ_cd": this.gridList[i - 1].prod_stat_typ_cd + ' Total',
            'namc_nm': '',
            "bom_proc_stat_typ_desc": '',
            "count": statusCnt
          }
          data.push(Obj)
          statusCnt = 0;
          descCnt = 0;
          rowCnt++;
          let Object = {
            "version_name": '',
            "prod_stat_typ_cd": this.gridList[i].prod_stat_typ_cd,
            'namc_nm': this.gridList[i].namc_nm,
            "bom_proc_stat_typ_desc": this.gridList[i].bom_proc_stat_typ_desc,
            "count": this.gridList[i].count
          }
          data.push(Object)
          descCnt += Number(this.gridList[i].count)
          statusCnt += Number(this.gridList[i].count)
          finalCnt += Number(this.gridList[i].count)
          versiontotal += Number(this.gridList[i].count)
          rowCnt++;
          console.log("data in 211", data);
        }
      }
    }

    if (this.gridList.length > 0) {
      let Obj1 = {
        "version_name": '',
        "prod_stat_typ_cd": '',
        'namc_nm': '',
        "bom_proc_stat_typ_desc": this.gridList[this.gridList.length - 1].bom_proc_stat_typ_desc + ' Total',
        "count": descCnt
      }
      data.push(Obj1)
      descCnt = 0;
      rowCnt++;
      console.log("rowCnt else", rowCnt);
      console.log("data in 197", data);
      let Obj = {
        "version_name": '',
        "prod_stat_typ_cd": this.gridList[this.gridList.length - 1].prod_stat_typ_cd + ' Total',
        'namc_nm': '',
        "bom_proc_stat_typ_desc": '',
        "count": statusCnt
      }
      data.push(Obj)
      let Obj4 = {
        "version_name": this.gridList[this.gridList.length - 1].version_name + ' Total',
        "prod_stat_typ_cd": '',
        'namc_nm': '',
        "bom_proc_stat_typ_desc": '',
        "count": versiontotal
      }
      data.push(Obj4)
      descCnt = 0;
      statusCnt = 0;
      versiontotal = 0;
      rowCnt++;
      let Object = {
        //  "version_name": this.gridList[0].version_name + ' Total',
        "version_name": 'Grand Total',
        "prod_stat_typ_cd": '',
        'namc_nm': '',
        "bom_proc_stat_typ_desc": '',
        "count": finalCnt
      }
      data.push(Object)
    }

    console.log("data", data);
    this.gridList = data
    console.log("gridlist", this.gridList);

  }



  print(): void {
    print({ printable: 'summRep', type: 'html' });
  }

  Exportexcel() {
    this.gridList.forEach(row => {

      let obj =
      {
        "Version": row.version_name,
        "Status": row.prod_stat_typ_cd,
        "Status Description": row.bom_proc_stat_typ_desc,
        "Namc": row.namc_nm,
        "Total": row.count

      }
      this.exportData.push(obj);
    });
    this.exportService.exportAsExcelFile(this.exportData, this.fileName, this.sheetName);
  }

}

