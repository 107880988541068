import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartSourceComponent } from '../part-source/part-source.component';
import {ReferenceModule} from '../reference.module';


@NgModule({
  declarations: [
    PartSourceComponent
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class PartSrcModule { }
