import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DialogData} from '../dialog-data/dialog-data';
import { NgxSpinnerService } from "ngx-spinner";  

@Component({
  selector: 'app-rep-units-view-dialog',
  templateUrl: './rep-units-view-dialog.component.html',
  styleUrls: ['./rep-units-view-dialog.component.css']
})
export class RepUnitsViewDialogComponent implements OnInit {

  //ngModel Values
  viewMonthlyRep: any;
  viewSapMtrlId: any;
  viewNamc: any;
  viewFamily: any;
  viewPart: any;
  viewPartDesc: any ;
  viewRelKatashiki: any;
  viewProdType: any;
  viewGrpi: any;
  viewExtTimeIn: any;
  viewExtTimeOut: any;
  viewComments: any;

  spinnerMessage="Please Wait...";

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
  private SpinnerService: NgxSpinnerService) { }

  ngOnInit() {
    console.log("data",this.data);
    
    this.viewMonthlyRep=this.data.monthlyRep;
    this.viewSapMtrlId=this.data.sapMatId;
    this.viewNamc=this.data.namc;
    this.viewFamily=this.data.family;
    this.viewPart=this.data.part;
    this.viewPartDesc=this.data.partDesc;
    this.viewRelKatashiki=this.data.katashiki;
    this.viewProdType=this.data.prodType;
    this.viewExtTimeIn=this.data.extractionTimeIn;
    this.viewExtTimeOut=this.data.extractionTimeOut;
    this.viewComments=this.data.comments;  
    this.viewGrpi =this.data.grpi;
  }

}
