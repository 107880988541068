<span *ngIf="accessEnable">
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>


<div class="alert alert-success alert-dismissible " *ngIf="successMessage!=''" role="alert">
  {{successMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>

<mat-accordion class="example-headers-align">
  <mat-expansion-panel [expanded]="step === 0" (opened)="(0)" hideToggle>

    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Search</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-form-field>
      <mat-label>Family</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="family" name="family">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let family of familyLst" [value]="family.fam_cd">{{family.fam_cd}}</mat-option>
      </mat-select>
    </mat-form-field>

      <mat-form-field class="picker">
        <mat-label>Effective In Date</mat-label>
        <input matInput [matDatepicker]="inPicker" [(ngModel)]="effectiveInDate">
        <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
        <mat-datepicker #inPicker></mat-datepicker>
        <mat-icon matDatepickerToggleIcon class="dateclear"
          (click)="clearDate($event,'effectiveInDate')">clear</mat-icon>
      </mat-form-field>
      <mat-form-field class="picker">
        <mat-label>Effective Out Date</mat-label>
        <input matInput [matDatepicker]="outPicker" [(ngModel)]="effectiveOutDate">
        <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
        <mat-datepicker #outPicker></mat-datepicker>
        <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'effectiveOutDate')">clear
        </mat-icon>
      </mat-form-field>

    <mat-form-field>
      <mat-label>Car Family Flag</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="carFamilyFlag" name="carFamilyFlag">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let carFamilyFlag of carFamilyFlagLst" [value]="carFamilyFlag.car_fam_flag">
          {{carFamilyFlag.car_fam_flag}}</mat-option>
      </mat-select>
    </mat-form-field>



    <button mat-button style="float: right;" (click)="resetAll('initial')">Reset</button>
    <button mat-button style="float: right;" (click)="search()">Search</button>
  </mat-expansion-panel>
</mat-accordion>

<div class="d-flex">
  <button mat-raised-button (click)="openEditDialog()">Edit </button>
  <button mat-raised-button (click)="openViewDialog()">View </button>
  <button mat-raised-button (click)="exportFile()" class="ms-auto"> Export To Excel </button>
</div>

<!-- <mat-card> -->
<ngx-datatable #myTable class="material striped" [rows]="rows" [columnMode]="ColumnMode.force" [headerHeight]="45"
  [footerHeight]="30" rowHeight="30" [limit]="limitSelected" [selected]="selected"
  [selectionType]="SelectionType.checkbox" [selectAllRowsOnPage]="false" [displayCheck]="displayCheck"
  (activate)="onActivate($event)" (select)="onSelect($event)"
  [ngClass]="{'hideerror': showdata, 'showerror': !showdata }">
  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
      let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
      <div class="page-count">
        {{selectedCount}} selected / {{rowCount}} total
      </div>
      <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
        (change)="table.onFooterPage($event)">
      </datatable-pager>
      <div class="test">
        <select [(ngModel)]="limitSelected">
          <option>50</option>
          <option>100</option>
          <option>500</option>
        </select>
        <div class="float-right records">View {{(offset * pageSize)+1}} - {{(rowCount>
          (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
          {{(rowCount)}}

          </div>
        </div>
      </ng-template>
    </ngx-datatable-footer>
    <ngx-datatable-column [width]="10" [sortable]="false" [canAutoResize]="false" [draggable]="false"
      [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true">
    </ngx-datatable-column>

    <ngx-datatable-column name="Family" prop="fam_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Series" prop="series_nm"></ngx-datatable-column>
    <ngx-datatable-column name="Generation" prop="gnrt_nm"></ngx-datatable-column>
    <ngx-datatable-column name="Family Description" prop="fam_desc"></ngx-datatable-column>
    <ngx-datatable-column name="Car Family Flag">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value" prop="carFamilyFlag">
        <input type="checkbox" [checked]="row.car_fam_flag=='Y'" disabled />
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Effective In Date" prop="eff_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Effective Out Date" prop="expr_dt"></ngx-datatable-column>
  </ngx-datatable>
</span>
<div *ngIf="!accessEnable">
  <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>
