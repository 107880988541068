<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
<h2 mat-dialog-title cdkDragHandle >View <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
</h2>

<mat-dialog-content class="mat-typography">
  <div class="container">

    <!-- row1 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>NAMC:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="viewnamc" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Family:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="viewfamily" disabled></div>
        </div>
      </div>
    </div>
    <!-- row2 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Katashiki:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="viewkatashiki" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Dest:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="viewdestination" disabled></div>
        </div>
      </div>
    </div>

    <!-- row3 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Hikiate Status:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="viewhikiate" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>PC Spec:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="viewpcSpec" disabled></div>
        </div>
      </div>
    </div>

    <!-- row5 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Time In-Date:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="viewtimeInDt" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Time Out-Date:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="viewtimeOutDt" disabled></div>
        </div>
      </div>
    </div>

    <!-- row6 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>VDW Effective In:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="viewvdwEffIn" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>VDW Effective Out:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="viewvdwEffOut" disabled></div>
        </div>
      </div>
    </div>

    <!-- row7 -->
    <div class="row rowClass">
      <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
      <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review" rows="4" cols="50"
          [(ngModel)]="viewcomments" disabled>
          </textarea>
      </div>
    </div>


    <!-- row8 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>100% Spec Id:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="viewhundPercent" disabled></div>
        </div>
      </div>
    </div>


  </div>

</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button mat-dialog-close>Ok</button>
</mat-dialog-actions>
</ng-container>


<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">

  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>