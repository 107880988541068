import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { DialogData } from '../dialog-data/dialog-data';
import { FamilyProjectNamcService } from './family-project-namc.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FamilyProjectNamcUploadDialogComponent } from '../family-project-namc-upload-dialog/family-project-namc-upload-dialog.component';
import { FamilyProjectNamcEditDialogComponent } from '../family-project-namc-edit-dialog/family-project-namc-edit-dialog.component';
import { FamilyProjectNamcViewDialogComponent } from '../family-project-namc-view-dialog/family-project-namc-view-dialog.component';
import { FamilyProjectNamcAddDialogComponent } from '../family-project-namc-add-dialog/family-project-namc-add-dialog.component';
import { FamilyProjectNamcMultieditDialogComponent } from '../family-project-namc-multiedit-dialog/family-project-namc-multiedit-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-family-project-namc',
  templateUrl: './family-project-namc.component.html',
  styleUrls: ['./family-project-namc.component.css']
})
export class FamilyProjectNamcComponent implements OnInit {


  //alertMessages
  errorMessage: string = '';
  successMessage: string = '';


  //export to excel
  fileName = 'FamilyProjectNamc';
  exportData = [];
  sheetName = [];
  errorSheetName = ['FAM_PRO_ERROR'];
  errorFileName = 'FamProError';

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');
  roleAccess: any = []
  Roledata: any = [];

  //ngModel values for search  
  step = 0;
  namc: any = "";
  fam: any = "";
  project: any = "";
  modelYear: any = "";
  effDateIn: any = "";
  effDateOut: any = "";
  hikiate: any = "";
  prodDateIn: any = "";
  prodDateOut: any = "";
  modelChangeType: any = "";
  comments: any = "";
  ssn: any = "";
  generation: any = "";
  lineOffPlant: any = "";
  spinnerMessage = "Please Wait...";
  recordcount: any = 0;
  sameLengthRows: boolean = false;
  accessEnable: boolean;
  constructor(private familyProjectNamcService: FamilyProjectNamcService, public dialog: MatDialog, private exportService: ExportToExcelService,
    private dialogData: DialogData, private SpinnerService: NgxSpinnerService, public globalrole: GlobalService) {

  }
  async ngOnInit() {
    this.SpinnerService.show();
    let a = await this.userroleAccessfn();
    if (a) {
      console.log('a', a)
      //grid
      this.getGridData("initial");
    }
    this.onloadfn();
  }

  onloadfn() {
    //to get namc
    this.familyProjectNamcService.getNamcFpn().subscribe(data => {
      console.log(data);
      this.namcLst = data.payload;
    });


    //to get family
    this.familyProjectNamcService.getFamilyFpn(this.namc).subscribe(data => {
      console.log(data);
      this.famLst = data.payload;

    });

    //to get project
    this.familyProjectNamcService.getProjectFpn(this.namc, this.fam).subscribe(data => {
      console.log(data);
      this.projectLst = data.payload;
    });

    //to get hikiateStatus
    this.familyProjectNamcService.getHikiateStatusFpn().subscribe(data => {
      console.log(data);
      this.hikiateLst = data.payload;
    });
    //to get modelChangeType
    this.familyProjectNamcService.getModelChangeTypefpn().subscribe(data => {
      console.log(data);
      this.modelChangeTypeLst = data.payload;
    });
    //to get modelYear
    this.familyProjectNamcService.getModelYearFpn(this.namc, this.fam, this.project).subscribe(data => {
      console.log(data);
      this.modelYearLst = data.payload;
    });

  }

  //List
  namcLst = [];
  famLst = [];
  projectLst = [];
  modelYearLst = [];
  hikiateLst = [];
  modelChangeTypeLst = [];

  //step for expansion panel
  setStep(index: number) {
    this.step = index;




  }
  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }


  resetAll(data) {
    this.selected = []
    this.namc = '';
    this.fam = '';
    this.project = '';
    this.modelYear = '';
    this.effDateIn = '';
    this.effDateOut = '';
    this.hikiate = '';
    this.modelChangeType = '';
    this.prodDateIn = '';
    this.prodDateOut = '';
    this.comments = '';
    this.errorMessage = "";
    this.successMessage = '';
    // this.getGridData(data);
    this.onloadfn();
  }

  search() {


    if (this.effDateIn && this.effDateOut && this.pipe.transform(this.effDateIn, 'yyyy-MM-dd') > this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')) {
      this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";
      this.globalrole.goToTop();
    }
    else if (this.prodDateIn && this.prodDateOut && this.pipe.transform(this.prodDateIn, 'yyyy-MM-dd') > this.pipe.transform(this.prodDateOut, 'yyyy-MM-dd')) {
      this.errorMessage = "Prod Time In should be smaller than Prod Time Out";
      this.globalrole.goToTop();
    }
    else {
      this.errorMessage = '';
      this.successMessage = "";
      this.getGridData("initial");
    }
  }

  //ngx-datatable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;
  showdata: boolean = true;
  columns = [
    { name: 'namc', prop: 'namc_cd' }, { name: 'family', prop: 'fam_cd' }, { name: 'project', prop: 'proj_cd' },
    { name: 'modelYear', prop: 'mdl_yr' }, { name: 'ssn', prop: 'spec_sheet_no' }, { name: 'generation', prop: 'gnrt_id' },
    { name: 'lineOffPlant', prop: 'line_off_plnt' }, { name: 'effDateIn', prop: 'xtraction_timein_dt' }, { name: 'effDateOut', prop: 'extraction_timeout_dt' },
    { name: 'prodDateIn', prop: 'prod_strt_dt' }, { name: 'prodDateOut', prop: 'prod_end_dt' }, { name: 'hikiate', prop: 'hikiate_stat_comb_cd' },
    { name: 'modelChangeType', prop: 'proj_chg_typ_cd' }, { name: 'comments', prop: 'rmrk_txt' }
  ];
  ColumnMode = ColumnMode;



  //ngx-table
  onSelect({ selected }) {
    this.selected = selected;
    if (this.selected.length == selected.length) {
      this.sameLengthRows = true
    }
    if (this.selected.length == 0) {
      this.sameLengthRows = false
    }
    console.log('Select Event', selected, this.selected);

    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
  }
  onActivate(event) {
    // console.log('Activate Event', event);
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }

  openAddDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    console.log(this.roleAccess.includes('Create'))
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(FamilyProjectNamcAddDialogComponent, { disableClose: true });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');

        if (result == 'success') {
          this.getGridData("add");
          this.successMessage = "Record Added Successfully"
          this.selected = []
        }
        else if (result == 'AlreadyExists') {
          this.resetAll("initial");
          this.errorMessage = 'Data Entered already Exists!'
          this.globalrole.goToTop();
        }
        else {
          // this.errorMessage = 'Data not inserted/Valid'
        }
      });
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }

  }




  openViewDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    console.log(this.roleAccess.includes('Read'))
    if (this.roleAccess.includes('Read')) {
      if (this.selected.length == 1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(FamilyProjectNamcViewDialogComponent, {
          disableClose: true,
          data: this.dialogData

        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');

        });
      }
      else {
        if (this.sameLengthRows == true) {
          this.errorMessage = "please select only one row to view"
          this.globalrole.goToTop();
        }
        if (this.sameLengthRows == false) {
          this.errorMessage = "please select a row to view"
          this.globalrole.goToTop();
        }
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }



  openUploadDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    console.log(this.roleAccess.includes('Create'))
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(FamilyProjectNamcUploadDialogComponent, {
        disableClose: true,
        // width: '50%'
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        if (result == 'success') {
          this.getGridData("add");
          this.successMessage ='Upload Process Started, Uploaded Information will be share via Mail'
        }
        else if (result == 'cancel') {
          this.successMessage = ''
          this.errorMessage = ''
        }
        else {
          this.errorMessage = result
          this.globalrole.goToTop();
        }
      })
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }
  }

  openEditDialog(): void {
    console.log(this.selected, this.selected.length)
    this.errorMessage = '';
    this.successMessage = '';
    console.log(this.roleAccess.includes('Update'))
    if (this.roleAccess.includes('Update')) {
      if (this.selected.length == 1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(FamilyProjectNamcEditDialogComponent, {
          disableClose: true,
          data: this.dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');

          if (result == 'success') {
            this.getGridData("add");
            this.successMessage = "Records Updated Successfully"
            this.selected = []
          }
          else if (result == 'AlreadyExists') {
            this.resetAll("initial");
            this.errorMessage = 'Data Entered already Exists!'
            this.globalrole.goToTop();
          }
          else {
            // this.errorMessage = 'Data not inserted/Valid'
          }

        });

      }
      else if (this.selected.length > 1) {
        const dialogRef = this.dialog.open(FamilyProjectNamcMultieditDialogComponent, {
          disableClose: true,
          data: this.selected
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          this.selected = [];
          if (result == 'success') {
            this.getGridData("add");
            this.successMessage = "Record Updated Successfully"
            this.selected = []
          }
          else if (result == 'AlreadyExists') {
            this.resetAll("initial");
            this.errorMessage = 'Data Entered already Exists!'
            this.globalrole.goToTop();
          }
          else {
            // this.errorMessage = 'Data not inserted/Valid'
          }

        });
      }
      else {
        this.errorMessage = "Please select a row to edit";
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
    }
  }

  //onchnge
  getFamByNamc(): void {
    if (this.namc == undefined) {
      this.namc = ""
    }
    this.familyProjectNamcService.getFamilyFpn(this.namc).subscribe(data => {
      console.log(data);
      this.famLst = data.payload;
      this.fam = ""
      this.project = ""
      this.modelYear = ""
    });
  }
  getProjByFam(): void {
    if (this.namc == undefined) {
      this.namc = ""
    }
    if (this.fam == undefined) {
      this.fam = ""
    }
    this.familyProjectNamcService.getProjectFpn(this.namc, this.fam).subscribe(data => {
      console.log(data);
      this.projectLst = data.payload;
      this.project = ""
    });
  }

  getModByProj(): void {
    if (this.namc == undefined) {
      this.namc = ""
    }
    if (this.fam == undefined) {
      this.fam = ""
    }
    if (this.project == undefined) {
      this.project = ""
    }
    this.familyProjectNamcService.getModelYearFpn(this.namc, this.fam, this.project).subscribe(data => {
      console.log(data);
      this.modelYearLst = data.payload;
      this.modelYear = ""
    });


  }
  getGridData(data) {
    this.selected = []
    console.log(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      if (this.fam == undefined) {
        this.fam = ""
      }
      if (this.namc == undefined) {
        this.namc = ""
      }
      if (this.project == undefined) {
        this.project = ""
      }
      if (this.modelYear == undefined) {
        this.modelYear = ""
      }
      if (this.hikiate == undefined) {
        this.hikiate = ""
      }
      if (this.modelChangeType == undefined) {
        this.modelChangeType = ""
      }
      let indateFormat = ''; let outdateFormat = '';
      if (this.effDateIn != "") {
        let d1 = moment(this.effDateIn).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.effDateIn = date
      }
      if (this.effDateOut != "") {
        let d1 = moment(this.effDateOut).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.effDateOut = date
      }
      let prodDateINFormat = ''; let prodDateOutFormat = '';
      if (this.prodDateIn != "") {
        let d1 = moment(this.prodDateIn).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        prodDateINFormat = moment(date).format('YYYY-MM-DD')
        this.prodDateIn = date

      }
      if (this.prodDateOut != "") {
        let d1 = moment(this.prodDateOut).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        prodDateOutFormat = moment(date).format('YYYY-MM-DD')
        this.prodDateOut = date
      }
      let gridData
      let cmts;
    if (this.comments!= undefined) {
      cmts = this.comments.replaceAll("'", "''");
    } else {
      cmts = this.comments
    }
      if (data == 'initial') {
        gridData =
        {
          namc: this.namc,
          fam: this.fam,
          project: this.project,
          modelYear: this.modelYear,
          ssn: this.ssn,
          generation: this.generation,
          lineOffPlant: this.lineOffPlant,
          effectiveDateIn: indateFormat,
          effectiveDateOut: outdateFormat,
          prodDateIn: prodDateINFormat,
          prodDateOut: prodDateOutFormat,
          hikiate: this.hikiate,
          modelChangeType: this.modelChangeType,
          comments: cmts,
          option: data
        }
      } else {
        gridData =
        {
          namc: "",
          fam: "",
          project: "",
          modelYear: "",
          ssn: "",
          generation: "",
          lineOffPlant: "",
          effectiveDateIn: "",
          effectiveDateOut: "",
          prodDateIn: "",
          prodDateOut: "",
          hikiate: "",
          modelChangeType: "",
          comments: "",
          option: data
        }
      }
      this.SpinnerService.show();
      this.familyProjectNamcService.getFamilyProjNamcGrid(gridData).subscribe(data => {
        console.log(data);
        this.showdata = true;
        console.log(this.showdata);
        this.SpinnerService.hide();
        this.recordcount = data.pagination.totalRecords;
        let datarow = data.payload;
        this.rows = datarow.map(row => ({
          fam_cd: row['fam_cd'],
          proj_cd: row['proj_cd'],
          mdl_yr: row['mdl_yr'],
          proj_chg_typ_cd: row['proj_chg_typ_cd'],
          namc_cd: row['namc_cd'],
          hikiate_stat_comb_cd: row['hikiate_stat_comb_cd'],
          spec_sheet_no: row['spec_sheet_no'],
          gnrt_id: row['gnrt_id'],
          line_off_plnt: row['line_off_plnt'],
          prod_strt_dt: (row['prod_strt_dt'] == null) ? '' : row['prod_strt_dt'].split('T')[0],
          prod_end_dt: (row['prod_end_dt'] == null) ? '' : row['prod_end_dt'].split('T')[0],
          extraction_timein_dt: (row['eff_dt'] == null) ? '' : row['eff_dt'].split('T')[0],
          extraction_timeout_dt: (row['expr_dt'] == null) ? '' : row['expr_dt'].split('T')[0],
          rmrk_txt: row['rmrk_txt'],
          fam_gnrt_proj_relshp_id: row['fam_gnrt_proj_relshp_id']
        }));

        console.log(this.rows)

      }, err => {
        console.log(err);
        this.showdata = false;
        this.successMessage = "";
        console.log(this.showdata);
        this.rows = [];
        if (err == 0) {
          this.errorMessage = "Request timed out. Please try again.";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        } else {
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        }
      });
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }



  exportFile(): void {
    this.successMessage = '';
    this.errorMessage = '';
    this.rows.forEach(row => {
      let obj = {
        "Family": row.fam_cd,
        "Project": row.proj_cd,
        "Model Year": row.mdl_yr,
        "Model Change Type": row.proj_chg_typ_cd,
        "NAMC": row.namc_cd,
        "Hikiate Status": row.hikiate_stat_comb_cd,
        "SSN": row.spec_sheet_no,
        "Generation": row.gnrt_id,
        "Line Off Plant": row.line_off_plnt,
        "Prod Time In": row.prod_strt_dt,
        "ProdTime Out": row.prod_end_dt,
        "Extaction Time In": row.extraction_timein_dt,
        "Extraction Time Out": row.extraction_timeout_dt,
        "Comments": row.rmrk_txt,

      }
      this.exportData.push(obj);
    });

    this.exportService.exportAsExcelFile(this.exportData, this.fileName, this.sheetName);
    this.exportData = [];
  }

  closeAlert() {
    this.errorMessage = '';
    this.successMessage = '';


  }


  sendToDialog(selected): void {
    selected.forEach(row => {
      this.dialogData.family = row.fam_cd
      this.dialogData.project = row.proj_cd
      this.dialogData.modelYear = row.mdl_yr
      this.dialogData.ssn = row.spec_sheet_no
      this.dialogData.namc = row.namc_cd
      this.dialogData.lineOffPlant = row.line_off_plnt
      this.dialogData.hikiate = row.hikiate_stat_comb_cd
      this.dialogData.generation = row.gnrt_id
      this.dialogData.modelChangeType = row.proj_chg_typ_cd
      this.dialogData.prodDateIn = row.prod_strt_dt
      this.dialogData.prodDateOut = row.prod_end_dt
      this.dialogData.effectiveDateIn = this.pipe.transform(row.extraction_timein_dt, 'yyyy-MM-dd');
      this.dialogData.effectiveDateOut = this.pipe.transform(row.extraction_timeout_dt, 'yyyy-MM-dd');
      this.dialogData.comments = row.rmrk_txt,
        this.dialogData.fam_gnrt_proj_relshp_id = row.fam_gnrt_proj_relshp_id;

    });


    console.log(this.dialogData.monthlyRep)


  }

  errorExport(data) {
    this.exportData = [];
    data.forEach(row => {
      let obj = {
        "Family": row.family,
        "Project": row.project,
        "Model Year": row.modelYear,
        "Model Change Type": row.modelChangeType,
        "NAMC": row.namc,
        "Hikiate Status": row.hikiate,
        "SSN": row.ssn,
        "Generation": row.generation,
        "Line Off Plant": row.lineOffPlant,
        "Prod Time In": row.prodDateIn,
        "Prod Time Out": row.prodDateOut,
        "Extaction Time In": row.effDateIn,
        "Extaction Time Out": row.effDateOut,
        "Comments": row.comments,
        "Error": row.errorMessage
      }
      this.exportData.push(obj);
    });
    console.log(this.exportData)
    this.exportService.exportAsExcelFile(this.exportData, this.errorFileName, this.errorSheetName);
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'effDateIn') {
      this.effDateIn = "";
    } if (name == 'effDateOut') {
      this.effDateOut = "";
    }
    if (name == 'prodDateIn') {
      this.prodDateIn = "";
    }
    if (name == 'prodDateOut') {
      this.prodDateOut = "";
    }
  }

  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.log(res)
        this.Roledata = res;
      })
      console.log(this.Roledata);
      if (this.Roledata.length != 0) {
        this.Roledata.filter((res) => {
          if (res.function_nm == 'NBPC_9120') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length == 0) {
        let arrayvalue: any; let roleList: any;
        let data = localStorage.getItem('role');
        arrayvalue = data.split(',')
        console.log(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str == '') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        let body = {
          security_grp_nm: roleList
        }
        let userpreviliagedata=localStorage.getItem('userpreviliagedata')

          if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
          this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
            this.userroleAccessfn();
            this.accessEnable = true;
            resolve(true);
          }
        else {
          console.log(this.roleAccess)
          this.roleAccess = []
          this.errorMessage = "User does not have permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        }
      }
      console.log(this.roleAccess);
    });
  }
}





















