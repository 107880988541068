import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { PartSourceRoutingService } from './part-source-routing.service';
import { DialogData } from '../dialog-data/dialog-data';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PartSourceRoutingUploadDialogComponent } from '../part-source-routing-upload-dialog/part-source-routing-upload-dialog.component';
import { PartSourceRoutingEditDialogComponent } from '../part-source-routing-edit-dialog/part-source-routing-edit-dialog.component';
import { PartSourceRoutingViewDialogComponent } from '../part-source-routing-view-dialog/part-source-routing-view-dialog.component';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../global.service';


@Component({
  selector: 'app-part-source-routing',
  templateUrl: './part-source-routing.component.html',
  styleUrls: ['./part-source-routing.component.css']
})
export class PartSourceRoutingComponent implements OnInit {


  //export 
  fileName = 'PartSourceRouting';
  exportData = [];
  sheetName = [];
  errorSheetName = ['PART_SOU_ERROR'];
  errorFileName = 'PartSourceError';

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');



  //alertMessage
  errorMessage: string = '';
  successMessage: string = '';
  roleAccess: any = []
  Roledata: any = [];

  //ngModel  
  step = 0;
  namc: any = "";
  comPartRouting: any = "";
  partSource: any = "";
  partSourceRouting: any = "";
  effDateIn: any = "";
  effDateOut: any = "";
  comments: any = "";
  spinnerMessage = "Please Wait...";
  recordcount: any = 0;
  sameLengthRows: boolean = false;
  accessEnable: boolean;
  constructor(private SpinnerService: NgxSpinnerService, public globalrole: GlobalService, 
    private partSourceRoutingService: PartSourceRoutingService, public dialog: MatDialog, private exportService: ExportToExcelService,
    private dialogData: DialogData) { }

  //list
  namcLst = [];
  partSourceLst = []

  //ngx-datatable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('partSourceTable') table;
  limitSelected: any = 50;



  showdata: boolean = true;
  columns = [
    { name: 'namc', prop: 'namc_cd' }, { name: 'comPartRouting', prop: 'cmplt_rtg_cd' }, { name: 'partSourceRouting', prop: 'part_src_typ_cd' },
    { name: 'effDateIn', prop: 'eff_dt' }, { name: 'effDateOut', prop: 'expr_dt' }, { name: 'comments', prop: 'rmrk_txt' }
  ];
  ColumnMode = ColumnMode;

  async ngOnInit() {
    this.SpinnerService.show();
    let a = await this.userroleAccessfn();
    if (a) {
      console.log('a', a)
      //grid
      this.getGridData("initial");
    }
    this.onloadfn();
  }
  onloadfn() {
    //to get namc
    this.partSourceRoutingService.getNamc().subscribe(data => {
      console.log(data);
      this.namcLst = data.payload;
    });

    this.getpartsource();

  }

  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }


  resetAll(data) {
    this.selected = []
    this.namc = '';
    this.comPartRouting = '';
    this.comPartRouting = '';
    this.partSource = '';
    this.effDateIn = '';
    this.effDateOut = '';
    this.comments = '';
    this.errorMessage = "";
    this.successMessage = '';
    // this.getGridData(data);
    this.onloadfn();
  }

  search() {
    if (this.effDateIn && this.effDateOut && this.pipe.transform(this.effDateIn, 'yyyy-MM-dd') > this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')) {
      this.errorMessage = "Effective Out Date should be greater than or equal to Effective In Date";
      this.globalrole.goToTop();
    }
    else {
      this.errorMessage = '';
      this.successMessage = "";
      this.getGridData("initial");
    }
  }

  //to get partsource
  getpartsource() {
    this.partSourceRoutingService.getPartSource(this.namc).subscribe(data => {
      console.log(data);
      this.partSourceLst = data.payload;
      this.partSource = ""
    });
  }

  //ngx-table
  onSelect({ selected }) {
    this.selected = selected;
    if (this.selected.length == selected.length) {
      this.sameLengthRows = true
    }
    if (this.selected.length == 0) {
      this.sameLengthRows = false
    }
    console.log('Select Event', selected, this.selected);

    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }
  displayCheck(row) {
    return row;
  }

  singleSelectCheck(row: any) {
    return this.selected.indexOf(row) === -1;
  }

  openUploadDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    console.log(this.roleAccess.includes('Create'))
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(PartSourceRoutingUploadDialogComponent, {
        disableClose: true,
        //   width: '50%'
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        if (result == 'success') {
          this.getGridData("add");
          this.successMessage = 'Upload Process Started, Uploaded Information will be share via Mail'
        }
        else if (result == 'cancel') {
          this.successMessage = ''
          this.errorMessage = ''
        }
        else {
          this.errorMessage = result
          this.globalrole.goToTop();
        }
      })
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }
  }

  openEditDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    console.log(this.roleAccess.includes('Update'))
    if (this.roleAccess.includes('Update')) {
      if (this.selected.length == 1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(PartSourceRoutingEditDialogComponent, {
          disableClose: true,
          data: this.dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');


          if (result == 'success') {
            this.getGridData("add");
            this.successMessage = "Records Updated Successfully"
            // this.getGridData();
            this.selected = []
          }
          else if (result == 'AlreadyExists') {
            this.resetAll("initial");
            this.errorMessage = 'Data Entered already Exists!'
            this.globalrole.goToTop();
          }
          else {
            // this.errorMessage = 'Data not inserted/Valid'
          }

        });

      }
      else {
        this.errorMessage = "Please select a row to edit";
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
    }
  }

  openViewDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    console.log(this.roleAccess.includes('Read'))
    if (this.roleAccess.includes('Read')) {
      if (this.selected.length == 1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(PartSourceRoutingViewDialogComponent, {
          disableClose: true,
          data: this.dialogData

        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');

        });
      }
      else {
        if (this.sameLengthRows == true) {
          this.errorMessage = "please select only one row to view"
          this.globalrole.goToTop();
        }
        if (this.sameLengthRows == false) {
          this.errorMessage = "please select a row to view"
          this.globalrole.goToTop();
        }
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }

  exportFile() {
    this.successMessage = '';
    this.errorMessage = '';
    this.rows.forEach(row => {
      let obj =
      {
        "NAMC": row.namc_cd,
        "Complete Part Routing": row.cmplt_rtg_cd,
        "Part Source Routing": row.part_src_typ_cd,
        "Effective In Date": row.eff_dt,
        "Effective Out Date": row.expr_dt,
        "Comments": row.rmrk_txt,

      }
      this.exportData.push(obj);

    });
    this.exportService.exportAsExcelFile(this.exportData, this.fileName, this.sheetName);
    this.exportData = []
  }

  sendToDialog(selected): void {
    selected.forEach(row => {
      this.dialogData.namcId = row.namc_lgl_entity_id
      this.dialogData.namc = row.namc_cd
      this.dialogData.completePartRouting = row.cmplt_rtg_cd
      this.dialogData.partSource = row.part_src_typ_cd
      this.dialogData.effectiveInDate = this.pipe.transform(row.eff_dt, 'yyyy-MM-dd');
      this.dialogData.effectiveOutDate = this.pipe.transform(row.expr_dt, 'yyyy-MM-dd');
      this.dialogData.comments = row.rmrk_txt
      this.dialogData.part_src_rtg_id = row.part_src_rtg_id;
    });

    console.log(this.dialogData.monthlyRep)
  }
  closeAlert() {
    this.errorMessage = '';
    this.successMessage = '';
  }

  errorExport(data) {
    this.exportData = [];
    data.forEach(row => {
      let obj = {
        "NAMC": row.namc,
        "Complete Part Routing": row.comPartRouting,
        "Part Source Routing": row.partSourceRouting,
        "Effective In Date": row.effDateIn,
        "Effective Out Date": row.effDateOut,
        "Comments": row.comments,
        "Error": row.errorMessage
      }
      this.exportData.push(obj);
    });
    console.log(this.exportData)
    this.exportService.exportAsExcelFile(this.exportData, this.errorFileName, this.errorSheetName);
  }

  getGridData(data) {
    this.selected = []
    console.log(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      if (this.namc == undefined) {
        this.namc = ""
      }
      if (this.partSource == undefined) {
        this.partSource = ""
      }
      if (this.effDateIn != "") {
        this.effDateIn = this.pipe.transform(this.effDateIn, 'yyyy-MM-dd')
      }
      if (this.effDateOut != "") {
        this.effDateOut = this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')
      }
      if (this.comments == undefined) {
        this.comments = ""
      }
      let gridData
      let cmts;
    if (this.comments!= undefined) {
      cmts = this.comments.replaceAll("'", "''");
    } else {
      cmts = this.comments
    }
      if (data == "initial") {
        gridData =
        {
          namc: this.namc,
          comPartRouting: this.comPartRouting,
          partSource: this.partSource,
          comments: cmts,
          effectiveInDate: this.effDateIn,
          effectiveOutDate: this.effDateOut,
          option: data
        }
      }
      else {
        gridData =
        {
          namc: "",
          comPartRouting: "",
          partSource: "",
          comments: "",
          effectiveInDate: "",
          effectiveOutDate: "",
          option: data
        }
      }
      this.SpinnerService.show();
      this.partSourceRoutingService.getGridData(gridData).subscribe(data => {
        console.log(data);
        this.showdata = true;
        console.log(this.showdata);
        this.SpinnerService.hide();
        this.recordcount = data.pagination.totalRecords;
        let datarow = data.payload;
        this.rows = datarow.map(row => ({
          namc_cd: row['namc_cd'],
          cmplt_rtg_cd: row['cmplt_rtg_cd'],
          part_src_typ_cd: row['part_src_typ_cd'],
          eff_dt: row['eff_dt'].split('T')[0],
          expr_dt: row['expr_dt'].split('T')[0],
          rmrk_txt: row['rmrk_txt'],
          part_src_rtg_id: row['part_src_rtg_id']
        }));

        console.log(this.rows)

      }, err => {
        console.log(err);
        this.showdata = false;
        this.successMessage = "";
        console.log(this.showdata);
        this.rows = [];
        if (err == 0) {
          this.errorMessage = "Request timed out. Please try again.";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        } else {
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        }
      });
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'effDateIn') {
      this.effDateIn = "";
    } else if (name == 'effDateOut') {
      this.effDateOut = "";
    }
  }
  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.log(res)
        this.Roledata = res;
      })
      console.log(this.Roledata);
      if (this.Roledata.length != 0) {
        this.Roledata.filter((res) => {
          if (res.function_nm == 'NBPC_2295') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length == 0) {
        let arrayvalue: any; let roleList: any;
        let data = localStorage.getItem('role');
        arrayvalue = data.split(',')
        console.log(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str == '') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        let body = {
          security_grp_nm: roleList
        }

        let userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
          this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
          this.userroleAccessfn();
          this.accessEnable = true;
          resolve(true);
        }else{
        console.log(this.roleAccess)
        this.roleAccess = []
        this.errorMessage = "User does not have permission to perform 'read' operation";
        this.accessEnable = false;
        this.SpinnerService.hide();
      };
      }
      console.log(this.roleAccess);
    })
  }

}

