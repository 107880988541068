import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogData } from '../dialog-data/dialog-data';
import { VersionDeleteDialogService } from './version-delete-dialog.service';

@Component({
  selector: 'app-version-delete-dialog',
  templateUrl: './version-delete-dialog.component.html',
  styleUrls: ['./version-delete-dialog.component.css']
})
export class VersionDeleteDialogComponent implements OnInit {
  [x: string]: any;
  repBomVerId: any;
  //to main page
  message = "Record Deleted Successfully";
  spinnerMessage = "Please Wait...";

  constructor(private VersionDeleteDialogService: VersionDeleteDialogService,
    public dialogRef: MatDialogRef<VersionDeleteDialogComponent>,
    private SpinnerService: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    console.log("this.data", this.data);
    this.repBomVerId = this.data.repBomVerId;
  }

  //save
  deleteVersion(): void {
    let fileName= localStorage.getItem('Filename')
    let body = {
      'rep_bom_ver_id': this.repBomVerId,
      "screenNo":"NBPC_10105",
       "fileName" :fileName
    }
    this.SpinnerService.show();
    this.VersionDeleteDialogService.deleteVersions(body).subscribe(data => {
      this.response = data.payload;
      this.SpinnerService.hide();
      console.log(data);
      this.dialogRef.close(this.response[0].result);
    }, err => {
      this.SpinnerService.hide();
      console.log("err", err);
      this.dialogRef.close("Representative product version cannot be deleted");
    });
  }
  cancel(): void {
    this.message = "";
    this.dialogRef.close('cancel');
  }


}
