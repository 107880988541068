import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { SapDataLoadServiceService } from './sap-data-load-service.service';
import { SapDataDeleteDialogComponent } from '../sap-data-delete-dialog/sap-data-delete-dialog.component';
import { SapDataEditDialogComponent } from '../sap-data-edit-dialog/sap-data-edit-dialog.component';
import { SapDataMultieditDialogComponent } from '../sap-data-multiedit-dialog/sap-data-multiedit-dialog.component';
//import { SapDataLoadMultideleteDialogComponent } from '../sap-data-load-multidelete/sap-data-load-multidelete.component';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { DialogData } from '../dialog-data/dialog-data';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../global.service';

import * as S3 from 'aws-sdk/clients/s3';

@Component({
  selector: 'app-sap-data-load',
  templateUrl: './sap-data-load.component.html',
  styleUrls: ['./sap-data-load.component.css']
})

export class SapDataLoadComponent implements OnInit {
  [x: string]: any;

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //excel
  fileName = "SAPDataloadEbom";
  exportData = [];
  sheetName = [];

  //alertMessage
  errorMessage: string = '';
  successMessage: string = '';
  response: any;
  loadEbom: any = []
  loadebomErrMsg: string = '';
  //ngModel values for search
  step = 0;
  modelNumber: any = "";
  optn: any = "";
  sapMatId: any = "";
  partNumber: any = "";
  namc: any = "";
  family: any = "ALL";
  katashiki: any = "ALL";
  customer: any = "";
  dest: any = "ALL";
  prodFlg: any = "";
  hikiateStatus: any = "";
  sp: any = "";
  netSpec: any = "";
  pcSpec: any = "";
  compPcSpec: any = "";
  effDateIn: any = "";
  effDateOut: any = "";
  prodTyp: any = "";
  sapStatus: any = "A";
  partDesc: any = "";
  comments: any = "";
  compBom: any = "";
  compHundPercent: any = "";
  compPCSpec: any = "";
  extractionTimeIn: any = "";
  extractionTimeOut: any = "";
  // hikiate: any = "";
  hundPercent: any = "";
  monthlyRep: any = "";
  sapMaterialId: any = "";
  sapProdDefId: any = "";
  hundrentPerSpec: any = "";
  compHundPerSpec: any = "";
  spinnerMessage = "Please Wait...";

  //list
  modelNumberLst = [];
  optionLst = [];
  namcLst = [];
  //namcLst:any=[]
  familyLst = [];
  katashikiLst = [];
  customerLst: string[] = [];
  destLst: string[] = [];
  hikiateStatusLst = [];
  spLst: string[] = [];
  prodTypLst: string[] = [];
  sapStatusLst: string[] = [];

  //mat-dialog
  data: any = {};

  //ngx-datatable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;
  recordcount: any = 0;
  showdata: boolean = true;
  roleAccess: any = [];
  Roledata: any;
  accessKey: any
  secretKey: any
  sessionToken: any
  columns = [
    { name: 'monthlyRep', prop: 'monthly_rep_flg' }, { name: 'compBom', prop: 'compar_bom_flg' },
    { name: 'namc', prop: 'namc_cd' }, { name: 'modelNumber', prop: 'mdl_no' },
    { name: 'option', prop: 'optn_cd' }, { name: 'sapMaterialId', prop: 'sap_mtrl_id' }, { name: 'family', prop: 'fam_cd' },
    { name: 'partNumber', prop: 'part_no' }, { name: 'partDesc', prop: 'part_desc' },
    { name: 'prodType', prop: 'prod_typ_desc' }, { name: 'katashiki', prop: 'katashiki_cd' },
    { name: 'compHundredPercent', prop: 'compar_100_pct_optn_flg' }, { name: 'customerId', prop: 'buyoff_cust_nm' },
    { name: 'dest', prop: 'dest_ctry_id' }, { name: 'hikiate', prop: ' hikiate_stat_comb_cd' },
    { name: 'netSpec', prop: 'net_spec_cd_comb_txt' }, { name: 'pcSpec', prop: 'pc_spec_cd_comb_txt ' }, { name: 'hundPercent', prop: 'hundred_pct_optn_flg' },
    { name: 'compPcSpec', prop: 'compar_pc_spec_cd_comb_txt' }, { name: 'compHundPercent', prop: 'compar_100_pct_optn_flg' }, { name: 'extractionTimeIn', prop: 'eff_dt' },
    { name: 'extractionTimeOut', prop: 'expr_dt' }, { name: 'comments', prop: 'rmrk_txt' }, { name: 'sapProdDefId', prop: 'rep_prod_def_sap_id' }, { name: 'errorMessage', prop: 'err_msg' }
    , { name: 'sapStatus', prop: 'stat_flg' }, { name: 'sp', prop: 'base_optn_typ_cd' }
  ];
  ColumnMode = ColumnMode;


  constructor(private SpinnerService: NgxSpinnerService, private sapDataLoadServiceService: SapDataLoadServiceService, public dialog: MatDialog, private exportService: ExportToExcelService, private dialogData: DialogData, public globalrole: GlobalService, ) { }

  async ngOnInit() {
    this.SpinnerService.show();
    let a = await this.userroleAccessfn();
    if (a) {
      console.log('a', a)
      //grid
      this.getGridData("initial");
    }
    this.onloadfn();

  }
  onloadfn() {
    // to get namc
    this.sapDataLoadServiceService.getNamcSap().subscribe(data => {
      console.log(data);
      this.namcLst = data.payload;
    });

    // to get family
    this.sapDataLoadServiceService.getFamilySap(this.namc).subscribe(data => {
      console.log(data);
      this.familyLst = data.payload;
    });

    // to get model number
    this.sapDataLoadServiceService.getModelNumberSap().subscribe(data => {
      console.log(data);
      this.modelNumberLst = data.payload;
    });

    // to get option
    this.sapDataLoadServiceService.getOptionSap().subscribe(data => {
      console.log(data);
      this.optionLst = data.payload;
    });

    // to get katashiki
    // this.sapDataLoadServiceService.getKatashikiSap(this.namc, this.family).subscribe(data => {
    //   console.log(data);
    //   this.katashikiLst = data.payload;
    // });

    // to get dest
    this.sapDataLoadServiceService.getDestByCustSap(this.namc, this.family, this.katashiki, this.customer).subscribe(data => {
      console.log(data);
      this.destLst = data.payload;
    });

    // to get hikiate
    this.sapDataLoadServiceService.getHikiateStatusSap(this.namc, this.family, this.katashiki, this.dest, this.customer).subscribe(data => {
      console.log(data);
      this.hikiateStatusLst = data.payload;
    });

    //to get sp
    this.sapDataLoadServiceService.getSp(this.namc, this.family, this.katashiki, this.customer, this.dest, this.hikiateStatus).subscribe(data => {
      console.log(data);
      this.spLst = data.payload;
    });

    //to get productTypeSap
    this.sapDataLoadServiceService.getProductTypeSap(this.prodFlg).subscribe(data => {
      console.log(data);
      this.prodTypLst = data.payload;
    });

    //to get customer
    this.sapDataLoadServiceService.getCust().subscribe(data => {
      console.log(data);
      this.customerLst = data.payload;
    });
  }
  //onChangeMethod
  getFamCdByNamc(): void {
    if (this.namc == undefined) {
      this.namc = ""
    }
    this.sapDataLoadServiceService.getFamilySap(this.namc).subscribe(data => {
      console.log(data);
      this.familyLst = data.payload;
      this.family = "ALL"
      this.katashiki = "ALL"
      this.customer = ""
      this.dest = "ALL"
      this.hikiateStatus = ""
      this.sp = ""
    });
  }

  getKatashikiByFamCd(): void {
    if (this.namc == undefined) {
      this.namc = ""
    }
    if (this.family == undefined) {
      this.family = ""
    }
    this.sapDataLoadServiceService.getKatashikiSap(this.namc, this.family).subscribe(data => {
      console.log(data);
      this.katashikiLst = data.payload;
      this.katashiki = "ALL"
    });
  }

  getCustByKatashiki(): void {
    if (this.namc == undefined) {
      this.namc = ""
    }
    if (this.family == undefined) {
      this.family = ""
    }
    if (this.katashiki == undefined) {
      this.katashiki = ""
    }
    this.sapDataLoadServiceService.getCustByKatashikiSap(this.namc, this.family, this.katashiki).subscribe(data => {
      console.log(data);
      this.customerLst = data.payload;
      this.customer = ""
    });
  }

  getDestByCust(): void {
    if (this.namc == undefined) {
      this.namc = ""
    }
    if (this.family == undefined) {
      this.family = ""
    }
    if (this.katashiki == undefined) {
      this.katashiki = ""
    }
    if (this.customer == undefined) {
      this.customer = ""
    }
    this.sapDataLoadServiceService.getDestByCustSap(this.namc, this.family, this.katashiki, this.customer).subscribe(data => {
      console.log(data);
      this.destLst = data.payload;
      this.dest = "ALL"
    });
  }


  getSpByHiki(): void {
    if (this.namc == undefined) {
      this.namc = ""
    }
    if (this.family == undefined) {
      this.family = ""
    }
    if (this.katashiki == undefined) {
      this.katashiki = ""
    }
    if (this.customer == undefined) {
      this.customer = ""
    }
    if (this.dest == undefined) {
      this.dest = ""
    }
    if (this.hikiateStatus == undefined) {
      this.hikiateStatus = ""
    }
    this.sapDataLoadServiceService.getSp(this.namc, this.family, this.katashiki, this.customer, this.dest, this.hikiateStatus).subscribe(data => {
      console.log(data);
      this.spLst = data.payload;
      this.sp = ""
    });
  }

  getHikiateByDest(): void {
    if (this.dest == undefined) {
      this.dest = ""
    }
    this.sapDataLoadServiceService.getHikiateStatusSap(this.namc, this.family, this.katashiki, this.dest, this.customer).subscribe(data => {
      console.log(data);
      this.hikiateStatusLst = data.payload;
      this.hikiateStatus = ""
    });
  }
  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  //ngx-table
  onSelect({ selected }) {
    this.selected = selected
    console.log('Select Event', selected, this.selected);
  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }
  openEditDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    this.loadebomErrMsg = '';
    let count = this.selected.length
    console.log(this.roleAccess.includes('Update'))
    if (this.roleAccess.includes('Update')) {
      if (this.selected.length == 1) {
        console.log(this.selected[0].stat_flg)
        if (this.selected[0].stat_flg != 'A') {
          this.errorMessage = "Please select Active records to edit"
          this.globalrole.goToTop();
          this.SpinnerService.hide()
        } else {
          this.sendToDialog(this.selected)
          const dialogRef = this.dialog.open(SapDataEditDialogComponent, {
            disableClose: true,
            data: this.dialogData
          });

          dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            this.selected = [];
            if (result == 'success') {
              // this.resetAll("add");
              this.getGridData('add')
              this.successMessage = "Record Updated Successfully"
              this.selected = []
              this.SpinnerService.hide()
            }
            else if (result == 'AlreadyExists') {
              this.resetAll("initial");
              this.errorMessage = 'Record already Exists!';
              this.globalrole.goToTop();
              this.SpinnerService.hide()
            }
            else {
              // this.errorMessage = 'Data not inserted/Valid'
            }
          });
        }

      }
      else if (this.selected.length > 1) {
        // this.sendToDialog(this.selected)
        let boolean = false;
        let statuscheck = []
        this.selected.filter((res) => {
          if (res.stat_flg != 'A') {
            statuscheck.push(res.prod_bom_id);
          }
        })
        console.log(statuscheck.length);
        for (let i = 0; i < this.selected.length; i++) {
          for (let j = i + 1; i < this.selected.length; i++) {
            if ((this.selected[i].namc_cd != this.selected[j].namc_cd) ||
              (this.selected[i].fam_cd != this.selected[j].fam_cd) ||
              (this.selected[i].prod_typ_desc != this.selected[j].prod_typ_desc)) {
              boolean = true;
            }

          }
        }
        if (boolean) {
          this.errorMessage = "Please select records with same NAMC/Family/Product type"
          this.globalrole.goToTop();
          this.SpinnerService.hide()
        }

        else if (statuscheck.length > 0) {
          this.errorMessage = "Please select only Active records to edit"
          this.globalrole.goToTop();
          this.SpinnerService.hide()
        }
        else {
          this.errorMessage = ''
          const dialogRef = this.dialog.open(SapDataMultieditDialogComponent, {
            disableClose: true,
            data: this.selected
          });
          dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            this.selected = [];
            if (result == 'success') {
              this.getGridData('add')
              // this.resetAll("add");
              this.successMessage = +count + " Record (S) Updated Successfully"
              this.SpinnerService.hide()
              this.selected = []
            }
            else if (result == 'AlreadyExists') {
              this.resetAll("initial");
              this.errorMessage = 'Record already Exists!'
              this.globalrole.goToTop();
              this.SpinnerService.hide()
            }
            else {
              // this.errorMessage = 'Data not inserted/Valid'
            }
          });
        }
      }
      else {
        this.errorMessage = "Please select a row to edit";
        this.globalrole.goToTop();
        this.SpinnerService.hide()
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
      this.SpinnerService.hide()
    }
  }

  openLoadtoebom(): void {

  }

  openDeleteDialog(): void {
    this.successMessage = '';
    console.log(this.selected, this.selected.length)
    this.errorMessage = '';
    this.loadebomErrMsg = '';
    console.log(this.roleAccess.includes('Delete'))
    if (this.roleAccess.includes('Delete')) {
      if (this.selected.length > 0) {
        this.sendToDialog(this.selected);
        const dialogRef = this.dialog.open(SapDataDeleteDialogComponent, {
          disableClose: true,
          width: '50%',
          data: this.selected
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed')
          this.selected = [];

          if (result == 'success') {
            this.getGridData('add')
            // this.resetAll("add");
            this.successMessage = "Record Deleted Successfully"
            this.globalrole.goToTop();
          }
          else if (result == 'AlreadyExists') {
            this.resetAll("initial");
            this.errorMessage = 'Record already Exists!'
            this.globalrole.goToTop();
          }
          else {
            // this.errorMessage = 'Data not inserted/Valid'
          }
        });

      }
      else {
        this.errorMessage = "Please select a row to delete"
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'delete' operation";
      this.globalrole.goToTop();
    }
  }

  exportFile(): void {

    this.rows.forEach(row => {
      let obj =
      {
        "Monthly Representative": row.monthly_rep_flg,
        "Comparitive BOM": row.compar_100_pct_optn_flg,
        "NAMC": row.namc_cd,
        "Model Number": row.mdl_no,
        "Option": row.optn_cd,
        "SAP Material ID": row.sap_mtrl_id,
        "Family": row.fam_cd,
        "Part Number": row.part_no,
        "Part Description": row.part_desc,
        "Product Type ": row.prod_typ_desc,
        "Katashiki": row.katashiki_cd,
        "Customer": row.buyoff_cust_nm,
        "Destination": row.dest_ctry_id,
        "Hikiate Status": row.hikiate_stat_comb_cd,
        "S/P": row.base_optn_typ_cd,
        "Net Spec": row.net_spec_cd_comb_txt,
        "PC Spec": row.pc_spec_cd_comb_txt,
        "100% Spec": row.hundred_pct_optn_flg,
        "Comp PC Spec": row.compar_pc_spec_cd_comb_txt,
        "Comp 100% Spec": row.compar_100_pct_optn_flg,
        "Extaction Time In": row.eff_dt,
        "Extraction Time Out": row.expr_dt,
        "Comments": row.rmrk_txt,
        "Sap Prod Def Id": row.rep_prod_def_sap_id,
        "Error Message": row.err_msg,
        "Sap Status": row.stat_flg,
      }
      this.exportData.push(obj);
    });
    this.exportService.exportAsExcelFile(this.exportData,'SAP_Dataload', ['SAP_Dataload']);
    this.exportData = []
  }
  closeAlert(): void {
    this.errorMessage = '';
    this.successMessage = '';
    this.loadebomErrMsg = '';
  }

  sendToDialog(selected): void {
    selected.forEach(row => {
      console.log(row.monthlyRep)
      this.dialogData.monthlyRep = row.monthly_rep_flg == 'Y' ? true : false
      this.dialogData.compBom = row.compar_bom_flg == 'Y' ? true : false
      this.dialogData.namc = row.namc_cd
      this.dialogData.modelNumber = row.mdl_no
      this.dialogData.option = row.optn_cd
      this.dialogData.sapMaterialId = row.sap_mtrl_id
      this.dialogData.family = row.fam_cd
      this.dialogData.partNumber = row.part_no
      this.dialogData.partDesc = row.part_desc
      // this.dialogData.prodTyp = row.prod_typ_cd
      this.dialogData.katashiki = row.katashiki_cd
      this.dialogData.customer = row.buyoff_cust_nm
      this.dialogData.dest = row.dest_ctry_id
      this.dialogData.hikiate = (row.hikiate_stat_comb_cd == null) ? '' : row.hikiate_stat_comb_cd.split(",")
      this.dialogData.sp = row.base_optn_typ_cd
      this.dialogData.netSpec = row.net_spec_cd_comb_txt
      this.dialogData.pcSpec = row.pc_spec_cd_comb_txt
      // this.dialogData.hundredPerSpec = row.hundred_pct_optn_flg
      // this.dialogData.compPcSpec = row.compar_pc_spec_cd_comb_txt
      this.dialogData.compHundPerSpec = row.compar_100_pct_optn_flg
      this.dialogData.extractionTimeIn = row.eff_dt
      this.dialogData.extractionTimeOut = row.expr_dt
      this.dialogData.comments = row.rmrk_txt
      this.dialogData.repProdDefId = row.rep_prod_def_sap_id
      this.dialogData.errorMessage = row.err_msg
      this.dialogData.sapStatus = row.sapStatus
    });
    console.log(this.dialogData.monthlyRep)
  }
  resetAll(data): void {
    this.selected = [];
    this.errorMessage = '';
    this.successMessage = '';
    this.modelNumber = '';
    this.optn = '';
    this.sapMaterialId = '';
    this.partNumber = '';
    this.namc = '';
    this.family = 'ALL';
    this.katashiki = 'ALL';
    this.customer = '';
    this.dest = 'ALL';
    this.hikiateStatus = '';
    this.sp = '';
    this.netSpec = '';
    this.pcSpec = '';
    this.compPcSpec = '';
    this.extractionTimeIn = '';
    this.extractionTimeOut = '';
    this.prodTyp = '';
    this.sapStatus = 'A';
    this.partDesc = '';
    this.selected = []
    this.katashikiLst = []
    // this.getGridData(data);
    this.onloadfn();
  }


  getGridData(data) {
    this.selected = [];
    console.log(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      if (this.namc == undefined) {
        this.namc = ""
      }
      if (this.modelNumber == undefined) {
        this.modelNumber = ""
      }
      if (this.optn == undefined) {
        this.optn = ""
      }
      if (this.family == undefined) {
        this.family = ""
      }
      if (this.katashiki == undefined) {
        this.katashiki = ""
      }
      if (this.customer == undefined) {
        this.customer = ""
      }
      if (this.hikiateStatus == undefined) {
        this.hikiateStatus = ""
      }
      if (this.prodTyp == undefined) {
        this.prodTyp = ""
      }
      if (this.sp == undefined) {
        this.sp = ""
      }
      if (this.dest == undefined) {
        this.dest = ""
      }
      if (this.sapStatus == undefined) {
        this.sapStatus = ""
      }

      let indateFormat = ''; let outdateFormat = '';
      if (this.extractionTimeIn != "") {
        let d1 = moment(this.extractionTimeIn).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.extractionTimeIn = date
      }
      if (this.extractionTimeOut != "") {
        let d1 = moment(this.extractionTimeOut).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.extractionTimeOut = date
      }
      console.log(this.prodTyp);
      let newDate = new Date();
      let date = '_' + newDate.getDate() + (newDate.getMonth() + 1) + newDate.getFullYear() + '_' + newDate.getHours() + newDate.getMinutes() + newDate.getSeconds() + newDate.getMilliseconds();
      let fileName = 'SAPDataLoad/SapdataloadGrid' + date + '.json'
      let gridData;
      if (data == "initial") {
        gridData =
        {
          monthlyRep: this.monthlyRep,
          compBom: this.compBom,
          namc: this.namc,
          modelNumber: this.modelNumber,
          optn: this.optn,
          sapMaterialId: this.sapMaterialId,
          family: this.family,
          partNumber: this.partNumber,
          partDesc: this.partDesc,
          prodTyp: this.prodTyp,
          katashiki: this.katashiki,
          customer: this.customer,
          dest: this.dest,
          hikiate: this.hikiateStatus,
          sp: this.sp,
          netSpec: this.netSpec,
          pcSpec: this.pcSpec,
          hundPercent: this.hundPercent,
          compPCSpec: this.compPcSpec,
          compHundPercent: this.compHundPercent,
          extractionTimeIn: indateFormat,
          extractionTimeOut: outdateFormat,
          comments: this.comments,
          sapProdDefId: this.sapProdDefId,
          errorMessage: this.errorMessage,
          sapStatus: this.sapStatus,
          hundrentPerSpec: this.hundrentPerSpec,
          compHundPerSpec: this.compHundPerSpec,
          option: data,
          fileName: fileName
        }
      } else {
        gridData =
        {
          monthlyRep: "",
          compBom: "",
          namc: "",
          modelNumber: "",
          optn: "",
          sapMaterialId: "",
          family: 'ALL',
          partNumber: "",
          partDesc: "",
          prodTyp: "",
          katashiki: 'ALL',
          customer: "",
          dest: 'ALL',
          hikiate: "",
          sp: "",
          netSpec: "",
          pcSpec: "",
          hundPercent: "",
          compPCSpec: "",
          compHundPercent: "",
          extractionTimeIn: "",
          extractionTimeOut: "",
          comments: "",
          sapProdDefId: "",
          errorMessage: "",
          sapStatus: 'A',
          hundrentPerSpec: "",
          compHundPerSpec: "",
          option: data,
          fileName: fileName
        }
      }
      console.log(gridData);
      this.SpinnerService.show();
      this.sapDataLoadServiceService.getGridData(gridData).subscribe(data => {
        console.log(data);
        this.showdata = true;
        console.log(this.showdata);

        this.recordcount = data.pagination.totalRecords;
        let datarow = data.payload;
        if (data.payload[0].RecordCnt > 1000) {
          setTimeout(() => {
            this.getFileFromS3(fileName);
          }, 2000);
        } else {
          this.SpinnerService.hide();
          this.rows = datarow.map(row => ({
            monthly_rep_flg: row['monthly_rep_flg'],
            compar_bom_flg: row['compar_bom_flg'],
            namc_cd: row['namc_cd'],
            mdl_no: row['mdl_no'],
            optn_cd: row['optn_cd'],
            sap_mtrl_id: row['sap_mtrl_id'],
            fam_cd: row['fam_cd'],
            part_no: row['part_no'],
            part_desc: row['part_desc'],
            prod_typ_desc: row['prod_typ_desc'],
            cmpnt_flg: row['cmpnt_flg'],
            base_optn_typ_cd: row['base_optn_typ_cd'],
            katashiki_cd: row['katashiki_cd'],
            compar_100_pct_optn_flg: row['compar_100_pct_optn_flg'],
            buyoff_cust_nm: row['buyoff_cust_nm'],
            dest_ctry_id: row['dest_ctry_id'],
            hikiate_stat_comb_cd: row['hikiate_stat_comb_cd'],
            net_spec_cd_comb_txt: row['net_spec_cd_comb_txt'],
            pc_spec_cd_comb_txt: row['pc_spec_cd_comb_txt'],
            hundred_pct_optn_flg: row['hundred_pct_optn_flg'],
            compar_pc_spec_cd_comb_txt: row['compar_pc_spec_cd_comb_txt'],
            eff_dt: (row['eff_dt'] == null) ? '' : row['eff_dt'].split('T')[0],
            expr_dt: (row['expr_dt'] == null) ? '' : row['expr_dt'].split('T')[0],
            rmrk_txt: row['rmrk_txt'],
            rep_prod_def_sap_id: row['rep_prod_def_sap_id'],
            err_msg: row['err_msg'],
            stat_flg: row['stat_flg'],
            // base_optn_typ_cd: row['base_optn_typ_cd'],

          })
          )
        }

      }, err => {
        console.log(err);
        this.successMessage = "";
        this.showdata = false;
        console.log(this.showdata);
        this.rows = [];
        if (err == 0) {
          this.errorMessage = "Request timed out. Please try again.";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        } else {
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        }
      });

    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }


  search() {

    if (this.extractionTimeIn && this.extractionTimeOut && this.pipe.transform(this.extractionTimeIn, 'yyyy-MM-dd') > this.pipe.transform(this.extractionTimeOut, 'yyyy-MM-dd')) {

      this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";
      this.globalrole.goToTop();
    }
    else {
      this.errorMessage = '';
      this.successMessage = "";
      this.getGridData("initial");
    }
  }
  errorExport(data) {
    this.exportData = [];
    data.forEach(row => {
      let obj = {
        "Monthly Representative": row.monthlyRep,
        "Comparitive BOM": row.compBom,
        "NAMC": row.namc,
        "Model Number": row.mdlNo,
        "Option": row.option,
        "SAP Material ID": row.sapMatId,
        "Family": row.family,
        "Part Number": row.partNo,
        "Part Description": row.partDesc,
        "Product Type ": row.prodTyp,
        "Katashiki": row.katashiki,
        "Customer": row.customer,
        "Destination": row.dest,
        "Hikiate Status": row.hikiate,
        "S/P": row.sp,
        "Net Spec": row.netspec,
        "PC Spec": row.pcSpec,
        "100% Spec": row.hundPerSpec,
        "Comp PC Spec": row.compPcSpec,
        "Comp 100% Spec": row.compHundPerSpec,
        "Extaction Time In": row.extTimeIn,
        "Extraction Time Out": row.extTimeOut,
        "Comments": row.comments,
        "Sap Prod Def Id": row.repProdDefId,
        "Sap Status": row.sapStatus,
        "Error": row.errorMessage
      }
      this.exportData.push(obj);
    });
    console.log(this.exportData)
    this.exportService.exportAsExcelFile(this.exportData, this.errorFileName, this.errorSheetName);
  }




  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'extractionTimeIn') {
      this.extractionTimeIn = "";
    } else if (name == 'extractionTimeOut') {
      this.extractionTimeOut = "";
    }
  }

  //function SapDataLoadToEbomDialogComponent(SapDataLoadToEbomDialogComponent: any, arg1: { data: any; }) {
  //throw new Error('Function not implemented.');

  getProcStatus() {
    this.successMessage = "";
    this.errorMessage = ""
    this.loadebomErrMsg = "";
    if (this.roleAccess.includes('Update')) {
      if (this.selected.length > 0) {
        let body = {
          'proc_nm': 'LOAD_TO_EBOM'
        }
        this.SpinnerService.show()
        this.sapDataLoadServiceService.getProcStatus(body).subscribe(data => {
          console.log(data);
          this.response = data.payload;
          if (this.response[0].proc_status != 'INITIATED') {
            this.updatetProcProcessStatus()
          }
          else if (this.response[0].proc_status == 'INITIATED') {
            this.SpinnerService.hide()
            this.errorMessage = "Already is Inprogress. Please Work Later";
            this.globalrole.goToTop();
          }
        }, err => {
          this.SpinnerService.hide();
          this.errorMessage = "Data not found";
          this.globalrole.goToTop();
        });
      }
      else {
        this.successMessage = ""
        this.errorMessage = "Please select a row to LoadToEbom"
        this.globalrole.goToTop();
        this.SpinnerService.hide()
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
      this.SpinnerService.hide()
    }
  }

  updatetProcProcessStatus() {
    let fileName= localStorage.getItem('Filename')
    let mail = localStorage.getItem('emailId');
    let user = localStorage.getItem('workDayId');
    let body = {
      'mailId': mail,      
      'proc_nm': 'LOAD_TO_EBOM',
      'proc_status': 'INITIATED',
      "screenNo":"NBPC_9998",
      "fileName" :fileName
    }
    this.sapDataLoadServiceService.editProcProcessStatus(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.FetchtruncstgrepProddefSap();
      } else {
        this.SpinnerService.hide();
        this.errorMessage = this.response[0].result;
        this.globalrole.goToTop();
      }
    }, err => {
      this.SpinnerService.hide();
      this.errorMessage = "Data not found";
      this.globalrole.goToTop();
    });
  }
  FetchtruncstgrepProddefSap() {
    this.FetchtrucstgrepProdDef('EBOM_ADM.tmp_load_to_ebom')
    // if (this.roleAccess.includes('Update')) {
    //   if (this.selected.length > 0) {
    //     this.SpinnerService.show();
    //     let body = {
    //       "table_name": 'EBOM_ADM.STG_REP_PROD_DEF_SAP'
    //     }
    //     this.sapDataLoadServiceService.truncTempRepProd(body).subscribe(data => {
    //       console.log(data);
    //       this.response = data.payload;
    //       if (this.response[0].result == 'success') {
    // this.FetchtrucstgrepProdDef('EBOM_ADM.STG_REP_PROD_DEF')
    //   } else {
    //     this.SpinnerService.hide();
    //     this.dialogRef.close(this.response[0].result);
    //   }
    // }, err => {
    //   this.SpinnerService.hide();
    //   this.dialogRef.close("Function Failed");
    // });
    //   }
    //       else {
    //   this.successMessage = ""
    //   this.errorMessage = "Please select a row to LoadToEbom"
    //   this.globalrole.goToTop();
    //   this.SpinnerService.hide()
    // }
    //     } else {
    //   this.errorMessage = "User does not have permission to perform 'update' operation";
    //   this.globalrole.goToTop();
    //   this.SpinnerService.hide()
    // }
  }

  FetchtrucstgrepProdDef(tblname) {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "table_name": tblname,
      "screenNo":"NBPC_9998",
      "fileName" :fileName
    }
    this.sapDataLoadServiceService.truncTempRepProd(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.loadToEbom();
      } else {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }

  loadToEbom() {
    let fileName= localStorage.getItem('Filename')
    console.log(this.selected.length);
    if (this.roleAccess.includes('Update')) {
      if (this.selected.length > 0) {
        this.errorMessage = ""
        let arrayvalue: any;
        arrayvalue = this.selected;
        let erepproddefid
        console.log(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str == '') {
            str = arrayvalue[i].rep_prod_def_sap_id
          } else {
            str += ',' + arrayvalue[i].rep_prod_def_sap_id;
          }
          erepproddefid = str;
        }
        console.log(erepproddefid);
        let body = {
          'repProdDefSapId': erepproddefid,
          "screenNo":"NBPC_9998",
          "fileName" :fileName
        }
        this.sapDataLoadServiceService.sendLoadToEbom(body).subscribe(data => {
          console.log(data);
          this.FetchSpCall();
          /////call and initiate procedure
        }, err => {
          this.SpinnerService.hide();
        })
      }
      else {
        this.successMessage = ""
        this.errorMessage = "Please select a row to LoadToEbom"
        this.globalrole.goToTop();
        this.SpinnerService.hide()
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
      this.SpinnerService.hide()
    }
  }


  FetchSpCall() {
    let data = 'EBOM_ADM.sp_ebom_load_to_ebom_initial'
    this.sapDataLoadServiceService.getSpCall(data).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      this.FetchProcStatus();
    }, err => {
      this.FetchProcStatus();
      // this.errorMessage = "Function Failed";
      this.globalrole.goToTop();
    });
  }

  FetchProcStatus() {
    let fileName= localStorage.getItem('Filename')
    let body = {
      'proc_nm': 'LOAD_TO_EBOM',
      "screenNo":"NBPC_9998",
      "fileName" :fileName
    }
    this.sapDataLoadServiceService.getProcStatus(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].proc_status == 'COMPLETED') {
        this.FetchCntTempRepProd();
      } else if (this.response[0].proc_status == 'INITIATED' || this.response[0].proc_status == 'INPROGRESS' || this.response[0].proc_status == 'IN_PROGRESS') {
        this.FetchProcStatus();
      }
      else if (this.response[0].proc_status == 'ERROR' && this.response[0].rmrk_txt == 'Inactive') {
        this.SpinnerService.hide()
        this.errorMessage = "Please select only Active Records to Delete"
        this.globalrole.goToTop();
      } else if (this.response[0].proc_status == 'ERROR' && this.response[0].rmrk_txt != 'Inactive') {
        this.SpinnerService.hide()
        this.errorMessage = ""
        this.globalrole.goToTop();
      }
      else {
      }
    }, err => {
      this.SpinnerService.hide();
      this.errorMessage = "Function Failed";
      this.globalrole.goToTop();
    });
  }

  FetchCntTempRepProd() {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "table_name": 'EBOM_ADM.STG_REP_PROD_DEF_SAP',
      "buttonName": 'Load to ebom',
      "screenNo":"NBPC_9998",
      "fileName" :fileName
    }
    this.sapDataLoadServiceService.getCntTempRepProd(body).subscribe(data => {
      console.log(data);
      this.cntrecordres = data.payload[0];
      this.getFailedRecordsfn();
      // let successCnt = this.response.CountData.rows
      // let failedCnt = this.response.FailedRecords.rows
      // this.loadEbom = this.response.FailedRecords.rows
      // if (failedCnt.length == 0) {
      //   this.resetAll("add");
      //   this.successMessage = successCnt[0].count + " record(s) loaded successfully and " + failedCnt.length + " record(s) failed"
      // }
      // else {
      //   this.resetAll("add");
      //   this.loadebomErrMsg = successCnt[0].count + " record(s) loaded successfully and " + failedCnt.length + " record(s) failed"
      // }
      // this.SpinnerService.hide();
      this.globalrole.goToTop();
    }, err => {
      this.SpinnerService.hide();
      this.errorMessage = "Load to ebom was failed"
      this.globalrole.goToTop();
    });
  }
  getFailedRecordsfn() {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "table_name": 'EBOM_ADM.STG_REP_PROD_DEF_SAP',
      "buttonName": 'Load to ebom',
      "screenNo":"NBPC_9998",
      "fileName" :fileName
    }
    this.sapDataLoadServiceService.getFailedRecords(body).subscribe(data => {
      console.log(data);
      this.Failedrecordres = data.payload[0];
      this.getResetRecordsfn();
      // let successCnt = this.response.CountData.rows
      // let failedCnt = this.response.FailedRecords.rows
      // this.loadEbom = this.response.FailedRecords.rows
      // if (failedCnt.length == 0) {
      //   this.resetAll("add");
      //   this.successMessage = successCnt[0].count + " record(s) loaded successfully and " + failedCnt.length + " record(s) failed"
      // }
      // else {
      //   this.resetAll("add");
      //   this.loadebomErrMsg = successCnt[0].count + " record(s) loaded successfully and " + failedCnt.length + " record(s) failed"
      // }
      // this.SpinnerService.hide();
      this.globalrole.goToTop();
    }, err => {
      this.SpinnerService.hide();
      this.errorMessage = "Load to ebom was failed"
      this.globalrole.goToTop();
    });
  }

  getResetRecordsfn() {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "table_name": 'EBOM_ADM.STG_REP_PROD_DEF_SAP',
      "buttonName": 'Load to ebom',
      "screenNo":"NBPC_9998",
      "fileName" :fileName
    }
    this.sapDataLoadServiceService.getResetRecords(body).subscribe(data => {
      console.log(data);
      this.ResetRecordsres = data.payload[0];
      let successCnt = this.cntrecordres.CountData.rows
      let failedCnt = this.Failedrecordres.FailedRecords.rows
      this.loadEbom = this.Failedrecordres.FailedRecords.rows
      console.log(this.loadEbom)
      // this.loadEbomresponse = this.response.FailedRecords.rows
      if (failedCnt.length == 0) {
        this.getGridData('loadToEbom')
        this.successMessage = successCnt[0].count + " record(s) loaded successfully and " + failedCnt.length + " record(s) failed"
      }
      else {
        this.getGridData('loadToEbom')
        this.loadebomErrMsg = successCnt[0].count + " record(s) loaded successfully and " + failedCnt.length + " record(s) failed"
      }
      this.SpinnerService.hide();
      this.globalrole.goToTop();
    }, err => {
      this.SpinnerService.hide();
      this.errorMessage = "Load to ebom was failed"
      this.globalrole.goToTop();
    });
  }


  downloadExcel() {
    this.exportData = [];
    let fileName = 'sap_dataload_err'

    console.log("result", this.loadEbom);
    this.loadEbom.forEach(row => {
      let obj = {
        'Monthly Representative': row.monthly_rep_flg,
        'Comparator BOM': row.compar_bom_flg,
        'Model No': row.mdl_no,
        'Option': row.optn_cd,
        'Sap Material Id': row.sap_mtrl_id,
        'NAMC': row.namc_lgl_entity_id,//namc_cd needs to add in tbl
        'Family': row.fam_cd,
        'Part Number': row.prod_part_no,
        'Product Type': row.cmpnt_flg,//prod_typ need
        'Katashiki': row.katashiki_cd,
        'Customer': row.buyoff_cust_id,
        'Dest': row.dest_ctry_id,
        'Hikiate Status': row.hikiate_stat_comb_cd,
        'S/P': row.base_optn_typ_cd,
        'NetSpec': row.net_spec_cd_comb_txt,
        'PC Spec': row.pc_spec_cd_comb_txt,
        '100% Spec': row.hundred_pct_optn_flg,
        'Comp PC Spec': row.compar_pc_spec_cd_comb_txt,
        'Comp 100% Spec': row.compar_100_pct_optn_flg,
        'Extraction Time In': (row.eff_dt == null) ? '' : row.eff_dt,
        'Extraction Time Out': (row.expr_dt == null) ? '' : row.expr_dt,
        'Comments': row.rmrk_txt,
        'Error Message': row.err_msg,
        'SAP Status': '',//sap status need in tbl
        'SAP Rep Prod Def Id': row.stg_rep_prod_def_sap_id
      }
      this.exportData.push(obj);
    })
    this.exportService.exportAsExcelFile(this.exportData, fileName, ['SAP_DATALOAD_ERR']);
    this.SpinnerService.hide();
  }


  Resetafterdialogmsg(data) {
    this.modelNumber = '';
    this.optn = '';
    this.sapMaterialId = '';
    this.partNumber = '';
    this.namc = '';
    this.family = '';
    this.katashiki = '';
    this.customer = '';
    this.dest = '';
    this.hikiateStatus = '';
    this.sp = '';
    this.netSpec = '';
    this.pcSpec = '';
    this.compPcSpec = '';
    this.extractionTimeIn = '';
    this.extractionTimeOut = '';
    this.prodTyp = '';
    this.sapStatus = '';
    this.partDesc = '';
  }

  partdescription(partdata) {
    this.successMessage = "";
    this.errorMessage = ""
    this.loadebomErrMsg = "";
    console.log(this.partNumber, partdata)
    if (this.partNumber != "") {

      if (this.partDesc == "") {
        this.sapDataLoadServiceService.getPartDesc(this.partNumber).subscribe(data => {
          console.log(data)
          if (data.payload) {
            this.partDesc = data.payload[0].part_desc;
            this.errorMessage = "";
            localStorage.setItem("partnumberpopup", this.aPart);
          }
          else {
            this.errorMessage = "Part Number <" + this.partNumber + "> Invalid"
            this.partNumber = ""
            this.globalrole.goToTop();
          }

        });
      }
      else if (this.partDesc != "") {
        if (this.partNumber != localStorage.getItem('partnumber'))
          this.sapDataLoadServiceService.getPartDesc(this.partNumber).subscribe(data => {
            console.log(data)
            if (data.payload) {
              this.aPartDesc = data.payload[0].part_desc;
              this.errorMessage = "";
              localStorage.setItem("partnumberpopup", this.aPart);
            }
            else {
              this.errorMessage = "Part Number <" + this.partNumber + "> Invalid"
              this.globalrole.goToTop();
            }
          });
      }
    }

  }
  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.log(res)
        this.Roledata = res;
      })
      console.log(this.Roledata);
      if (this.Roledata.length != 0) {
        this.Roledata.filter((res) => {
          if (res.function_nm == 'NBPC_9998') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length == 0) {
        let arrayvalue: any; let roleList: any;
        let data = localStorage.getItem('role');
        arrayvalue = data.split(',')
        console.log(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str == '') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        let body = {
          security_grp_nm: roleList
        }
        let userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
          this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
          this.userroleAccessfn();
          this.accessEnable = true;
          resolve(true);
        }else{
        console.log(this.roleAccess)
        this.roleAccess = []
        this.errorMessage = "User does not have permission to perform 'read' operation";
        this.accessEnable = false;
        this.SpinnerService.hide();
      };
      }
      console.log(this.roleAccess);
    })
  }

  ////get from s3 Grid search data
  async getFileFromS3(fileName) {
    this.SpinnerService.show();
    // return new Promise(async resolve => {
    let a = await this.getS3Info();
    if (a) {
      const bucket = new S3(
        {
          accessKeyId: this.accessKey,
          secretAccessKey: this.secretKey,
          sessionToken: this.sessionToken,
          region: 'us-west-2'
        }
      );
      const params = {
        Bucket: 'search-grids',
        Key: fileName,
      };
      console.log(params);
      bucket.getObject(params, (err: any, data: any) => {
        if (err) {
          console.log('download err:::::::::', err)
          this.SpinnerService.hide();
        } else {
          console.log(data)
          this.SpinnerService.hide();
          let result = data.Body.toString();
          let res = JSON.parse(result);
          console.log("res and reult", res, result)
          if (res.length > 0) {
            this.rows = res.map(row => ({
              monthly_rep_flg: row['monthly_rep_flg'],
              compar_bom_flg: row['compar_bom_flg'],
              namc_cd: row['namc_cd'],
              mdl_no: row['mdl_no'],
              optn_cd: row['optn_cd'],
              sap_mtrl_id: row['sap_mtrl_id'],
              fam_cd: row['fam_cd'],
              part_no: row['part_no'],
              part_desc: row['part_desc'],
              prod_typ_desc: row['prod_typ_desc'],
              cmpnt_flg: row['cmpnt_flg'],
              base_optn_typ_cd: row['base_optn_typ_cd'],
              katashiki_cd: row['katashiki_cd'],
              compar_100_pct_optn_flg: row['compar_100_pct_optn_flg'],
              buyoff_cust_nm: row['buyoff_cust_nm'],
              dest_ctry_id: row['dest_ctry_id'],
              hikiate_stat_comb_cd: row['hikiate_stat_comb_cd'],
              net_spec_cd_comb_txt: row['net_spec_cd_comb_txt'],
              pc_spec_cd_comb_txt: row['pc_spec_cd_comb_txt'],
              hundred_pct_optn_flg: row['hundred_pct_optn_flg'],
              compar_pc_spec_cd_comb_txt: row['compar_pc_spec_cd_comb_txt'],
              eff_dt: (row['eff_dt'] == null) ? '' : row['eff_dt'].split('T')[0],
              expr_dt: (row['expr_dt'] == null) ? '' : row['expr_dt'].split('T')[0],
              rmrk_txt: row['rmrk_txt'],
              rep_prod_def_sap_id: row['rep_prod_def_sap_id'],
              err_msg: row['err_msg'],
              stat_flg: row['stat_flg'],
              // base_optn_typ_cd: row['base_optn_typ_cd'],

            })
            )
            console.log(this.rows);
          }
        }
      })
    }
    // })
  }

  getS3Info() {
    return new Promise(resolve => {
      this.sapDataLoadServiceService.fileUpldData().subscribe(data => {
        let result = data.payload[0]
        if (result !== null) {
          const aws = result;
          this.accessKey = aws.AccessKeyId;
          this.secretKey = aws.SecretAccessKey;
          this.sessionToken = aws.SessionToken;
          resolve(true)
        }
        else {
          // this.toastr.error('Data Storage Disconnected.');
          resolve(false)
        }
      }, err => {
        // this.toastr.error('Unable to get keys and token', 'Failed');
      });
    })
  }
}

