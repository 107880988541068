import { Component, OnInit } from '@angular/core';
import { DialogData } from '../dialog-data/dialog-data';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-hikiate-status-view-dialog',
  templateUrl: './hikiate-status-view-dialog.component.html',
  styleUrls: ['./hikiate-status-view-dialog.component.css']
})
export class HikiateStatusViewDialogComponent implements OnInit {

//ngModelValues
  vNamc:any;
  vFamily:any;
  vHikiateStatus:any;
  vHikiateStatusDescription:any;
  vEffectiveInDate:any;
  vEffectiveOutDate:any;
  vDataLoadTS:any;
  spinnerMessage="Please Wait...";


  constructor(public data: DialogData,private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.vNamc=this.data.namc;
    this.vFamily=this.data.family;
    this.vHikiateStatus=this.data.hikiateStatus;
    this.vHikiateStatusDescription=this.data.hikiateStatusDescription;
    this.vEffectiveInDate=this.data.effectiveInDate;
    this.vEffectiveOutDate=this.data.effectiveOutDate;
    this.vDataLoadTS=this.data.dataLoadTS;
    
  }

}
