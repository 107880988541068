<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
<h2 mat-dialog-title cdkDragHandle>View <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
</h2>
<mat-dialog-content class="mat-typography">
  <div class="container">
    <!-- row1 -->
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>NAMC:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vNamc" class="inputtext" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Family:</label></div>
          <div class=col-8><input type="family" [(ngModel)]="vFamily" class="inputtext" disabled></div>
        </div>
      </div>
    </div>
    <br>
    <!-- row2 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Hikiate Status:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vHikiateStatus" class="inputtext" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Hikiate Status Description:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vHikiateStatusDescription" class="inputtext" disabled></div>
        </div>
      </div>
    </div>
    <br>

    <!-- row3 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Data Load TS:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vDataLoadTS" class="inputtext" disabled></div>
        </div>
      </div>
    </div>

    <br>

    <!-- row4 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Effective In Date:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vEffectiveInDate" class="inputtext" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Effective Out Date:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vEffectiveOutDate" class="inputtext" disabled></div>
        </div>
      </div>
    </div>
  </div>
  <br>

</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
</mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">

  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>