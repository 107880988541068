<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <h2 mat-dialog-title cdkDragHandle>Correct BoMs <mat-icon class="close-icon" [mat-dialog-close]="true">close
        </mat-icon>
    </h2>

    <div>
        <table aria-describedby="table" class="table">
            <tr>
                <th scope="col" class="headerCol">Total Count of Affected Prod BoMs</th>
                <th scope="col" class="headerCol">{{total}}</th>
            </tr>
        </table>
    </div>
    <div>
        <table aria-describedby="table" class="table">
            <tr>
                <th scope="col" class="headerCol">Manual Adjustment Rule Id</th>
                <th scope="col" class="headerCol">No Of VIN/Serial # Affected</th>
                <th scope="col" class="headerCol">No Of BOMs Affected</th>
            </tr>
            <tr *ngFor="let correctBom of totalaffected" style="text-align: center;">
                <td class="dataRow">{{correctBom.man_adjstmnt_rule_def_id}}</td>

                <td class="dataRow">{{correctBom.vincount}}</td>
                <td class="dataRow">{{correctBom.count}}</td>
            </tr>
        </table>
    </div>

    <mat-dialog-actions align="center">
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="EditManAdjCorrectBoms()">Correct
            BoMs</button>
        <button mat-button mat-dialog-close>Cancel</button>
    </mat-dialog-actions>

</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
    <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>
