<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Add <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>

  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>

  <mat-dialog-content class="mat-typography">
    <div class="container">
      <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are mandatory</span>
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Version Name<span class="text-danger">*</span>:</label></div>
            <div class="col-8">
              <select aria-label="Default select example" class="picker" [(ngModel)]="versionName"
                (change)="getnamc();getVerEffDate()">
                <option value=""></option>
                <option value="0">Production</option>
                <option *ngFor="let versionName of versionNameLst" [value]="versionName.rep_bom_ver_id">
                  {{versionName.rep_bom_ver_nm}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">

          </div>
        </div>
      </div>
      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>BOM Effective From<span class="text-danger" *ngIf="!hidedata">*</span>:</label>
            </div>
            <div class=col-8>

              <mat-form-field class="datedrop" appearance="outline">
                <input matInput [matDatepicker]="inPicker" [disabled]="hidedata" [(ngModel)]="bomEffectiveFrom" [max]="maxDate"
                  (blur)="dateCheck()" (dateChange)="getnamc()">
                <mat-datepicker-toggle [disabled]="hidedata" matSuffix [for]="inPicker"></mat-datepicker-toggle>
                <mat-datepicker [disabled]="hidedata" #inPicker></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'bomEffectiveFrom')">clear
                </mat-icon>

              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>BOM Effective To<span class="text-danger" *ngIf="!hidedata">*</span>:</label>
            </div>
            <div class=col-8>
              <mat-form-field class="datedrop" appearance="outline">
                <input matInput [matDatepicker]="outPicker" [disabled]="hidedata" [(ngModel)]="bomEffectiveTo" [max]="maxDate"
                  (dateChange)="getnamc()" (blur)="dateCheck()">
                <mat-datepicker-toggle [disabled]="hidedata" matSuffix [for]="outPicker"></mat-datepicker-toggle>
                <mat-datepicker [disabled]="hidedata" #outPicker></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'bomEffectiveTo')">clear
                </mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <!-- row3 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>NAMC<span class="text-danger">*</span>:</label></div>
            <div class="col-8"><select aria-label="Default select example" class="datedrop inputtext" [(ngModel)]="namc"
                (change)='getfamily()'>
                <option value=""></option>
                <option *ngFor="let namc of namcLst" [value]="namc.namc_lgl_entity_id">{{namc.namc_cd}}</option>
              </select></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Part Number<span class="text-danger">*</span>:</label></div>
            <div class="col-8"><input type="text" class="inputtext" [(ngModel)]="partNumber"> </div>
          </div>
        </div>
      </div>
      <!-- row4 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Family<span class="text-danger">*</span>:</label></div>
            <div class="col-8"><select aria-label="Default select example" class="picker" [(ngModel)]="family"
                (change)="getkatashiki()">
                <option value=""></option>
                <option *ngFor="let family of familyLst" [value]="family.fam_cd">{{family.fam_cd}}</option>
              </select></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Katashiki<span class="text-danger">*</span>:</label></div>
            <div class="col-8"><select aria-label="Default select example" class="picker" [(ngModel)]="katashiki">
                <option value=""></option>
                <option *ngFor="let katashiki of katashikiLst" [value]="katashiki.katashiki_cd">
                  {{katashiki.katashiki_cd}}</option>
              </select></div>
          </div>
        </div>
      </div>
      <br>
      <!-- row5 -->
      <div class="row rowClass">
        <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
        <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review" rows="2" cols="50"
            [(ngModel)]="comments">
            </textarea>
        </div>
      </div>

    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-button cdkFocusInitial (click)="SavePartSourceAdj()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">

  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>