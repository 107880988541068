import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogData } from '../dialog-data/dialog-data';
import { ExtraneousPartsListEditDialogService } from './extraneous-parts-list-edit-dialog.service';
import * as moment from 'moment';

@Component({
  selector: 'app-extraneous-parts-list-edit-dialog',
  templateUrl: './extraneous-parts-list-edit-dialog.component.html',
  styleUrls: ['./extraneous-parts-list-edit-dialog.component.css']
})
export class ExtraneousPartsListEditDialogComponent implements OnInit {
  //to main page
  message = "Records Edited Successfully";
  [x: string]: any;
  index: any;

  //ngModelValues
  eExtraneousPartType: any;
  eExtraneousPartDescription: any;
  eMatchingPartText: any;
  eMatchingPartDescriptionText: any;
  eComponentCode: any;
  eSourceRouting: any;
  eDestRouting: any;
  eEffectiveInDate: any;
  eEffectiveOutDate: any;
  eComments: any;
  espcl_part_typ_id: any
  //respone after inserting a record
  response: any;

  constructor(private ExtraneousPartsListEditDialogService: ExtraneousPartsListEditDialogService, public dialogRef: MatDialogRef<ExtraneousPartsListEditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.eExtraneousPartType = this.data.extraneousPartType;
    this.eExtraneousPartDescription = this.data.extraneousPartDescription;
    this.eMatchingPartText = this.data.matchingPartText;
    this.eMatchingPartDescriptionText = this.data.matchingPartDescriptionText;
    this.eComponentCode = this.data.componentCode;
    this.eSourceRouting = this.data.sourceRouting;
    this.eDestRouting = this.data.destRouting;
    this.eEffectiveInDate = this.data.effectiveInDate;
    this.eEffectiveOutDate = this.data.effectiveOutDate;
    this.eComments = this.data.comments;
    this.espcl_part_typ_id = this.data.spcl_part_typ_id;
  }
  uploadExtPart() {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    if (this.effectiveOutDate == "") {
      this.effectiveOutDate = "9999-12-31"
    }
    if (this.eComments == "") {
      this.eComments=" "
    }
    let outdateFormat=""
    if (this.eEffectiveOutDate != "") {
      let d1 = moment(this.eEffectiveOutDate).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.fEffDateOut = date
    }
    this.errorMessage = "";
    let cmts;
    if (this.eComments!= undefined) {
      cmts = this.eComments.replaceAll("'", "''");
    } else {
      cmts = this.eComments
    }
    let body = {
      'expr_dt': outdateFormat,
      'rmrk_txt':cmts ,
      'spcl_part_typ_id': this.espcl_part_typ_id,
      "screenNo":"NBPC_2250",
      "fileName" :fileName
      
    }
    this.SpinnerService.show();
    this.ExtraneousPartsListEditDialogService.editExtPart(body).subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.dialogRef.close(this.response[0].result);
      } else if (this.response[0].result == 'AlreadyExists') {
        this.errorMessage = 'Data Entered already Exists!';
      }
    }, err => {
      this.SpinnerService.hide();
    });
  }

  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });

  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'eEffectiveInDate') {
      this.eEffectiveInDate = "";
    }
    else if (name == 'eEffectiveOutDate') {
      this.eEffectiveOutDate = "";
    }
  }
}

