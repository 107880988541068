<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>View <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>

  <mat-dialog-content class="mat-typography">
    <div class="container">
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>NAMC:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="viewNamc" disabled> </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Complete Part Routing:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="viewCompletePartRouting" disabled> </div>
          </div>
        </div>
      </div>
      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Part Source:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="viewPartSource" disabled> </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
        </div>
      </div>
      <br>
      <!-- row3 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>Effective In Date:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="viewEffectiveInDate" disabled> </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Effective Out Date:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="viewEffectiveOutDate" disabled> </div>
          </div>
        </div>
      </div>
      <!-- row4 -->
      <div class="row rowClass">
        <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
        <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review" rows="4" cols="50"
            [(ngModel)]="viewComments" disabled>
          </textarea>
        </div>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button mat-dialog-close>Ok</button>
  </mat-dialog-actions>
</ng-container>

<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">

  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>