import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse,HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject,Observable, throwError, } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {environment} from 'src/environments/environment';
var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('Ebom_accessToken')
    })
  };
}
@Injectable({
  providedIn: 'root'
})
export class VersionTypesService {

  searchdropdown1UrlApi=environment.Mastersearchdropdown1apiUrl;
  version='getVersionType';
  verDescByType='/getVersionTypeDescription';
  verTypGrid='/getVersionTypeGrid';

  constructor(private http: HttpClient) { }

  //grid data
  getGridData(gridData): Observable<any> {
    let fileName= localStorage.getItem('Filename')
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let verTypGridParams= new HttpParams().set('bomVerTypId',gridData.bomVerTypCdt)
    //                                   .append('bomVerTypDesc',gridData.bomVerTypDesc)
    //                                   .append('option',gridData.option);
    let API_URL = environment.MastergridapiUrl + this.verTypGrid + '?bomVerTypId=' + gridData.bomVerTypCdt
      + '&bomVerTypDesc=' + gridData.bomVerTypDesc + '&option=' + gridData.option +'&screenNo=NBPC_10105'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //versionType
  getVersion(): Observable<any> {
    let fileName= localStorage.getItem('Filename')
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = `${this.searchdropdown1UrlApi}/${this.version}`+'?screenNo=NBPC_10105'+'&fileName=' +fileName;
    return this.http.get(API_URL,httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //getDescByVerType
  getDescByVerType(verTypCd): Observable<any> {
    let fileName= localStorage.getItem('Filename')
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = this.searchdropdown1UrlApi + this.verDescByType + '?bomVerTypeId='+ verTypCd+'&screenNo=NBPC_10105'+'&fileName=' +fileName;
    // let verParams = new HttpParams().set('bomVerTypeId',verTypCd)
    return this.http.get(API_URL,httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage:any = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error.status;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }


}
