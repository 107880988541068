<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Add <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>

<div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
  {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>

  <mat-dialog-content class="mat-typography" >
    <div class="container">
      <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are mandatory</span>
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-3"> <label>Version Type<span class="text-danger">*</span>:</label></div>
            <div class=col-9><select [(ngModel)]="addVerType" (change)='getVerQual()' name=versionType>
                <option value=""></option>
                <option *ngFor="let verTypeLst of verTypeLst" [value]="verTypeLst.bom_ver_typ_id">
                  {{verTypeLst.bom_ver_typ_cd}}</option>
              </select></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-3"> <label>Version Qualifier<span class="text-danger">*</span>:</label></div>
            <div class=col-4 *ngIf="EnableAddqualselect">
              <select [(ngModel)]="addVerQualVal">
                <option value="ACT_AH">ACT_AH</option>
                <option value="STD_AH">STD_AH</option>
              </select>
            </div>
            <div _ngcontent-krv-c360="" class="col-5">
              <span class="text-danger" *ngIf="EnableAddqualselect"> * </span>
              <input _ngcontent-krv-c360="" type="text" class="qualdes" [(ngModel)]="addVerQual">
            </div>
          </div>
        </div>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="center" >
    <button mat-button cdkFocusInitial (click)="submitVerQual()">Save</button>
    <button mat-button mat-dialog-close (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</ng-container>

<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>