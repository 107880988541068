import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DialogData} from '../dialog-data/dialog-data';
import { NgxSpinnerService } from "ngx-spinner"; 

@Component({
  selector: 'app-manual-adjustment-rule-view-dialog',
  templateUrl: './manual-adjustment-rule-view-dialog.component.html',
  styleUrls: ['./manual-adjustment-rule-view-dialog.component.css']
})
export class ManualAdjustmentRuleViewDialogComponent implements OnInit {

	viewRuleType: any;
    viewVersionName: any;
    viewNamc: any;
    viewFamily: any;
    viewKatasPart: any;
    viewGrpi: any;
    viewPartNo: any;
    viewRuleStatus: any;
    viewCreateFromDt: any;
    viewCreateToDt: any;
    viewBomEffFrom: any;
    viewBomEffTo: any;
    viewSp: any;
    viewNetSpec: any;
    viewDest: any;
    viewQtyPerPar: any;
    viewCmpRouting: any;
    viewActUnitCst: any;
    viewActCurrencyCd: any;
    viewComments: any;
    viewPartDesc: any;
      

    spinnerMessage="Please Wait...";
  constructor(private SpinnerService: NgxSpinnerService,@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    this.viewRuleType=this.data.ruleType;
    this.viewVersionName=this.data.versionName;
    this.viewNamc=this.data.namc;
    this.viewFamily=this.data.family;
    this.viewKatasPart=this.data.katasPart;
    this.viewGrpi=this.data.grpi;
    this.viewRuleStatus=this.data.ruleStatus;
    this.viewPartNo=this.data.partNo;
    this.viewCreateFromDt=this.data.createFromDt;
    this.viewCreateToDt=this.data.createToDt;
    this.viewBomEffFrom=this.data.bomEffFrom;
    this.viewBomEffTo=this.data.bomEffFrom;
    this.viewSp=this.data.sp;
    this.viewNetSpec=this.data.netSpec;
    this.viewDest=this.data.dest
    this.viewQtyPerPar=this.data.qtyPerPar;
    this.viewCmpRouting=this.data.cmpRouting;
    this.viewActUnitCst=this.data.actUnitCst;
    this.viewActCurrencyCd=this.data.actCurrencyCd;
    this.viewComments=this.data.comments;
    this.viewPartDesc=this.data.partDesc    
  }

}
