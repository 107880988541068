import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse,HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject,Observable, throwError, } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {environment} from 'src/environments/environment';
var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('Ebom_accessToken')
    })
  };
}

@Injectable({
  providedIn: 'root'
})
export class ProductWithinVersionUploadDialogService {
  fileUploadUrl = environment.BussinessgridapiUrl + '/AssumeRole'

  constructor(private http: HttpClient) { }


  fileUpldData():Observable<any>{
    let fileName= localStorage.getItem('Filename')
    return this.http.get(this.fileUploadUrl+'?screenNo=NBPC_10110'+'&fileName=' +fileName,httpOptions)
    .map((response: any) => response).catch(this.handleError)
  }


  // ===================================== Exception Handler ===========================================
  private handleError(error: HttpErrorResponse) {
    var errorMsg = '';
    if (error.error instanceof ErrorEvent) {
      //A client-side or network error occurred. Handle it accordingly.
      errorMsg = `An error occurred: ${error.error.message}`;
    } else {
      //The backend returned an unsuccessful response code.
      //The response body may contain clues as to what went wrong,
      errorMsg = `Backend returned code ${error.status}`;
    }
    //return an observable with a user-facing error message
    return throwError(errorMsg);
  }

}
