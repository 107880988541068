<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <h2 mat-dialog-title cdkDragHandle>Add <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
    </h2>

    <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
        {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
    </div>

    <mat-dialog-content class="mat-typography" >
        <div class="container">
            <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are
                mandatory</span>
            <mat-card class="mat-background">
                <strong>Choose version to add Representative Products</strong>
                <!-- row1 -->
                <div class="row rowClass">
                    <div class="col-lg-4 col-xs-6 col-md-6">
                        <div class="row">
                            <div class="col-3">
                                <label>Version Type<span class="text-danger">*</span>:</label>
                            </div>
                            <div class="col-9">
                                <select aria-label="Default select example" [(ngModel)]="versionType"
                                    (change)="getVersionQual();enableDates()">
                                    <option value=""></option>
                                    <option *ngFor="let verType of verTypeLst" [value]="verType.bom_ver_typ_id">
                                        {{verType.bom_ver_typ_cd}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xs-6 col-md-6">
                        <div class="row">
                            <div class="col-3">
                                <label>Version Qualifier<span class="text-danger">*</span>:</label>
                            </div>
                            <div class="col-9">
                                <select aria-label="Default select example" [(ngModel)]="versionQual"
                                    (change)="getVersionRevData()">
                                    <option value=""></option>
                                    <option option *ngFor="let verQualifier of verQualifierLst"
                                        [value]="verQualifier.bom_ver_qual_id">
                                        {{verQualifier.bom_ver_qual_cd}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xs-6 col-md-6">
                        <div class="row">
                            <div class="col-3">
                                <label>Version Revision<span class="text-danger">*</span>:</label>
                            </div>
                            <div class="col-9"><input type="text" disabled [(ngModel)]="versionRev"></div>
                        </div>
                    </div>
                </div>

                <!-- row2 -->
                <div class="row">
                    <div class="col-lg-4 col-xs-6 col-md-6">
                        <div class="row">
                            <div class="col-3">
                                <label>BoM Effectivity Date:</label>
                            </div>
                            <div class="col-9"><input type="text" disabled [(ngModel)]="bomEffDate"></div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xs-6 col-md-6">
                        <div class="row">
                            <div class="col-3">
                                <label>Cost Effectivity Date:</label>
                            </div>
                            <div class="col-9"><input type="text" disabled [(ngModel)]="costEffDate"></div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xs-6 col-md-6">
                        <div class="row">

                        </div>
                    </div>
                </div>

            <!-- row3 -->
            <div class="row">
                <div class="col-lg-4 col-xs-6 col-md-6">
                    <div class="row">
                        <div class="col-3">
                            <label>NAMC:</label>
                        </div>
                        <div class="col-9">
                            <select aria-label="Default select example" [(ngModel)]="namc"
                                (change)="this.getFamilyFpn()">
                                <option value=""></option>
                                <option *ngFor="let namc of namcLst" [value]="namc.namc_lgl_entity_id">{{namc.namc_cd}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xs-6 col-md-6">
                    <div class="row">
                        <div class="col-3">
                            <label>Family:</label>
                        </div>
                        <div class="col-9">
                            <select aria-label="Default select example" [(ngModel)]="family"
                                (change)="getKatashikiByFamCd()">
                                <option value=""></option>
                                <option *ngFor="let famcd of famcdLst" [value]="famcd.fam_cd">{{famcd.fam_cd}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xs-6 col-md-6">
                    <div class="row">
                        <div class="col-3">
                            <label>Katashiki:</label>
                        </div>
                        <div class="col-9">
                            <select aria-label="Default select example" [(ngModel)]="katashiki" (change)="getDest()">
                                <option value=""></option>
                                <option *ngFor="let katashiki of katashikiLst" [value]="katashiki.katashiki_cd">
                                    {{katashiki.katashiki_cd}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <!-- row4 -->
            <div class="row rowClass">
                <div class="col-lg-4 col-xs-6 col-md-6">
                    <div class="row">
                        <div class="col-3">
                            <label>Dest:</label>
                        </div>
                        <div class="col-9">
                            <select aria-label="Default select example" [(ngModel)]="dest">
                                <option value=""></option>
                                <option *ngFor="let dest of destLst" [value]="dest.dest_ctry_id">{{dest.dest_ctry_id}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xs-6 col-md-6">
                    <div class="row">
                        <div class="col-3">
                            <label>Hikiate Status:</label>
                        </div>
                        <div class="col-9">
                            <select aria-label="Default select example" [(ngModel)]="hikiate">
                                <option value=""></option>
                                <option value={{hikiate.hikiate_stat_comb_cd}} *ngFor="let hikiate of hikiateLst">
                                    {{hikiate.hikiate_stat_comb_cd}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xs-6 col-md-6">
                    <div class="row">
                        <div class="col-3">
                            <label>Part:</label>
                        </div>
                        <div class="col-9"><input type="text" [(ngModel)]="part"  oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                </div>

            </div>
                <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6" style="text-align: right;">
                        <button mat-raised-button (click)="openGrid()">Go</button>
                        <button mat-raised-button (click)="resetAll()">Reset</button>
                    </div>
                </div>

            </mat-card>
            <div class="d-flex" *ngIf="showItems">
                <button mat-raised-button (click)="addVersion()">Add</button>
                <button mat-raised-button [mat-dialog-close]="true">Cancel</button>
            </div>
            <div *ngIf="showItems">
                <strong>Representative Products</strong>
                <button mat-raised-button (click)="selectMonthlyprod()">Select Monthly Rep Prod</button>
            </div>

        <ngx-datatable #addVerTable *ngIf="showItems" class="material striped" [rows]="rows"
            [columnMode]="ColumnMode.force" [headerHeight]="45" [footerHeight]="30" rowHeight="30"
            [limit]="limitSelected" [selected]="selected" [selectionType]="SelectionType.checkbox"
            [selectAllRowsOnPage]="false" [displayCheck]="displayCheck" (activate)="onActivate($event)"
            (select)="onSelect($event)"  [scrollbarH]="true">
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset"
                    let-isVisible="isVisible">
                    <div class="page-count">
                        {{selectedCount}} selected / {{rowCount}} total
                    </div>
                    <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
                        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
                        [hidden]="!((rowCount / pageSize) > 1)" (change)="table.onFooterPage($event)">
                    </datatable-pager>
                    <div class="test">
                        <select [(ngModel)]="limitSelected" style="width: 20% !important">
                            <option>50</option>
                            <option>100</option>
                        </select>
                        <div class="float-right">View {{(offset * pageSize)+1}} - {{(rowCount>
                            (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
                            {{(rowCount)}}
                  
                        </div>
                    </div>
                </ng-template>
            </ngx-datatable-footer>
            <ngx-datatable-column [width]="10" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true">
            </ngx-datatable-column>
            <ngx-datatable-column name="NAMC" prop="namc_cd"></ngx-datatable-column>
            <ngx-datatable-column name="Model Number" prop="mdl_no"></ngx-datatable-column>
            <ngx-datatable-column name="Option" prop="optn_cd"></ngx-datatable-column>
            <ngx-datatable-column name="SAP Material ID" prop="sap_mtrl_id" [width]="200"></ngx-datatable-column>
            <ngx-datatable-column name="Family" prop="fam_cd"></ngx-datatable-column>
            <ngx-datatable-column name="Katashiki" prop="katashiki_cd"></ngx-datatable-column>
            <ngx-datatable-column name="Dest" prop="dest_ctry_id"></ngx-datatable-column>
            <ngx-datatable-column name="S/P" prop="base_optn_typ_cd"></ngx-datatable-column>
            <ngx-datatable-column name="Net Spec" prop="net_spec_cd_comb_txt"></ngx-datatable-column>
            <ngx-datatable-column name="PC Spec" prop="pc_spec_cd_comb_txt"></ngx-datatable-column>
            <ngx-datatable-column name="Comp PC Spec" prop="compar_pc_spec_cd_comb_txt"></ngx-datatable-column>
            <ngx-datatable-column name="Hikiate Status" prop="hikiate_stat_comb_cd"></ngx-datatable-column>
            <ngx-datatable-column name="Comments" prop="rmrk_txt"></ngx-datatable-column>
            <ngx-datatable-column name="Part No.(Product)" prop="prod_part_no"></ngx-datatable-column>
            <ngx-datatable-column name="100% Spec">
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value" prop="hundred_pct_optn_flg">
                    <input type="checkbox" [checked]="row.hundred_pct_optn_flg=='Y'" disabled />
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Comp 100% Spec">
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value" prop="compar_100_pct_optn_flg">
                    <input type="checkbox" [checked]="row.compar_100_pct_optn_flg=='Y'" disabled />
                </ng-template>
            </ngx-datatable-column>

            </ngx-datatable>

        </div>
    </mat-dialog-content>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
    <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>
