import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { DialogData } from '../dialog-data/dialog-data';
import { MajorPartTypeAddDialogComponent } from '../major-part-type-add-dialog/major-part-type-add-dialog.component';
import { MajorPartTypeEditDialogComponent } from '../major-part-type-edit-dialog/major-part-type-edit-dialog.component';
import { MajorPartTypeService } from './major-part-type.service';
import { NgxSpinnerService } from "ngx-spinner";

import { GlobalService } from '../global.service';

@Component({
  selector: 'app-major-part-type',
  templateUrl: './major-part-type.component.html',
  styleUrls: ['./major-part-type.component.css']
})
export class MajorPartTypeComponent implements OnInit {

  //export to excel
  fileName = 'MajorPartType';
  exportData = [];
  sheetName = ['MAJ_PART_TYP'];

  //alertMessage
  errorMessage: string = '';

  //messagefromDialog
  successMessage: string = '';

  //ngModel values for search
  step = 0;
  majPartType: any = "";
  productType: any = "";
  status: any = "Active";
  comments: any = "";

  //list
  majPartTypeLst = [];
  productTypeLst = [];
  statusLst = [];

  //ngx-datatable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('majPartTypeTable') table;
  limitSelected: any = 50;

  spinnerMessage = "Please Wait...";

  showdata: boolean = true;
  columns = [
    { name: 'majorePartType', prop: 'maj_part_typ_nm' }, { name: 'productType', prop: 'prod_typ_cd' },
    { name: 'status', prop: 'inactv_flg' }, { name: 'comments', prop: 'rmrk_txt' }
  ];
  ColumnMode = ColumnMode;
  recordcount: any = 0;
  Roledata: any;
  roleAccess: any = [];
  accessEnable: boolean;

  constructor(public dialog: MatDialog, private majPartTypService: MajorPartTypeService,
    private exportService: ExportToExcelService, private dialogData: DialogData,
    private SpinnerService: NgxSpinnerService, public globalrole: GlobalService, ) { }


  async ngOnInit() {
    this.SpinnerService.show();
    let a = await this.userroleAccessfn();
    if (a) {
      console.log('a', a)
      //grid
      this.getGridData("initial");
    }
    this.onloadfn();
  }

  onloadfn() {
    //majPartTyp
    this.majPartTypefn()

    //majProdTyp
    this.producTypefn()
  }

  majPartTypefn() {
    this.majPartTypService.getMajPartTyp().subscribe(data => {
      console.log(data);
      this.majPartTypeLst = data.payload;
    });
  }
  producTypefn() {
    this.majPartTypService.getProdTyp().subscribe(data => {
      console.log(data);
      this.productTypeLst = data.payload;
    });
  }
  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  //ngx-table
  onSelect({ selected }) {
    this.selected = selected
    console.log('Select Event', selected, this.selected);

    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }
  singleSelectCheck(row: any) {
    return this.selected.indexOf(row) === -1;
  }

  resetAll(data): void {
    this.selected = [];
    this.majPartType = '';
    this.productType = '';
    this.status = 'Active';
    this.comments = '';
    this.errorMessage = "";
    this.successMessage = '';
    // this.getGridData(data);
    this.onloadfn();

  }
  openAddDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(MajorPartTypeAddDialogComponent, {
        disableClose: true,
        width: '50%'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        if (result == 'success') {
          // this.resetAll("add");
          this.getGridData('add');
          this.majPartTypefn()
          this.producTypefn()
          this.successMessage = "Records Added Successfully"
          //  this.getGridData();
        }
        else if (result == 'AlreadyExists') {
          this.resetAll("initial");
          this.errorMessage = 'Data Entered already Exists!'
          this.globalrole.goToTop();
        }
        else {
          // this.errorMessage = 'Data not inserted/Valid'
        }
      });
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }
  }
  openEditDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    if (this.roleAccess.includes('Update')) {
      if (this.selected.length == 1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(MajorPartTypeEditDialogComponent, {
          disableClose: true,
          width: '50%',
          data: this.dialogData
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed', result);
          if (result == 'success') {
            this.getGridData("add");
            this.successMessage = "Records Updated Successfully"
            // this.getGridData();
          }
          else if (result == 'AlreadyExists') {
            this.resetAll("initial");
            this.errorMessage = 'Data Entered already Exists!';
            this.globalrole.goToTop();
          }
          else {
            // this.errorMessage = 'Data not inserted/Valid'
          }
        });
      }
      else {
        this.errorMessage = "Please select a row to edit";
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
    }
  }

  exportFile(): void {
    this.successMessage = '';
    this.errorMessage = '';
    this.rows.forEach(row => {
      let obj = {
        "Majore Part Type": row.maj_part_typ_nm,
        "Product Type": row.prod_typ_cd,
        "Status": row.inactv_flg,
        "Comments": row.rmrk_txt
      }
      this.exportData.push(obj);
    });

    this.exportService.exportAsExcelFile(this.exportData, this.fileName, this.sheetName);
    this.exportData = [];
  }

  closeAlert(): void {
    this.errorMessage = '';
    this.successMessage = '';
  }

  sendToDialog(selected): void {
    selected.forEach(row => {
      this.dialogData.majorPartType = row.maj_part_typ_nm;
      this.dialogData.majorPartProdType = row.prod_typ_cd;
      this.dialogData.majorPartComments = row.rmrk_txt;
      this.dialogData.majorPartStatus = row.inactv_flg;
      this.dialogData.majorPartTypId = row.maj_part_typ_id;

    });
    console.log(this.dialogData.monthlyRep)
  }

  getGridData(data) {
    this.selected = [];
    console.log(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      let sts = ""

      if (this.majPartType == undefined) {
        this.majPartType = ""
      }
      if (this.productType == undefined) {
        this.productType = ""
      }
      if (this.status == undefined) {
        this.status = ""
      }
      if (this.status == "Active") {
        sts = 'N'
      } else if (this.status == "Inactive") {
        sts = 'Y'
      }
      let majPartTypObj
      let cmts;
    if (this.comments!= undefined) {
      cmts = this.comments.replaceAll("'", "''");
    } else {
      cmts = this.comments
    }
      if (data == 'initial') {
        majPartTypObj =
        {
          mjPrtTypNm: this.majPartType,
          prodTypCd: this.productType,
          comments: cmts,
          status: sts,
          option: data
        }
      } else {
        majPartTypObj =
        {
          mjPrtTypNm: "",
          prodTypCd: "",
          comments: "",
          status: 'N',
          option: data
        }
      }
      //gridData
      this.SpinnerService.show();
      this.majPartTypService.getGridData(majPartTypObj).subscribe(data => {
        console.log(data);
        this.showdata = true;
        this.recordcount = data.pagination.totalRecords;
        console.log(this.showdata);
        this.SpinnerService.hide();
        let datarow = data.payload;
        this.rows = datarow.map(row => ({
          maj_part_typ_nm: row['maj_part_typ_nm'],
          // prod_typ_cd: row['prod_typ_cd'],
          prod_typ_cd: row['prod_typ_desc'],
          inactv_flg: row['inactv_flg'] == "N" ? "Active" : "Inactive",
          rmrk_txt: row['rmrk_txt'],
          maj_part_typ_id: row['maj_part_typ_id']
        }));
      }, err => {
        console.log(err);
        this.showdata = false;
        this.successMessage = "";
        console.log(this.showdata);
        this.rows = [];
        if (err == 0) {
          this.errorMessage = "Request timed out. Please try again.";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        } else {
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        }
      });
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }

  search() {
    this.errorMessage = '';
    this.successMessage = '';
    this.getGridData("initial");
  }

  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.log(res)
        this.Roledata = res;
      })
      console.log(this.Roledata);
      if (this.Roledata.length != 0) {
        this.Roledata.filter((res) => {
          if (res.function_nm == 'NBPC_2221') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length == 0) {
        let arrayvalue: any; let roleList: any;
        let data = localStorage.getItem('role');
        arrayvalue = data.split(',')
        console.log(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str == '') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        let body = {
          security_grp_nm: roleList
        }
        let userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
          this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
          this.userroleAccessfn();
          this.accessEnable = true;
          resolve(true);
        }else{
        console.log(this.roleAccess)
        this.roleAccess = []
        this.errorMessage = "User does not have permission to perform 'read' operation";
        this.accessEnable = false;
        this.SpinnerService.hide();
      };
      }
      console.log(this.roleAccess);
    })
  }

}
