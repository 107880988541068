import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { SelectivityPartEditDialogService } from './selectivity-part-edit-dialog.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-selectivity-part-edit-dialog',
  templateUrl: './selectivity-part-edit-dialog.component.html',
  styleUrls: ['./selectivity-part-edit-dialog.component.css']
})


export class SelectivityPartEditDialogComponent implements OnInit {
  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');
  //to main page

  [x: string]: any;
  //to main page
  message = "Records Added Successfully";
  index: any;
  errorMessage = "";
  //ngModelValues

  eNamc: any;
  eFamily: any;
  eGrpi: any;
  eSelectivity: any;
  ePartNumber: any;
  eParentPart: any;
  eCompletePartRouting: any;
  eSelectivityRuleVin: any;
  eSelectivityRuleRep: any;
  eSelectivityRuleTimeIn: any;
  eSelectivityRuleTimeOut: any;
  eComments: any;
  eslctv_part_rule_id: any;
  //respone after inserting a record
  response: any;
  spinnerMessage = "Please Wait...";

  constructor(private SelectivityPartEditDialogService: SelectivityPartEditDialogService, public dialogRef: MatDialogRef<SelectivityPartEditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public SpinnerService: NgxSpinnerService) { }
  ngOnInit(): void {
    this.eNamc = this.data.namc;
    this.eFamily = this.data.family;
    this.eGrpi = this.data.grpi;
    this.eSelectivity = this.data.selectivity;
    this.ePartNumber = this.data.partNumber;
    this.eParentPart = this.data.parentPart;
    this.eCompletePartRouting = this.data.completePartRouting;
    this.eSelectivityRuleVin = this.data.selectivityRuleVin;
    this.eSelectivityRuleRep = this.data.selectivityRuleRep;
    this.eSelectivityRuleTimeIn = this.data.selectivityRuleTimeIn;
    this.eSelectivityRuleTimeOut = this.data.selectivityRuleTimeOut;
    this.eComments = this.data.comments;
    this.eslctv_part_rule_id = this.data.slctv_part_rule_id;
  }



  uploadSelPart() {
    let fileName= localStorage.getItem('Filename')
    if (this.eSelectivityRuleTimeIn && this.eSelectivityRuleTimeOut && this.pipe.transform(this.eSelectivityRuleTimeIn, 'yyyy-MM-dd') > this.pipe.transform(this.eSelectivityRuleTimeOut, 'yyyy-MM-dd')) {

      this.errorMessage = "Selectivity Rule Time Out should be greater than or equal to Selectivity Rule Time In";

    }

    else {
      let outdateFormat=""
      let user = localStorage.getItem('workDayId');
      this.errorMessage = "";
      console.log(this.eSelectivityRuleTimeOut)
      if (this.eSelectivityRuleTimeOut == "") {
        outdateFormat = "9999-12-31"
      }
     
      if (this.eSelectivityRuleTimeOut != "") {
        let d1 = moment(this.eSelectivityRuleTimeOut).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.eSelectivityRuleTimeOut = date
      }
      let cmts;
      if (this.eComments!= undefined) {
        cmts = this.eComments.replaceAll("'", "''");
      } else {
        cmts = this.eComments
      }
      let body = {
        'slctvRuleTimeOut': outdateFormat,
        'comments': cmts,
        'slctvPartRuleID': this.eslctv_part_rule_id,
        "screenNo":"NBPC_2230",
        "fileName" :fileName      

      }
      this.SpinnerService.show();
      this.SelectivityPartEditDialogService.editSelectivityPart(body).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.response = data.payload;
        if (this.response[0].result == 'success') {
          this.dialogRef.close(this.response[0].result);
        } else if (this.response[0].result == 'AlreadyExists') {
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        this.SpinnerService.hide();
      });
    }

  }

  //closealert
  closeAlert() {
    this.errorMessage = "";
  }

  cancel() {

    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });

  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'eSelectivityRuleTimeOut') {
      this.eSelectivityRuleTimeOut = "";
    }
  }

}












