import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReferenceModule} from '../reference.module';
import { FamilyProjectNamcComponent } from '../family-project-namc/family-project-namc.component';
import { FamilyProjectNamcUploadDialogComponent } from '../family-project-namc-upload-dialog/family-project-namc-upload-dialog.component';
import { FamilyProjectNamcEditDialogComponent } from '../family-project-namc-edit-dialog/family-project-namc-edit-dialog.component';
import { FamilyProjectNamcViewDialogComponent } from '../family-project-namc-view-dialog/family-project-namc-view-dialog.component';
import { FamilyProjectNamcAddDialogComponent } from '../family-project-namc-add-dialog/family-project-namc-add-dialog.component';
import { FamilyProjectNamcMultieditDialogComponent } from '../family-project-namc-multiedit-dialog/family-project-namc-multiedit-dialog.component';



@NgModule({
  declarations: [
    FamilyProjectNamcComponent,
    FamilyProjectNamcUploadDialogComponent,
    FamilyProjectNamcEditDialogComponent,
    FamilyProjectNamcViewDialogComponent,
    FamilyProjectNamcAddDialogComponent,
    FamilyProjectNamcMultieditDialogComponent
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class FamProjNamcModule { }
