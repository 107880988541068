<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane" >
  <h2 mat-dialog-title cdkDragHandle>Edit <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon></h2>

  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>

  <mat-dialog-content class="mat-typography" >
    <div class="container">
      <span class="fst-italic">Field marked with asterisk ' <span class="text-danger">*</span> ' are mandatory</span>

      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"><label>Monthly Representative:</label></div>
            <div class="col-2"><input type="checkbox" [(ngModel)]="emMonthlyRep"></div>
          </div>
        </div>
      </div>
      <!-- row6 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Extraction Time In<span class="text-danger">*</span>:</label></div>
            <div class=col-2>
              <input type="date" class="picker" [(ngModel)]="emExtractionTimeIn" max="9999-12-31">
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Extraction Time Out<span class="text-danger">*</span>:</label></div>
            <div class=col-2>
              <input type="date" class="picker" [(ngModel)]="emExtractionTimeOut" max="9999-12-31">
            </div>
          </div>
        </div>
      </div>
      <!-- row7 -->
      <div class="row rowClass">
        <div class="col-2"> <label>Comments:</label></div>
        <div class=col-10><textarea class="w-100" name="w3review" rows="4" cols="50" [(ngModel)]="emComments">
        </textarea>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center" >
    <button mat-button cdkFocusInitial (click)="updateMultiRepUnits()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>
