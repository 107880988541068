import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReferenceModule} from '../reference.module';
import { ExchangeRateSearchComponent } from '../exchange-rate-search/exchange-rate-search.component';
import { ExchangeRateAddDialogComponent } from '../exchange-rate-add-dialog/exchange-rate-add-dialog.component';
import { ExchangeRateEditDialogComponent } from '../exchange-rate-edit-dialog/exchange-rate-edit-dialog.component';



@NgModule({
  declarations: [
    ExchangeRateSearchComponent,
    ExchangeRateAddDialogComponent,
    ExchangeRateEditDialogComponent
  ],
  imports: [
    CommonModule,
    ReferenceModule
    
  ]
})
export class ExcRateModule { }
