<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>View <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>

  <mat-dialog-content class="mat-typography">
    <div class="container">
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Family:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="fFamily" disabled> </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Project:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="fProject" disabled> </div>
          </div>
        </div>
      </div>
      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Model Year:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="fModelYear" disabled> </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Model Change Type:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="fModelChangeType" disabled> </div>
          </div>
        </div>
      </div>
      <!-- row3 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>NAMC:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="fNamc" disabled> </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>SSN:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="fSsn" disabled> </div>
          </div>
        </div>
      </div>
      <!-- row4 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Hikiate Status:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="fHikiate" disabled> </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="row">
        </div>
      </div>
      <!-- row5 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>Prod Time In:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="fProdDateIn" disabled> </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Prod Time Out:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="fProdDateOut" disabled> </div>
          </div>
        </div>
      </div>
      <!-- row5 -->

      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>Generation Id:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="fGeneration" disabled> </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Line Off Plant:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="fLineOffPlant" disabled> </div>
          </div>
        </div>
      </div>
      <!-- row6 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>Extraction Time In :</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="fEffDateIn" disabled> </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Extraction Time Out:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="fEffDateOut" disabled> </div>
          </div>
        </div>
      </div>
      <!-- row7 -->
      <div class="row rowClass">
        <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
        <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review" rows="4" cols="50"
            [(ngModel)]="fComments" disabled>
            </textarea>
        </div>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button mat-dialog-close>Ok</button>
  </mat-dialog-actions>
</ng-container>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>