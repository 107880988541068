<span *ngIf="accessEnable">
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
  {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>
<mat-accordion class="example-headers-align">
  <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>

    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Please select the fields to generate the reports</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-form-field>
      <mat-label>NAMC</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="namc" name="namc" (selectionChange)='getFamCdByNamc()'>
        <mat-option value="">ALL</mat-option>
        <mat-option *ngFor="let namc of namcLst" [value]="namc.namc_lgl_entity_id">{{namc.namc_cd}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Family</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="family" name="family">
        <mat-option value="">ALL</mat-option>
        <mat-option *ngFor="let family of familyLst" [value]="family.fam_cd">{{family.fam_cd}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Version</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="version" name="version">
        <mat-option value="">ALL</mat-option>
        <mat-option *ngFor="let version of versionLst" [value]="version.version">{{version.version}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Part Source</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="partSource" name="partSource">
        <mat-option value="">ALL</mat-option>
        <mat-option *ngFor="let partSource of partSourceLst" [value]="partSource.part_src_typ_cd">
          {{partSource.part_src_typ_cd}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Complete Part Routing</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="completePartRouting" name="completePartRouting">
        <mat-option value="">ALL</mat-option>
        <mat-option *ngFor="let completePartRouting of completePartRoutingLst"
          [value]="completePartRouting.cmplt_rtg_cd">{{completePartRouting.cmplt_rtg_cd}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Cost Type</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="costType" name="costType">
        <mat-option value="">ALL</mat-option>
        <mat-option *ngFor="let costType of costTypeLst" [value]="costType.cost_typ_nm">{{costType.cost_typ_nm}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Katashki Part</mat-label>
      <input matInput placeholder="" [(ngModel)]="katashkiPart">
    </mat-form-field>


    <mat-form-field>
      <mat-label>GRPI</mat-label>
      <input matInput placeholder="" [(ngModel)]="grpi">
    </mat-form-field>

    <mat-form-field>
      <mat-label>VIN/SR No</mat-label>
      <input matInput placeholder="" [(ngModel)]="vinsrNo">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Part</mat-label>
      <input matInput placeholder="" [(ngModel)]="part">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Buyer</mat-label>
      <input matInput placeholder="" [(ngModel)]="buyer">
    </mat-form-field>

    <mat-form-field class="picker">
      <mat-label>Start Date</mat-label>
      <input matInput [matDatepicker]="inPicker" [(ngModel)]="startDate">
      <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
      <mat-datepicker #inPicker></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'startDate')">clear</mat-icon>
    </mat-form-field>

    <mat-form-field class="picker">
      <mat-label>End Date</mat-label>
      <input matInput [matDatepicker]="outPicker" [(ngModel)]="endDate">
      <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
      <mat-datepicker #outPicker></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'endDate')">clear</mat-icon>
    </mat-form-field>


    <mat-form-field>
      <mat-label>Error Message</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="errMsg" name="errMsg">
        <mat-option value="">ALL</mat-option>
        <mat-option *ngFor="let errMsg of errMsgLst" [value]="errMsg.err_desc">{{errMsg.err_desc}}</mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-button style="float: right;" (click)="generateReport()">Generate Report</button>
  </mat-expansion-panel>
</mat-accordion>

<div class="d-flex" *ngIf="showButtons">
  <button mat-raised-button (click)="exportFile()" class="ms-auto"> Export To Excel </button>
</div>

<!-- <mat-card> -->
<ngx-datatable #myTable *ngIf="showGrid" class="material striped" [rows]="rows" [columnMode]="ColumnMode.force"
  [headerHeight]="45" [footerHeight]="30" rowHeight="30" [limit]="limitSelected" [selected]="selected"
  [selectionType]="SelectionType.checkbox" [selectAllRowsOnPage]="false" [displayCheck]="displayCheck"
  (activate)="onActivate($event)" (select)="onSelect($event)" [scrollbarH]="true"
  [ngClass]="{'hideerror': showdata, 'showerror': !showdata }">

  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
      let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
      <div class="page-count">

      </div>
      <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
        (change)="table.onFooterPage($event)">
      </datatable-pager>
      <div class="test">
        <select [(ngModel)]="limitSelected">
          <option>50</option>
          <option>100</option>
          <option>500</option>
        </select>
        <div class="float-right">View {{(offset * pageSize)+1}} - {{(rowCount>
          (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
          {{(rowCount)}}

        </div>
      </div>
    </ng-template>
  </ngx-datatable-footer>


  <ngx-datatable-column name="NAMC" prop="namc_cd"></ngx-datatable-column>
  <ngx-datatable-column name="Family" prop="fam_cd"></ngx-datatable-column>
  <ngx-datatable-column name="Version" prop="prod_bom_desc"></ngx-datatable-column>
  <ngx-datatable-column name="Katashiki/Part" prop="katashiki_cd"></ngx-datatable-column>
  <ngx-datatable-column name="BOM Effectivity Date" prop="prod_trnfr_dt"></ngx-datatable-column>
  <ngx-datatable-column name="GRPI" prop="grpi_cd"></ngx-datatable-column>
  <ngx-datatable-column name="Part Source" prop="part_source"></ngx-datatable-column>
  <ngx-datatable-column name="Complete Part Routing" prop="part_src_rtng"></ngx-datatable-column>
  <ngx-datatable-column name="Part" prop="part_no"></ngx-datatable-column>
  <ngx-datatable-column name="Part Description" prop="part_desc"></ngx-datatable-column>
  <ngx-datatable-column name="Dest" prop="dest_ctry_id"></ngx-datatable-column>
  <ngx-datatable-column name="VIN/SR No" prop="vin_serl_no"></ngx-datatable-column>
  <ngx-datatable-column name="Supplier" prop="supplier_cd"></ngx-datatable-column>
  <ngx-datatable-column name="Supplier Name" prop="supplier_nm"></ngx-datatable-column>
  <ngx-datatable-column name="Buyer" prop="buyer_cd"></ngx-datatable-column>
  <ngx-datatable-column name="Buyer Name" prop="buyer_nm"></ngx-datatable-column>
  <ngx-datatable-column name="Cost Type" prop="cost_type"></ngx-datatable-column>
  <ngx-datatable-column name="ACT Cost" prop="actl_cost"></ngx-datatable-column>
  <ngx-datatable-column name="TRN Cost" prop="trnfr_cost"></ngx-datatable-column>
  <ngx-datatable-column name="Currency Code" prop="crncy_cd"></ngx-datatable-column>
  <ngx-datatable-column name="Waterfall Status" prop="waterfall_status"></ngx-datatable-column>
  <ngx-datatable-column name="Suspension Date" prop="excp_dt"></ngx-datatable-column>
  <ngx-datatable-column name="Error Message" prop="err_desc"></ngx-datatable-column>
  <ngx-datatable-column name="Prod Def Id" prop="prod_def_id"></ngx-datatable-column>
  <ngx-datatable-column name="Prod Bom Id" prop="prod_bom_id"></ngx-datatable-column>

</ngx-datatable>
</span>
<div *ngIf="!accessEnable">
  <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">

  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>
