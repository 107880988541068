import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReferenceModule} from '../reference.module';
import { MngDataTransSapComponent } from '../mng-data-trans-sap/mng-data-trans-sap.component';
import { MngDataTransEditDialogComponent } from '../mng-data-trans-edit-dialog/mng-data-trans-edit-dialog.component';
import { MngDataTransViewDialogComponent } from '../mng-data-trans-view-dialog/mng-data-trans-view-dialog.component';



@NgModule({
  declarations: [
    MngDataTransSapComponent,
    MngDataTransEditDialogComponent,
    MngDataTransViewDialogComponent,
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class MngDataTrnsfrModule { }
