<span *ngIf="accessEnable">
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <div class="alert alert-success alert-dismissible " *ngIf="successMessage!=''" role="alert">
    {{successMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <mat-accordion class="example-headers-align">
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>

    <mat-expansion-panel-header>
      <mat-panel-title>
        Search
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-form-field>
      <mat-label>Pc Spec Position</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="pcSpecPosition" name="pcSpecPosition">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let pcSpecPosition of pcSpecPositionLst" [value]="pcSpecPosition.pc_spec_posn_cd">
          {{pcSpecPosition.pc_spec_posn_cd}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Comments</mat-label>
      <input matInput placeholder="" [(ngModel)]="comments">
    </mat-form-field>


    <button mat-button style="float: right;" (click)="resetAll('initial')">Reset</button>
    <button mat-button style="float: right;" (click)="search()">Search</button>

  </mat-expansion-panel>
</mat-accordion>

<div class="d-flex">
  <button mat-raised-button (click)="openAddDialog()">Add </button>
  <button mat-raised-button (click)="openEditDialog()">Edit </button>
  <button mat-raised-button (click)="exportFile()" class="ms-auto"> Export To Excel </button>
</div>

<!-- <mat-card> -->
<ngx-datatable #myTable class="material striped" [rows]="rows" [columnMode]="ColumnMode.force" [headerHeight]="45"
  [footerHeight]="30" rowHeight="30" [limit]="limitSelected" [selected]="selected"
  [selectionType]="SelectionType.single" [selectAllRowsOnPage]="false" [displayCheck]="displayCheck"
  (activate)="onActivate($event)" (select)="onSelect($event)"
  [ngClass]="{'hideerror': showdata, 'showerror': !showdata}">
  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
      let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
      <div class="page-count">
        {{selectedCount}} selected / {{recordcount}} total
      </div>
      <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
        (change)="table.onFooterPage($event)">
      </datatable-pager>
      <div class="test">
        <select [(ngModel)]="limitSelected">
          <option>50</option>
          <option>100</option>
          <option>500</option>
        </select>
        <div class="float-right records">View {{(offset * pageSize)+1}} - {{(rowCount>
          (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
          {{(recordcount)}}

          </div>
        </div>
      </ng-template>
    </ngx-datatable-footer>
    <ngx-datatable-column [width]="60" [sortable]="false" [canAutoResize]="false" [draggable]="false"
      [resizeable]="false" [headerCheckboxable]="false" [checkboxable]="true" name="Select">
    </ngx-datatable-column>
    <ngx-datatable-column name="Pc Spec Position" prop="pc_spec_posn_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Hikiate Status">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value" prop="hikiate_stat_flg">
        <input type="checkbox" [checked]="row.hikiate_stat_flg=='Y'" disabled />
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Effective From" prop="eff_dt" [width]="200"></ngx-datatable-column>
    <ngx-datatable-column name="Effective To" prop="expr_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Comments" prop="rmrk_txt"></ngx-datatable-column>

  </ngx-datatable>
</span>
<div *ngIf="!accessEnable">
  <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">

  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>