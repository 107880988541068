<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
<h2 mat-dialog-title cdkDragHandle >Add <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
</h2>
<div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
  {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>

</div>

<mat-dialog-content class="mat-typography">
  <div class="container">
    <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are mandatory</span>
    <!-- row1 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Currency Code From<span class="text-danger">*</span>:</label></div>
          <div class=col-8>
            <select class="heightadd" [(ngModel)]="eCurrencyCodeFrom">
              <option value=""></option>
              <option *ngFor="let data of currcodefromArray" [(ngValue)]="data.crnc_cd">{{data.crnc_cd}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Currency Code To<span class="text-danger">*</span>:</label></div>
          <div class=col-8><input type="text" disabled [(ngModel)]="eCurrencyCodeTo" class="inputtext"></div>
        </div>
      </div>
    </div>
    <!-- row2 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Cost Type<span class="text-danger">*</span>:</label></div>
          <div class=col-8>
            <select class="heightadd" [(ngModel)]="eCostType" aria-label="Default select example">
              <option value=""></option>
              <option value="ACT">ACT</option>
              <option value="STD">STD</option>
            </select>

          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Month Selection<span class="text-danger">*</span>:</label></div>
          <div class=col-8><select class="heightadd" [(ngModel)]="eMonthSelection" aria-label="Default select example"
              (change)="getExchangeRtEffDates()" id="aExchngRate">
              <option value=""></option>
              <option value="1">JAN</option>
              <option value="2">FEB</option>
              <option value="3">MAR</option>
              <option value="4">APR</option>
              <option value="5">MAY</option>
              <option value="6">JUN</option>
              <option value="7">JUL</option>
              <option value="8">AUG</option>
              <option value="9">SEP</option>
              <option value="10">OCT</option>
              <option value="11">NOV</option>
              <option value="12">DEC</option>

            </select></div>
        </div>
      </div>
    </div>

    <!-- row3 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row ">
          <div class="col-4"> <label>Effective In Date:</label></div>
          <div class=col-8><input type="text" disabled [(ngModel)]="eEffectiveInDate" id="aExchngRtExtrctTimeIn" class="inputtext"></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Effective Out Date:</label></div>
          <div class=col-8><input type="text" disabled [(ngModel)]="eEffectiveOutDate" id="aExchngRtExtrctTimeOut" class="inputtext">
          </div>
        </div>
      </div>
    </div>

    <!-- row4 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Exchange Rate<span class="text-danger">*</span>:</label></div>
          <div class=col-8>
            <input type="number" [(ngModel)]="eExchangeRate" class="inputtext">
          </div>
        </div>
      </div>
    </div>


    <!-- row5 -->
    <div class="row rowClass">
      <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
      <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review" rows="2" cols="95" [(ngModel)]="eComments">
          </textarea>
      </div>
    </div>
  </div>



</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button cdkFocusInitial (click)="save()">Save</button>
  <button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>