import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { VersionTypesAddDailogComponent } from '../version-types-add-dailog/version-types-add-dailog.component';
import { VersionTypesDeleteDailogComponent } from '../version-types-delete-dailog/version-types-delete-dailog.component'
import { VersionTypesService } from './version-types.service';
import { NgxSpinnerService } from "ngx-spinner";
import { DialogData } from '../dialog-data/dialog-data';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-version-types',
  templateUrl: './version-types.component.html',
  styleUrls: ['./version-types.component.css']
})
export class VersionTypesComponent implements OnInit {


  //List
  verTypeLst = [];
  verTypeDescLst = [];

  //ngModel
  verType: any = "";
  verTypeDesc: any = "";
  step = 0;

  //errorMessage
  errorMessage: string = '';

  //messagefromDialog
  successMessage: string = '';

  spinnerMessage = "Please Wait...";
  recordcount: any = 0;
  roleAccess: any = [];
  Roledata: any;
  accessEnable: boolean;

  constructor(public dialog: MatDialog, private verTypService: VersionTypesService,
    private SpinnerService: NgxSpinnerService, private dialogData: DialogData, public globalrole: GlobalService, ) { }

  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  resetAll(data) {
    this.selected = [];
    this.verType = '';
    this.verTypeDesc = '';
    this.errorMessage = '';
    this.successMessage = '';
    // this.getGridData(data);
    this.onloadfn();
  }

  //ngx-datatable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('verTypTable') table;
  limitSelected: any = 50;

  showdata: boolean = true;
  columns = [
    { name: 'versionType', prop: 'bom_ver_typ_cd' }, { name: 'versionTypeDesc', prop: 'bom_ver_typ_desc' },
    { name: 'versionTypId', prop: 'bom_ver_typ_id' }, { name: 'createById', prop: 'create_by_id' },
    { name: 'createTs', prop: 'create_ts' }, { name: 'effDt', prop: 'eff_dt' },
    { name: 'exchngRtTyp', prop: 'exchng_rt_typ' }, { name: 'exprDt', prop: 'expr_dt' },
    { name: 'updateById', prop: 'update_by_id' }, { name: 'updateTs', prop: 'update_ts' }

  ];
  ColumnMode = ColumnMode;

  async ngOnInit() {
    this.SpinnerService.show();
    let a = await this.userroleAccessfn();
    if (a) {
      console.log('a', a)
      //grid
      this.getGridData("initial");
    }
    this.onloadfn();


  }
  onloadfn(){
    //getVersion
    this.getversion();
    //getversiondescription
    this.getDescByTyp();


  }
  getversion() {
    this.verTypService.getVersion().subscribe(data => {
      console.log(data);
      this.verTypeLst = data.payload;
      // this.verTypeDescLst = data.versionType.response;
    });

  }

  //ngx-table
  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);

  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }
  displayCheck(row) {
    return row;
  }

  singleSelectCheck(row: any) {
    return this.selected.indexOf(row) === -1;
  }

  openAddDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(VersionTypesAddDailogComponent, {
        disableClose: true,
        // width: '50%'
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == 'success') {
          this.getGridData("add");
          this.getversion();
          this.getDescByTyp();
          this.successMessage = "Representative Product Version Type Added Successfully"
          this.selected = []
        }
        else if (result == 'AlreadyExists') {
          this.errorMessage = 'Data Entered already Exists!'
          this.globalrole.goToTop();
        }
        else {
          // this.errorMessage = 'Data not inserted/Valid'
        }
      });
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }
  }
  openDeleteDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    if (this.roleAccess.includes('Delete')) {
      if (this.selected.length > 0) {
        this.sendToDialog(this.selected);
        const dialogRef = this.dialog.open(VersionTypesDeleteDailogComponent, {
          disableClose: true,
          width: '50%',
          data: this.selected
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result == 'success') {
            this.getGridData("add");
            this.getversion();
            this.getDescByTyp();
            this.successMessage = "Representative Product Version Type Deleted Successfully"
            this.selected = []
          } else if (result == 'cancel') {
            this.errorMessage = "";
            this.successMessage = "";
          }
          else {
            this.errorMessage = result
          }
        });
      }
      else {
        this.errorMessage = "Please select a row to delete"
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'delete' operation"
      this.globalrole.goToTop();
    }
  }

  //onchangeMethod
  getDescByTyp(): void {
    if (this.verType == undefined) {
      this.verType = ""
    }
    this.verTypService.getDescByVerType(this.verType).subscribe(data => {
      console.log(data);
      this.verTypeDescLst = data.payload;
      this.verTypeDesc = ""
    });

  }

  closeAlert() {
    this.errorMessage = '';
    this.successMessage = '';
  }
  search() {
    this.errorMessage = '';
    this.successMessage = '';
    this.getGridData("initial");
  }

  getGridData(data) {
    this.selected = [];
    console.log(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      if (this.verType == undefined) {
        this.verType = ""
      }
      if (this.verTypeDesc == undefined) {
        this.verTypeDesc = ""
      }
      let verTypObj 
      if (data == 'initial') {
        verTypObj =
        {
          bomVerTypCdt: this.verType,
          bomVerTypDesc: this.verTypeDesc,
          option: data
        }
      } else {
        verTypObj =
        {
          bomVerTypCdt: "",
          bomVerTypDesc: "",
          option: data
        }
      }
      this.SpinnerService.show();
      this.verTypService.getGridData(verTypObj).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.showdata = true;
        this.recordcount = data.pagination.totalRecords;
        console.log(this.showdata);
        this.rows = data.payload;
      }, err => {
        console.log(err);
        this.showdata = false;
        this.successMessage = "";
        console.log(this.showdata);
        this.rows = [];
        if (err == 0) {
          this.errorMessage = "Request timed out. Please try again.";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        } else {
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        }
      });
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }

  sendToDialog(selected): void {

    selected.forEach(row => {
      this.dialogData.verTypId = row.bom_ver_typ_id;
      console.log(this.dialogData.verTypId);
    });
  }

  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.log(res)
        this.Roledata = res;
      })
      console.log(this.Roledata);
      if (this.Roledata.length != 0) {
        this.Roledata.filter((res) => {
          if (res.function_nm == 'NBPC_10105') {
            this.roleAccess.push(res.access_desc);
          }
        })
        resolve(true);
        this.accessEnable = true;
      } else if (this.Roledata.length == 0) {
        let arrayvalue: any; let roleList: any;
        let data = localStorage.getItem('role');
        arrayvalue = data.split(',')
        console.log(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str == '') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        let body = {
          security_grp_nm: roleList
        }
        let userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
          this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
          this.userroleAccessfn();
          this.accessEnable = true;
          resolve(true);
        }else{
        console.log(this.roleAccess)
        this.roleAccess = []
        this.errorMessage = "User does not have permission to perform 'read' operation";
        this.accessEnable = false;
        this.SpinnerService.hide();
      };
      }
      console.log(this.roleAccess);
    })

  }


}
