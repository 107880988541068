import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { ProductWithinVersionService } from './product-within-version.service';
import { ProductWithinVersionAddDialogComponent } from '../product-within-version-add-dialog/product-within-version-add-dialog.component';
import { ProductWithinVersionEditDialogComponent } from '../product-within-version-edit-dialog/product-within-version-edit-dialog.component';
import { GenerateRepVerSumDialogComponent } from '../generate-rep-ver-sum-dialog/generate-rep-ver-sum-dialog.component';
import { GenerateActProdStatusComponent } from '../generate-act-prod-status/generate-act-prod-status.component';
import { ProductWithinVersionUploadDialogComponent } from '../product-within-version-upload-dialog/product-within-version-upload-dialog.component';
import { ProductWithinVersionViewDialogComponent } from '../product-within-version-view-dialog/product-within-version-view-dialog.component';
import { ProductWithinVersion } from '../dialog-data/product-within-version';
import { NgxSpinnerService } from "ngx-spinner";
import { ProdVersionActionsComponent } from '../prod-version-actions/prod-version-actions.component'
import { ProdVersionPart2ActionsComponent } from '../prod-version-part2-actions/prod-version-part2-actions.component'
import * as moment from 'moment';
import { GlobalService } from '../global.service';

declare var $: any;
import * as S3 from 'aws-sdk/clients/s3';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-product-within-version',
  templateUrl: './product-within-version.component.html',
  styleUrls: ['./product-within-version.component.css']
})
export class ProductWithinVersionComponent implements OnInit {
  [x: string]: any;

  limitexceed: any;
  //export to excel
  fileName = 'ProductWithinVersion';
  exportData = [];
  errorExportData = [];
  sheetName = ["PROD_WITHIN_VER"];
  repData = [];
  errorSheetName = ['PROD_WITHIN_VER_ERROR'];
  errorFileName = 'ProdWithinVerError';

  roleAccess: any = []
  Roledata: any = [];
  //alertMessage
  errorMessage: string = '';


  //messagefromDialog
  successMessage: string = '';

  //ngModel values for search
  step = 0;
  products: any = 'All'
  versionNm: any = '';
  modelNumber: any = "";
  option: any = "";
  sapMatId: any = "";
  sapStatus: any = "";
  versionType: any = "";
  versionQual: any = "";
  versionRev: any = "";
  approvalStatus: any = 'Open';
  namc: any = "";
  family: any = "";
  katashiki: any = "";
  status: any = "";
  partNo: any = "";
  customer: any = "";
  dest: any = "ALL";
  hikiate: any = "";
  sp: any = "";
  netSpec: any = "";
  netStructure: any = "";
  onHoldStatus: any = "";
  copyStruc: any = "";
  screen = 'vehicle';

  //list
  versionNmLst = [];
  modelNumberLst = [];
  optionLst = [];
  sapStatusLst = [];
  versionTypeLst = [];
  versionQualLst = [];
  versionRevLst = [];
  namcLst = [];
  famcdLst = [];
  katashikiLst = [];
  statusLst = [];
  partNoLst = [];
  customerLst = [];
  destLst = [];
  hikiateLst = [];
  netStructureLst = [];
  approvalStatusLst = [];

  //ngx-datatable
  showGrid: boolean = false;
  showButtons: boolean = false;
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;

  spinnerMessage = "Please wait...";
  showcpyStrucErrMsg = false
  cpyErrMsg = ''
  addtoversionErrMsg = ''
  cpystruFailedRows: any;
  showdata: boolean = true;

  columns = [
    { name: 'namc', prop: 'namc_cd' }, { name: 'modelNumber', prop: 'mdl_no' },
    { name: 'option', prop: 'optn_cd' }, { name: 'sapMatId', prop: 'sap_mtrl_id' },
    { name: 'family', prop: 'fam_cd' }, { name: 'katashiki', prop: 'katashiki_cd' },
    { name: 'dest', prop: 'dest_ctry_id' }, { name: 'cust', prop: 'buyoff_cust_nm' },
    { name: 'sp', prop: 'base_optn_typ_cd' }, { name: 'netStructure', prop: 'net_struc_flg' },
    { name: 'netSpec', prop: 'net_spec_cd_comb_txt' }, { name: 'pcSpec', prop: 'pc_spec_cd_comb_txt' },
    { name: 'compPCSpec', prop: 'compar_pc_spec_cd_comb_txt' }, { name: 'partNo', prop: 'prod_part_no' },
    { name: 'copyStruc', prop: 'reuse_struc_flg' }, { name: 'status', prop: 'prod_stat_typ_cd' },
    { name: 'versionType', prop: 'bom_ver_typ_cd' }, { name: 'versionQual', prop: 'bom_ver_qual_cd' },
    { name: 'versionRev', prop: 'bom_ver_rev_nbr' }, { name: 'repProdDefId', prop: 'rep_prod_def_id' },
    { name: 'repProdId', prop: 'rep_prod_id' }, { name: 'hikiate', prop: 'hikiate_stat_comb_cd' },
    { name: 'hundPercent', prop: 'hundred_pct_optn_flg' }, { name: 'compHundPercent', prop: 'compar_100_pct_optn_flg' },
    { name: 'comments', prop: 'rmrk_txt' }, { name: 'bomEffDt', prop: 'bom_eff_dt' },
    { name: 'costEffDt', prop: 'cost_eff_dt' }, { name: 'onHoldStatus', prop: 'on_hold_flg' },
    { name: 'procToSap', prop: 'sent_to_sap_ts' }, { name: 'sapProcStat', prop: 'sap_proc_stat_typ_nm' }


  ];
  ColumnMode = ColumnMode;
  value: any = [];


  constructor(public dialog: MatDialog, private productWithinVerService: ProductWithinVersionService,
    private exportService: ExportToExcelService, private dialogData: ProductWithinVersion,
    private SpinnerService: NgxSpinnerService, private elementRef: ElementRef,
    public globalrole: GlobalService, ) { }

  async ngOnInit(): Promise<void> {
    if (localStorage.getItem('login_time') == '1') {
      window.location.reload()
      localStorage.setItem('login_time', '2');
    }
    let a = await this.userroleAccessfn();
    if (a) {
      this.onloadfn();
    }
  }

  onloadfn() {
    this.getversionNameData();

    this.versioncnt();
    // to get model number
    this.productWithinVerService.getModelNumber(this.screen).subscribe(data => {
      console.log(data);
      this.modelNumberLst = data.payload;
    });


    // to get option
    this.productWithinVerService.getOption(this.screen).subscribe(data => {
      console.log(data);
      this.optionLst = data.payload;
    });
    // to processedSapStatus
    this.productWithinVerService.getProcessedSapStat().subscribe(data => {
      console.log(data);
      this.sapStatusLst = data.payload;
    });

    //vertype
    this.productWithinVerService.getVersionType().subscribe(data => {
      console.log(data);
      this.versionTypeLst = data.payload;
    });

    //verQual
    this.getVersionQual()


    //approvalStatus
    // this.productWithinVerService.getApprovalStat().subscribe(data => {
    //   console.log(data);
    //   this.approvalStatusLst = data.payload;
    // });
    this.approvalstsfn('');

    //to get namc
    this.productWithinVerService.getNamcFpn().subscribe(data => {
      console.log(data);
      this.namcLst = data.payload;
    });

    //to get status

    this.productWithinVerService.getStatus().subscribe(data => {
      console.log(data);
      this.statusLst = data.payload;
    });



    this.getHikiate();

    // to get customer;
    this.getCustByKatashiki();

    // to get dest
    this.getDestProdWthnVersion();

    //to get Katashiki
    // this.getKatashikiByFamCd();

    // to get family
    this.getFamilyFpn();
  }
  versioncnt() {
    let fileName= localStorage.getItem('Filename')
    let body={
      "screenNo":"NBPC_10110",
      "fileName" :fileName
    }
    this.productWithinVerService.versioncnt(body).subscribe(res => {
      console.log("res from version name", res.payload);
      this.versioncntval = res.payload[0].Count
      console.log(this.versioncntval)
    })
  }

  getversionNameData() {
    this.productWithinVerService.getversionNmdropdown().subscribe(res => {
      console.log("res from version name", res.payload);
      this.versionNmLst = res.payload
    })
  }

  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  //ngx-table
  onSelect({ selected }) {
    this.selected = selected;

    console.log('Select Event', selected, this.selected.length);
    // console.log('Select Event', selected, this.selected);
    // // this.selected = selected
    let data = []

    if (selected.length == this.rows.length) {
      data = this.selected.filter(function (el) {
        return el.checkboxdata != false
      });
      this.value = data;
    } else {
      this.value = this.selected
    }

    console.log(this.value);

  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }
  displayCheck(row) {
    return row.checkboxdata != false;
  }

  //export function
  exportFile(): void {

    this.exportData = [];
    console.log(this.rows)
    this.rows.forEach(row => {
      let obj = {
        "NAMC": row.namc_cd,
        "Model Number": row.mdl_no,
        "Option": row.optn_cd,
        "SAP Material Id": row.sap_mtrl_id,
        "Family": row.fam_cd,
        "Katashiki": row.katashiki_cd,
        "Dest": row.dest_ctry_id,
        "Cust": row.buyoff_cust_nm,
        "S/P": row.base_optn_typ_cd,
        "Net Structure": row.net_struc_flg,
        "Net Spec": row.net_spec_cd_comb_txt,
        "PC Spec": row.pc_spec_cd_comb_txt,
        "Comp PC Spec": row.compar_pc_spec_cd_comb_txt,
        "Part No": row.prod_part_no,
        "Copy Structure": row.reuse_struc_flg,
        "Status": row.prod_stat_typ_cd,
        "Version Type": row.bom_ver_typ_cd,
        "Version Qualifier": row.bom_ver_qual_cd,
        "Version Revision": row.bom_ver_rev_nbr,
        "Rep Prod Def Id": row.rep_prod_def_id,
        "Rep Prod Id": row.rep_prod_id,
        "Hikiate Status": row.hikiate_stat_comb_cd,
        "100% Spec": row.hundred_pct_optn_flg,
        "Comp 100% Spec": row.compar_100_pct_optn_flg,
        "Comments": row.rmrk_txt,
        "BoM Effectivity Date": row.bom_eff_dt,
        "Cost Effectivity Date": row.cost_eff_dt,
        "On-Hold Status": row.on_hold_flg,
        "Processed to SAP": row.sent_to_sap_ts,
        "SAP Processing Status": row.sap_proc_stat_typ_nm
      }
      this.exportData.push(obj);
    });
    console.log(this.exportData)
    this.exportService.exportAsExcelFile(this.exportData, this.fileName, this.sheetName);
    this.exportData = []
  }
  //alert close
  closeAlert(): void {
    this.errorMessage = '';
    this.successMessage = '';
    this.cpyErrMsg = '';
    this.addtoversionErrMsg = ''
  }

  //reset
  resetAll(data): void {
    // this.selected = [];
    this.modelNumber = '';
    this.option = '';
    this.sapMatId = '';
    this.sapStatus = '';
    this.versionType = '';
    this.versionQual = '';
    this.versionRev = '';
    this.approvalStatus = 'Open';
    this.namc = '';
    this.family = '';
    this.katashiki = '';
    this.status = '';
    this.partNo = '';
    this.customer = '';
    this.dest = 'ALL';
    this.hikiate = '';
    this.sp = '';
    this.netSpec = '';
    this.netStructure = '';
    this.onHoldStatus = '';
    this.copyStruc = '';
    this.successMessage = ''
    this.errorMessage = ''
    this.cpyErrMsg = ''
    this.addtoversionErrMsg = ''
    this.versionRevLst = [];
    this.katashikiLst = [];
    // this.selected = []
    // this.value = [];
    // if (data != 'upload' && data != '') {
    //   this.getGridData(false);
    // } else {
    //   console.log('data')
    //   this.showGrid = false;
    //   this.showButtons = false;
    // }
    this.onloadfn();
  }

  //search func
  search(): void {
    this.selected = []
    this.value = []
    this.showGrid = true;
    this.showButtons = true;
    this.errorMessage = '';
    this.successMessage = '';
    this.cpyErrMsg = ''
    this.addtoversionErrMsg = ''
    this.getGridData(false);

  }

  //add dialog
  openAddDialog(): void {
    this.errorMessage = "";
    this.successMessage = "";
    this.cpyErrMsg = "";
    this.addtoversionErrMsg = ''
    console.log(this.roleAccess.includes('Create'))
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(ProductWithinVersionAddDialogComponent, {
        disableClose: true,
        width: '100%',
        data: this.dialogData
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        console.log(result.result)
        let FailedRows;
        let successData;
        let Failedcount;
        if (result.result[1].FailedRecords != undefined) {
          FailedRows = result.result[1].FailedRecords.rows
          Failedcount = result.result[1].FailedRecordscount.rows[0].count
          this.addtoversionErrlist = result.result[1].FailedRecords.rows
          successData = result.result[0].CountData.rows[0]
        }
        if (result == "Already is in progress. Please Work Later") {
          this.errorMessage = "Already is Inprogress. Please Work Later";
          this.globalrole.goToTop();
        }
        else if (FailedRows.length == 0) {
          this.successMessage = successData.count + " Product(s) added successfully to Version <" + result.vertyp + result.verqual + result.verrev + '>'
          // this.getGridData(true)
          this.globalrole.goToTop();
        }
        else if (FailedRows.length != 0) {
          this.addtoversionErrMsg = successData.count + " Product(s) added successfully to Version <" + result.vertyp + result.verqual + result.verrev + '> and ' + Failedcount + ' Product(s) failed to add successfully'
          this.globalrole.goToTop();
        }
        else {
          this.errorMessage = result
          this.globalrole.goToTop();
        }
      });
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation"
      this.globalrole.goToTop();
    }
  }

  //edit dialog
  openEditDialog(): void {
    this.errorMessage = "";
    this.successMessage = "";
    this.cpyErrMsg = "";
    this.addtoversionErrMsg = ''
    console.log(this.roleAccess.includes('Update'))
    if (this.roleAccess.includes('Update')) {
      if (this.value.length == 1) {
        this.sendToDialog(this.value)
        const dialogRef = this.dialog.open(ProductWithinVersionEditDialogComponent, {
          disableClose: true,
          data: this.dialogData
        });

        dialogRef.afterClosed().subscribe(result => {

          this.selected = [];
          this.value = [];
          console.log('The dialog was closed', result);
          if (result == 'success') {
            this.successMessage = "Data Updated Successfully"
            this.getGridData(true)
          }
          else if (result == 'cancel') {
            this.successMessage = ""
            this.errorMessage = ""
          }
          else {
            console.log("Data nt updated");
            this.errorMessage = "Data not updated!"
            this.globalrole.goToTop();
            // this.errorMessage = 'Data not inserted/Valid'
          }
        });
      }
      else {
        this.errorMessage = "Please select a row to edit";
        this.selected = []
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation"
      this.globalrole.goToTop();
    }
  }

  //view dialog
  openViewDialog(): void {
    this.errorMessage = '';
    this.successMessage = "";
    this.cpyErrMsg = "";
    this.addtoversionErrMsg = ''
    console.log(this.roleAccess.includes('Read'))
    if (this.roleAccess.includes('Read')) {
      if (this.value.length == 1) {

        this.sendToDialog(this.value)
        const dialogRef = this.dialog.open(ProductWithinVersionViewDialogComponent, {
          disableClose: true,
          data: this.dialogData
          // width:'100%'
        });

        dialogRef.afterClosed().subscribe(result => {
          this.selected = [];
          this.value = [];
          if (result.data) {
            this.successMessage = result.data;
          }
        });
      }
      else {
        this.errorMessage = "Please select a row to view";
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'read' operation"
      this.globalrole.goToTop();
    }
  }


  //repversummary
  generateRepVerSummary(): void {
    this.errorMessage = '';
    console.log(this.versionNm);
    if (this.versionNm.length <= 20) {
      let prodtyp = this.products == '' ? null : this.products
      this.dialogData.versionName = this.versionNm
      this.dialogData.productType = prodtyp
      const dialogRef = this.dialog.open(GenerateRepVerSumDialogComponent, {
        disableClose: true,
        width: '100%',
        data: this.dialogData
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }
    else if (this.versionNm.length > 20) {
      this.errorMessage = "Version Limit Exceeded";
      this.globalrole.goToTop();
    }
    else {
      this.errorMessage = "Please select a version to Generate Rep Version Sumamry";
      this.globalrole.goToTop();
    }
  }

  //actProdStatus
  generateActProdStatus(): void {
    this.errorMessage = '';
    let prodtyp = this.products == '' ? null : this.products
    this.dialogData.versionName = this.versionNm
    this.dialogData.productType = prodtyp
    const dialogRef = this.dialog.open(GenerateActProdStatusComponent, {
      disableClose: true,
      width: '100%',
      data: this.dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Data not found') {
        this.errorMessage = result;
        this.globalrole.goToTop();
      } else {
        this.errorMessage = result;
        this.globalrole.goToTop();
      }

    });

  }

  //version Report
  generateVerValRpt(): void {
    this.successMessage = '';
    this.errorMessage = '';
    this.cpyErrMsg = "";
    this.addtoversionErrMsg = ''
    this.exportData = [];
    this.fileName = "VersionValidationReport";
    this.sheetName = ["VER_VAL_REP"];
    let tempdata = [];
    this.value.filter((res) => {
      if (res.bom_ver_typ_cd == 'ACT' || res.bom_ver_typ_cd == 'HON') {
        if (res.base_optn_typ_cd == 'S' && (res.prod_stat_typ_cd != 'Verified' || res.prod_stat_typ_cd != 'Ready for Review')) {
          tempdata.push(res)
        }
      } else if (res.bom_ver_typ_cd == 'STD') {
        if (res.base_optn_typ_cd == 'P' && (res.prod_stat_typ_cd != 'Verified' || res.prod_stat_typ_cd != 'Ready for Review')) {
          tempdata.push(res)
        }
      }
    })
    console.log(tempdata)
    if (this.value.length > 0) {
      this.errorMessage = '';
      if (tempdata.length > 0) {
        this.sendToDialogData(this.value, 'genvervalRpt')
        const dialogRef = this.dialog.open(ProdVersionActionsComponent, {
          disableClose: true,
          data: this.dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
          this.selected = [];
          this.value = []
          console.log('The dialog was closed');
          let FailedRows;
          let successData;
          let resetrec;

          if (result[1].FailedRecords != undefined) {
            FailedRows = result[1].FailedRecords.rows
            successData = result[0].CountData.rows[0]
            resetrec = result[2].ResetRecords.rows
          }
          if (result == "Already is in progress. Please Work Later") {
            this.errorMessage = "Already is Inprogress. Please Work Later";
            this.globalrole.goToTop();
          }
          else if (FailedRows.length == 0) {
            // this.successMessage = successData.count + " product(s) Generate Version Validation Report"
            // this.getGridData(true);
            this.downloadrpt(FailedRows)
            this.globalrole.goToTop();
          }
          else if (FailedRows.length != 0) {
            // this.showcpyStrucErrMsg = true
            // this.errorMessage = successData.count + " products(s) Generate Version Validation Report and "
            //   + FailedRows.length + " products(s) failed "
            this.globalrole.goToTop();
            this.downloadrpt(FailedRows)
            // this.errorMessage = 'Data not inserted/Valid'
          } else {
            this.errorMessage = result
          }
        });
      } else {
        this.errorMessage = " Please select 'Ready for Review' or higher statuses for 'S' Products in ACT & HON Versions and 'P' Products in STD Version"
        this.globalrole.goToTop();
      }
    }
    else {
      this.errorMessage = "Please select a row"
      this.globalrole.goToTop();
    }


  }
  downloadrpt(data) {
    let dataRow = data
    console.log(dataRow)
    if (dataRow.length != 0) {
      dataRow.forEach(row => {
        let obj = {
          "IC Part #": row.ic_part,
          "Version": row.version,
          "NAMC": row.namc,
          "Family": row.family,
          "Katashiki": row.katashiki,
          "Customer": row.cust,
          "Dest": row.dest,
          "PC Spec": row.pc_spec,
          // "100% Spec": row.hundred_pct_optn_flg,
          "Comparison Spec": row.comp_pc_spec,
          "Comparison 100% Spec": row.comp_hun_spec,
          "Net Spec": row.net_spec,
          "Error Message": row.err_msg

        }
        this.exportData.push(obj);
      });
    } else {
      let obj = {
        "IC Part #": "",
        "Version": "",
        "NAMC": "",
        "Family": "",
        "Katashiki": "",
        "Customer": "",
        "Dest": "",
        "PC Spec": "",
        "100% Spec": "",
        "Comparison Spec": "",
        "Comparison 100% Spec": "",
        "Net Spec": "",
        "Error Message": "",

      }
      this.exportData.push(obj);
    }
    this.SpinnerService.hide()
    this.exportService.exportAsExcelFile(this.exportData, "VersionValidationReport", ["VER_VAL_REP"]);
    this.exportData = []
  }

  //upload function
  uploadExcel() {
    this.errorMessage = "";
    this.successMessage = "";
    this.cpyErrMsg = "";
    this.addtoversionErrMsg = ''
    console.log(this.roleAccess.includes('Create'))
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(ProductWithinVersionUploadDialogComponent, {
        disableClose: true,
        width: '50%'
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        if (result == 'success') {
          this.getGridData('upload');
          this.successMessage = 'Upload Process Started, Uploaded Information will be share via Mail'
        }
        else if (result == 'cancel') {
          this.cpyErrMsg = "";
          this.addtoversionErrMsg = ''
          this.successMessage = ''
          this.errorMessage = ''
        }
        else {
          this.errorMessage = result
          this.globalrole.goToTop();
        }
      });
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation"
      this.globalrole.goToTop();
    }
  }

  // sendToDialog(){

  // }


  //data to dialog box
  sendToDialog(selected): void {
    selected.forEach(row => {
      this.dialogData.modelNumber = row.mdl_no,
        this.dialogData.option = row.optn_cd,
        this.dialogData.sapMatId = row.sap_mtrl_id,
        this.dialogData.versionType = row.bom_ver_typ_cd,
        this.dialogData.versionQual = row.bom_ver_qual_cd,
        this.dialogData.versionRev = row.bom_ver_rev_nbr,
        this.dialogData.bomEffDate = row.bom_eff_dt,
        this.dialogData.costEffDate = row.cost_eff_dt,
        this.dialogData.status = row.bom_proc_stat_typ_cd,
        this.dialogData.namc = row.namc_cd,
        this.dialogData.family = row.fam_cd,
        this.dialogData.katashiki = row.katashiki_cd,
        this.dialogData.partNo = row.prod_part_no,
        this.dialogData.cust = row.buyoff_cust_nm,
        this.dialogData.dest = row.dest_ctry_id,
        this.dialogData.hikiate = row.hikiate_stat_comb_cd,
        this.dialogData.sp = row.base_optn_typ_cd,
        this.dialogData.pcSpec = row.pc_spec_cd_comb_txt,
        this.dialogData.hundPercentSpec = row.hundred_pct_optn_flg == 'Y' ? true : false,
        this.dialogData.compPcSpec = row.compar_pc_spec_cd_comb_txt,
        this.dialogData.compHundPercentSpec = row.compar_100_pct_optn_flg == 'Y' ? true : false,
        this.dialogData.netSpec = row.net_spec_cd_comb_txt,
        this.dialogData.onHoldStatus = row.on_hold_flg == 'Y' ? true : false,
        this.dialogData.comments = row.rmrk_txt
      this.dialogData.net_struc_flg = row.net_struc_flg
      this.dialogData.reuse_struc_flg = row.reuse_struc_flg
      this.dialogData.rep_prod_id = row.rep_prod_id
      this.dialogData.rep_prod_def_id = row.rep_prod_def_id

    });

  }

  // generateRepVerDetail() {
  //   this.successMessage = ''
  //   this.errorMessage = ''
  //   let body = {
  //     'proc_nm': 'REP_VERISON_DETAIL'
  //   }
  //   this.SpinnerService.show();
  //   this.productWithinVerService.getProcStatus(body).subscribe(data => {
  //     console.log(data);
  //     this.response = data.payload;
  //     if (this.response[0].proc_status == 'INITIATED') {
  //       this.errorMessage = "Already inProgress, Please wait sometime"
  //     } else if (this.response[0].proc_status != 'INITIATED') {
  //       this.updateProcProcessStatus();
  //     }
  //   }, err => {
  //     this.SpinnerService.hide();
  //     this.errorMessage = "Function Failed";
  //   });
  // }
  // updateProcProcessStatus() {
  //   let mail = localStorage.getItem('emailId');
  //   let body = {
  //     'mailId': mail,
  //     'proc_nm': 'REP_VERISON_DETAIL'
  //   }
  //   this.productWithinVerService.editProcProcessStatus(body).subscribe(data => {
  //     console.log(data);
  //     this.response = data.payload;
  //     if (this.response[0].result == 'success') {
  //       this.fetchspcall()
  //     } else {
  //       this.SpinnerService.hide();
  //       this.errorMessage = this.response[0].result;
  //     }
  //   }, err => {
  //     this.SpinnerService.hide();
  //     this.errorMessage = "Function Failed";
  //   });
  // }

  // fetchspcall() {
  //   let data = 'EBOM_ADM.sp_ebom_rep_version_detail'
  //   this.productWithinVerService.getSpCall(data).subscribe(data => {
  //     console.log(data);
  //     // this.response = data.payload;
  //     this.FetchProcStatus();
  //   }, err => {
  //     this.FetchProcStatus();
  //     this.errorMessage = "Function Failed";
  //   });
  // }

  // FetchProcStatus() {
  //   let body = {
  //     'proc_nm': 'REP_VERISON_DETAIL'
  //   }
  //   this.productWithinVerService.getProcStatus(body).subscribe(data => {
  //     console.log(data);
  //     this.response = data.payload;
  //     if (this.response[0].proc_status == 'IN_PROGRESS') {
  //       this.tmptoexcel();
  //     } else if (this.response[0].proc_status != 'IN_PROGRESS') {
  //       this.FetchProcStatus();
  //     }
  //     else if (this.response[0].proc_status == 'ERROR') {
  //       this.SpinnerService.hide();
  //       this.errorMessage = "Error returned in Procedure";
  //       console.log("error returned in procedure");
  //     }
  //     else {

  //     }
  //   }, err => {
  //     this.SpinnerService.hide();
  //     this.errorMessage = "Function Failed";
  //   })
  // }

  // tmptoexcel() {
  //   this.productWithinVerService.insertexcelS3().subscribe(data => {
  //     console.log(data);
  //     this.response = data.payload;
  //     if (this.response == 'excel uploaded successfully') {
  //       this.gets3file()
  //     }
  //     else {
  //       this.errorMessage = "Excel Not Download";
  //     }
  //   }, err => {
  //     this.SpinnerService.hide();
  //     this.errorMessage = "Function Failed";
  //   })
  // }


  // gets3file() {
  //   return new Promise(async resolve => {

  //     let a = await this.getS3Info();
  //     if (a) {
  //       const bucket = new S3(
  //         {
  //           accessKeyId: this.accessKey,
  //           secretAccessKey: this.secretKey,
  //           sessionToken: this.sessionToken,
  //           region: 'us-west-2'
  //         }
  //       );
  //       const params = {
  //         Bucket: 'excelpivot',
  //         Key: 'PivotData.xlsx',
  //       };
  //       console.log(params);
  //       bucket.getObject(params, (err: any, data: any) => {
  //         if (err) {
  //           console.log('download err:::::::::', err)
  //           this.SpinnerService.hide();
  //         } else {
  //           console.log(data)
  //           this.SpinnerService.hide();
  //           let result = data.Body.toString();
  //           // let res = JSON.parse(result);
  //           // console.log("res and reult", res, result)
  //           const file: Blob = new Blob([data.Body], {
  //             type: ''
  //           });
  //           FileSaver.saveAs(file, 'PivotData.xlsx');
  //         }
  //       })
  //     }
  //   })
  // }
  getGridData(data) {
    let TfileName= localStorage.getItem('Filename')
    this.selected = [];
    console.log(this.roleAccess.includes('Read'))
    if (this.roleAccess.includes('Read')) {
      this.SpinnerService.show();
      if (this.dest == undefined) {
        this.dest = "";
      }
      if (this.approvalStatus == undefined) {
        this.approvalStatus = "";
      }
      let mdlno = this.modelNumber == '' ? null : this.modelNumber
      let opt = this.option == '' ? null : this.option
      let sapMtrlID = this.sapMatId == '' ? null : this.sapMatId
      let sapproStatus = this.sapStatus == '' ? null : this.sapStatus
      let verTyp = this.versionType == '' ? null : this.versionType
      let verqual = this.versionQual == '' ? null : this.versionQual
      let vernum = this.versionRev == '' ? null : this.versionRev
      let apprStatus = this.approvalStatus == '' ? null : this.approvalStatus
      let namc = this.namc == '' ? null : this.namc
      let family = this.family == '' ? null : this.family
      let katashiki = this.katashiki == '' ? null : this.katashiki
      let status = this.status == '' ? null : this.status
      let partnum = this.partNo == '' ? null : this.partNo
      let cust = this.customer == '' ? null : this.customer
      let dest = this.dest == '' ? null : this.dest
      let hikiate = this.hikiate == '' ? null : this.hikiate
      let sp = this.sp == '' ? null : this.sp
      let netspec = this.netSpec == '' ? null : this.netSpec
      let netStruc = this.netStructure == '' ? null : this.netStructure
      let holdflg = this.onHoldStatus == '' ? null : this.onHoldStatus
      let cpystruc = this.copyStruc == '' ? null : this.copyStruc
      let gridLoad = '';
      if (data == false) {
        gridLoad = null
      }
      else {
        gridLoad = 'ADD'
      }
      let newDate = new Date();
      let date = '_' + newDate.getDate() + (newDate.getMonth() + 1) + newDate.getFullYear() + '_' + newDate.getHours() + newDate.getMinutes() + newDate.getSeconds() + newDate.getMilliseconds();
      let fileName = 'ProdwithinVersion-grid/prodWithinVergridSrc' + date + '.json'
      console.log(apprStatus)
      let body = {
        "mdl_No": mdlno,
        "option": opt,
        "sap_Mtrl_Id": sapMtrlID,
        "sapProcStatTypId": sapproStatus,
        "bom_ver_typ_id": verTyp,
        "bom_ver_qual_id": verqual,
        "bom_ver_rev_nbr": vernum,
        "verApprovalSts": apprStatus,
        "namc_Lgl_Entity_Id": namc,
        "fam_Cd": family,
        "katashiki_Cd": katashiki,
        "prodStatTypId": status,
        "prodPartNo": partnum,
        "buyoff_Cust_Id": cust,
        "dest_Ctry_Id": dest,
        "hikiate_Stat": hikiate,
        "s_p": sp,
        "net_Spec_Cd_Comb_Txt": netspec,
        "net_Struc_Flg": netStruc,
        "onHoldFlg": holdflg,
        "copy_Struc_Flg": cpystruc,
        "gridLoad": gridLoad,
        "GfileName": fileName,
        "screenNo":"NBPC_10110",
        "fileName" :TfileName
      }
      this.productWithinVerService.getGridDetails(body).subscribe(data => {
        console.log(data)
        // this.rows = data.payload;
        let datarow = data.payload;
        this.showdata = true;
        if (data.payload[0].RecordCnt > 1000) {
          setTimeout(() => {
            this.getFileFromS3(fileName);
          }, 2000);
        } else {
          this.rows = datarow.map(row => ({
            checkboxdata: (row['ver_stat_enable_flg'] == 'Y') ? true : false,
            namc_cd: row['namc_cd'],
            mdl_no: row['mdl_no'],
            optn_cd: row['optn_cd'],
            sap_mtrl_id: row['sap_mtrl_id'],
            fam_cd: row['fam_cd'],
            katashiki_cd: row['katashiki_cd'],
            dest_ctry_id: row['dest_ctry_id'],
            buyoff_cust_nm: row['buyoff_cust_nm'],
            base_optn_typ_cd: row['base_optn_typ_cd'],
            net_struc_flg: row['net_struc_flg'],
            net_spec_cd_comb_txt: row['net_spec_cd_comb_txt'],
            pc_spec_cd_comb_txt: row['pc_spec_cd_comb_txt'],
            compar_pc_spec_cd_comb_txt: row['compar_pc_spec_cd_comb_txt'],
            prod_part_no: row['prod_part_no'],
            reuse_struc_flg: row['reuse_struc_flg'],
            prod_stat_typ_cd: row['prod_stat_typ_cd'],
            bom_ver_typ_cd: row['bom_ver_typ_cd'],
            bom_ver_qual_cd: row['bom_ver_qual_cd'],
            bom_ver_rev_nbr: row['bom_ver_rev_nbr'],
            rep_prod_def_id: row['rep_prod_def_id'],
            rep_prod_id: row['rep_prod_id'],
            hikiate_stat_comb_cd: row['hikiate_stat_comb_cd'],
            hundred_pct_optn_flg: row['hundred_pct_optn_flg'],
            compar_100_pct_optn_flg: row['compar_100_pct_optn_flg'],
            rmrk_txt: row['rmrk_txt'],
            bom_eff_dt: (row['bom_eff_dt'] == null) ? '' : row['bom_eff_dt'].split('T')[0],
            cost_eff_dt: (row['cost_eff_dt'] == null) ? '' : row['cost_eff_dt'].split('T')[0],
            on_hold_flg: row['on_hold_flg'],
            sent_to_sap_ts: (row['sent_to_sap_ts'] == null) ? '' : row['sent_to_sap_ts'].replace('Z', '').replace('T', ' '),
            sap_proc_stat_typ_nm: row['sap_proc_stat_typ_nm']
          }));
          this.SpinnerService.hide();
        }
        console.log(this.rows)
      }, err => {
        this.showdata = false;
        console.log("err in netwrk", err);
        this.rows = []
        console.log(data);
        if (err == 0) {
          if (data != true && data != 'upload') {
            this.errorMessage = "Request timed out. Please try again.";
            this.successMessage = "";
            this.cpyErrMsg = "";
            this.addtoversionErrMsg = ''
            this.SpinnerService.hide();
            this.globalrole.goToTop();
          } else {
            this.SpinnerService.hide()
            this.globalrole.goToTop()
          }
        } else {
          if (data != true && data != 'upload') {
            this.errorMessage = "No records found";
            this.successMessage = "";
            this.cpyErrMsg = "";
            this.addtoversionErrMsg = ''
            this.SpinnerService.hide()
            this.globalrole.goToTop()
          } else {
            this.SpinnerService.hide()
            this.globalrole.goToTop()
          }
        }
        // if (data != true) {
        //   this.errorMessage = "No records found";
        //   this.successMessage = "";
        //   this.cpyErrMsg = "";
        //   this.addtoversionErrMsg = ''
        // }
      });
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.successMessage = "";
      this.cpyErrMsg = "";
      this.addtoversionErrMsg = ''
      this.globalrole.goToTop();
    }
  }

  errorExport(data) {
    this.errorExportData = [];
    data.forEach(row => {
      let obj = {
        "NAMC": row.namc,
        "Model Number": row.mdlNo,
        "Option": row.option,
        "SAP Material ID": row.sapMatId,
        "Family": row.family,
        "Katashiki": row.katashiki,
        "Dest": row.dest,
        "Cust": row.customer,
        "S/P": row.sp,
        "NetStructure": row.netStruc,
        "Net Spec": row.netspec,
        "PC Spec": row.pcSpec,
        "Comp PC Spec": row.compPcSpec,
        "Part No(Product)": row.partNo,
        "Copy Struc": row.copyStruc,
        "Status": row.status,
        "Version Type": row.verTyp,
        "Version Qualifier": row.verQual,
        "Version Revision": row.verRev,
        "Rep Prod Def ID": row.repProdDefId,
        "Rep Prod ID": row.repProdId,
        "Hikiate Status": row.hikiate,
        "100% Spec": row.hundPerSpec,
        "Comp 100% Spec": row.compHundPerSpec,
        "Comment": row.comment,
        "Bom Effectivity Date": row.bonEffDate,
        "Cost Effectivity Date": row.costEffDt,
        "On-Hold Status": row.onHoldStat,
        "Sent To SAP": row.sentToSap,
        "SAP Processing Status": row.sapProcStat,
        "Error": row.errorMessage
      }
      this.errorExportData.push(obj);
    });
  }
  downloadExcel() {
    this.exportService.exportAsExcelFile(this.errorExportData, this.errorFileName, this.errorSheetName);
    this.errorExportData = []
  }

  //onchange method

  getVersionQual() {
    if (this.versionType == undefined) {
      this.versionType = ""
    }
    this.productWithinVerService.getQualifier(this.versionType).subscribe(data => {
      console.log(data);
      this.versionQualLst = data.payload;
      this.versionQual = ""
    });
  }

  getVersionRev() {
    if (this.versionQual == undefined) {
      this.versionQual = ""
    }
    if (this.versionQual != "") {
      this.productWithinVerService.getVersionRev(this.versionQual).subscribe(data => {
        console.log(data);
        this.versionRevLst = data.payload;
        this.versionRev = ""
        // this.approvalStatus = "";
      });
    } else {
      this.versionRevLst = []
      this.versionRev = ""
    }
  }

  getFamilyFpn(): void {
    if (this.namc == undefined) {
      this.namc = ""
    }
    this.productWithinVerService.getFamilyByNamcFpn(this.namc).subscribe(data => {
      console.log(data);
      this.famcdLst = data.payload;
      this.family = ""
      this.katashiki = ""
    });
  }

  getKatashikiByFamCd(): void {
    console.log(this.namc)
    if (this.family == undefined) {
      this.family = ""
    }
    this.productWithinVerService.getKatashikiByFamCd(this.family).subscribe(data => {
      console.log(data);
      this.katashikiLst = data.payload;
      this.katashiki = ""
    });

  }

  getCustByKatashiki(): void {
    if (this.namc == undefined) {
      this.namc = ""
    }
    if (this.family == undefined) {
      this.family = ""
    }
    if (this.katashiki == undefined) {
      this.katashiki = ""
    }
    this.productWithinVerService.getCustByKatashiki(this.namc, this.family, this.katashiki).subscribe(data => {
      console.log(data);
      this.customerLst = data.payload;
      this.customer = ""
    });
  }

  // getDestByCust(): void {
  //   if (this.namc == undefined) {
  //     this.namc = ""
  //   }
  //   if (this.family == undefined) {
  //     this.family = ""
  //   }
  //   if (this.katashiki == undefined) {
  //     this.katashiki = ""
  //   }
  //   // if (this.customer == undefined) {
  //   //   this.customer = ""
  //   // }
  //   this.productWithinVerService.getDestByCustdata(this.namc, this.family, this.katashiki).subscribe(data => {
  //     console.log(data);
  //     this.destLst = data.payload;
  //     this.dest = ""
  //   });
  // }

  //destbyprodwithinver
  getDestProdWthnVersion() {
    let fileName= localStorage.getItem('Filename')
    if (this.versionQual == undefined) {
      this.versionQual = ""
    }
    let body = {
      "bomVerQualId": this.versionQual,
      "screenNo":"NBPC_10110",
          "fileName" :fileName
    }
    this.productWithinVerService.getDestProdWthnVer(body).subscribe(data => {
      console.log(data);
      this.destLst = data.payload;
      // this.approvalStatus = "";
    }, err => {
      this.destLst = []
    });
  }
  getHikiate(): void {
    if (this.dest == undefined) {
      this.dest = "";
    }
    let hikiObj =
    {
      namc: this.namc,
      family: this.family,
      katashiki: this.katashiki,
      cust: this.customer,
      dest: this.dest
    }
    this.productWithinVerService.getHikiate(hikiObj).subscribe(data => {
      console.log(data);
      this.hikiateLst = data.payload;
      this.hikiate = ""
    });
  }

  getHikiateByDest(): void {
    this.getHikiate();
  }


  openApproveDialog() {
    console.log(this.roleAccess.includes('Approve'))
    this.errorMessage = '';
    this.successMessage = "";
    this.cpyErrMsg = "";
    this.addtoversionErrMsg = ''
    if (this.roleAccess.includes('Approve') || this.roleAccess.includes('Approve Adhoc Version')) {
      if (this.value.length > 0) {
        this.errorMessage = '';
        this.sendToDialogData(this.value, 'Approve')
        const dialogRef = this.dialog.open(ProdVersionActionsComponent, {
          disableClose: true,
          data: this.dialogData
        });
        dialogRef.afterClosed().subscribe(result => {
          this.selected = [];
          this.value = [];

          console.log('The dialog was closed');
          if (result == 'success') {
            this.successMessage = "Data Updated Successfully"
            this.getGridData(true)
          }
          else {
            console.log("Data nt updated");
            this.errorMessage = result
            this.globalrole.goToTop();
            // this.errorMessage = 'Data not inserted/Valid'
          }
        });
      } else {
        this.errorMessage = "Please select a Product to approve"
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'approve' operation"
      this.globalrole.goToTop();
    }

  }
  openVerifyDialog() {
    console.log(this.roleAccess.includes('Update'))
    if (this.roleAccess.includes('Update')) {
      if (this.value.length > 0) {
        this.errorMessage = '';
        this.successMessage = "";
        this.cpyErrMsg = "";
        this.addtoversionErrMsg = ''
        this.sendToDialogData(this.value, 'Verify')
        const dialogRef = this.dialog.open(ProdVersionActionsComponent, {
          disableClose: true,
          data: this.dialogData
        });
        dialogRef.afterClosed().subscribe(result => {
          this.selected = [];
          this.value = [];
          console.log('The dialog was closed');
          if (result == 'success') {
            this.successMessage = "Data Updated Successfully"
            this.getGridData(true)
          }
          else {
            console.log("Data nt updated");
            this.errorMessage = result;
            this.globalrole.goToTop();
            // this.errorMessage = 'Data not inserted/Valid'
          }
        });
      } else {
        this.errorMessage = "Please select a Product to verify"
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation"
      this.globalrole.goToTop();
    }
  }
  openverRemoveDialog() {

    var proceed = confirm("Are you sure?");
    if (proceed) {
      console.log(this.roleAccess.includes('Delete'))
      if (this.roleAccess.includes('Delete')) {
        if (this.value.length > 0) {
          this.errorMessage = '';
          this.successMessage = "";
          this.cpyErrMsg = "";
          this.addtoversionErrMsg = ''
          this.sendToDialogData(this.value, 'VersionRemove')
          const dialogRef = this.dialog.open(ProdVersionActionsComponent, {
            disableClose: true,
            data: this.dialogData
          });
          dialogRef.afterClosed().subscribe(result => {
            this.selected = [];
            this.value = []
            console.log('The dialog was closed', result);
            let FailedRows;
            let successData
            if (result[1].FailedRecords != undefined) {
              FailedRows = result[1].FailedRecords.rows
              successData = result[0].CountData.rows[0]
            }
            if (result == "Already is in progress. Please Work Later") {
              this.errorMessage = "Already is Inprogress. Please Work Later";
              this.globalrole.goToTop();
            }
            else if (FailedRows.length == 0) {
              this.successMessage = successData.count + " product(s) removed from version " + FailedRows.length + " products(s) cannot be removed "
              this.getGridData(true)
              this.globalrole.goToTop();
            }
            else if (FailedRows.length != 0) {
              // this.showcpyStrucErrMsg = true
              this.errorMessage = successData.count + " products(s) removed from version and "
                + FailedRows.length + " products(s) cannot be removed"
              this.globalrole.goToTop();
              // this.errorMessage = 'Data not inserted/Valid'
            }
            else {
              this.errorMessage = result
            }
          });
        } else {
          this.errorMessage = "Please select a Product to removed from Version"
          this.globalrole.goToTop();
        }
      } else {
        this.errorMessage = "User does not have permission to perform 'delete' operation"
        this.globalrole.goToTop();
      }
    } else {
      console.log("no update");

    }
  }

  clickCpyStruc() {
    console.log(this.roleAccess.includes('Update'))
    if (this.roleAccess.includes('Update')) {
      let text = "Are you sure ?"
      if (confirm(text) == true) {
        this.errorMessage = "";
        this.successMessage = "";
        this.cpyErrMsg = "";
        this.addtoversionErrMsg = ''
        this.CopyStructureDialog();
      } else {
        console.log("alert dialog is closed");
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation"
      this.globalrole.goToTop();
    }
  }

  CopyStructureDialog() {
    if (this.value.length > 0) {
      this.errorMessage = '';
      this.successMessage = "";
      this.cpyErrMsg = "";
      this.addtoversionErrMsg = ''
      this.sendToDialogData(this.value, 'CopyStructure')
      const dialogRef = this.dialog.open(ProdVersionActionsComponent, {
        disableClose: true,
        data: this.dialogData
      });
      dialogRef.afterClosed().subscribe(result => {
        this.selected = [];
        this.value = [];
        console.log("result", result);
        let FailedRows;
        let successData
        if (result[1].FailedRecords != undefined) {
          this.cpystruFailedRows = result[1].FailedRecords.rows
          successData = result[0].CountData.rows[0]
        }
        // this.cpystruFailedRows = result[1].FailedRecords.rows
        // let successData = result[0].CountData.rows[0]
        if (result == "Already is in progress. Please Work Later") {
          this.errorMessage = "Already is Inprogress. Please Work Later";
          this.globalrole.goToTop();
        }
        else if (this.cpystruFailedRows.length == 0) {
          this.successMessage = successData.count + " products(s) marked as Copy Structure"
          this.getGridData(true)
          this.globalrole.goToTop();
        }
        else if (this.cpystruFailedRows.length != 0) {
          // this.showcpyStrucErrMsg = true
          this.cpyErrMsg = successData.count + " products(s) marked as Copy Structure and "
            + this.cpystruFailedRows.length + " products(s) failed "
          this.globalrole.goToTop();
          // this.errorMessage = 'Data not inserted/Valid'
        } else {
          this.errorMessage = result
        }
      });
    } else {
      this.errorMessage = "Please Select a row"
      this.globalrole.goToTop();
    }
  }

  IdenDepProd() {
    // console.log(this.roleAccess.includes('Create'))
    // if (this.roleAccess.includes('Create')) {
    console.log(this.value)
    let tempdata = [];
    this.value.filter((res) => {
      if (res.base_optn_typ_cd == 'S' || res.base_optn_typ_cd == 'P') {
        tempdata.push(res)
      }
    })
    console.log(tempdata)
    this.errorMessage = "";
    this.successMessage = "";
    this.cpyErrMsg = "";
    this.addtoversionErrMsg = ''
    if (this.value.length > 0) {
      if (tempdata.length > 0) {

        this.sendToDialogData(this.value, 'IdenDepProdVer')
        const dialogRef = this.dialog.open(ProdVersionActionsComponent, {
          disableClose: true,
          data: this.dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
          this.selected = [];
          this.value = []
          let FailedRows;
          let successData
          if (result[1].FailedRecords != undefined) {
            FailedRows = result[1].FailedRecords.rows
            successData = result[0].CountData.rows[0]
          }
          if (result == "Already is in progress. Please Work Later") {
            this.errorMessage = "Already is Inprogress. Please Work Later";
            this.globalrole.goToTop();
          }
          else if (FailedRows.length == 0) {
            this.SpinnerService.show();
            if (successData.rep_prod_id_lst != '') {
              this.getIdeDeptRecordsIngrid(successData.rep_prod_id_lst)
            }
            else {
              this.rows = []
              this.selected = []
              this.value = []
            }
            // this.getGridData(true)
          }
          else {
            this.errorMessage = 'No Dependent Products'
            this.globalrole.goToTop();
          }
        });
      }
      else {
        this.errorMessage = "Please Select Representive vehicle";
        this.globalrole.goToTop();
      }
    }
    else {
      this.errorMessage = "Please select a row "
      this.globalrole.goToTop();
    }
    // }else{
    // this.errorMessage = "User does not have permission to perform 'update' operation"
    // }
  }


  getIdeDeptRecordsIngrid(ids) {
    let fileName= localStorage.getItem('Filename')
    console.log("ids fr depend records", ids);
    let body = {
      "screenNm": 'prodwithinversion',
      "rep_prod_id": ids,
      "screenNo":"NBPC_10110",
      "fileName" :fileName
    }
    this.productWithinVerService.getIdeDeptProdLst(body).subscribe(res => {
      console.log("res from idenDeptProd", res.payload);
      let datarow = res.payload
      this.rows = datarow.map(row => ({
        checkboxdata: (row['ver_stat_enable_flg'] == 'Y') ? true : false,
        namc_cd: row['namc_cd'],
        mdl_no: row['mdl_no'],
        optn_cd: row['optn_cd'],
        sap_mtrl_id: row['sap_mtrl_id'],
        fam_cd: row['fam_cd'],
        katashiki_cd: row['katashiki_cd'],
        dest_ctry_id: row['dest_ctry_id'],
        buyoff_cust_nm: row['buyoff_cust_nm'],
        base_optn_typ_cd: row['base_optn_typ_cd'],
        net_struc_flg: row['net_struc_flg'],
        net_spec_cd_comb_txt: row['net_spec_cd_comb_txt'],
        pc_spec_cd_comb_txt: row['pc_spec_cd_comb_txt'],
        compar_pc_spec_cd_comb_txt: row['compar_pc_spec_cd_comb_txt'],
        prod_part_no: row['prod_part_no'],
        reuse_struc_flg: row['reuse_struc_flg'],
        prod_stat_typ_cd: row['prod_stat_typ_cd'],
        bom_ver_typ_cd: row['bom_ver_typ_cd'],
        bom_ver_qual_cd: row['bom_ver_qual_cd'],
        bom_ver_rev_nbr: row['bom_ver_rev_nbr'],
        rep_prod_def_id: row['rep_prod_def_id'],
        rep_prod_id: row['rep_prod_id'],
        hikiate_stat_comb_cd: row['hikiate_stat_comb_cd'],
        hundred_pct_optn_flg: row['hundred_pct_optn_flg'],
        compar_100_pct_optn_flg: row['compar_100_pct_optn_flg'],
        rmrk_txt: row['rmrk_txt'],
        bom_eff_dt: (row['bom_eff_dt'] == null) ? '' : row['bom_eff_dt'].split('T')[0],
        cost_eff_dt: (row['cost_eff_dt'] == null) ? '' : row['cost_eff_dt'].split('T')[0],
        on_hold_flg: row['on_hold_flg'],
        sent_to_sap_ts: (row['sent_to_sap_ts'] == null) ? '' : row['sent_to_sap_ts'].replace('Z', '').replace('T', ' '),
        sap_proc_stat_typ_nm: row['sap_proc_stat_typ_nm']
      }));
      this.SpinnerService.hide();
      this.selected = this.rows
    })
    console.log("rows", this.rows);


  }

  sendtoSap() {
    console.log(this.roleAccess.includes('Update'))
    if (this.roleAccess.includes('Update')) {
      if (this.value.length > 0) {
        this.errorMessage = "";
        this.successMessage = "";
        this.cpyErrMsg = "";
        this.addtoversionErrMsg = '';
        this.sendToDialogData(this.value, 'sendtosap')
        const dialogRef = this.dialog.open(ProdVersionPart2ActionsComponent, {
          disableClose: true,
          data: this.dialogData,
          width: '0'
        });

        dialogRef.afterClosed().subscribe(result => {
          this.selected = [];
          this.value = [];
          console.log('The dialog was closed');
          if (result == 'success') {
            this.successMessage = "Data Updated Successfully"
            this.globalrole.goToTop();
            this.getGridData(true)
          }
          else {
            console.log("Data nt updated");
            this.errorMessage = result
            this.globalrole.goToTop();
            // this.errorMessage = 'Data not inserted/Valid'
          }
        });

      }
      else {
        this.errorMessage = "Please select a Product to Send To SAP";
        this.globalrole.goToTop();

      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation"
      this.globalrole.goToTop();
    }
  }

  resetReadyforReview() {
    console.log(this.roleAccess.includes('Update'))
    if (this.roleAccess.includes('Update')) {
      if (this.value.length > 0) {
        this.errorMessage = '';
        this.sendToDialogData(this.value, 'resetReadyforReview')
        const dialogRef = this.dialog.open(ProdVersionPart2ActionsComponent, {
          disableClose: true,
          data: this.dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
          this.selected = [];
          this.value = [];
          console.log('The dialog was closed');
          if (result == 'Success') {
            this.successMessage = "Selected product(s) have been Reset to Ready for Review successfully"
            this.globalrole.goToTop();
            this.getGridData(true)
          }
          else {
            console.log("Data nt updated");
            this.errorMessage = result
            this.globalrole.goToTop();
            // this.errorMessage = 'Data not inserted/Valid'
          }
        });
      }
      else {
        this.errorMessage = "Please select a Product to Reset RFR";
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation"
      this.globalrole.goToTop();
    }
  }

  sendToDialogData(selected, buttonName): void {
    console.log(selected);
    let arrayValue = [];
    for (let i = 0; i < selected.length; i++) {
      arrayValue.push(selected[i].rep_prod_id);
    }
    console.log(arrayValue);
    selected.forEach(row => {
      this.dialogData.rep_prod_id = arrayValue
      this.dialogData.buttonName = buttonName
    });
  }


  userroleAccessfn() {
    // this.globalrole.Roledetails.subscribe(res => {
    //   console.log(res)
    //   this.Roledata = res;
    // })
    // console.log(this.Roledata);
    // if (this.Roledata.length != 0) {
    //   this.Roledata.filter((res) => {
    //     if (res.function_nm == 'NBPC_10110') {
    //       this.roleAccess.push(res.access_desc);
    //     }
    //   })
    // } else if (this.Roledata.length == 0) {
    //   let arrayvalue: any; let roleList: any;
    //   let data = localStorage.getItem('role');
    //   arrayvalue = data.split(',')
    //   console.log(arrayvalue.length, arrayvalue);
    //   let str = '';
    //   for (let i = 0; i < arrayvalue.length; i++) {
    //     if (str == '') {
    //       str = "'" + arrayvalue[i] + "'"
    //     } else {
    //       str += ',' + "'" + arrayvalue[i] + "'";
    //     }
    //     roleList = str;
    //   }
    //   let body = {
    //     security_grp_nm: roleList
    //   }
    //   this.globalrole.userprevilageapi(body).subscribe(data => {
    //     console.log(data);
    //     if (data.payload) {
    //       this.globalrole.changeMessage(data.payload)
    //       this.userroleAccessfn();
    //       this.accessEnable = true;
    //     }
    //    }, err => {
    //       console.log(this.roleAccess)
    //       this.roleAccess = []
    //        this.errorMessage = "User does not have permission to perform 'read' operation";
    //       this.accessEnable = false;
    //       this.SpinnerService.hide();
    //     });
    // }
    // console.log(this.roleAccess);
    this.SpinnerService.show()
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.log(res)
        this.Roledata = res;
      })
      console.log(this.Roledata);
      if (this.Roledata.length != 0) {
        this.Roledata.filter((res) => {
          if (res.function_nm == 'NBPC_10110') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        this.SpinnerService.hide()
        resolve(true);
      } else if (this.Roledata.length == 0) {
        let arrayvalue: any; let roleList: any;
        let data = localStorage.getItem('role');
        arrayvalue = data.split(',')
        console.log(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str == '') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        let body = {
          security_grp_nm: roleList
        }
        let userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
        this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
            this.userroleAccessfn();
            this.accessEnable = true;
            this.SpinnerService.hide()
            resolve(true);
          }
        else {
          console.log(this.roleAccess)
          this.roleAccess = []
          this.errorMessage = "User does not have permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        }
      }
      console.log(this.roleAccess);
    })
  }


  ////get from s3 Grid search data
  async getFileFromS3(fileName) {
    this.SpinnerService.show();
    // return new Promise(async resolve => {
    let a = await this.getS3Info();
    if (a) {
      const bucket = new S3(
        {
          accessKeyId: this.accessKey,
          secretAccessKey: this.secretKey,
          sessionToken: this.sessionToken,
          region: 'us-west-2'
        }
      );
      const params = {
        Bucket: 'search-grids',
        Key: fileName,
      };
      console.log(params);
      bucket.getObject(params, (err: any, data: any) => {
        if (err) {
          console.log('download err:::::::::', err)
          this.SpinnerService.hide();
        } else {
          console.log(data)
          this.SpinnerService.hide();
          let result = data.Body.toString();
          let res = JSON.parse(result);
          console.log("res and reult", res, result)
          if (res.length > 0) {
            this.rows = res.map(row => ({
              checkboxdata: (row['ver_stat_enable_flg'] == 'Y') ? true : false,
              namc_cd: row['namc_cd'],
              mdl_no: row['mdl_no'],
              optn_cd: row['optn_cd'],
              sap_mtrl_id: row['sap_mtrl_id'],
              fam_cd: row['fam_cd'],
              katashiki_cd: row['katashiki_cd'],
              dest_ctry_id: row['dest_ctry_id'],
              buyoff_cust_nm: row['buyoff_cust_nm'],
              base_optn_typ_cd: row['base_optn_typ_cd'],
              net_struc_flg: row['net_struc_flg'],
              net_spec_cd_comb_txt: row['net_spec_cd_comb_txt'],
              pc_spec_cd_comb_txt: row['pc_spec_cd_comb_txt'],
              compar_pc_spec_cd_comb_txt: row['compar_pc_spec_cd_comb_txt'],
              prod_part_no: row['prod_part_no'],
              reuse_struc_flg: row['reuse_struc_flg'],
              prod_stat_typ_cd: row['prod_stat_typ_cd'],
              bom_ver_typ_cd: row['bom_ver_typ_cd'],
              bom_ver_qual_cd: row['bom_ver_qual_cd'],
              bom_ver_rev_nbr: row['bom_ver_rev_nbr'],
              rep_prod_def_id: row['rep_prod_def_id'],
              rep_prod_id: row['rep_prod_id'],
              hikiate_stat_comb_cd: row['hikiate_stat_comb_cd'],
              hundred_pct_optn_flg: row['hundred_pct_optn_flg'],
              compar_100_pct_optn_flg: row['compar_100_pct_optn_flg'],
              rmrk_txt: row['rmrk_txt'],
              bom_eff_dt: (row['bom_eff_dt'] == null) ? '' : row['bom_eff_dt'].split('T')[0],
              cost_eff_dt: (row['cost_eff_dt'] == null) ? '' : row['cost_eff_dt'].split('T')[0],
              on_hold_flg: row['on_hold_flg'],
              sent_to_sap_ts: (row['sent_to_sap_ts'] == null) ? '' : row['sent_to_sap_ts'].replace('Z', '').replace('T', ' '),
              sap_proc_stat_typ_nm: row['sap_proc_stat_typ_nm']
            }))
            console.log(this.rows);
          }
        }
      })
    }
    // })
  }

  getS3Info() {
    return new Promise(resolve => {
      this.productWithinVerService.fileUpldData().subscribe(data => {
        let result = data.payload[0]
        if (result !== null) {
          const aws = result;
          this.accessKey = aws.AccessKeyId;
          this.secretKey = aws.SecretAccessKey;
          this.sessionToken = aws.SessionToken;
          resolve(true)
        }
        else {
          // this.toastr.error('Data Storage Disconnected.');
          resolve(false)
        }
      }, err => {
        // this.toastr.error('Unable to get keys and token', 'Failed');
      });
    })
  }

  exportFailedId(data) {
    if (data == 'copystruc') {
      this.cpystruFailedRows.forEach(row => {
        let obj = {
          "Reason": row.err_msg,
          "failed Id's": row.rep_prod_id
        }
        this.exportData.push(obj);
      });
    } else {
      this.addtoversionErrlist.forEach(row => {
        let obj = {
          "Reason": row.err_msg,
          "failed Id's": row.rep_prod_id
        }
        this.exportData.push(obj);
      });
    }
    this.exportService.exportAsExcelFile(this.exportData, this.fileName, this.sheetName);
    this.exportData = []
  }


  approvalstsfn(id) {
    console.log(id);
    let repBomVerId = id
    //approvalStatus
    this.productWithinVerService.getApprovalStat(repBomVerId).subscribe(data => {
      console.log(data);
      this.approvalStatusLst = data.payload;
      if (repBomVerId == "") {
        this.approvalStatus = 'Open'
      } else {
        this.approvalStatus = ""
      }
    });
  }

  checkedState(event, checkBox) {
    console.log(event.source.selected)
    this.selectedvalue = event.source.selected
    this.selectedlengthversion = event.value.length
    console.log(Number(this.versioncntval))
    if (event.source.selected.length < Number(this.versioncntval)) {
      this.limitexceed = false;
      console.log(this.limitexceed)
    } else {
      this.limitexceed = true;
      alert('Maximum 20 Version Selected');
      console.log(this.limitexceed)
    }
  }

}
