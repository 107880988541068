import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CircularParentChildComponent } from './circular-parent-child/circular-parent-child.component';
import { DuplicateGrpiComponent } from './duplicate-grpi/duplicate-grpi.component';
import { EbomIsDashboardComponent } from './ebom-is-dashboard/ebom-is-dashboard.component';
import { ExchangeRateSearchComponent } from './exchange-rate-search/exchange-rate-search.component';
import { ExtraneousPartsListComponent } from './extraneous-parts-list/extraneous-parts-list.component';
import { FamilyProjectNamcComponent } from './family-project-namc/family-project-namc.component';
import { FamilyComponent } from './family/family.component';
import { HikiateStatusComponent } from './hikiate-status/hikiate-status.component';
import { HomeComponent } from './home/home.component';
import { HunderedSpecificationsComponent } from './hundered-specifications/hundered-specifications.component';
import { IsSystemParameterComponent } from './is-system-parameter/is-system-parameter.component';
import { ProcstatusComponent } from './procstatus/procstatus.component';
import { MajorPartListComponent } from './major-part-list/major-part-list.component';
import { MajorPartTypeComponent } from './major-part-type/major-part-type.component';
import { MajorPartsDiscrepancyComponent } from './major-parts-discrepancy/major-parts-discrepancy.component';
import { ManualAdjustmentRuleComponent } from './manual-adjustment-rule/manual-adjustment-rule.component';
import { MngDataTransSapComponent } from './mng-data-trans-sap/mng-data-trans-sap.component';
import { PartSourceAdjustmentComponent } from './part-source-adjustment/part-source-adjustment.component';
import { PartSourceRoutingComponent } from './part-source-routing/part-source-routing.component';
import { PartSourceComponent } from './part-source/part-source.component';
import { PartsWithoutCostDetailComponent } from './parts-without-cost-detail/parts-without-cost-detail.component';
import { PartsWithoutCostSummaryComponent } from './parts-without-cost-summary/parts-without-cost-summary.component';
import { PcSpecsExclusionListComponent } from './pc-specs-exclusion-list/pc-specs-exclusion-list.component';
import { PcspecToMfgspecComponent } from './pcspec-to-mfgspec/pcspec-to-mfgspec.component';
import { PlantUserCodeComponent } from './plant-user-code/plant-user-code.component';
import { ProdProcStatusComponent } from './prod-proc-status/prod-proc-status.component';
import { ProductWithinVersionComponent } from './product-within-version/product-within-version.component';
import { QualityAndExceptionComponent } from './quality-and-exception/quality-and-exception.component';
import { RecordCountsFromDataSourcesComponent } from './record-counts-from-data-sources/record-counts-from-data-sources.component';
import { RepresentativeUnitsComponent } from './representative-units/representative-units.component';
import { RepresentativeVehiclesComponent } from './representative-vehicles/representative-vehicles.component';
import { SapDataLoadComponent } from './sap-data-load/sap-data-load.component';
import { SelectivityPartRuleComponent } from './selectivity-part-rule/selectivity-part-rule.component';
import { UnresolvedPartSourceComponent } from './unresolved-part-source/unresolved-part-source.component';
import { UnresolvedSlctvtyComponent } from './unresolved-slctvty/unresolved-slctvty.component';
import { UsageVarianceCalculationComponent } from './usage-variance-calculation/usage-variance-calculation.component';
import { VersionQualifierComponent } from './version-qualifier/version-qualifier.component';
import { VersionTypesComponent } from './version-types/version-types.component';
import { VersionsComponent } from './versions/versions.component';
import { RoleGuard } from './auth-guard/role-guard';
import { ManualJobTriggerComponent } from './manual-job-trigger/manual-job-trigger.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'id_token',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  { path: 'home', component: HomeComponent, canActivate: [RoleGuard] },
  { path: 'repVehicles', component: RepresentativeVehiclesComponent, canActivate: [RoleGuard] },
  { path: 'repUnits', component: RepresentativeUnitsComponent, canActivate: [RoleGuard] },
  { path: 'sapDataLd', component: SapDataLoadComponent, canActivate: [RoleGuard] },
  { path: 'mngDataTrnsfrSap', component: MngDataTransSapComponent, canActivate: [RoleGuard] },
  { path: 'versionTypes', component: VersionTypesComponent, canActivate: [RoleGuard] },
  { path: 'verQualifiers', component: VersionQualifierComponent, canActivate: [RoleGuard] },
  { path: 'majorPartLst', component: MajorPartListComponent, canActivate: [RoleGuard] },
  { path: 'majorPartTyp', component: MajorPartTypeComponent, canActivate: [RoleGuard] },
  { path: 'partSource', component: PartSourceComponent, canActivate: [RoleGuard] },
  { path: 'partSourceRtg', component: PartSourceRoutingComponent, canActivate: [RoleGuard] },
  { path: 'exchangeRate', component: ExchangeRateSearchComponent, canActivate: [RoleGuard] },
  { path: 'slctvyPartRule', component: SelectivityPartRuleComponent, canActivate: [RoleGuard] },
  { path: '100perSpec', component: HunderedSpecificationsComponent, canActivate: [RoleGuard] },
  { path: 'famProNamc', component: FamilyProjectNamcComponent, canActivate: [RoleGuard] },
  { path: 'extPartLst', component: ExtraneousPartsListComponent, canActivate: [RoleGuard] },
  { path: 'pcSpecExclLst', component: PcSpecsExclusionListComponent, canActivate: [RoleGuard] },
  { path: 'family', component: FamilyComponent, canActivate: [RoleGuard] },
  { path: 'hikiateStatus', component: HikiateStatusComponent, canActivate: [RoleGuard] },
  { path: 'plntUsrCd', component: PlantUserCodeComponent, canActivate: [RoleGuard] },
  { path: 'isSystemParm', component: IsSystemParameterComponent, canActivate: [RoleGuard] },
  { path: 'procStatus', component: ProcstatusComponent, canActivate: [RoleGuard] },

  
  //for business
  {path:'versions',component:VersionsComponent,canActivate: [RoleGuard]},
  {path:'prodWithinVersion',component:ProductWithinVersionComponent,canActivate: [RoleGuard]},
  {path:'prodProcStatus', component:ProdProcStatusComponent,canActivate: [RoleGuard]},
  {path:'manAdjRule',component:ManualAdjustmentRuleComponent,canActivate: [RoleGuard]},
  {path:'partSrcAdj',component:PartSourceAdjustmentComponent,canActivate: [RoleGuard]},
  
  
  //for exception
  {
    path:'qualityExceptRpt' , component:QualityAndExceptionComponent,
    children:[
      {path:'c1UnSlctvty', component:UnresolvedSlctvtyComponent,outlet:'quality'},
      {path:'c2UnPartSource', component:UnresolvedPartSourceComponent,outlet:'quality'},
      {path:'c4CirParChildRel', component:CircularParentChildComponent,outlet:'quality'},
      {path:'c5MajPartsDisc', component:MajorPartsDiscrepancyComponent,outlet:'quality'},
      {path:'c6DupGrpi', component:DuplicateGrpiComponent,outlet:'quality'},
      {path:'c7ParWitCosOrZerValSum', component:PartsWithoutCostSummaryComponent,outlet:'quality'},
      {path:'c8ParWitCosOrZerValDet', component:PartsWithoutCostDetailComponent,outlet:'quality'},
      {path:'c11PcSpToMfgSPCon', component:PcspecToMfgspecComponent,outlet:'quality'},
      {path:'c13UsVarCal', component:UsageVarianceCalculationComponent,outlet:'quality'},
      {path:'i27RecordsCntFromDataSrc',component:RecordCountsFromDataSourcesComponent,outlet:'quality'},
    ]
  },
  //for Ebom is
  {path:'ebomIs',component:EbomIsDashboardComponent,canActivate: [RoleGuard]},

  //for Ebom eBoM Manual Job Trigger
  {path:'manJobTrigger',component:ManualJobTriggerComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule],
  providers: [RoleGuard]
})
export class AppRoutingModule { }
