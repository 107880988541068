<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
<h2 mat-dialog-title cdkDragHandle >View <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
</h2>

<mat-dialog-content class="mat-typography">
  <div class="container">
    <!-- row1 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row ">
          <div class="col-4"> <label>Extraneous Part Type:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vExtraneousPartType" class="inputtext" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
        </div>
      </div>
    </div>

    <!-- row2 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row ">
          <div class="col-4"> <label>Extraneous Part Description:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vExtraneousPartDescription" class="inputtext" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
        </div>
      </div>
    </div>

    <!-- row3 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Matching Part Text:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vMatchingPartText" class="inputtext" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Matching Part Description Text:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vMatchingPartDescriptionText" class="inputtext" disabled>
          </div>
        </div>
      </div>
    </div>

    <!-- row4 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Component Code:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vComponentCode" class="inputtext" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Source Routing:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vSourceRouting" class="inputtext" disabled></div>
        </div>
      </div>
    </div>

    <!-- row5-->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row ">
          <div class="col-4"> <label>Dest Routing:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vDestRouting" class="inputtext" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
        </div>
      </div>
    </div>

    <!-- row6 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Effective In Date:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vEffectiveInDate" class="inputtext" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Effective Out Date:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vEffectiveOutDate" class="inputtext" disabled></div>
        </div>
      </div>
    </div>

    <!-- row7 -->
    <div class="row rowClass">
      <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
      <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" [(ngModel)]="vComments" disabled
          name="w3review" rows="4" cols="50">
            </textarea>
      </div>
    </div>

  </div>

</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>Ok</button>
</mat-dialog-actions>
</ng-container>