import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RepVehicleAddDialogComponent } from '../rep-vehicle-add-dialog/rep-vehicle-add-dialog.component';
import { RepVehicleViewDialogComponent } from '../rep-vehicle-view-dialog/rep-vehicle-view-dialog.component';
import { RepVehicleEditDialogComponent } from '../rep-vehicle-edit-dialog/rep-vehicle-edit-dialog.component';
import { RepVehicleUploadDialogComponent } from '../rep-vehicle-upload-dialog/rep-vehicle-upload-dialog.component';
import { RepVehicleMultieditDialogComponent } from '../rep-vehicle-multiedit-dialog/rep-vehicle-multiedit-dialog.component';
import { RepVehicleCopyaddDialogComponent } from '../rep-vehicle-copyadd-dialog/rep-vehicle-copyadd-dialog.component';
import {RepresentativeVehiclesComponent} from '../representative-vehicles/representative-vehicles.component';
import {ReferenceModule} from '../reference.module';


@NgModule({
  declarations: [
    RepresentativeVehiclesComponent,
    RepVehicleAddDialogComponent,
    RepVehicleViewDialogComponent,
    RepVehicleEditDialogComponent,
    RepVehicleUploadDialogComponent,
    RepVehicleMultieditDialogComponent,
    RepVehicleCopyaddDialogComponent,
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class RepVehicleModule { }
