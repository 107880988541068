<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Edit <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
</h2>

<div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
  {{errorMessage}}<mat-icon class="close-icon"  (click)="closeAlert()">close</mat-icon>
</div>

<mat-dialog-content class="mat-typography">
  <div class="container">
    <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are mandatory</span>
     <!-- row1 -->
     <div class="row rowClass">
      <div class="col-md-6">
        <div class ="row">
          <div class="col-4"> <label>Family:</label></div>
          <div class=col-8><input type="text" class="inputtext"[(ngModel)]="family" disabled> </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class ="row">
          <div class="col-4"> <label>NAMC:</label></div>
          <div class=col-8><input type="text" class="inputtext"[(ngModel)]="namc" disabled > </div>
        </div>
      </div>
    </div>
     <!-- row2 -->
     <div class="row rowClass">
      <div class="col-md-6">
        <div class ="row">
          <div class="col-4"> <label>User Code:</label></div>
          <div class=col-8><input type="text" class="inputtext"[(ngModel)]="userCode" disabled> </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class ="row">
          <div class="col-4"> <label>Product Type:</label></div>
          <div class=col-8><input type="text" class="inputtext"[(ngModel)]="productType" disabled > </div>
        </div>
      </div>
    </div>
     <!-- row3 -->
     <div class="row rowClass">
      <div class="col-md-6">
        <div class ="row">
          <div class="col-4"> <label>Active Flag:</label></div>
          <div class=col-2><input type="checkbox"[(ngModel)]="activeFlag" > </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class ="row">
          <div class="col-4"> <label>GRPI Code:</label></div>
          <div class=col-8><input type="text" class="inputtext"[(ngModel)]="grpiCode" disabled > </div>
        </div>
      </div>
    </div>
    <!-- row4 -->
    <div class="row rowClass">
      <div class="col-md-6" style="margin-top: 5px;">
        <div class ="row ">
          <div class="col-4"> <label>Effective In Date:<span class="text-danger">*</span></label></div>
          <div class=col-8>
            <mat-form-field class="picker inputtext" appearance="outline">
              <input matInput [matDatepicker]="inPicker" [(ngModel)]="effectiveInDate" 
               >
              <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
              <mat-datepicker #inPicker ></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'effectiveInDate')">clear</mat-icon>
            </mat-form-field>
           </div>
        </div>
      </div>
      <div class="col-md-6" style="margin-top: 5px;">
        <div class ="row">
          <div class="col-4"> <label>Effective Out Date:</label></div>
          <div class=col-8>
            <mat-form-field class="picker inputtext" appearance="outline">
              <input matInput [matDatepicker]="outPicker" [(ngModel)]="effectiveOutDate" 
               >
              <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
              <mat-datepicker #outPicker ></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'effectiveOutDate')">clear</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
     </div>
  </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button mat-button  cdkFocusInitial (click)="updatePlantUserCode()">Save</button>
      <button mat-button  (click)="cancel()">Cancel</button>
    </mat-dialog-actions> 
</ng-container>

<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>