<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Add <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>

  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>

  <mat-dialog-content class="mat-typography" (click)="clickerroroff()">
    <div class="container">
      <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are mandatory</span>
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>NAMC:</label></div>
            <div class=col-8><select class="heightadd" [(ngModel)]="majorPartAddNamc" (change)='getFamily()'
                aria-label="Default select example"  >
                <option value=""></option>
                <option *ngFor="let namc of namcLst" [value]="namc.namc_lgl_entity_id">{{namc.namc_cd}}</option>
              </select></div>
          </div>

        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Family:</label></div>
            <div class=col-8><select class="heightadd" [(ngModel)]="majorPartAddFamily" (change)='onchangecall();'
                aria-label="Default select example">
                <option value=""></option>
                <option *ngFor="let famcd of famcdLst" [value]="famcd.fam_cd">{{famcd.fam_cd}}</option>
              </select></div>
          </div>
        </div>
      </div>

      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6" *ngIf="enableKatashiki">
          <div class="row">
            <div class="col-4"> <label>Katashiki/Part:</label></div>
            <div class=col-8>
              <select class="heightadd" [(ngModel)]="majorPartAddKatashiki" (change)='onchangekatashiki()' aria-label="Default select example">
                <option value=""></option>
                <option *ngFor="let katashiki of katashikiLst" [value]="katashiki.katashiki_cd">
                  {{katashiki.katashiki_cd}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="enablePartnum">
          <div class="row">
            <div class="col-4"> <label>Katashiki/Part:</label></div>
            <div class=col-8>
              <select class="heightadd" [(ngModel)]="majorPartAddKatashiki" (change)='onchangekatashiki()' aria-label="Default select example">
                <option value=""></option>
                <option *ngFor="let partNo of partnumLst" [value]="partNo.partno">{{partNo.partno}}
                </option>
              </select>
            </div>
          </div>

        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Major Part Type<span class="text-danger">*</span>:</label></div>
            <div class=col-8><select class="heightadd" [(ngModel)]="majorPartAddPartType"
                aria-label="Default select example" (change)='getProdTypByMajPartNM()'>
                <option value=""></option>
                <option *ngFor="let majPartTyp of majPartTypLst" [value]="majPartTyp.maj_part_typ_id">
                  {{majPartTyp.maj_part_typ_nm}}</option>
              </select></div>
          </div>
        </div>
      </div>

      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Expected Major Part Type Quantity<span class="text-danger">*</span>:</label>
            </div>
            <div class=col-8><input type="number" [maxLength]="3" class="inputtext"
                [(ngModel)]="majorPartAddExpPartQty">
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Product Type<span class="text-danger">*</span>:</label></div>
            <div class=col-8><select class="heightadd" [(ngModel)]="majorPartAddProdType" (change)='getActiveByProd();
            getinactiveByProd();getQtyCommentsByProdType()' aria-label="Default select example">
                <option value=""></option>
                <option *ngFor="let prodTyp of prodTypLst" [value]="prodTyp.prod_typ_cd">{{prodTyp.prod_typ_desc}}
                </option>
              </select></div>
          </div>
        </div>
      </div>

      <!-- row3 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>Active Part Code:</label></div>
            <div class=col-8><select class="multiple" [(ngModel)]="majorPartAddActPartCd" multiple>
                <option *ngFor="let active of activeLst" [value]="active.maj_part_prfx_cd">{{active.maj_part_prfx_cd}}
                </option>

              </select></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Inactive Part Code:</label></div>
            <div class=col-8><select multiple class="multiple" disabled [(ngModel)]="majorPartAddInActPartCd">
                <option *ngFor="let inactive of inactiveLst" [value]="inactive.col_0_0_">{{inactive.col_0_0_}}</option>

              </select></div>
          </div>
        </div>
      </div>

      <!-- row4 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>Part Code<span class="text-danger">*</span>:</label></div>
            <div class=col-8><input type="text" class="inputtext" oninput="this.value = this.value.toUpperCase()"
                [maxLength]="6" [(ngModel)]="majorPartAddPartCd" onkeyup="this.value=this.value.toUpperCase()"></div>
          </div>
        </div>
        <div class="col-md-6">
        </div>
      </div>

      <!-- row5 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <button (click)="addPartCode()">Add</button><br><br>
              <button (click)="removePartCode()">Remove</button>
            </div>
            <div class=col-8><select multiple class="multiple" [(ngModel)]="majorPartAddSlctPartCd">
                <option value={{partCode}} *ngFor="let partCode of partCodeList">{{partCode}}
              </select></div>
          </div>
        </div>
        <div class="col-md-6">
        </div>
      </div>

      <!-- row13 -->
      <div class="row rowClass">
        <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
        <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review"
            [(ngModel)]="majorPartAddComments">
          </textarea>
        </div>
      </div>

    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-button cdkFocusInitial (click)="submitMajLst()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>
