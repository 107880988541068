import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReferenceModule} from '../reference.module';
import { HunderedSpecificationsComponent } from '../hundered-specifications/hundered-specifications.component';
import { HunderedSpecificationsAddDialogComponent } from '../hundered-specifications-add-dialog/hundered-specifications-add-dialog.component';
import { HunderedSpecificationsEditDialogComponent } from '../hundered-specifications-edit-dialog/hundered-specifications-edit-dialog.component';
import { HunderedSpecificationsMultieditDialogComponent } from '../hundered-specifications-multiedit-dialog/hundered-specifications-multiedit-dialog.component';
import { HunderedSpecificationsViewDialogComponent } from '../hundered-specifications-view-dialog/hundered-specifications-view-dialog.component';
import { HunderedSpecificationsUploadDialogComponent } from '../hundered-specifications-upload-dialog/hundered-specifications-upload-dialog.component';




@NgModule({
  declarations: [
    HunderedSpecificationsComponent,
    HunderedSpecificationsAddDialogComponent,
    HunderedSpecificationsEditDialogComponent,
    HunderedSpecificationsMultieditDialogComponent,
    HunderedSpecificationsViewDialogComponent,
    HunderedSpecificationsUploadDialogComponent,
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class HundPerSpecModule { }
