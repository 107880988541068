import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { DialogData } from '../dialog-data/dialog-data';
import { VersionQualifierAddDialogComponent } from '../version-qualifier-add-dialog/version-qualifier-add-dialog.component';
import { VersionQualifierDeleteDialogComponent } from '../version-qualifier-delete-dialog/version-qualifier-delete-dialog.component';
import { VersionQualifierService } from './version-qualifier.service';
import { NgxSpinnerService } from "ngx-spinner";

import { GlobalService } from '../global.service';

@Component({
  selector: 'app-version-qualifier',
  templateUrl: './version-qualifier.component.html',
  styleUrls: ['./version-qualifier.component.css']
})
export class VersionQualifierComponent implements OnInit {

  //export to excel
  fileName = 'VersionQualifier';
  exportData = [];
  sheetName = [];


  //List
  verTypeLst = [];
  verQualifierLst = [];

  //ngModel
  verType: any = "";
  verQualifier: any = "";
  step = 0;

  //errorMessage
  errorMessage: string = '';

  //messagefromDialog
  successMessage: string = '';

  spinnerMessage = "Please Wait...";
  recordcount: any = 0;
  roleAccess: any = [];
  Roledata: any;
  accessEnable: boolean;
  constructor(public dialog: MatDialog, private exportService: ExportToExcelService,
    private dialogData: DialogData, private verQualService: VersionQualifierService,
    private SpinnerService: NgxSpinnerService, public globalrole: GlobalService, ) { }

  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  resetAll(data) {
    this.selected = [];
    this.verType = '';
    this.verQualifier = '';
    this.errorMessage = '';
    this.successMessage = '';
    // this.getGridData(data);
    this.onloadfn();
  }

  //ngx-datatable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('verQualTable') table;
  limitSelected: any = 50;

  showdata: boolean = true;
  columns = [
    { name: 'bomVerQualId', prop: 'bom_ver_qual_id' }, { name: 'versionType', prop: 'bom_ver_typ_cd' },
    { name: 'bomVerQualCd', prop: 'bom_ver_qual_cd' }, { name: 'bomVerQualDesc', prop: 'bom_ver_qual_desc' },
    { name: 'adhocProcScenarioId', prop: 'adhoc_proc_scenario_id' }, { name: 'bomVerTypId', prop: 'bom_ver_typ_id' },
    { name: 'createById', prop: 'create_by_id' }, { name: 'createTs', prop: 'create_ts' },
    { name: 'effDt', prop: 'eff_dt' }, { name: 'exprDt', prop: 'expr_dt' },
    { name: 'updateById', prop: 'update_by_id' }, { name: 'updateTs', prop: 'update_ts' }
  ];
  ColumnMode = ColumnMode;

  async ngOnInit() {
    this.SpinnerService.show();
    let a = await this.userroleAccessfn();
    if (a) {
      console.log('a', a)
      //grid
      this.getGridData("initial");
    }

    this.onloadfn();


  }
onloadfn(){
  //vertype
  this.verQualService.getVersionType().subscribe(data => {
    console.log(data);
    this.verTypeLst = data.payload;
  });

    //verQual
    this.verQualService.getQualifier().subscribe(data => {
      console.log(data);
      this.verQualifierLst = data.payload;
    });


  }

  //ngx-table
  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);

    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }
  displayCheck(row) {
    return row;
  }

  singleSelectCheck(row: any) {
    return this.selected.indexOf(row) === -1;
  }

  openAddDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(VersionQualifierAddDialogComponent, {
        disableClose: true,
        // width: '50%'
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result == 'success') {
          this.getGridData("add");
          this.successMessage = "Version Qualifier Added Successfully"
          this.selected = []
        }
        else if (result == 'AlreadyExists') {
          this.resetAll("initial");
          this.errorMessage = "Same combination Of Version Type and Version Qualifier exists already"
          this.globalrole.goToTop();
        }
        else {
          // this.errorMessage = 'Data not inserted/Valid'
        }
      });
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }

  }
  openDeleteDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    if (this.roleAccess.includes('Delete')) {
      if (this.selected.length == 1) {
        this.sendToDialog(this.selected);
        const dialogRef = this.dialog.open(VersionQualifierDeleteDialogComponent, {
          disableClose: true,
          width: '50%',
          data: this.dialogData
        });
        dialogRef.afterClosed().subscribe(result => {
          this.selected = [];
          console.log(result)
          if (result == 'success') {
            this.getGridData("add");
            this.successMessage = "Version Qualifier Deleted Successfully"
            this.selected = []
          }
          else if (result == 'AlreadyExists') {
            this.resetAll("initial");
            this.errorMessage = 'Data Entered already Exists!'
            this.globalrole.goToTop();
          }
          else if (result == "cancel") {
            this.errorMessage = "";
            this.successMessage = ""
          }
          else {
            this.errorMessage = result
          }
        });
      }
      else {
        this.errorMessage = "Please select a row to delete"
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'delete' operation";
      this.globalrole.goToTop();
    }
  }

  exportFile(): void {

    this.rows.forEach(row => {
      let obj = {
        "Version Type": row.bom_ver_typ_cd,
        "Version Qualifier": row.bom_ver_qual_cd
      }
      this.exportData.push(obj);
    });

    this.exportService.exportAsExcelFile(this.exportData, this.fileName, this.sheetName);
    this.exportData = [];
  }

  //onchangemethod
  getVerQualByTyp(): void {
    if (this.verType == undefined) {
      this.verType = ""
    }
    this.verQualService.getVerQualByTyp(this.verType).subscribe(data => {
      console.log(data);
      this.verQualifierLst = data.payload;
      this.verQualifier = ""
    });

  }

  closeAlert() {
    this.successMessage = '';
    this.errorMessage = ''
  }

  search() {
    this.errorMessage = ""
    this.successMessage = ""
    this.getGridData("initial");
  }

  getGridData(data) {
    this.selected = [];
    console.log(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      if (this.verType == undefined) {
        this.verType = ""
      }
      if (this.verQualifier == undefined) {
        this.verQualifier = ""
      }

      let verQualObj
      if (data == 'initial') {
        verQualObj =
        {
          bomVerTypId: this.verType,
          bomVerQualCd: this.verQualifier,
          option: data
        }
      } else {
        verQualObj =
        {
          bomVerTypId: "",
          bomVerQualCd: "",
          option: data
        }
      }
      this.SpinnerService.show();
      this.showdata = true;
      console.log(this.showdata);
      this.verQualService.getGridData(verQualObj).subscribe(data => {
        console.log(data);
        this.recordcount = data.pagination.totalRecords;
        this.SpinnerService.hide();
        this.rows = data.payload;
      }, err => {
        console.log(err);
        this.showdata = false;
        this.successMessage = "";
        console.log(this.showdata);
        this.rows = [];
        if (err == 0) {
          this.errorMessage = "Request timed out. Please try again.";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        } else {
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        }
      });
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }


  sendToDialog(selected): void {
    selected.forEach(row => {
      this.dialogData.verType = row.bom_ver_typ_cd;
      this.dialogData.verQualifier = row.bom_ver_qual_cd;
      this.dialogData.verQualId = row.bom_ver_qual_id;

    });
  }

  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.log(res)
        this.Roledata = res;
      })
      console.log(this.Roledata);
      if (this.Roledata.length != 0) {
        this.Roledata.filter((res) => {
          if (res.function_nm == 'NBPC_10105') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length == 0) {
        let arrayvalue: any; let roleList: any;
        let data = localStorage.getItem('role');
        arrayvalue = data.split(',')
        console.log(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str == '') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        let body = {
          security_grp_nm: roleList
        }
        let userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
          this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
          this.userroleAccessfn();
          this.accessEnable = true;
          resolve(true);
        }else{
        console.log(this.roleAccess)
        this.roleAccess = []
        this.errorMessage = "User does not have permission to perform 'read' operation";
        this.accessEnable = false;
        this.SpinnerService.hide();
      };
      }
      console.log(this.roleAccess);
    })
  }

}
