import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('Ebom_accessToken')
    })
  };
}
@Injectable({
  providedIn: 'root'
})
export class ExchangeRateSearchService {



  currencyCodeFrom = '/getCurrencyCodeFrom'
  currencyCodeTo = '/getCurrencyCodeTo'
  costType = '/getCostTypeByCurrencyCd'
  exchangeRate = '/getExchangeRateByEffDt'
  gridData = '/getExchangeRateGrid'



  constructor(private http: HttpClient) { }

  //Currency Code From
  getCurrencyCodeFrom(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = environment.Mastersearchdropdown1apiUrl + this.currencyCodeFrom+'?screenNo=NBPC_3910'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //Currency Code To
  getCurrencyCodeTo(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = environment.Mastersearchdropdown1apiUrl + this.currencyCodeTo+'?screenNo=NBPC_3910'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //Cost Type
  getCostType(crncy1Cd, crncy2Cd): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let costParams = new HttpParams().set('crncy1Cd', crncy1Cd)
    //                                 .append('crncy2Cd', crncy2Cd);
    let API_URL = environment.Mastersearchdropdown1apiUrl + this.costType + "?crncy1Cd=" + crncy1Cd + "&crncy2Cd=" + crncy2Cd+'&screenNo=NBPC_3910'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //Exchange Rate
  getExchangeRate(effDt, crncy1Cd, crncy2Cd, exchngRtTyp, exprDt): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }

    let API_URL = environment.Mastersearchdropdown1apiUrl + this.exchangeRate + '?effDt=' + effDt + '&crncy1Cd=' + crncy1Cd +
    '&crncy2Cd=' + crncy2Cd + '&exchngRtTyp=' + exchngRtTyp + '&exprDt=' + exprDt+'&screenNo=NBPC_3910'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }



  //grid data
  getExchangeRateGrid(gridData): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = environment.MastergridapiUrl + this.gridData + '?crncy1Cd=' + gridData.currencyCodeFrom +
      '&crncy2Cd=' + gridData.currencyCodeTo + '&exchngRtTyp=' + gridData.costType + '&effDt=' + gridData.effectiveInDate +
      '&exprDt=' + gridData.effectiveOutDate + '&curExchngRt=' + gridData.exchangeRate + '&inactvFlg=' + gridData.status +
      '&option=' + gridData.option+'&screenNo=NBPC_3910'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(catchError(this.error)
      )
  }
  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage:any = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error.status;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

}
