import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { VersionDialogData } from '../dialog-data/version-dialog-data';
import { NgxSpinnerService } from "ngx-spinner";
import { VersionEditDialogService } from './version-edit-dialog.service';

@Component({
  selector: 'app-version-edit-dialog',
  templateUrl: './version-edit-dialog.component.html',
  styleUrls: ['./version-edit-dialog.component.css']
})
export class VersionEditDialogComponent implements OnInit {
  [x: string]: any;

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //Errormessage
  errorMessage: string = "";

  //ngModel values

  verEditApprovalStat: any;
  verEditVerType: any;
  verEditVerQual: any;
  verEditVerRev: any;
  verEditPriority: any;
  verEditVolCntStatus: any;
  verEditBomEffDate: any;
  verEditCostEffDate: any;
  verEditEffInDate: any;
  verEditEffOutDate: any;
  verEditComments: any;
  priorityArray: any = []
  rep_bom_ver_id:any
  //to main page
  message = "Record Updated Successfully";

  spinnerMessage = "Please Wait...";

  constructor(private VersionEditDialogService: VersionEditDialogService, 
    @Inject(MAT_DIALOG_DATA) public data: VersionDialogData,
    public dialogRef: MatDialogRef<VersionEditDialogComponent>,
    private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    console.log("data", this.data);
    this.getpriorityData();
    this.verEditApprovalStat = this.data.approvalStat;
    this.verEditVerType = this.data.versionType;
    this.verEditVerQual = this.data.versionQual;
    this.verEditVerRev = this.data.versionRev;
    this.verEditPriority = this.data.priorityId;
    this.verEditVolCntStatus = this.data.volCntStatus;
    this.verEditBomEffDate = this.data.bomEffDate;
    this.verEditCostEffDate = this.data.costEffDate;
    this.verEditEffInDate = this.data.effInDate;
    this.verEditEffOutDate = this.data.effOutDate;
    this.verEditComments = this.data.comments;
    this.rep_bom_ver_id = this.data.repBomVerId
  }

  getpriorityData() {
    this.VersionEditDialogService.getPrioritydropdown().subscribe(res => {
      console.log("res from getverpriority", res.payload);
      this.priorityArray = res.payload
    })
  }

  //save
  updateVersion(): void {
    let fileName = localStorage.getItem('Filename')
    console.log(this.verEditBomEffDate);
    console.log(this.verEditEffOutDate);//null
    const effOutDateStr = this.pipe.transform(this.verEditEffOutDate, 'yyyy-MM-dd');
    const bomEffDateStr = this.pipe.transform(this.verEditBomEffDate, 'yyyy-MM-dd');
    console.log(effOutDateStr,"Muskan",this.verEditPriority);
    if (!this.verEditPriority || this.verEditEffOutDate==='' || this.verEditBomEffDate ==='') {
      console.log(effOutDateStr,this.verEditBomEffDate);
      this.errorMessage = "Please enter all mandatory fields";
      console.log((!/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(effOutDateStr)),(!/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(bomEffDateStr)));
    }
    else if (!/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(effOutDateStr) ||
      (!/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(bomEffDateStr)&& this.verEditBomEffDate!=='' ))
      {
        console.log((!/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(effOutDateStr)),(!/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(bomEffDateStr)));
      this.errorMessage = "Date format should be yyyy-mm-dd";
    } else if (this.verEditEffInDate && this.verEditEffOutDate &&
      this.pipe.transform(this.verEditEffInDate, 'yyyy-MM-dd') > effOutDateStr) {
      this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";
    }
 
    else {
      this.errorMessage = "";
      let outdateFormat = '';
      let bomEffDateFormat = '';
 
      if (this.verEditEffOutDate != "") {
        let d1 = moment(this.verEditEffOutDate).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.verEditEffOutDate = date
      }
      if (this.verEditBomEffDate != "") {
        let d2 = moment(this.verEditBomEffDate).format('YYYY-MM-DD')
        let b2 = d2.split('T')[0]
        let parts2 = []
        parts2 = b2.split('-');
        let date2 = new Date(parts2[0], parts2[1] - 1, parts2[2]);
        bomEffDateFormat = moment(date2).format('YYYY-MM-DD')
        this.verEditBomEffDate = date2
      }
 
      let updateId = localStorage.getItem('workDayId')
      let cmts;
      if (this.verEditComments!= undefined) {
        cmts = this.verEditComments.replaceAll("'", "''");
      } else {
        cmts = this.verEditComments
      }
      let body = {
        "ver_priority_typ_id": this.verEditPriority,
        "expr_dt": outdateFormat,
        "bom_eff_dt": bomEffDateFormat,
        "rmrk_txt": cmts,
        "rep_bom_ver_id": this.rep_bom_ver_id,
        "screenNo":"NBPC_10105",
        "fileName" :fileName
      }
      this.VersionEditDialogService.editVersions(body).subscribe(data => {
        console.log(data);
        this.response = data.payload;
        if (this.response[0].result == 'success') {
          this.dialogRef.close(this.response[0].result);
        } 
        // this.dialogRef.close({ event: 'Cancel', data: this.message });
      },err =>{
        this.errorMessage = "Data not updated!!"
        console.log("err",err);
        this.dialogRef.close({ event: 'Cancel', data: this.message });
      });
    }
  }
 
  dateChange(value){
    console.log(value)
    if(value===''){
      this.verEditEffOutDate=''
    }
  }
 
  dateChange1(value){
    if(value===''){
      this.verEditBomEffDate=''
    }
  }
 
 
  cancel(): void {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }
  closeAlert() {
    this.errorMessage = '';
  }
 
 
  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'verEditEffOutDate') {
      this.verEditEffOutDate = "";
    } else if (name == 'verEditBomEffDate') {
      this.verEditBomEffDate = "";
    }
  }

}
