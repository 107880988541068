<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
<h2 mat-dialog-title cdkDragHandle>View <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
</h2>

<mat-dialog-content class="mat-typography">
  <div class="container">
    <!-- row1 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Parameter Name:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="vParameterName" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Parameter Value:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="vParameterValue" disabled></div>
        </div>
      </div>
    </div>

    <!-- row2 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Parameter Description:</label></div>
          <div class=col-8><textarea class="w-100" [(ngModel)]="vParameterDescription" disabled name="w3review" rows="4"
              cols="50">
            </textarea>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- row3 -->
  <div class="row rowClass">
    <div class="col-md-6">
      <div class="row">
        <div class="col-4"> <label>Create Ts:</label></div>
        <div class=col-8><input type="text" class="inputtext" [(ngModel)]="vCreateTs" disabled></div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-4"> <label>Create By Id:</label></div>
        <div class=col-8><input type="text" class="inputtext" [(ngModel)]="vCreateById" disabled></div>
      </div>
    </div>
  </div>

  <!-- row4 -->
  <div class="row rowClass">
    <div class="col-md-6">
      <div class="row">
        <div class="col-4"> <label>Update Ts:</label></div>
        <div class=col-8><input type="text" class="inputtext" [(ngModel)]="vUpdateTs" disabled></div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-4"> <label>Update By Id:</label></div>
        <div class=col-8><input type="text" class="inputtext" [(ngModel)]="vUpdateById" disabled></div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>Ok</button>
</mat-dialog-actions>
</ng-container>