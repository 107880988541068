import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MajorPartListComponent } from '../major-part-list/major-part-list.component';
import { MajorPartlstAddDialogComponent } from '../major-partlst-add-dialog/major-partlst-add-dialog.component';
import { MajorPartlstEditDialogComponent } from '../major-partlst-edit-dialog/major-partlst-edit-dialog.component';
import { MajorPartlstViewDialogComponent } from '../major-partlst-view-dialog/major-partlst-view-dialog.component';
import { MajorPartlstUploadDialogComponent } from '../major-partlst-upload-dialog/major-partlst-upload-dialog.component';
import {ReferenceModule} from '../reference.module';


@NgModule({
  declarations: [
    MajorPartListComponent,
    MajorPartlstAddDialogComponent,
    MajorPartlstEditDialogComponent,
    MajorPartlstViewDialogComponent,
    MajorPartlstUploadDialogComponent,
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class MajPartLstModule { }
