<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Edit <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>

  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>

  <mat-dialog-content class="mat-typography" >
    <div class="container">
      <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are mandatory</span>
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Monthly Representative:</label></div>
            <div class=col-2><input type="checkbox" [(ngModel)]="eMonthlyRep"></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Comparitive BOM:</label></div>
            <div class=col-2><input type="checkbox" [(ngModel)]="eCompBom"></div>
          </div>
        </div>
      </div>
      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Model Number:</label></div>
            <div class=col-8><input type="text" oninput="this.value = this.value.toUpperCase()" [maxLength]="10"
                class="inputtext" [(ngModel)]="eModelNumber"></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Option:</label></div>
            <div class=col-8><input type="text" oninput="this.value = this.value.toUpperCase()" [maxLength]="5"
                class="inputtext" [(ngModel)]="eOption"></div>
          </div>
        </div>
      </div>

    <!-- row3 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row ">
          <div class="col-4"> <label>SAP Material ID:</label></div>
          <div class=col-8><input type="text" oninput="this.value = this.value.toUpperCase()"
            [maxLength]="20" class="inputtext" [(ngModel)]="eSapMatId"></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          </div>
        </div>
      </div>

      <!-- row4 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>NAMC:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eNamc" class="inputtext" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Family:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eFamily" class="inputtext" disabled></div>
          </div>
        </div>
      </div>

      <!-- row5 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Katashiki:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eKatashiki" class="inputtext" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Customer:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eCustomerId" class="inputtext" disabled></div>
          </div>
        </div>
      </div>

      <!-- row6 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Dest:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eDest" class="inputtext" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Hikiate Status:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eHikiate" class="inputtext" disabled></div>
          </div>
        </div>
      </div>

      <!-- row7 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>S/P:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eSP" class="inputtext" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>PC Spec:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="ePcSpec" class="inputtext" disabled></div>
          </div>
        </div>
      </div>

      <!-- row8 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Comp PC Spec:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eCompPcSpec" class="inputtext" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Net Spec:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eNetSpec" class="inputtext" disabled></div>
          </div>
        </div>
      </div>


      <!-- row9 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>100% Spec:</label></div>
            <div class=col-2><input type="checkbox" [(ngModel)]="eHundPercentSpec" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Comp 100% Spec:</label></div>
            <div class=col-2><input type="checkbox" [(ngModel)]="eCompHundPercentSpec" disabled></div>
          </div>
        </div>
      </div>

    <!-- row10 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Extraction Time In<span class="text-danger">*</span>:</label></div>
          <div class=col-8>
            <mat-form-field class="picker" appearance="outline">
              <input matInput [matDatepicker]="inPicker" [(ngModel)]="eExtractionTimeIn" 
               >
              <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
              <mat-datepicker #inPicker ></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'eExtractionTimeIn')">clear</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Extraction Time Out:</label></div>
          <div class=col-8>
            <mat-form-field class="picker" appearance="outline">
              <input matInput [matDatepicker]="outPicker" [(ngModel)]="eExtractionTimeOut" 
               >
              <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
              <mat-datepicker #outPicker ></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'eExtractionTimeOut')">clear</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <!-- row11 -->
    <div class="row rowClass">
      <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
      <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review" rows="4" cols="50" [(ngModel)]="eComments">
          </textarea>
        </div>
      </div>

    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="center" >
    <button mat-button cdkFocusInitial (click)="updateRepVeh()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>
