import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionQualifierComponent } from '../version-qualifier/version-qualifier.component';
import { VersionQualifierAddDialogComponent } from '../version-qualifier-add-dialog/version-qualifier-add-dialog.component';
import { VersionQualifierDeleteDialogComponent } from '../version-qualifier-delete-dialog/version-qualifier-delete-dialog.component';
import {ReferenceModule} from '../reference.module';


@NgModule({
  declarations: [
    VersionQualifierComponent,
    VersionQualifierAddDialogComponent,
    VersionQualifierDeleteDialogComponent,
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class VerQualModule { }
