<nav class="navbar navbar-light bg-transparent" *ngIf="show" aria-label>
  <div class="container-fluid justify ">
    <a class="navbar-brand vr-line">
      <img src="assets/ebom_logo.png" alt="" width="90" height="50" class="d-inline-block align-text-top">
    </a>
    <span class="navbar-brand">
      Welcome {{user_name | uppercase}} {{last_name | uppercase}}, {{ now| date:"yyyy-MM-dd" }}</span>
    <span class="ms-auto ">
      <a href="https://myteams.toyota.com/sites/eBOMModernization/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FeBOMModernization%2FShared%20Documents%2FGeneral%2FDocumentation%2FeBoM%20System%5FUser%20Manual%20%2D%20MASTER%2Epdf&parent=%2Fsites%2FeBOMModernization%2FShared%20Documents%2FGeneral%2FDocumentation&p=true&ga=1 " target="_blank" class="line">Help</a>
      <a (click)="logout()" class="logoutLink">Logout</a><br>
      <div class="dropdown" style="right: 8%;">
        <button class="dropbtn">Related Links</button>
        <div class="dropdown-content">
          <a (click)="openRelatedLink()">eBoM Reporting</a>
        </div>
      </div>
    </span>
  </div>
</nav>

<nav class="navbar navbar-expand-lg navbar-light bg-light" *ngIf="show" aria-label>
  <div class="container-fluid">
    <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav">
        <li class="nav-item active homeicon">
          <a routerLink="/home" class="nav-link "><em class="fa fa-home"></em></a>
        </li>
        <li class="nav-item active dropdown">
          <a href="#" class="nav-link dropdown-toggle" id="master" data-toggle="dropdown">Master Data</a>
          <ul class="dropdown-menu" aria-labelledby="master">
            <li><a routerLink="/repVehicles" (click)="masterScreens('repVehicles');reload('/repVehicles')"
                class="dropdown-item">Representative Vehicle</a></li>
            <div class="dropdown-divider"></div>
            <li><a routerLink="/repUnits" (click)="masterScreens('repUnits');reload('/repUnits')"
                class="dropdown-item">Representative Units</a></li>
            <div class="dropdown-divider"></div>
            <li><a routerLink="/sapDataLd" (click)="masterScreens('sapDataLd');reload('/sapDataLd')"
                class="dropdown-item">SAP Dataload to eBoM </a></li>
            <div class="dropdown-divider"></div>
            <li><a routerLink="/mngDataTrnsfrSap"
                (click)="masterScreens('mngDataTrnsfrSap');reload('/mngDataTrnsfrSap')" class="dropdown-item">Manage
                Data Transfer to SAP</a></li>
            <div class="dropdown-divider"></div>
            <li><a (click)="masterScreens('versionTypes');reload('/versionTypes')" class="dropdown-item">Version
                Types</a></li>
            <div class="dropdown-divider"></div>
            <li><a routerLink="/verQualifiers" (click)="masterScreens('verQualifiers');reload('/verQualifiers')"
                class="dropdown-item">Version Qualifiers</a></li>
            <div class="dropdown-divider"></div>
            <li><a routerLink="/majorPartLst" (click)="masterScreens('majorPartLst');reload('/majorPartLst')"
                class="dropdown-item">Major Part List </a></li>
            <div class="dropdown-divider"></div>
            <li><a routerLink="/majorPartTyp" (click)="masterScreens('majorPartTyp');reload('/majorPartTyp')"
                class="dropdown-item">Major Part Type</a></li>
            <div class="dropdown-divider"></div>
            <li><a routerLink="/partSource" (click)="masterScreens('partSource');reload('/partSource')"
                class="dropdown-item">Part
                Source</a></li>
            <div class="dropdown-divider"></div>
            <li><a routerLink="/partSourceRtg" (click)="masterScreens('partSourceRtg');reload('/partSourceRtg')"
                class="dropdown-item">Part Source Routing</a></li>
            <div class="dropdown-divider"></div>
            <li><a routerLink="/exchangeRate" (click)="masterScreens('exchangeRate');reload('/exchangeRate')"
                class="dropdown-item">Exchange Rate</a></li>
            <div class="dropdown-divider"></div>
            <li><a routerLink="/slctvyPartRule" (click)="masterScreens('slctvyPartRule');reload('/slctvyPartRule')"
                class="dropdown-item">Selectivity Part Rule</a></li>
            <div class="dropdown-divider"></div>
            <li><a routerLink="/100perSpec" (click)="masterScreens('100perSpec');reload('/100perSpec')"
                class="dropdown-item">100 %
                Specifications</a></li>
            <div class="dropdown-divider"></div>
            <li><a routerLink="/famProNamc" (click)="masterScreens('famProNamc');reload('/famProNamc')"
                class="dropdown-item">Family,
                Project and NAMC </a></li>
            <div class="dropdown-divider"></div>
            <li><a routerLink="/extPartLst" (click)="masterScreens('extPartLst');reload('/extPartLst')"
                class="dropdown-item">Extraneous Parts List </a></li>
            <div class="dropdown-divider"></div>
            <li><a routerLink="/pcSpecExclLst" (click)="masterScreens('pcSpecExclLst');reload('/pcSpecExclLst')"
                class="dropdown-item">PC Specs Exclusion List </a></li>
            <div class="dropdown-divider"></div>
            <li><a routerLink="/family" (click)="masterScreens('family');reload('/family')"
                class="dropdown-item">Family</a></li>
            <div class="dropdown-divider"></div>
            <li><a routerLink="/hikiateStatus" (click)="masterScreens('hikiateStatus');reload('/hikiateStatus')"
                class="dropdown-item">Hikiate Status</a></li>
            <div class="dropdown-divider"></div>
            <li><a routerLink="/plntUsrCd" (click)="masterScreens('plntUsrCd');reload('/plntUsrCd')"
                class="dropdown-item">Plant User Code
              </a></li>
            <div *ngIf="roleshown == 'true'" class="dropdown-divider"></div>
            <li *ngIf="roleshown == 'true'"><a routerLink="/isSystemParm"
                (click)="masterScreens('isSystemParm');reload('/isSystemParm')" class="dropdown-item">IS System Parm</a>
            </li>
            <div *ngIf="roleshown == 'true'" class="dropdown-divider"></div>
            <li *ngIf="roleshown == 'true'"><a routerLink="/procStatus"
              (click)="masterScreens('procStatus');reload('/procStatus')" class="dropdown-item">Procedure Status</a>
          </li>

          </ul>
        </li>
        <li class="nav-item active">
          <a href="#" class="nav-link dropdown-toggle" id="business" data-toggle="dropdown">Business Process </a>
          <ul class="dropdown-menu" aria-labelledby="business">
            <li><a routerLink="/versions" (click)="businessScreens('versions');reload('/versions')"
                class="dropdown-item">Versions</a></li>
            <div class="dropdown-divider"></div>
            <li><a routerLink="/prodWithinVersion"
                (click)="businessScreens('prodWithinVersion');reload('/prodWithinVersion')"
                class="dropdown-item">Product Within Version</a></li>
            <div class="dropdown-divider"></div>
            <li><a routerLink="/prodProcStatus" (click)="businessScreens('prodProcStatus');reload('/prodProcStatus')"
                class="dropdown-item">Product Processing Status</a></li>
            <div class="dropdown-divider"></div>
            <li><a routerLink="/manAdjRule" (click)="businessScreens('manAdjRule');reload('/manAdjRule')"
                class="dropdown-item">Manual
                Adjustment Rule</a></li>
            <div class="dropdown-divider"></div>
            <li><a routerLink="/partSrcAdj" (click)="businessScreens('partSrcAdj');reload('/partSrcAdj')"
                class="dropdown-item">Part
                Source Adjustment</a></li>
          </ul>
        </li>
        <li class="nav-item active">
          <a routerLink="/qualityExceptRpt" (click)="exceptionScreen('qualityExceptRpt');reload('/qualityExceptRpt')"
            class="nav-item nav-link">Quality and Exception Reports</a>
        </li>
        <li class="nav-item active">
          <a routerLink="/ebomIs" (click)="ebomIsDashboard();reload('/ebomIs')" class="nav-item nav-link "
            tabindex="-1">eBoM IS
            Dashboard</a>
        </li>
        <li class="nav-item active" *ngIf="manJobShow == 'true'">
          <a routerLink="/manJobTrigger" (click)="ebomIsDashboard();reload('/manJobTrigger')" class="nav-item nav-link "
            tabindex="-1">Manual Job Trigger</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="container-fluid header">
  <span *ngIf="show">
    <span class="screenName">{{screenName}}</span>
    <button *ngIf="hidden" class="btn btn-dark" type="button" data-toggle="dropdown">
      Related Functions<span class="caret"></span></button>
    <ul class="dropdown-menu" *ngIf="hidden">
      <li *ngIf="screenName==='Maintain Version Qualifiers'"><a routerLink="/versionTypes"
          (click)="masterScreens('versionTypes')" class="relLink">Version Types</a></li>
      <li *ngIf="screenName==='Maintain Versions'"><a routerLink="/verQualifiers"
          (click)="masterScreens('verQualifiers')" class="relLink">Version Qualifiers</a></li>
      <li *ngIf="screenName==='Maintain Version Types'"><a routerLink="/verQualifiers"
          (click)="masterScreens('verQualifiers')" class="relLink">Version Qualifiers</a></li>
      <li *ngIf="screenName==='Maintain Major Part List'"><a routerLink="/majorPartTyp"
          (click)="masterScreens('majorPartTyp')" class="relLink">Major Part type</a></li>
      <li *ngIf="screenName==='Maintain Major Part Type'"><a routerLink="/majorPartLst"
          (click)="masterScreens('majorPartLst')" class="relLink">Major Part List</a></li>
      <li *ngIf="screenName==='View Part Source'"><a routerLink="/partSourceRtg"
          (click)="masterScreens('partSourceRtg')" class="relLink">Part Source Routing</a></li>
      <li *ngIf="screenName==='Maintain Part Source Routing'"><a routerLink="/partSource"
          (click)="masterScreens('partSource')" class="relLink">Part Source Type</a></li>
      <li *ngIf="screenName==='Maintain Product within Version'"><a routerLink="/versions"
          (click)="businessScreens('versions')" class="relLink">Versions</a></li>
      <li *ngIf="screenName==='Maintain Product within Version'"><a routerLink="/prodProcStatus"
          (click)="businessScreens('prodProcStatus')" class="relLink">Product Processing Status</a></li>
      <li *ngIf="screenName==='Product Processing Status'"><a routerLink="/prodWithinVersion"
          (click)="businessScreens('prodWithinVersion')" class="relLink">Product within Version</a></li>
      <li *ngIf="screenName==='GL Reconciliation Period'"><a routerLink="/glReconPeriodProcStat"
          (click)="businessScreens('glReconPeriodProcStat')" class="relLink">GL Reconciliation Processing Status</a>
      </li>
      <li *ngIf="screenName==='GL Reconciliation Processing Status'"><a routerLink="/glReconPeriod"
          (click)="businessScreens('glReconPeriod')" class="relLink">GL Reconciliation Period</a></li>
          <li *ngIf="screenName==='GL Reconciliation Processing Status'"><a routerLink="/glReconPeriod"
            (click)="businessScreens('glReconPeriod')" class="relLink">GL Reconciliation Period</a></li>
    </ul>
    <hr *ngIf="screenName!=''" />
  </span>
  <router-outlet></router-outlet>
</div>


<div class="modal fade" id="idleModal" tabindex="-1" role="dialog" aria-hidden="true"
  data-backdrop="static" >
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row text-center">
            <div class="col-md-12 py-3 px-0 col-lg-12">
              {{idleStates}}
            </div>
            <div>

            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer ">
        <div class='row w-100'>
          <div class='offset-md-4 col-md-3'>
            <button type="button" class="btndata btn btn-secondary btn-lg"
              (click)="submit()">OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>