<span *ngIf="accessEnable">
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
  {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>


<div class="alert alert-success alert-dismissible " *ngIf="successMessage!=''" role="alert">
  {{successMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>

<mat-accordion class="example-headers-align">
  <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>

    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Search</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-form-field>
      <mat-label>NAMC</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="namc" name="namc" (selectionChange)='getFamByNamc()'>
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let namc of namcLst" [value]="namc.namc_lgl_entity_id">{{namc.namc_cd}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Family</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="fam" name="family" (selectionChange)='getProjByFam()'>
        <mat-option [value]=""></mat-option>
        <span *ngFor="let fam of famLst">
          <mat-option *ngIf="fam.fam_cd != ''" [value]="fam.fam_cd">{{fam.fam_cd}}</mat-option>
        </span>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Project</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="project" name="project"
        (selectionChange)='getModByProj()'>
        <mat-option [value]=""></mat-option>
        <span *ngFor="let project of projectLst">
          <span *ngIf="project.proj_cd != null">
            <mat-option *ngIf="project.proj_cd != ''" [value]="project.proj_cd">
              {{project.proj_cd}}</mat-option>
          </span>
        </span>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Model Year</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="modelYear" name="modelYear">
        <mat-option [value]=""></mat-option>
        <span *ngFor="let modelYear of modelYearLst">
          <span *ngIf="modelYear.mdl_yr != null">
            <mat-option *ngIf="modelYear.mdl_yr != ''" [value]="modelYear.mdl_yr">
              {{modelYear.mdl_yr}}</mat-option>
          </span>
        </span>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="picker">
      <mat-label>Extraction Time In</mat-label>
      <input matInput [matDatepicker]="inPicker" [(ngModel)]="effDateIn">
      <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
      <mat-datepicker #inPicker></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'effDateIn')">clear</mat-icon>
    </mat-form-field>
    <mat-form-field class="picker">
      <mat-label>Extraction Time Out</mat-label>
      <input matInput [matDatepicker]="outPicker" [(ngModel)]="effDateOut">
      <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
      <mat-datepicker #outPicker></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'effDateOut')">clear</mat-icon>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Hikiate Status</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="hikiate" name="hikiate">
        <mat-option [value]=""></mat-option>
        <span *ngFor="let hikiate of hikiateLst">
          <mat-option *ngIf="hikiate.hikiate_stat_comb_cd !=''" [value]="hikiate.hikiate_stat_comb_cd">
            {{hikiate.hikiate_stat_comb_cd}}</mat-option>
        </span>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="picker">
      <mat-label>Prod Time In</mat-label>
      <input matInput [matDatepicker]="prodInPicker" [(ngModel)]="prodDateIn">
      <mat-datepicker-toggle matSuffix [for]="prodInPicker"></mat-datepicker-toggle>
      <mat-datepicker #prodInPicker></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'prodDateIn')">clear</mat-icon>
    </mat-form-field>
    <mat-form-field class="picker">
      <mat-label>Prod Time Out</mat-label>
      <input matInput [matDatepicker]="prodOutPicker" [(ngModel)]="prodDateOut">
      <mat-datepicker-toggle matSuffix [for]="prodOutPicker"></mat-datepicker-toggle>
      <mat-datepicker #prodOutPicker></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'prodDateOut')">clear</mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Model Change Type</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="modelChangeType" name="modelChangeType">
        <mat-option [value]=""></mat-option>
        <span *ngFor="let modelChangeType of modelChangeTypeLst">
          <mat-option *ngIf="modelChangeType.proj_typ_cd != ''" [value]="modelChangeType.proj_typ_cd">
            {{modelChangeType.proj_typ_cd}}</mat-option>
        </span>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Comments</mat-label>
      <input matInput placeholder="" [(ngModel)]="comments">
    </mat-form-field>


    <button mat-button style="float: right;" (click)="resetAll('initial')">Reset</button>
    <button mat-button style="float: right; " (click)="search()">Search</button>


  </mat-expansion-panel>
</mat-accordion>

<div class="d-flex">
  <button mat-raised-button (click)="openAddDialog()">Add </button>
  <button mat-raised-button (click)="openEditDialog()">Edit </button>
  <button mat-raised-button (click)="openViewDialog()">View </button>
  <button mat-raised-button (click)="openUploadDialog()">Upload From Excel </button>
  <button mat-raised-button (click)="exportFile()" class="ms-auto"> Export To Excel </button>
</div>

<!-- <mat-card> -->
<ngx-datatable #myTable class="material striped" [rows]="rows" [columnMode]="ColumnMode.force" [headerHeight]="45"
  [footerHeight]="30" rowHeight="30" [limit]="limitSelected" [selected]="selected"
  [selectionType]="SelectionType.checkbox" [selectAllRowsOnPage]="false" [displayCheck]="displayCheck"
  (activate)="onActivate($event)" (select)="onSelect($event)" [scrollbarH]="true"
  [ngClass]="{'hideerror': showdata, 'showerror': !showdata}">
  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
      let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
      <div class="page-count">
        {{selectedCount}} selected / {{rowCount}} total
      </div>
      <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
        (change)="table.onFooterPage($event)">
      </datatable-pager>
      <div class="test">

        <select [(ngModel)]="limitSelected">
          <option>50</option>
          <option>100</option>
          <option>500</option>
        </select>
        <div class="float-right records">View {{(offset * pageSize)+1}} - {{(rowCount>
          (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
          {{(rowCount)}}

        </div>
      </div>
    </ng-template>
  </ngx-datatable-footer>
  <ngx-datatable-column [width]="10" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false"
    [headerCheckboxable]="true" [checkboxable]="true">
  </ngx-datatable-column>
  <ngx-datatable-column class="w-50" name="Family" prop="fam_cd"></ngx-datatable-column>
  <ngx-datatable-column name="Project" prop="proj_cd"></ngx-datatable-column>
  <ngx-datatable-column name="Model Year" prop="mdl_yr"></ngx-datatable-column>
  <ngx-datatable-column name="Model Change Type" prop="proj_chg_typ_cd" [width]="200"></ngx-datatable-column>
  <ngx-datatable-column name="NAMC" prop="namc_cd"></ngx-datatable-column>
  <ngx-datatable-column name="Hikiate Status" prop="hikiate_stat_comb_cd"></ngx-datatable-column>
  <ngx-datatable-column name="SSN" prop="spec_sheet_no"></ngx-datatable-column>
  <ngx-datatable-column name="Generation" prop="gnrt_id"></ngx-datatable-column>
  <ngx-datatable-column name="Line Off Plant" prop="line_off_plnt"></ngx-datatable-column>
  <ngx-datatable-column name="Prod Time In" prop="prod_strt_dt"></ngx-datatable-column>
  <ngx-datatable-column name="Prod Time Out" prop="prod_end_dt"></ngx-datatable-column>
  <ngx-datatable-column name="Extraction Time In" prop="extraction_timein_dt"></ngx-datatable-column>
  <ngx-datatable-column name="Extraction Time Out" prop="extraction_timeout_dt"></ngx-datatable-column>
  <ngx-datatable-column name="Comments" prop="rmrk_txt"></ngx-datatable-column>
</ngx-datatable>
</span>
<div *ngIf="!accessEnable">
  <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>
