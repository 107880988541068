<div class="container-fluid">
  <div class="row rowClass">
    <div class="col-md-8">
      <div class="row">
        <div class="col-3"> <label>Select Report:</label></div>
        <div class=col-9>
          <select class="dropdown" aria-label="Default select example" (change)="routeToExceptioRpt()"
            [(ngModel)]="rptName">
            <option *ngFor="let rpt of rptNameLst" [value]="rpt.rptUrl">{{rpt.rptDesc}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid header" *ngIf="rptName!=''">
  <router-outlet name="quality"></router-outlet>
</div>