<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
<h2 mat-dialog-title cdkDragHandle >Add <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
</h2>
<div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
  {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>

<mat-dialog-content class="mat-typography">
  <div class="container">
    <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are mandatory</span>
    <br>

    <div class="container">
      <span class="fst-italic"><span class="text-danger">#</span> - Please Fill in at Least one of the fields</span>

      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>Extraneous Part Type<span class="text-danger">*</span>:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="extPart" maxlength="30"></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
          </div>
        </div>
      </div>

      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>Extraneous Part Description:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="extDesc" maxlength="100"></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
          </div>
        </div>
      </div>

      <!-- row3 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>Matching Part Text<span class="text-danger">#</span>:</label></div>
            <div class=col-8><input type="text" class="inputtext" oninput="this.value = this.value.toUpperCase()"
                [maxLength]="30" [(ngModel)]="matPart"></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Matching Part Description Text<span class="text-danger">#</span>:</label></div>
            <div class=col-8><input type="text" class="inputtext" oninput="this.value = this.value.toUpperCase()"
                [maxLength]="30" [(ngModel)]="matPartDesc"></div>
          </div>
        </div>
      </div>

      <!-- row4 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>Component Code<span class="text-danger">#</span>:</label></div>
            <div class=col-8><input type="text" class="inputtext" oninput="this.value = this.value.toUpperCase()"
                [maxLength]="2" [(ngModel)]="compCode"></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Source Routing<span class="text-danger">#</span>:</label></div>
            <div class=col-8><input type="text" class="inputtext" oninput="this.value = this.value.toUpperCase()"
                [maxLength]="6" [(ngModel)]="srcRout"></div>
          </div>
        </div>
      </div>

      <!-- row5 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>Dest Routing<span class="text-danger">#</span>:</label></div>
            <div class=col-8><input type="text" class="inputtext" oninput="this.value = this.value.toUpperCase()"
                [maxLength]="6" [(ngModel)]="destRout"></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
          </div>
        </div>
      </div>

      <!-- row6 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Effective In Date<span class="text-danger">*</span>:</label></div>
            <div class=col-8>
              <mat-form-field class="picker" appearance="outline">
                <input matInput [matDatepicker]="inPicker" [(ngModel)]="effectiveInDate" >
                <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
                <mat-datepicker #inPicker ></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'effectiveInDate')">clear</mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Effective Out Date:</label></div>
            <div class=col-8>
              <mat-form-field class="picker" appearance="outline">
                <input matInput [matDatepicker]="outPicker" [(ngModel)]="effectiveOutDate">
                <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
                <mat-datepicker #outPicker ></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'effectiveOutDate')">clear</mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <!-- row7 -->
      <div class="row rowClass">
        <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
        <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review" rows="2" cols="50"
            [(ngModel)]="comments" maxlength="100">
                </textarea>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-button cdkFocusInitial (click)="updateExtPart()">Save</button>
  <button mat-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
</ng-container>