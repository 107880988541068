<span *ngIf="accessEnable">
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <mat-accordion class="example-headers-align">
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>

    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Please select the fields to generate the reports.</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>


      <mat-form-field>
        <mat-label>NAMC</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="namc" name="namc"
          (selectionChange)='getFamCdByNamc()'>
          <mat-option value="">ALL</mat-option>
          <mat-option *ngFor="let namc of namcLst" [value]="namc.namc_lgl_entity_id">{{namc.namc_cd}}</mat-option>
        </mat-select>
      </mat-form-field>

    <mat-form-field>
      <mat-label>Family</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="family" name="family">
        <mat-option value="">ALL</mat-option>
        <mat-option *ngFor="let family of familyLst" [value]="family.fam_cd">{{family.fam_cd}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Katashiki/Part</mat-label>
      <input matInput placeholder="" [(ngModel)]="katashiki">
    </mat-form-field>


    <button mat-button style="float: right;" (click)="generateReport()">Generate Report</button>

  </mat-expansion-panel>
</mat-accordion>

<div class="d-flex" *ngIf="showButtons">
  <span class="ms-auto">
    <button mat-raised-button (click)="exportFile()">Export to Excel </button>
  </span>
</div>

<!-- <mat-card> -->
<ngx-datatable #myTable *ngIf="showGrid" class="material striped" [rows]="rows" [columnMode]="ColumnMode.force"
  [headerHeight]="45" [footerHeight]="30" rowHeight="30" [limit]="limitSelected" [selected]="selected"
  [selectionType]="SelectionType.checkbox" [selectAllRowsOnPage]="false" [displayCheck]="displayCheck"
  (activate)="onActivate($event)" (select)="onSelect($event)" [scrollbarH]="true"
  [ngClass]="{'hideerror': showdata, 'showerror': !showdata }">
  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
      let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
      <div class="page-count">

      </div>
      <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
        (change)="table.onFooterPage($event)">
      </datatable-pager>
      <div class="test">
        <select [(ngModel)]="limitSelected">
          <option>50</option>
          <option>100</option>
          <option>500</option>
        </select>
        <div class="float-right records">View {{(offset * pageSize)+1}} - {{(rowCount>
          (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
          {{(rowCount)}}

          </div>
        </div>
      </ng-template>
    </ngx-datatable-footer>
    <ngx-datatable-column [width]="10" [sortable]="false" [canAutoResize]="false" [draggable]="false"
      [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="false">
    </ngx-datatable-column>
    <ngx-datatable-column name="NAMC" prop="namc_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Family" prop="fam_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Katashiki/Part" prop="katashiki_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Suspension Date" prop="excp_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Prod BoM ID" prop="prod_bom_id"></ngx-datatable-column>
    <ngx-datatable-column name="Dest" prop="dest_ctry_id"></ngx-datatable-column>
    <ngx-datatable-column name="Prod Def ID" prop="prod_def_id"></ngx-datatable-column>
    <ngx-datatable-column name="Error message" prop="err_desc"></ngx-datatable-column>
    <ngx-datatable-column name="BoM Effectivity Date" prop="prod_trnfr_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Production Order" prop="sap_prod_ord_no"></ngx-datatable-column>
    <ngx-datatable-column name="SAP Material ID" prop="sap_mtrl_id"></ngx-datatable-column>
    <ngx-datatable-column name="Model Number" prop="mdl_no"></ngx-datatable-column>
    <ngx-datatable-column name="VIN (or SN)" prop="vin"></ngx-datatable-column>
  </ngx-datatable>
  </span>
  <div *ngIf="!accessEnable">
    <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
  </div>
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">{{spinnerMessage}} </p>
  </ngx-spinner>