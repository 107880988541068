import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReferenceModule} from '../reference.module';
import { RepresentativeUnitsComponent } from '../representative-units/representative-units.component';
import { RepUnitsAddDialogComponent } from '../rep-units-add-dialog/rep-units-add-dialog.component';
import { RepUnitsCopyaddDialogComponent } from '../rep-units-copyadd-dialog/rep-units-copyadd-dialog.component';
import { RepUnitsEditDialogComponent } from '../rep-units-edit-dialog/rep-units-edit-dialog.component';
import { RepUnitsMultieditDialogComponent } from '../rep-units-multiedit-dialog/rep-units-multiedit-dialog.component';
import { RepUnitsViewDialogComponent } from '../rep-units-view-dialog/rep-units-view-dialog.component';
import { RepUnitsUploadDialogComponent } from '../rep-units-upload-dialog/rep-units-upload-dialog.component';



@NgModule({
  declarations: [
    RepresentativeUnitsComponent,
    RepUnitsAddDialogComponent,
    RepUnitsCopyaddDialogComponent,
    RepUnitsEditDialogComponent,
    RepUnitsMultieditDialogComponent,
    RepUnitsViewDialogComponent,
    RepUnitsUploadDialogComponent
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class RepUnitsModule { }
