
export class DialogData {
        [x: string]: any;
        //for 9350/9355
        monthlyRep: boolean = false;
        compBom: boolean = false;
        modelNumber: string = "";
        option: string = "";
        sapMatId: string = "";
        namc: string = "";
        namcId: string = '';
        family: string = "";
        katashiki: string = "";
        customerId: string = "";
        dest: string = "";
        hikiate: string = "";
        sp: string = "";
        netSpec: string = "";
        pcSpec: string = "";
        hundrentPerSpec: boolean = false;
        compPCSpec: string = "";
        compHundPerSpec: boolean = false;
        extractionTimeIn: string = "";
        extractionTimeOut: string = "";
        comments: string = "";
        part: String = "";
        partDesc: String = "";
        prodType: string = "";
        repProdDefId: string = "";
        man_adjstmnt_rule_def_id: string = "";
        //version screen
        verType: string = "";
        verQualifier: string = "";
        verQualId: any;
        verTypId: any;
        repBomVerId: any;

        //major part screen
        majorPartFamily: string = "";
        majorPartType: string = "";
        majorPartProdType: string = "";
        expectedMajorPartTypeQty: string = "";
        majorPartStatus: string = "";
        majorPartPCode: string = "";
        majorPartComments: string = "";
        majorPartTypId: string = "";
        maj_part_lst_id: string = ""
        MajPrtTypId: string = "";
        majorPartList: string = "";
        maj_part_typ_id: string = "";
        majorPartEditNAMC: string = "";
        majorPartEditKatashiki: string = "";
        majorPartpartno: string = "";
        // 100% specifications screen    
        timeInDt: string = "";
        timeOutDt: string = "";
        vdwEffIn: string = "";
        vdwEffOut: string = "";
        hundPercent: string = "";
        reqd_100pct_pc_spec_optn_id: string = "";

        // GL Reconciliation Accounts/map
        glAccount: string = "";
        reconcProced: string = "";
        naOs: string = "";
        vehUnit: string = "";
        glAccDesc: string = "";
        mapGlAccount: string = "";
        mapGlAccountDescription: string = "";

        //Manual Adjustment Rule
        ruleType: string;
        versionName: string;
        katasPart: string;
        partNo: string;
        ruleStatus: string;
        createFromDt: string;
        createToDt: string;
        bomEffFrom: string;
        bomEffTo: string;
        qtyPerPar: string;
        cmpRouting: string;
        actUnitCst: string;
        statusDesc: string;
        manAdjRuleId: any = "";
        createdBy: string;
        actCurrencyCd: string;

        //part source routing screen
        completePartRouting: string = "";
        partSource: string = "";
        effectiveInDate: string = "";
        effectiveOutDate: string = "";
        part_src_rtg_id: string = "";

        //family project namc screen
        project: string = "";
        ssn: string = "";
        modelYear: string = "";
        lineOffPlant: string = "";
        generation: string = "";
        modelChangeType: string = "";
        prodDateIn: string = "";
        prodDateOut: string = "";
        effectiveDateIn: string = "";
        effectiveDateOut: string = "";
        fam_gnrt_proj_relshp_id: string = ""
        //gl reconciliation accounts by namc screen
        // glAccount: string="";
        glAccountDescription: string = "";
        reconProcedure: string = "";

        //plant user code screen
        productType: string = "";
        userCode: string = "";
        grpiCode: string = "";
        activeFlag: string = "";

        //sap data load screen
        MonthlyRep: false;
        CompBom: false;
        ModelNumber: string = "";
        Option: string = "";
        SapMatId: string = "";
        PartNumber: string = "";
        PartDescription: string = "";
        ProductType: string = "";
        Namc: string = "";
        Family: string = "";
        Katashiki: string = "";
        // CustomerId:string="";
        Dest: string = "";
        Hikiate: string = "";
        SP: string = "";
        PcSpec: string = "";
        CompPcSpec: string = "";
        NetSpec: string = "";
        HundPercentSpec: false;
        CompHundPercentSpec: false;
        ExtractionTimeIn: string = "";
        ExtractionTimeOut: string = "";
        Comments: string = "";
        customer: string = "";
        sapMaterialId: string = "";
        errorMessage: string = "";
        sapStatus: string = "";

        //extraneous parts list screen
        ExtraneousPartType: string = "";
        ExtraneousPartDescription: string = "";
        MatchingPartText: string = "";
        MatchingPartDescriptionText: string = "";
        ComponentCode: string = "";
        SourceRouting: string = "";
        DestRouting: string = "";
        ExtraneousTimeIn: string = "";
        ExtraneousTimeOut: string = "";
        Comment: string = "";
        spcl_part_typ_id: string = "";

        //manage data transfer
        ebomToSAPTransmissionType: string = "";
        sendToSAPStartDate: string = "";
        sendToSAPEndDate: string = "";
        sendToSAPStartTime: string = "";
        sendToSAPEndTime: string = "";
        sap_manage_data_trnfr_id: string = "";

        //exchange rate
        currencyCodeFrom: string = "";
        currencyCodeTo: string = "";
        costType: string = "";
        monthSelection: string = "";
        active: string = "";
        // effectiveInDate: string="";
        // effectiveOutDate: string="";
        exchangeRate: string = "";

        exchng_rt_id: string = "";

        //pcSpec Exclusion list
        pcSpecPosition: string = "";
        hikiateStatus: string = "";
        effectiveFrom: string = "";
        effectiveTo: string = "";
        pc_spec_excl_set_id: string = ""
        //exchange rate

        hikiateStatusDescription: string = "";

        dataLoadTS: string = "";

        //gl recon period
        fiscalYear: string = "";
        acctPeriod: string = "";
        noOfPeriods: string = "";
        glReconPeriodName: string = "";
        perpetualInventoryPeriodName: string = "";
        glReconPeriodStartDate: string = "";
        glReconPeriodEndDate: string = "";
        glReconPeriodStatus: string = "";

        //is system parameter
        ParameterName: string = "";
        ParameterValue: string = "";
        job: string = "";
        processingState: string = "";
        description: string = "";
        updateById: string = "";
        updateTs: string = "";
        createTs: string = "";
        createById: string = "";

        //selectivity part rule
        grpi: any;
        selectivity: any;
        parentPart: any;
        partNumber: any;
        selectivityRuleVin: any;
        selectivityRuleRep: any;
        selectivityRuleTimeIn: any;
        selectivityRuleTimeOut: any;
        slctv_part_rule_id: any;

        //perpetual inventory
        perpInv: string = "";
        periodStartDate: string = "";
        periodEndDate: string = "";
        perpetualInventoryStatus: string = "";
        processedToDatatable: string = "";
        lastProcessed: string = "";

        //extraneous part Lst
        extraneousPartType: string = "";
        extraneousPartDescription: string = "";
        matchingPartText: string = "";
        matchingPartDescriptionText: string = "";
        componentCode: string = "";
        sourceRouting: string = "";
        destRouting: string = "";

        //family
        series: string = "";
        familyDescription: string = "";
        carFamilyFlag: any;

        //manualJobTrigger
        Td:string = '';
        paramName:string = '';
        jobTime:string ='';


}
