<span *ngIf="accessEnable">
<div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>
<mat-accordion class="example-headers-align">
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>

        <mat-expansion-panel-header>
            <mat-panel-title>
                Search
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field>
            <mat-label>Part Source</mat-label>
            <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="partSource" name="partSource"
                (selectionChange)="changePartSourceDescription()">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let partSource of partSourceLst" [value]="partSource.part_src_typ_cd">
                    {{partSource.part_src_typ_cd}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Part Source Description</mat-label>
            <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="partSourceDescription"
                name="partSourceDescription">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let partSourceDescription of partSourceDescriptionLst"
                    [value]="partSourceDescription.part_src_typ_desc">{{partSourceDescription.part_src_typ_desc}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    
        <button mat-button style="float: right;" (click)="resetAll()">Reset</button>
        <button mat-button style="float: right;" (click)="search()">Search</button>
    </mat-expansion-panel>
</mat-accordion>


<!-- <mat-card> -->
<ngx-datatable #myTable class="material striped" [rows]="rows" [columnMode]="ColumnMode.force" [headerHeight]="45"
    [footerHeight]="30" rowHeight="30" [limit]="limitSelected" [selected]="selected"
    [selectionType]="SelectionType.checkbox" [selectAllRowsOnPage]="false" [displayCheck]="displayCheck"
    (activate)="onActivate($event)" (select)="onSelect($event)"
    [ngClass]="{'hideerror': showdata, 'showerror': !showdata}">
    <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
            let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
            <div class="page-count">

            </div>
            <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
                [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
                (change)="table.onFooterPage($event)">
            </datatable-pager>
            <div class="test">
                <select [(ngModel)]="limitSelected">
                    <option>50</option>
                    <option>100</option>
                    <option>500</option>
                </select>
                <div class="float-right records">View {{(offset * pageSize)+1}} - {{(rowCount>
                    (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
                    {{(rowCount)}}
          
                </div>
            </div>
        </ng-template>
    </ngx-datatable-footer>


    <ngx-datatable-column class="w-50" name="Part Source" prop="part_src_typ_cd"></ngx-datatable-column>

    <ngx-datatable-column name="Part Source Description" prop="part_src_typ_desc"></ngx-datatable-column>

    <ngx-datatable-column name="Actual Direct Material Cost">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value" prop="actl_dir_mtrl_cost_flg">
            <input type="checkbox" [checked]="row.actualDirectMaterialCost=='Y'" disabled />
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Standard Direct Material Cost">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value" prop="std_dir_mtrl_cost_flg">
            <input type="checkbox" [checked]="row.standardDirectMaterialCost=='Y'" disabled />
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Transfer Cost">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value" prop="trnfr_cost_flg">
            <input type="checkbox" [checked]="row.transferCost=='Y'" disabled />
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Extaction Time In" prop="eff_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Extraction Time Out" prop="expr_dt"></ngx-datatable-column>

</ngx-datatable>
</span>
<div *ngIf="!accessEnable">
  <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">

    <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>
