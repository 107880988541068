<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
<h2  cdkDragHandle mat-dialog-title>Edit <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
</h2>

<div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
  {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>

<mat-dialog-content class="mat-typography">
  <div class="container">
    <span class="fst-italic"><mark>#</mark>- Please fill in at least one of the fields</span>
    <!-- row1 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Hikiate Status:</label></div>
          <div class=col-2><input type="text" class="picker" [(ngModel)]="eHikiateStatus"></div>
        </div>
      </div>
    </div>
    <!-- row2 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Time In-Date<span class="text-danger">#</span>:</label></div>
          <div class=col-2>
            <mat-form-field class="picker" appearance="outline">
              <input matInput [matDatepicker]="inPicker" [(ngModel)]="edittimeInDt" 
               >
              <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
              <mat-datepicker #inPicker ></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'edittimeInDt')">clear</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Time Out-Date<span class="text-danger">#</span>:</label></div>
          <div class=col-2>
            <mat-form-field class="picker" appearance="outline">
              <input matInput [matDatepicker]="outPicker" [(ngModel)]="edittimeOutDt" 
              >
              <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
              <mat-datepicker #outPicker ></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'edittimeOutDt')">clear</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <!-- row3 -->
    <div class="row rowClass">
      <div class="col-2"> <label>Comments:</label></div>
      <div class=col-10><textarea class="w-100" name="w3review" [(ngModel)]="eComments" rows="4" cols="50">
            </textarea>
      </div>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button cdkFocusInitial (click)="multiEditHundPer()">Save</button>
  <button mat-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>