<span *ngIf="accessEnable"> 
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <div class="alert alert-success alert-dismissible " *ngIf="successMessage!=''" role="alert">
    {{successMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <mat-accordion class="example-headers-align">
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>

    <mat-expansion-panel-header>
      <mat-panel-title>
        Search
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-form-field>
      <mat-label>NAMC</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="namc" name="namc">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let namc of namcLst" [value]="namc.namc_lgl_entity_id">{{namc.namc_cd}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>eBoM To SAP Transmission Type</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="ebomToSAPTransmissionType"
        name="ebomToSAPTransmissionType">
        <mat-option [value]=""></mat-option>
        <mat-option value="M">Manual</mat-option>
        <mat-option value="A">Automatic</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="picker">
      <mat-label>Send To SAP Start Date</mat-label>
      <input matInput [matDatepicker]="inPicker" [(ngModel)]="sendToSAPStartDate">
      <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
      <mat-datepicker #inPicker></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'sendToSAPStartDate')">clear
      </mat-icon>
    </mat-form-field>

    <mat-form-field class="picker">
      <mat-label>Send To SAP End Date</mat-label>
      <input matInput [matDatepicker]="outPicker" [(ngModel)]="sendToSAPEndDate">
      <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
      <mat-datepicker #outPicker></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'sendToSAPEndDate')">clear
      </mat-icon>
    </mat-form-field>


    <mat-form-field>
      <mat-label>Send To SAP Start Time</mat-label>
      <input matInput placeholder="" [(ngModel)]="sendToSAPStartTime">
    </mat-form-field>



    <mat-form-field>
      <mat-label>Send To SAP End Time</mat-label>
      <input matInput placeholder="" [(ngModel)]="sendToSAPEndTime">
    </mat-form-field>


    <mat-form-field>
      <mat-label>Comments</mat-label>
      <input matInput placeholder="" [(ngModel)]="comments">
    </mat-form-field>

    <button mat-button style="float: right;" (click)="resetAll('initial')">Reset</button>
    <button mat-button style="float: right;" (click)="search()">Search</button>

  </mat-expansion-panel>
</mat-accordion>

<div class="d-flex">
  <button mat-raised-button (click)="openEditDialog()">Edit </button>
  <button mat-raised-button (click)="openViewDialog()">View </button>
  <button mat-raised-button (click)="exportFile()" class="ms-auto"> Export To Excel </button>
</div>

<!-- <mat-card> -->

<ngx-datatable #myTable class="material striped" [rows]="rows" [columnMode]="ColumnMode.force" [headerHeight]="45"
  [footerHeight]="30" rowHeight="30" [limit]="limitSelected" [selected]="selected"
  [selectionType]="SelectionType.checkbox" [selectAllRowsOnPage]="false" [displayCheck]="displayCheck"
  (activate)="onActivate($event)" (select)="onSelect($event)"
  [ngClass]="{'hideerror': showdata, 'showerror': !showdata }">

  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
      let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
      <div class="page-count">
        {{selectedCount}} selected / {{rowCount}} total
      </div>
      <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
        (change)="table.onFooterPage($event)">
      </datatable-pager>
      <div class="test">
        <select [(ngModel)]="limitSelected">
          <option>50</option>
          <option>100</option>
          <option>500</option>
        </select>
        <div class="float-right records">View {{(offset * pageSize)+1}} - {{(rowCount>
          (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
          {{(rowCount)}}

          </div>
        </div>
      </ng-template>
    </ngx-datatable-footer>
    <ngx-datatable-column [width]="10" [sortable]="false" [canAutoResize]="false" [draggable]="false"
      [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true">
    </ngx-datatable-column>

    <ngx-datatable-column class="w-50" name="NAMC" prop="namc_cd"></ngx-datatable-column>
    <ngx-datatable-column name="eBoM To SAP Transmission Type" prop="trans_typ"></ngx-datatable-column>
    <ngx-datatable-column name="Send To SAP Start Date" prop="strt_dt" [width]="200"></ngx-datatable-column>
    <ngx-datatable-column name="Send To SAP Start Time" prop="strt_time"></ngx-datatable-column>
    <ngx-datatable-column name="Send To SAP End Date" prop="end_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Send To SAP End Time" prop="end_time"></ngx-datatable-column>

    <ngx-datatable-column [width]="350" name="Comments" prop="comments"></ngx-datatable-column>

  </ngx-datatable>

  </span>
  <div *ngIf="!accessEnable">
    <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
  </div>
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">

  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>
