import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductWithinVersion } from '../dialog-data/product-within-version';
import {ProductWithinVersionEditDialogService} from './product-within-version-edit-dialog.service'

@Component({
  selector: 'app-product-within-version-edit-dialog',
  templateUrl: './product-within-version-edit-dialog.component.html',
  styleUrls: ['./product-within-version-edit-dialog.component.css']
})
export class ProductWithinVersionEditDialogComponent implements OnInit {

    //errormessage
    errorMessage: string = "";

  //ngModel values
  eModelNumber:any;
  eOption:any;
  eSapMatId:any;
  eVerType:any;
  eVerQual:any;
  eVerRev:any;
  eBomEffDt:any;
  eCostEffDt:any;
  eStatus:any;
  eNamc:any;
  eFamily:any;
  eKatashiki:any;
  ePartNo:any;
  eCust:any;
  eDest:any;
  eHikiate:any;
  eSP:any;
  ePcSpec:any;
  eHundPercentSpec:any;
  eCompPcSpec:any;
  eCompHundPercentSpec:any;
  eNetSpec:any;
  eOnHoldStat:any;
  eComments:any;
  repProdId:any;
   //to main page
   message="Record Updated Successfully";

   spinnerMessage="Please Wait...";
  response: any;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: ProductWithinVersion,
  public dialogRef: MatDialogRef<ProductWithinVersionEditDialogComponent>,
  private SpinnerService: NgxSpinnerService,
  private prodWithinVerEditService:ProductWithinVersionEditDialogService) { }

  ngOnInit(): void {
    console.log("data",this.dialogData);
      this.repProdId = this.dialogData.rep_prod_id
      this.eModelNumber=this.dialogData.modelNumber
      this.eOption=this.dialogData.option
      this.eSapMatId=this.dialogData.sapMatId
      this.eVerType=this.dialogData.versionType
      this.eVerQual=this.dialogData.versionQual
      this.eVerRev=this.dialogData.versionRev
      this.eBomEffDt=this.dialogData.bomEffDate
      this.eCostEffDt=this.dialogData.costEffDate
      this.eStatus=this.dialogData.status
      this.eNamc=this.dialogData.namc
      this.eFamily=this.dialogData.family
      this.eKatashiki=this.dialogData.katashiki
      this.ePartNo=this.dialogData.partNo
      this.eCust=this.dialogData.cust
      this.eDest=this.dialogData.dest
      this.eHikiate=this.dialogData.hikiate
      this.eSP=this.dialogData.sp
      this.ePcSpec=this.dialogData.pcSpec
      this.eHundPercentSpec=this.dialogData.hundPercentSpec
      this.eCompPcSpec=this.dialogData.compPcSpec
      this.eCompHundPercentSpec=this.dialogData.compHundPercentSpec
      this.eNetSpec=this.dialogData.netSpec
      this.eOnHoldStat=this.dialogData.onHoldStatus
      this.eComments=this.dialogData.comments
  }

  //save
  updateRepVersion(): void {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId')
    let cmts;
    if (this.eComments!= undefined) {
      cmts = this.eComments.replaceAll("'", "''");
    } else {
      cmts = this.eComments
    }
    let body = {
      "comments":cmts,
      "rep_prod_id":this.repProdId,
      "screenNo":"NBPC_10110",
      "fileName" :fileName

    }
    this.prodWithinVerEditService.editprodwithinVersions(body).subscribe(res =>{
      console.log("res from edit",res);
      this.response = res.payload;
      if (this.response[0].result == 'success') {
        this.dialogRef.close(this.response[0].result);
      } 
      // this.dialogRef.close({ event: 'Cancel', data: this.message });
    },err =>{
      console.log("err",err);
      this.dialogRef.close({ event: 'Cancel', data: this.message });
    });
}

cancel():void{
  this.message="cancel";
  this.dialogRef.close(this.message);
}
closeAlert()
{
  this.errorMessage='';

}

}
