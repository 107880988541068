<span *ngIf="accessEnable">
   <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
      {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
   </div>

<div class="alert alert-success alert-dismissible " *ngIf="successMessage!=''" role="alert">
   {{successMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>
<mat-accordion class="example-headers-align">
   <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>

      <mat-expansion-panel-header>
         <mat-panel-title>
            <strong>Search</strong>
         </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field>
         <mat-label>Version Name</mat-label>
         <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="versionName" name="versionName"
            (selectionChange)="getnamc()">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let versionName of versionNameLst" [value]="versionName.rep_bom_ver_id">
               {{versionName.rep_bom_ver_nm}}</mat-option>
         </mat-select>
      </mat-form-field>

         <mat-form-field>
            <mat-label>NAMC</mat-label>
            <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="namc" name="namc"
               (selectionChange)="getfamily();getkatashiki()">
               <mat-option [value]=""></mat-option>
               <mat-option *ngFor="let namc of namcLst" [value]="namc.namc_lgl_entity_id">{{namc.namc_cd}}</mat-option>
            </mat-select>
         </mat-form-field>



      <mat-form-field>
         <mat-label>Family</mat-label>
         <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="family" name="family"
            (selectionChange)="getkatashiki()">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let family of familyLst" [value]="family.fam_cd">{{family.fam_cd}}</mat-option>
         </mat-select>
      </mat-form-field>

      <mat-form-field>
         <mat-label>Katashiki</mat-label>
         <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="katashiki" name="katashiki">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let katashiki of katashikiLst" [value]="katashiki.katashiki_cd">
               {{katashiki.katashiki_cd}}</mat-option>
         </mat-select>
      </mat-form-field>

      <mat-form-field>
         <mat-label>Part Number</mat-label>
         <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="partNumber" name="partNumber">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let partNumber of partNumberLst" [value]="partNumber.part_no">{{partNumber.part_no}}
            </mat-option>
         </mat-select>
      </mat-form-field>


      <mat-form-field>
         <mat-label>Status</mat-label>
         <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="status" name="status">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let data of statusLst" [value]="data.man_adjstmnt_flg_typ_cd">
               {{data.man_adjstmnt_flg_typ_nm}} </mat-option>
         </mat-select>
      </mat-form-field>

      <br>
  
      <button mat-button style="float: right;" (click)="resetAll('initial')">Reset</button>
      <button mat-button style="float: right; " (click)="search()">Search</button>

   </mat-expansion-panel>
</mat-accordion>

<div class="d-flex">

   <button mat-raised-button (click)="openAddDialog()">Add </button>

</div>


<br>

<!-- <mat-card> -->
<ngx-datatable #myTable class="material striped" [rows]="rows" [columnMode]="ColumnMode.force" [headerHeight]="45"
   [footerHeight]="30" rowHeight="30" [limit]="limitSelected" [selected]="selected"
   [selectionType]="SelectionType.checkbox" [selectAllRowsOnPage]="false" [displayCheck]="displayCheck"
   (activate)="onActivate($event)" (activate)="onActivate($event)" (select)="onSelect($event)" [scrollbarH]="true"
   [ngClass]="{'hideerror': showdata, 'showerror': !showdata}">
   <ngx-datatable-footer>
      <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
         let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
         <div class="page-count">

         </div>
         <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
            [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
            [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
            (change)="table.onFooterPage($event)">
         </datatable-pager>
         <div class="test">
            <select [(ngModel)]="limitSelected">
               <option>50</option>
               <option>100</option>
               <option>500</option>
            </select>
            <div class="float-right records">View {{(offset * pageSize)+1}} - {{(rowCount>
               (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
               {{(rowCount)}}
     
            </div>
         </div>
      </ng-template>
   </ngx-datatable-footer>
   <ngx-datatable-column [width]="10" [sortable]="false" [canAutoResize]="false" [draggable]="false"
      [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="false"></ngx-datatable-column>
   <ngx-datatable-column name="Version Name" prop="rep_bom_ver_nm"></ngx-datatable-column>
   <ngx-datatable-column name="BoM Effective From" prop="bom_eff_dt_from"></ngx-datatable-column>
   <ngx-datatable-column name="BoM Effective To" prop="bom_eff_dt_to"></ngx-datatable-column>
   <ngx-datatable-column name="NAMC" prop="namc_cd"></ngx-datatable-column>
   <ngx-datatable-column name="Family" prop="fam_cd"></ngx-datatable-column>
   <ngx-datatable-column name="Katashiki" prop="katashiki_cd"></ngx-datatable-column>
   <ngx-datatable-column name="Part Number" prop="part_no"></ngx-datatable-column>
   <ngx-datatable-column name="Status" prop="status"></ngx-datatable-column>
   <ngx-datatable-column name="Comments" prop="rmrk_txt"></ngx-datatable-column>

   </ngx-datatable>
</span>
<div *ngIf="!accessEnable">
   <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">

   <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>
