import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductWithinVersion } from '../dialog-data/product-within-version';

@Component({
  selector: 'app-product-within-version-view-dialog',
  templateUrl: './product-within-version-view-dialog.component.html',
  styleUrls: ['./product-within-version-view-dialog.component.css']
})
export class ProductWithinVersionViewDialogComponent implements OnInit {

  //ngModel values
  vModelNumber:any;
  vOption:any;
  vSapMatId:any;
  vVerType:any;
  vVerQual:any;
  vVerRev:any;
  vBomEffDt:any;
  vCostEffDt:any;
  vStatus:any;
  vNamc:any;
  vFamily:any;
  vKatashiki:any;
  vPartNo:any;
  vCust:any;
  vDest:any;
  vHikiate:any;
  vSP:any;
  vPcSpec:any;
  vHundPercentSpec:any;
  vCompPcSpec:any;
  vCompHundPercentSpec:any;
  vNetSpec:any;
  vOnHoldStat:any;
  vComments:any;

   //to main page
   message="Record uploaded Successfully";

   spinnerMessage="Please Wait...";

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: ProductWithinVersion,
  public dialogRef: MatDialogRef<ProductWithinVersionViewDialogComponent>,private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.vModelNumber=this.dialogData.modelNumber
      this.vOption=this.dialogData.option
      this.vSapMatId=this.dialogData.sapMatId
      this.vVerType=this.dialogData.versionType
      this.vVerQual=this.dialogData.versionQual
      this.vVerRev=this.dialogData.versionRev
      this.vBomEffDt=this.dialogData.bomEffDate
      this.vCostEffDt=this.dialogData.costEffDate
      this.vStatus=this.dialogData.status
      this.vNamc=this.dialogData.namc
      this.vFamily=this.dialogData.family
      this.vKatashiki=this.dialogData.katashiki
      this.vPartNo=this.dialogData.partNo
      this.vCust=this.dialogData.cust
      this.vDest=this.dialogData.dest
      this.vHikiate=this.dialogData.hikiate
      this.vSP=this.dialogData.sp
      this.vPcSpec=this.dialogData.pcSpec
      this.vHundPercentSpec=this.dialogData.hundPercentSpec
      this.vCompPcSpec=this.dialogData.compPcSpec
      this.vCompHundPercentSpec=this.dialogData.compHundPercentSpec
      this.vNetSpec=this.dialogData.netSpec
      this.vOnHoldStat=this.dialogData.onHoldStatus
      this.vComments=this.dialogData.comments
  }

}
