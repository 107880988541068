<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Delete <mat-icon class="close-icon" (click)="cancel()">close</mat-icon>
  </h2>

  <mat-dialog-content class="mat-typography" >
    <div class="container" style="text-align:center">
      <h2><span class="fa fa-question-circle pr-10" aria-hidden="true"></span>Are you sure ?</h2>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="center" >
    <button mat-button (click)="deleteVerQual()">Yes</button>
    <button mat-button cdkFocusInitial (click)="cancel()">No</button>
  </mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>