import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { PartsWithoutCostSummaryService } from './parts-without-cost-summary.service';
import { DialogData } from '../dialog-data/dialog-data';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../global.service';
import * as S3 from 'aws-sdk/clients/s3';

@Component({
  selector: 'app-parts-without-cost-summary',
  templateUrl: './parts-without-cost-summary.component.html',
  styleUrls: ['./parts-without-cost-summary.component.css']
})
export class PartsWithoutCostSummaryComponent implements OnInit {


  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //export
  fileName = "c7partsWithoutCostSummary";
  exportData = [];
  sheetName = [];

  //alertMessage
  errorMessage: string = '';

  //ngModel values for search
  step = 0;
  namc: any = "";
  partSource: any = "";
  completePartRouting: any = "";
  costType: any = "";
  part: any = "";
  supplier: any = "";
  buyer: any = "";
  errMsg: any = "";
  status: any = "";
  partDescription: any = "";
  waterfallStatus: any = "";
  actualStatus: any = "";
  currencyCode: any = "";
  supplierName: any = "";
  actCost: any = "";
  trnCost: any = "";
  completePart: any = "";
  noOfRepBOMsImpacted: any = "";
  noOfVINsSeriaNosImpacted: any = "";
  spinnerMessage = "Please Wait...";
  accessKey: any
  secretKey: any
  sessionToken: any
  fileFormat: any

  //list
  namcLst: string[] = [];
  partSourceLst: string[] = [];
  completePartRoutingLst: string[] = [];
  costTypeLst: string[] = [];
  partLst: string[] = [];
  supplierLst: string[] = [];
  buyerLst: string[] = [];
  errMsgLst: string[] = [];

  //mat-dialog
  data: any = {};

  //ngx-datatable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;
  showGrid: boolean = false;
  showButtons: boolean = false;
  showdata: boolean = true;
  columns = [

    { name: 'namc', prop: 'namc_cd' }, { name: 'partSource', prop: 'part_source' },
    { name: 'completePartRouting', prop: 'part_src_rtng' }, { name: 'supplier', prop: 'supplier_cd' },
    { name: 'buyer', prop: 'buyer_cd' }, { name: 'costType', prop: 'cost_type' },
    { name: 'actCost', prop: 'actl_cost' }, { name: 'trnCost', prop: 'trnfr_cost' },
    { name: 'currencyCode', prop: 'crncy_cd' }, { name: 'waterfallStatus', prop: 'waterfall_status' },
    { name: 'part', prop: 'part_no' }, { name: 'partDescription', prop: 'part_desc' },
    { name: 'noOfRepBOMsImpacted', prop: 'rep_cnt' }, { name: 'noOfVINs/SeriaNosImpacted', prop: 'vin_serl_cnt' },
    { name: 'errorMessage', prop: 'err_desc' }

  ];
  ColumnMode = ColumnMode;
  recordcount: any;
  accessEnable: boolean;
  roleAccess: any = []
  Roledata: any = []

  constructor(private partsWithoutCostSummaryService: PartsWithoutCostSummaryService, public dialog: MatDialog, public globalrole: GlobalService,
    private dialogData: DialogData, private exportService: ExportToExcelService, private SpinnerService: NgxSpinnerService) {

  }

  async ngOnInit(): Promise<void> {
    this.SpinnerService.show();
    let a = await this.userroleAccessfn();
    this.SpinnerService.hide();
    if (a) {
      console.log('a', a)
      //grid
      this.onloadfn();
    }
  }

  onloadfn() {
    //to get namc
    this.partsWithoutCostSummaryService.getNamc().subscribe(data => {
      console.log(data);
      this.namcLst = data.payload;
    });

    // to getPartSource
    this.partsWithoutCostSummaryService.getPartSource().subscribe(data => {
      console.log(data);
      this.partSourceLst = data.payload;
    });

    // to getCompletePartRouting
    this.partsWithoutCostSummaryService.getCompletePartRouting(this.namc).subscribe(data => {
      console.log(data);
      this.completePartRoutingLst = data.payload;
    });

    // to geterrorMessage
    this.partsWithoutCostSummaryService.getErrorMessage().subscribe(data => {
      console.log(data);
      this.errMsgLst = data.payload;
    });

    // to getCostType
    this.partsWithoutCostSummaryService.getCostType().subscribe(data => {
      console.log(data);
      this.costTypeLst = data.payload;
    });
  }

  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  //ngx-table
  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);

    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
  }
  onActivate(event) {
    // console.log('Activate Event', event);
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }

  //search func
  generateReport(): void {
    console.log(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      this.showGrid = true;
      this.showButtons = true;
      this.errorMessage = ""
      this.getGridData();
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }

  exportFile(): void {
    if (this.rows.length != 0) {
      this.rows.forEach(row => {

        let obj =
        {
          "NAMC": row.namc_cd,
          "Part Source": row.part_source,
          "Complete Part": row.part_src_rtng,
          "Supplier": row.supplier_cd,
          "Buyer": row.buyer_cd,
          "Cost Type": row.cost_type,
          "ACT Cost": row.actl_cost,
          "TRN Cost": row.trnfr_cost,
          "Currency Code": row.crncy_cd,
          "Waterfall Status": row.waterfall_status,
          "Part": row.part_no,
          "Part Description": row.part_desc,
          "No Of Rep BOMs Impacted": row.rep_cnt,
          "No Of VINs/Seria Nos Impacted": row.vin_serl_cnt,
          "Error Message": row.err_desc,

        }
        this.exportData.push(obj);
      });
    } else {
      let obj =
      {
        "NAMC": "",
        "Part Source": "",
        "Complete Part": "",
        "Supplier": "",
        "Buyer": "",
        "Cost Type": "",
        "ACT Cost": "",
        "TRN Cost": "",
        "Currency Code": "",
        "Waterfall Status": "",
        "Part": "",
        "Part Description": "",
        "No Of Rep BOMs Impacted": "",
        "No Of VINs/Seria Nos Impacted": "",
        "Error Message": ""
      }
      this.exportData.push(obj);
    }

    this.exportService.exportAsExcelFileForReport(this.exportData, this.fileName);
    this.exportData = []
  }

  getGridData() {
    let newDate = new Date();
    let date = '_' + newDate.getDate() + (newDate.getMonth() + 1) + newDate.getFullYear() + '_' + newDate.getHours() + newDate.getMinutes() + newDate.getSeconds() + newDate.getMilliseconds();
    let fileName = 'c7-parts_without_cost/c7GrdSrc' + date + '.json';
    let gridData =
    {

      namc: this.namc,
      partSource: this.partSource,
      completePart: this.completePartRouting,
      supplier: this.supplier,
      buyer: this.buyer,
      costType: this.costType,
      actCost: this.actCost,
      trnCost: this.trnCost,
      currencyCode: this.currencyCode,
      waterfallStatus: this.waterfallStatus,
      part: this.part,
      partDescription: this.partDescription,
      noOfRepBOMsImpacted: this.noOfRepBOMsImpacted,
      noOfVINsSeriaNosImpacted: this.noOfVINsSeriaNosImpacted,
      errorMessage: this.errMsg,
      fileName: fileName

    }
    this.SpinnerService.show();
    this.partsWithoutCostSummaryService.getGridData(gridData).subscribe(data => {
      console.log(data)
      this.SpinnerService.hide();
      this.showdata = true;
      console.log(this.showdata);
      this.recordcount = data.pagination.totalRecords;
      let datarow = data.payload;
      if (data.payload[0].RecordCnt > 1000) {
        setTimeout(() => {
          this.getFileFromS3(fileName);
        }, 2000);
      } else {
    this.rows = data.payload;
    this.SpinnerService.hide();
    this.errorMessage="";
      }
    }, err => {
      console.log(err);
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      if (err == 0) {
        this.errorMessage = "Request timed out. Please try again.";
        this.SpinnerService.hide();
        this.globalrole.goToTop();
      } else {
        this.errorMessage = "No Records";
        this.SpinnerService.hide();
        this.globalrole.goToTop();
      }
    });

  }
  ////get from s3 Grid search data
  async getFileFromS3(fileName) {
    this.SpinnerService.show();
    // return new Promise(async resolve => {
    let a = await this.getS3Info();
    if (a) {
      const bucket = new S3(
        {
          accessKeyId: this.accessKey,
          secretAccessKey: this.secretKey,
          sessionToken: this.sessionToken,
          region: 'us-west-2'
        }
      );
      const params = {
        Bucket: 'search-grids',
        Key: fileName,
      };
      console.log(params);
      bucket.getObject(params, (err: any, data: any) => {
        if (err) {
          console.log('download err:::::::::', err)
          this.SpinnerService.hide();
        } else {
          console.log(data)
          this.SpinnerService.hide();
          let result = data.Body.toString();
          let res = JSON.parse(result);
          console.log("res and reult", res, result)
          if (res.length > 0) {
            this.rows = res;
            console.log(this.rows);
          }
        }
      })
    }
    // })
  }

  getS3Info() {
    return new Promise(resolve => {
      this.partsWithoutCostSummaryService.fileUpldData().subscribe(data => {
        let result = data.payload[0]
        if (result !== null) {
          const aws = result;
          this.accessKey = aws.AccessKeyId;
          this.secretKey = aws.SecretAccessKey;
          this.sessionToken = aws.SessionToken;
          resolve(true)
        }
        else {
          // this.toastr.error('Data?Storage?Disconnected.');
          resolve(false)
        }
      }, err => {
        // this.toastr.error('Unable?to?get?keys?and?token', 'Failed');
      });
    })
  }

  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.log(res)
        this.Roledata = res;
      })
      console.log(this.Roledata);
      if (this.Roledata.length != 0) {
        this.Roledata.filter((res) => {
          if (res.function_nm == 'NBPC_7310') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length == 0) {
        let arrayvalue: any; let roleList: any;
        let data = localStorage.getItem('role');
        arrayvalue = data.split(',')
        console.log(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str == '') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        let body = {
          security_grp_nm: roleList
        }
        let userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
        this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
            this.userroleAccessfn();
            this.accessEnable = true;
            resolve(true);
          }
        else {
          console.log(this.roleAccess)
          this.roleAccess = []
          this.errorMessage = "User does not have permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        }
      }
      console.log(this.roleAccess);
    })
  }

}

