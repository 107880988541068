<span *ngIf="accessEnable">
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>

<div class="alert alert-success alert-dismissible " *ngIf="successMessage!=''" role="alert">
  {{successMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>
<mat-accordion class="example-headers-align">
  <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>

    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Search</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>

      <mat-form-field>
        <mat-label>Rule Type</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="ruleType" name="ruleType"
          (selectionChange)='getVerNameByRulType()'>
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let ruleType of ruleTypeLst" [value]="ruleType.man_adjstmnt_rule_typ_id">
            {{ruleType.man_adjstmnt_rule_typ_nm}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Version Name</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="versionName" name="versionName"
          (selectionChange)='getNamcByVerName();getFamCdByNamc();getKatashikiByFamCd();getGrpiByKatas();getPartNoByGrpi();getRuleStatByPartNo()'>
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let versionName of versionNameLst" [value]="versionName.version_name">
            {{versionName.version_name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>NAMC</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="namc" name="namc"
          (selectionChange)='getFamCdByNamc();getKatashikiByFamCd();getGrpiByKatas();getPartNoByGrpi();getRuleStatByPartNo()'>
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let namc of namcLst" [value]="namc.namc_lgl_entity_id">{{namc.namc_cd}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Family</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="family" name="family"
          (selectionChange)='getKatashikiByFamCd();getGrpiByKatas();getPartNoByGrpi();getRuleStatByPartNo()'>
          <mat-option [value]=""></mat-option>
          <span *ngFor="let family of famLst">
            <span *ngIf="family.fam_cd !='null'">
              <mat-option *ngIf="family.fam_cd !=''" [value]="family.fam_cd">{{family.fam_cd}}</mat-option>
            </span>
          </span>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Katashiki/Part</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="katasPart" name="katasPart"
          (selectionChange)='getGrpiByKatas();getPartNoByGrpi();getRuleStatByPartNo()'>
          <mat-option [value]=""></mat-option>
          <span *ngFor="let katasPart of katasPartLst">
            <span *ngIf="katasPart.katashiki_cd != null">
              <mat-option *ngIf="katasPart.katashiki_cd != ''"
                [value]="katasPart.katashiki_cd">{{katasPart.katashiki_cd}}
              </mat-option>
            </span>
          </span>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>GRPI</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="grpi" name="grpi"
          (selectionChange)='getPartNoByGrpi();getRuleStatByPartNo()'>
          <mat-option [value]=""></mat-option>
          <span *ngFor="let grpi of grpiLst">
            <span *ngIf="grpi.grpi_cd !='null'">
              <mat-option *ngIf="grpi.grpi_cd !=''" [value]="grpi.grpi_cd">{{grpi.grpi_cd}}</mat-option>
            </span>
          </span>

        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Part Number</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="partNo" name="partNo"
          (selectionChange)='getRuleStatByPartNo()'>
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let partNo of partNumberLst" [value]="partNo.child_part_no">{{partNo.child_part_no}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    <mat-form-field>
      <mat-label>Rule Status</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="ruleStatus" name="ruleStatus">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let ruleStatus of ruleStatusLst" [value]="ruleStatus.man_adjstmnt_rule_stat_flg">
          {{ruleStatus.rule_status}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="picker">
      <mat-label>Created From Date</mat-label>
      <input matInput [matDatepicker]="inPicker" [(ngModel)]="createFromDt">
      <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
      <mat-datepicker #inPicker ></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'createFromDt')">clear</mat-icon>
    </mat-form-field>

    <mat-form-field class="picker">
      <mat-label>Created To Date</mat-label>
      <input matInput [matDatepicker]="outPicker" [(ngModel)]="createToDt">
      <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
      <mat-datepicker #outPicker ></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'createToDt')">clear</mat-icon>
    </mat-form-field>

    <button mat-button style="float: right;" (click)="resetAll('initial')">Reset</button>
    <button mat-button style="float: right;" (click)="search()">Search</button>
  </mat-expansion-panel>
</mat-accordion>

<div class="d-flex">
  <button mat-raised-button (click)="correctBoms()">Correct BoMs</button>
  <button mat-raised-button (click)="openAddDialog()">Add</button>
  <button mat-raised-button (click)="openEditDialog()">Edit</button>
  <button mat-raised-button (click)="openViewDialog()">View</button>
  <button mat-raised-button (click)="openDeleteDialog()">Delete</button>
  <button mat-raised-button (click)="openUploadDialog()">Upload From Excel </button>
  <button mat-raised-button (click)="exportFile()" class="ms-auto">Export To Excel</button>

</div>

<ngx-datatable #myTable class="material striped" [rows]="rows" [columnMode]="ColumnMode.force" [headerHeight]="45"
  [footerHeight]="30" rowHeight="30" [limit]="limitSelected" [selected]="selected"
  [selectionType]="SelectionType.checkbox" [selectAllRowsOnPage]="false" [displayCheck]="displayCheck"
  (activate)="onActivate($event)" (select)="onSelect($event)" [scrollbarH]="true"
  [ngClass]="{'hideerror': showdata, 'showerror': !showdata }">
  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
      let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
      <div class="page-count">
        {{selectedCount}} selected / {{rowCount}} total
      </div>
      <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
        (change)="table.onFooterPage($event)">
      </datatable-pager>
      <div class="test">

        <select [(ngModel)]="limitSelected">
          <option>50</option>
          <option>100</option>
          <option>500</option>
        </select>
        <div class="float-right records">View {{(offset * pageSize)+1}} - {{(rowCount>
          (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
          {{(rowCount)}}

          </div>
        </div>
      </ng-template>
    </ngx-datatable-footer>
    <ngx-datatable-column [width]="10" [sortable]="false" [canAutoResize]="false" [draggable]="false"
      [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true">
    </ngx-datatable-column>
    <ngx-datatable-column name="Rule Type" prop="man_adjstmnt_rule_typ_nm"></ngx-datatable-column>
    <ngx-datatable-column name="Version Name" prop="version_name"></ngx-datatable-column>
    <ngx-datatable-column name="BoM Effective From" prop="bom_eff_dt_from"></ngx-datatable-column>
    <ngx-datatable-column name="BoM Effective To" prop="bom_eff_dt_to"></ngx-datatable-column>
    <ngx-datatable-column name="NAMC" prop="namc_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Family" prop="fam_cd"></ngx-datatable-column>
    <ngx-datatable-column name="S/P" prop="base_optn_typ_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Net Spec" prop="net_spec_cd_comb_txt"></ngx-datatable-column>
    <ngx-datatable-column name="Katashiki/Part" prop="katashiki_cd"></ngx-datatable-column>
    <ngx-datatable-column name="GRPI" prop="grpi_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Dest" prop="dest_ctry_id_comb_txt"></ngx-datatable-column>
    <ngx-datatable-column name="Part Number" prop="child_part_no"></ngx-datatable-column>
    <ngx-datatable-column name="Qty Per Parent" prop="per_veh_qty"></ngx-datatable-column>
    <ngx-datatable-column name="Complete Routing" prop="cmplt_rtg_cd"></ngx-datatable-column>
    <ngx-datatable-column name="ACT Unit Cost" prop="actl_unit_cost_amt"></ngx-datatable-column>
    <ngx-datatable-column name="Rule Status" prop="rule_status"></ngx-datatable-column>
    <ngx-datatable-column name="Status Description" prop="man_adjstmnt_rule_stat_desc"></ngx-datatable-column>
    <ngx-datatable-column name="Manual Adjustment Rule Id" prop="man_adjstmnt_rule_typ_id"></ngx-datatable-column>
    <ngx-datatable-column name="Comments" prop="rmrk_txt"></ngx-datatable-column>
    <ngx-datatable-column name="Create Date" prop="create_ts"></ngx-datatable-column>
    <ngx-datatable-column name="Last Rule Process Date" prop="last_exec_ts"></ngx-datatable-column>
    <ngx-datatable-column name="Created By" prop="create_by_id"></ngx-datatable-column>
  </ngx-datatable>
</span>
<div *ngIf="!accessEnable">
  <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">

  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>
