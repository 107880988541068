import { Component, Inject, OnInit } from '@angular/core';
import { DialogData } from '../dialog-data/dialog-data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ManualJobTriggerEditDialogService } from './manual-job-trigger-edit-dialog.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-manual-job-trigger-edit-dialog',
  templateUrl: './manual-job-trigger-edit-dialog.component.html',
  styleUrls: ['./manual-job-trigger-edit-dialog.component.css']
})
export class ManualJobTriggerEditDialogComponent implements OnInit {
  [x: string]: any;
  //to main page
  message = "Records Edited Successfully";
  index: any;
  errorMessage = "";

  //ngModelValues
  eParameterName: any;
  eParameterValue: any='';
  eParameterRemark: any
  //respone after inserting a record
  response: any;

  spinnerMessage = "Please Wait";
  constructor(public dialogRef: MatDialogRef<ManualJobTriggerEditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, private Service: ManualJobTriggerEditDialogService, private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    console.log(this.data);

    this.eParameterName = this.data.job;
    this.td = this.data.Td
    this.pramName = this.data.paramName
    this.jobTime=this.data.jobTime
    // this.eParameterValue = this.data.processingState;
  }

  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }
  async uploadParm() {
    console.info("info work");
    
    
    let fileName = localStorage.getItem('Filename')
    // let user = localStorage.getItem('workDayId'); 

      this.SpinnerService.show()
      await this.Service.ecsStatusCheck(this.td, this.pramName, this.eParameterName).subscribe(async data => {
        console.log(data.status);
        if (data.status === 'ecs status not running') {
          await this.Service.triggerecs(this.td, this.eParameterValue,this.eParameterName).subscribe(data => {
            console.log("tigger");
            this.SpinnerService.hide()
            this.message = 'ECS is successfully Triggered'
            this.dialogRef.close({ event: 'Cancel', data: this.message });
          },err=>{
            this.message = 'Something went wrong please contact support people'
              this.SpinnerService.hide()
              this.dialogRef.close({ event: 'Cancel', data: this.message });
          })
        }
        else if (data.status === 'ETL Job is Running '){
          this.message = 'ETL Job is Running'
          this.SpinnerService.hide()
          this.dialogRef.close({ event: 'Cancel', data: this.message });
        }
        else {
          this.message = 'ECS is already Running'
          this.SpinnerService.hide()
          this.dialogRef.close({ event: 'Cancel', data: this.message });
        }

      },err=>{
        this.message = 'Something went wrong please contact system administrator'
          this.SpinnerService.hide()
          this.dialogRef.close({ event: 'Cancel', data: this.message });
      })
    
  }
  closeAlert(): void {
    this.errorMessage = '';
    this.successMessage = '';
  }

}
