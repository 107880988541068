<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
<h2  cdkDragHandle mat-dialog-title>Edit <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
</h2>
<div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
  {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>

</div>

<mat-dialog-content class="mat-typography">
  <div class="container">
    <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are mandatory</span>
    <!-- row1 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>NAMC:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="eNamc" disabled></div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>eBoM To SAP Transmission Type<span class="text-danger">*</span>:</label></div>
          <div class=col-8><select class="heightadd" [(ngModel)]="eEbomToSAPTransmissionType"
              aria-label="Default select example" (change)="saptransenable()">

              <option value="Automatic">Automatic </option>
              <option value="Manual">Manual </option>
            </select></div>
        </div>
      </div>
    </div>
    <!-- row2 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row" style="margin-top: 5px">
          <div class="col-4"> <label>Send To SAP Start Date<span class="text-danger">*</span>:</label></div>
          <div class=col-8>
            <mat-form-field class="picker" appearance="outline">
              <input matInput [matDatepicker]="inPicker" [(ngModel)]="eSendToSAPStartDate"
                 [disabled]="enablesaptrans">
              <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
              <mat-datepicker #inPicker></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'eSendToSAPStartDate')">
                clear</mat-icon>

            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row" style="margin-top: 5px">
          <div class="col-4"> <label>Send To SAP End Date<span class="text-danger">*</span>:</label></div>
          <div class=col-8>
            <mat-form-field class="outPicker" appearance="outline">
              <input matInput [matDatepicker]="outPicker" [(ngModel)]="eSendToSAPEndDate"
                 [disabled]="enablesaptrans">
              <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
              <mat-datepicker #outPicker ></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'eSendToSAPEndDate')">clear
              </mat-icon>

            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <!-- row3 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row ">
          <div class="col-4"> <label>Send To SAP Start Time<span class="text-danger">*</span>:</label></div>
          <div class=col-8>
            <input type="text" [(ngModel)]="eSendToSAPStartTime" max="9999-12-31" [disabled]="enablesaptrans">
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Send To SAP End Time<span class="text-danger">*</span>:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="eSendToSAPEndTime" max="9999-12-31"
              [disabled]="enablesaptrans">
          </div>
        </div>
      </div>

    </div>
    <br>
    <!-- row4 -->
    <div class="row rowClass">
      <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
      <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8" style="padding-left: 0px;"><textarea class="w-100"
          name="w3review" rows="2" cols="80" [(ngModel)]="eComments">
          </textarea>
      </div>
    </div>


  </div>

</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button cdkFocusInitial (click)="save()">Save</button>
  <button mat-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>