<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Edit <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>

  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>

  <mat-dialog-content class="mat-typography" >
    <div class="container">
      <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are mandatory</span>
      <!-- row1 -->
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Monthly Representative:</label></div>
            <div class=col-2><input type="checkbox" [(ngModel)]="eMonthlyRep"></div>
          </div>
        </div>
      </div>

    <!-- row2 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>NAMC:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="eNamc" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Family:</label></div>
          <div class=col-8><select class="dateadd" [(ngModel)]="eFamily" aria-label="Default select example"
              (change)="getHikiatechange()">
              <option value=""></option>
              <option *ngFor="let family of familyLst" [hidden]="family.fam_cd == null?true:null" [value]=family.fam_cd>
                {{family.fam_cd}}</option>
            </select></div>
        </div>
      </div>
    </div>

      <!-- row3 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Model Number:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="eModelNumber" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Option:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="eOption" disabled></div>
          </div>
        </div>
      </div>

      <!-- row4 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Sap Material Id:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="eSapMaterialId" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Part Number:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="ePartNumber" disabled></div>
          </div>
        </div>
      </div>

      <!-- row5 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Katashiki:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="eKatashiki" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Customer:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="eCustomer" disabled></div>
          </div>
        </div>
      </div>

      <!-- row6 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>Dest:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="eDest" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
          </div>
        </div>
      </div>

      <!-- row7 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Hikiate Status:</label></div>
            <div class=col-8><select id="hikiSelect" [(ngModel)]="dummyHikiateLst" class="multiple" multiple
                aria-label="Default select example">
                <option value={{hikiate.hikiate}} *ngFor="let hikiate of hikiateLstFromDB">
                  {{hikiate.hikiate}}</option>
              </select></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <button class="btn" (click)="moveHikiForward()">>></button><br>
              <button class="btn" (click)="moveHikiBackward()">
                << </button>
            </div>
            <div class=col-8><select class="multiple" multiple [(ngModel)]="dummyHikiateLstToDB"
                aria-label="Default select example">
                <option value={{selectedHikiate}} *ngFor="let selectedHikiate of selectedHikiateLst">{{selectedHikiate}}
                </option>
              </select></div>
          </div>
        </div>
      </div>

      <!-- row8 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Sp:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="eSp" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Pc Spec:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="ePcSpec" disabled></div>
          </div>
        </div>
      </div>


      <!-- row9 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Comp Pc Spec:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="eCompPcSpec" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Net Spec:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="eNetSpec" disabled></div>
          </div>
        </div>
      </div>



      <!-- row10 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>100% Spec:</label></div>
            <div class=col-2><input type="checkbox" [(ngModel)]="eHundPercentSpec" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Comp 100% Spec:</label></div>
            <div class=col-2><input type="checkbox" [(ngModel)]="eCompHundPercentSpec" disabled></div>
          </div>
        </div>
      </div>

    <!-- row11 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Extraction Time In<span class="text-danger">*</span>:</label></div>
          <div class=col-8>
            <mat-form-field class="picker" appearance="outline">
              <input matInput [matDatepicker]="inPicker" [(ngModel)]="eExtractionTimeIn">
              <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
              <mat-datepicker #inPicker></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'eExtractionTimeIn')">clear</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Extraction Time Out:</label></div>
          <div class=col-8>
            <mat-form-field class="picker" appearance="outline">
              <input matInput [matDatepicker]="outPicker" [(ngModel)]="eExtractionTimeOut">
              <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
              <mat-datepicker #outPicker></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'eExtractionTimeOut')">clear</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <!-- row13 -->
    <div class="row rowClass">
      <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
      <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review" rows="4" cols="50" [(ngModel)]="eComments">
          </textarea>
        </div>
      </div>

    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="center" >
    <button mat-button cdkFocusInitial (click)="updateSapDataLoad()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>