<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Edit <mat-icon class="close-icon" (click)="cancel()">close</mat-icon>
  </h2>

<div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
  {{errorMessage}}<mat-icon class="close-icon"  (click)="closeAlert()">close</mat-icon>
</div>

  <mat-dialog-content class="mat-typography" >
    <div class="container">
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Model Number:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eModelNumber" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Option:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eOption" disabled></div>
          </div>
        </div>
      </div>

      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>SAP Material ID:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eSapMatId" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
          </div>
        </div>
      </div>

      <!-- row3 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Version Type:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eVerType" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Version Qualifier:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eVerQual" disabled></div>
          </div>
        </div>
      </div>

      <!-- row4 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Version Revision:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eVerRev" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>BoM Effectivity Date:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eBomEffDt" disabled></div>
          </div>
        </div>
      </div>

      <!-- row5 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Cost Effectivity Date:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eCostEffDt" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Status:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eStatus" disabled></div>
          </div>
        </div>
      </div>

      <!-- row6 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>NAMC:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eNamc" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Family:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eFamily" disabled></div>
          </div>
        </div>
      </div>

      <!-- row7 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Katashiki:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eKatashiki" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Part Number (Product):</label></div>
            <div class=col-8><input type="text" [(ngModel)]="ePartNo" disabled></div>
          </div>
        </div>
      </div>

      <!-- row8 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Customer:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eCust" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Dest:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eDest" disabled></div>
          </div>
        </div>
      </div>

      <!-- row 9 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Hikiate Status:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eHikiate" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>S/P:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eSP" disabled></div>
          </div>
        </div>
      </div>

      <!-- row10 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>PC Spec:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="ePcSpec" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>100% Spec:</label></div>
            <div class=col-8><input type="checkbox" [(ngModel)]="eHundPercentSpec" disabled></div>
          </div>
        </div>
      </div>


      <!-- row11 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Comp PC Spec</label></div>
            <div class=col-8><input type="text" [(ngModel)]="eCompPcSpec" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Comp 100% Spec:</label></div>
            <div class=col-8><input type="checkbox" [(ngModel)]="eCompHundPercentSpec" disabled></div>
          </div>
        </div>
      </div>

      <!-- row12 -->
      <div class="row rowClass">
        <div class="col-2"> <label>Net Spec:</label></div>
        <div class=col-10><input type="text" class="w-100" [(ngModel)]="eNetSpec" disabled>
        </div>
      </div>

      <!-- row13 -->
      <div class="row rowClass">
        <div class="col-2"> <label>On-Hold Status:</label></div>
        <div class=col-10><input type="checkbox" [(ngModel)]="eOnHoldStat" disabled>
        </div>
      </div>

      <!-- row14 -->
      <div class="row rowClass">
        <div class="col-2"> <label>Comments:</label></div>
        <div class=col-10><textarea name="w3review" rows="2" cols="70" [(ngModel)]="eComments" maxlength="100">
          </textarea>
        </div>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="center" >
    <button mat-button (click)="updateRepVersion()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>