<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Edit <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>

  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>

  <mat-dialog-content class="mat-typography" >
    <div class="container">
      <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are mandatory</span>
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>NAMC:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="pNamc" disabled> </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Complete Part Routing:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="pCompletePartRouting" disabled> </div>
          </div>
        </div>
      </div>
      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Part Source<span class="text-danger">*</span>:</label></div>
            <div class=col-8><select aria-label="Default select example" class="inputtext" [(ngModel)]="pPartSource">
                <option value=""></option>
                <option *ngFor="let partSource of partSourceLst" [value]="partSource.part_src_typ_cd">
                  {{partSource.part_src_typ_cd}}</option>

              </select></div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
        </div>
      </div>
      <br>
      <!-- row3 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>Effective In Date:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="pEffectiveInDate" disabled> </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Effective Out Date<span class="text-danger">*</span>:</label></div>
            <div class=col-8>
              <mat-form-field style="width: 95%;margin: 5px;" appearance="outline">
                <input matInput [matDatepicker]="outPicker" [(ngModel)]="pEffectiveOutDate">
                <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
                <mat-datepicker #outPicker></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear"
                  (click)="clearDate($event,'pEffectiveOutDate')">clear</mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <!-- row4 -->
      <div class="row rowClass">
        <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
        <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review" rows="4" cols="50"
            [(ngModel)]="pComments">
            </textarea>
        </div>
      </div>

    </div>


  </mat-dialog-content>
  <mat-dialog-actions align="center" >
    <button mat-button cdkFocusInitial (click)="updatePartSrcRtng()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</ng-container>

<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">

  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>