import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { RepresentativeVehiclesService } from './representative-vehicles.service';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RepVehicleAddDialogComponent } from '../rep-vehicle-add-dialog/rep-vehicle-add-dialog.component';
import { RepVehicleViewDialogComponent } from '../rep-vehicle-view-dialog/rep-vehicle-view-dialog.component';
import { RepVehicleEditDialogComponent } from '../rep-vehicle-edit-dialog/rep-vehicle-edit-dialog.component';
import { RepVehicleUploadDialogComponent } from '../rep-vehicle-upload-dialog/rep-vehicle-upload-dialog.component';
import { RepVehicleMultieditDialogComponent } from '../rep-vehicle-multiedit-dialog/rep-vehicle-multiedit-dialog.component';
import { ExportToExcelService } from '../common/export-to-excel.service'
import { RepVehicleCopyaddDialogComponent } from '../rep-vehicle-copyadd-dialog/rep-vehicle-copyadd-dialog.component';
import { DialogData } from '../dialog-data/dialog-data'
import { NgxSpinnerService } from "ngx-spinner";
import { access } from 'fs';
import { GlobalService } from '../global.service';

import * as S3 from 'aws-sdk/clients/s3';
//import { RepUnitsUploadDialogComponent } from '../rep-units-upload-dialog/rep-units-upload-dialog.component';




@Component({
  selector: 'app-representative-vehicles',
  templateUrl: './representative-vehicles.component.html',
  styleUrls: ['./representative-vehicles.component.css']
})
export class RepresentativeVehiclesComponent implements OnInit {
  [x: string]: any;

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //export to excel
  fileName = 'RepresentativeVehicles';
  exportData = [];
  sheetName = ['MAINTAIN_REPR_VEH'];
  errorSheetName = ['REP_VEH_ERROR'];
  errorFileName = 'RepVehicleError';


  //alertMessage
  errorMessage: string = '';

  //messagefromDialog
  successMessage: string = '';

  //ngModel values for search
  step = 0;
  modelNumber: any = "";
  option: string = "";
  sapMatId: string = "";
  namc: string = "";
  famcd: string = "";
  katashiki: string = "";
  customer: string = "";
  dest = "ALL";
  hikiate: string = "";
  sp: any = "";
  netSpec: string = "";
  pcSpec: string = "";
  compPcSpec: string = "";
  hundPercent: string = "";
  compHundPercent: string = "";
  effDateIn: any = "";
  effDateOut: any = "";
  screen = 'vehicle';
  accessKey: any
  secretKey: any
  sessionToken: any
  fileFormat: any


  //list
  modelNumberLst = []
  optionLst = [];
  namcLst = []
  //namcLst:any=[]
  famcdLst: string[] = [];
  katashikiLst: string[] = [];
  customerLst: string[] = [];
  destLst: string[] = [];
  spLst: string[] = [];
  hikiateLst: string[] = [];
  recordcount: any = 0;


  //ngx-datatable
  rows: any;
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;

  spinnerMessage = "Please wait...";

  showdata: boolean = true;
  columns = [
    { name: 'monthlyRep', prop: 'monthly_rep_flg' }, { name: 'compBom', prop: 'compar_bom_flg' }, { name: 'modelNumber', prop: 'mdl_no' },
    { name: 'option', prop: 'optn_cd' }, { name: 'sapMatId', prop: 'sap_mtrl_id' }, { name: 'namc', prop: 'namc_cd' },
    { name: 'family', prop: 'fam_cd' }, { name: 'katashiki', prop: 'katashiki_cd' }, { name: 'customerId', prop: 'buyoff_cust_nm' },
    { name: 'dest', prop: 'dest_ctry_id' }, { name: 'hikiate', prop: 'hikiate_stat_comb_cd' }, { name: 'sp', prop: 'base_optn_typ_cd' },
    { name: 'netSpec', prop: 'net_spec_cd_comb_txt' }, { name: 'pcSpec', prop: 'pc_spec_cd_comb_txt' }, { name: 'hundPercent', prop: 'hundred_pct_optn_flg' },
    { name: 'compPCSpec', prop: 'compar_pc_spec_cd_comb_txt' }, { name: 'compHundPercent', prop: 'compar_100_pct_optn_flg' }, { name: 'extractionTimeIn', prop: 'eff_dt' },
    { name: 'extractionTimeOut', prop: 'expr_dt' }, { name: 'comments', prop: 'rmrk_txt' }, { name: 'repProdDefId', prop: 'rep_prod_def_id' }
  ];
  ColumnMode = ColumnMode;
  sameLengthRows: boolean = false;
  roleAccess: any = [];
  Roledata: any;
  constructor(private repVehicleService: RepresentativeVehiclesService, public dialog: MatDialog,
    private exportService: ExportToExcelService, private dialogData: DialogData,
    private SpinnerService: NgxSpinnerService, public globalrole: GlobalService, ) {

  }

  async ngOnInit() {
    this.SpinnerService.show();
    let a = await this.userroleAccessfn();
    if (a) {
      console.log('a', a)
      //grid
      this.getGridData("initial");
    }
    this.onloadfn();
  }

  onloadfn() {
    // to get model number
    this.getmodelonload();
    // to get option
    this.getoptiononload();
    //to get namc
    this.getnamconload();
    //to get sp
    this.getsponload();

    // to get family
    this.getFamilyonload();

    // to get hikiate
    this.getHikiate();

    // to get customer;
    this.getCustomer();

    // to get dest
    this.getDestByCustonload();

    //to get Katashiki
    //this.getKatashikiByFamCd();
  }
  getmodelonload() {
    this.repVehicleService.getModelNumber(this.screen).subscribe(data => {
      console.log(data);
      this.modelNumberLst = data.payload;
    });
  }

  getoptiononload() {
    this.repVehicleService.getOption(this.screen).subscribe(data => {
      console.log(data);
      this.optionLst = data.payload;
    });
  }

  getnamconload() {
    this.repVehicleService.getNamc().subscribe(data => {
      console.log(data);
      this.namcLst = data.payload;
    });
  }
  getsponload() {
    this.repVehicleService.getSp(this.namc, this.famcd, this.katashiki, this.customer, this.dest, this.hikiate).subscribe(data => {
      console.log(data);
      this.spLst = data.payload;
    });
  }
  getCustomer() {
    this.repVehicleService.getCustomer().subscribe(data => {
      console.log(data);
      this.customerLst = data.payload;
    });
}
  getDestByCustonload() {
    if (this.katashiki == undefined) {
      this.katashiki = ""
    }
    if (this.famcd == undefined) {
      this.famcd = ""
    }
    if (this.namc == undefined) {
      this.namc = ""
    }
    if (this.customer == undefined) {
      this.customer = ""
    }
    this.repVehicleService.getDestByCust(this.namc, this.famcd, this.katashiki, this.customer).subscribe(data => {
      console.log(data);
      this.destLst = data.payload;
    });
  }

  getFamilyonload(): void {
    console.log(this.namc)
    if (this.namc == undefined) {
      this.namc = ""
    }
    this.repVehicleService.getFamily(this.namc, this.screen).subscribe(data => {
      console.log(data);
      this.famcdLst = data.payload;
    });
  }
  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  //ngx-table
  onSelect({ selected }) {
    this.selected = selected;
    if (this.selected.length == selected.length) {
      this.sameLengthRows = true
    }
    if (this.selected.length == 0) {
      this.sameLengthRows = false
    }
    console.log('Select Event', selected, this.selected.length);

    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }

  openAddDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    console.log(this.roleAccess.includes('Create'))
    if (this.roleAccess.includes('Create')) { 
      const dialogRef = this.dialog.open(RepVehicleAddDialogComponent, { disableClose: true });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result.data);

        if (result == 'success') {
          this.successMessage = "Record Added Successfully"
          // this.Resetafterdialogmsg("add")
          this.getGridData("add");
          this.selected = []
        }
        else if (result == 'AlreadyExists') {
          // this.Resetafterdialogmsg("initial")
          this.errorMessage = 'Data Entered already Exists!'
          this.globalrole.goToTop();
        }
        else {
          // this.errorMessage = 'Data not inserted/Valid'
        }
      });
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }
  }
  openCopyAddDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    console.log(this.roleAccess.includes('Create'))
    if (this.roleAccess.includes('Create')) {
      if (this.selected.length == 1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(RepVehicleCopyaddDialogComponent, {
          disableClose: true,
          data: this.dialogData

        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed', result);

          if (result == 'success') {
            this.successMessage = "Record Added Successfully";
            // this.Resetafterdialogmsg("add");
            this.getGridData("add");
            this.selected = []
          }
          else if (result == 'AlreadyExists') {
            // this.Resetafterdialogmsg("initial")
            this.errorMessage = 'Data Entered already Exists!'
            this.globalrole.goToTop();
          }
          else {
            // this.errorMessage = 'Data not inserted/Valid'
          }
        });
      }
      else if (this.selected.length <= 0) {
        this.errorMessage = "Please select a row to copy-add";
        this.globalrole.goToTop();
      }
      else {
        this.errorMessage = "Please select only one row to copy-add";
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }

  }
  openEditDialog(): void {
    this.successMessage = '';
    let count = this.selected.length
    console.log(this.selected, this.selected.length)
    this.errorMessage = '';
    console.log(this.roleAccess.includes('Update'))
    if (this.roleAccess.includes('Update')) {
      if (this.selected.length == 1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(RepVehicleEditDialogComponent, {
          disableClose: true,
          data: this.dialogData
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          this.selected = [];
          if (result == 'success') {
            // this.Resetafterdialogmsg("add")
            this.successMessage = "Record Updated Successfully"
            this.getGridData("add")
          }
          else if (result == 'AlreadyExists') {
            // this.Resetafterdialogmsg("initial")
            this.errorMessage = 'Data Entered already Exists!'
            this.globalrole.goToTop();
          }
          else {
            // this.errorMessage = 'Data not inserted/Valid'
          }
        });
      }
      else if (this.selected.length > 1) {
        const dialogRef = this.dialog.open(RepVehicleMultieditDialogComponent, {
          disableClose: true,
          data: this.selected
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          this.selected = [];
          if (result == 'success') {
            this.successMessage = +count + " Record(s) Updated Successfully"
            // this.Resetafterdialogmsg("add")
            this.getGridData("add")
          }
          else if (result == 'AlreadyExists') {
            this.errorMessage = 'Data Entered already Exists!'
            this.globalrole.goToTop();
            // this.Resetafterdialogmsg("initial")
          }
          else {
            // this.errorMessage = 'Data not inserted/Valid'
          }
        });
      }
      else {
        this.errorMessage = "Please select a row to edit";
        this.globalrole.goToTop();
      }
    }
    else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
    }
  }
  openViewDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    console.log(this.roleAccess.includes('Read'))
    if (this.roleAccess.includes('Read')) {
      if (this.selected.length == 1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(RepVehicleViewDialogComponent, {
          disableClose: true,
          data: this.dialogData
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      }
      else {
        if (this.sameLengthRows == true) {
          this.errorMessage = "Please select only one row to view"
          this.globalrole.goToTop();
        }
        if (this.sameLengthRows == false) {
          this.errorMessage = "Please select a row to view"
          this.globalrole.goToTop();
        }
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'read' operation"
      this.globalrole.goToTop();
    }
  }
  openUploadDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    console.log(this.roleAccess.includes('Create'))
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(RepVehicleUploadDialogComponent, {
        disableClose: true,
        // width: '50%'
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        if (result == 'success') {
          this.getGridData("add");
          this.successMessage = 'Upload Process Started, Uploaded Information will be share via Mail'
        }
        else if (result == 'cancel') {
          this.successMessage = ''
          this.errorMessage = ''
        }
        else {
          this.errorMessage = result
          this.globalrole.goToTop();
        }
      });
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation"
      this.globalrole.goToTop();
    }
  }
  exportFile(): void {
    this.successMessage = '';
    this.errorMessage = '';
    this.rows.forEach(row => {
      let obj = {
        "Monthly Representative": row.monthly_rep_flg,
        "Comparitive BOM": row.compar_bom_flg,
        "Model Number": row.mdl_no,
        "Option": row.optn_cd,
        "SAP Material ID": row.sap_mtrl_id,
        "NAMC": row.namc_cd,
        "Family": row.fam_cd,
        "Katashiki": row.katashiki_cd,
        "Customer": row.buyoff_cust_nm,
        "Destination": row.dest_ctry_id,
        "Hikiate Status": row.hikiate_stat_comb_cd,
        "S/P": row.base_optn_typ_cd,
        "Net Spec": row.net_spec_cd_comb_txt,
        "PC Spec": row.pc_spec_cd_comb_txt,
        "100% Spec": row.hundred_pct_optn_flg,
        "Comp PC Spec": row.compar_pc_spec_cd_comb_txt,
        "Comp 100% Spec": row.compar_100_pct_optn_flg,
        "Extaction Time In": row.eff_dt,
        "Extraction Time Out": row.expr_dt,
        "Comments": row.rmrk_txt,
        "Rep Prod Def Id": row.rep_prod_def_id
      }
      this.exportData.push(obj);
    });
    this.exportService.exportAsExcelFile(this.exportData, this.fileName, this.sheetName);
    this.exportData = []
  }
  closeAlert(): void {
    this.errorMessage = '';
    this.successMessage = '';
  }

  sendToDialog(selected): void {
    selected.forEach(row => {
      this.dialogData.monthlyRep = row.monthly_rep_flg == 'Y' ? true : false
      this.dialogData.compBom = row.compar_bom_flg == 'Y' ? true : false
      this.dialogData.modelNumber = row.mdl_no
      this.dialogData.option = row.optn_cd
      this.dialogData.sapMatId = row.sap_mtrl_id
      this.dialogData.namc = row.namc_cd
      this.dialogData.family = row.fam_cd
      this.dialogData.katashiki = row.katashiki_cd
      this.dialogData.customerId = row.buyoff_cust_nm
      this.dialogData.dest = row.dest_ctry_id
      this.dialogData.hikiate = (row.hikiate_stat_comb_cd == null) ? '' : (row.hikiate_stat_comb_cd.split(","))
      this.dialogData.sp = row.base_optn_typ_cd
      this.dialogData.netSpec = row.net_spec_cd_comb_txt
      this.dialogData.pcSpec = (row.pc_spec_cd_comb_txt == null) ? '' : (row.pc_spec_cd_comb_txt.split(","))
      this.dialogData.hundrentPerSpec = row.hundred_pct_optn_flg == 'Y' ? true : false
      this.dialogData.compPCSpec = (row.compar_pc_spec_cd_comb_txt == null) ? '' : (row.compar_pc_spec_cd_comb_txt.split(","))
      this.dialogData.compHundPerSpec = row.compar_100_pct_optn_flg == 'Y' ? true : false
      this.dialogData.extractionTimeIn = this.pipe.transform(row.eff_dt, 'yyyy-MM-dd');
      this.dialogData.extractionTimeOut = this.pipe.transform(row.expr_dt, 'yyyy-MM-dd');
      this.dialogData.comments = row.rmrk_txt;
      this.dialogData.repProdDefId = row.rep_prod_def_id;
    });
    console.log(this.dialogData.monthlyRep)
  }

  resetAll(data): void {
    this.selected = []
    this.modelNumber = '';
    this.option = '';
    this.sapMatId = '';
    this.namc = '';
    this.famcd = '';
    this.katashiki = '';
    this.customer = '';
    this.dest = 'ALL';
    this.hikiate = '';
    this.sp = '';
    this.netSpec = '';
    this.pcSpec = '';
    this.compPcSpec = '';
    this.hundPercent = '';
    this.compHundPercent = '';
    this.effDateIn = '';
    this.effDateOut = '';
    this.errorMessage = '';
    this.successMessage = '';
    // this.getGridData(data);
    this.onloadfn();
  }

  search() {
    if (this.effDateIn && this.effDateOut && this.pipe.transform(this.effDateIn, 'yyyy-MM-dd') > this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')) {
      this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";
      this.globalrole.goToTop();
    }
    else {
      this.errorMessage = '';
      this.successMessage = "";
      this.getGridData("initial");
    }
  }

  getFamily(): void {
    console.log(this.namc)
    if (this.namc == undefined) {
      this.namc = ""
    }
    this.repVehicleService.getFamily(this.namc, this.screen).subscribe(data => {
      console.log(data);
      this.famcdLst = data.payload;
      this.famcd = ""
      this.katashiki = ""
      this.customer = "";
      this.dest = "ALL"
      this.hikiate = ""
      this.sp = ""
    });
  }

  //onChangeMethod
  getFamCdByNamc(): void {
    this.getFamily();
  }

  getKatashiki(): void {
    console.log(this.famcd)
    if (this.famcd == undefined) {
      this.famcd = ""
      this.katashikiLst = [];
      console.log(this.katashiki)
    }
    if (this.famcd != "") {
      this.repVehicleService.getKatashikiByFamCd(this.famcd).subscribe(data => {
        console.log(data);
        this.katashikiLst = data.payload;
        this.katashiki = ""
      });
    }

  }

  //onChangeMethod
  getKatashikiByFamCd(): void {
    this.getKatashiki();
  }

  getCustByKatashiki(): void {
    if (this.katashiki == undefined) {
      this.katashiki = ""
    }
    if (this.famcd == undefined) {
      this.famcd = ""
    }
    if (this.namc == undefined) {
      this.namc = ""
    }
    this.repVehicleService.getCustByKatashiki(this.namc, this.famcd, this.katashiki).subscribe(data => {
      console.log(data);
      this.customerLst = data.payload;
      this.customer = ""
    });
  }

  getDestByCust(): void {
    if (this.katashiki == undefined) {
      this.katashiki = ""
    }
    if (this.famcd == undefined) {
      this.famcd = ""
    }
    if (this.namc == undefined) {
      this.namc = ""
    }
    if (this.customer == undefined) {
      this.customer = ""
    }
    this.repVehicleService.getDestByCust(this.namc, this.famcd, this.katashiki, this.customer).subscribe(data => {
      console.log(data);
      this.destLst = data.payload;
      this.dest = "ALL"
    });
  }

  getSpByHiki(): void {
    if (this.namc == undefined) {
      this.namc = ""
    }
    if (this.famcd == undefined) {
      this.famcd = ""
    }
    if (this.katashiki == undefined) {
      this.katashiki = ""
    }
    if (this.customer == undefined) {
      this.customer = ""
    }
    if (this.dest == undefined) {
      this.dest = ""
    }
    if (this.hikiate == undefined) {
      this.hikiate = ""
    }
    this.repVehicleService.getSp(this.namc, this.famcd, this.katashiki, this.customer, this.dest, this.hikiate).subscribe(data => {
      console.log(data);
      this.spLst = data.payload;
      this.sp = ""
    });
  }


  getHikiate(): void {

    let hikiObj =
    {
      namc: this.namc,
      family: this.famcd,
      katashiki: this.katashiki,
      cust: this.customer,
      dest: this.dest
    }
    console.log(hikiObj)
    this.repVehicleService.getHikiate(hikiObj).subscribe(data => {
      console.log(data);
      this.hikiateLst = data.payload;
      this.hikiate = ""
    });
  }

  getHikiateByDest(): void {
    if (this.namc == undefined) {
      this.namc = ""
    }
    if (this.famcd == undefined) {
      this.famcd = ""
    }
    if (this.katashiki == undefined) {
      this.katashiki = ""
    }
    if (this.customer == undefined) {
      this.customer = ""
    }
    if (this.dest == undefined) {
      this.dest = ""
    }

    let hikiObj =
    {
      namc: this.namc,
      family: this.famcd,
      katashiki: this.katashiki,
      cust: this.customer,
      dest: this.dest
    }
    console.log(hikiObj)
    this.repVehicleService.getHikiate(hikiObj).subscribe(data => {
      console.log(data);
      this.hikiateLst = data.payload;
      this.hikiate = ""
    });
  }

  // getHikiateByDest(): void {
  //   this.getHikiate();
  // }

  getGridData(data) {
    this.selected = [];
    console.log(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      if (this.modelNumber == undefined) {
        this.modelNumber = ""
      }
      if (this.option == undefined) {
        this.option = ""
      }
      if (this.katashiki == undefined) {
        this.katashiki = ""
      }
      if (this.famcd == undefined) {
        this.famcd = ""
      }
      if (this.namc == undefined) {
        this.namc = ""
      }
      if (this.customer == undefined) {
        this.customer = ""
      }
      if (this.hikiate == undefined) {
        this.hikiate = ""
      }
      if (this.dest == undefined) {
        this.dest = ""
      }
      if (this.sp == undefined) {
        this.sp = ""
      }
      if (this.hundPercent == undefined) {
        this.hundPercent = ""
      }
      if (this.compHundPercent == undefined) {
        this.compHundPercent = ""
      }
      let indateFormat = ''; let outdateFormat = '';
      if (this.effDateIn != "") {
        let d1 = moment(this.effDateIn).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.effDateIn = date
        // let date = this.pipe.transform(this.effDateIn, 'yyyy-MM-dd')
        // this.effDateIn = date
        // console.log("this.effDateIn",date,this.effDateIn);

      }
      if (this.effDateOut != "") {
        let d1 = moment(this.effDateOut).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.effDateOut = date
        // this.effDateOut = this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')
      }
      let newDate = new Date();
      let date = '_' + newDate.getDate() + (newDate.getMonth() + 1) + newDate.getFullYear() + '_' + newDate.getHours() + newDate.getMinutes() + newDate.getSeconds() + newDate.getMilliseconds();
      let fileName = 'Rep-veh-Grid/RepVehGrdSrc' + date + '.json';
      let gridData
      if (data == 'initial') {
        gridData =
        {
          mdlNo: this.modelNumber,
          optnCd: this.option,
          sapMtrlId: this.sapMatId,
          namc: this.namc,
          family: this.famcd,
          katashiki: this.katashiki,
          cust: this.customer,
          hikiate: this.hikiate,
          dest: this.dest,
          sp: this.sp,
          netSpec: this.netSpec,
          pcSpec: this.pcSpec,
          compPcSpec: this.compPcSpec,
          hunSpec: this.hundPercent,
          cmpHunSpec: this.compHundPercent,
          extTimeIn: indateFormat,
          extTimeOut: outdateFormat,
          option: data,
          fileName: fileName
        }
      } else {
        gridData =
        {
          mdlNo: "",
          optnCd: "",
          sapMtrlId: "",
          namc: "",
          family: "",
          katashiki: "",
          cust: "",
          hikiate: "",
          dest: "ALL",
          sp: "",
          netSpec: "",
          pcSpec: "",
          compPcSpec: "",
          hunSpec: "",
          cmpHunSpec: "",
          extTimeIn: "",
          extTimeOut: "",
          option: data,
          fileName: fileName
        }
      }
      this.SpinnerService.show();
      this.repVehicleService.getGridData(gridData).subscribe(data => {
        console.log(data);
        this.showdata = true;
        console.log(this.showdata);
        this.recordcount = data.pagination.totalRecords;
        let datarow = data.payload;
        if (data.payload[0].RecordCnt > 1000) {
          setTimeout(() => {
            this.getFileFromS3(fileName);
          }, 2000);
        } else {
          this.rows = datarow.map(row => ({
            monthly_rep_flg: row['monthly_rep_flg'],
            compar_bom_flg: row['compar_bom_flg'],
            mdl_no: (row['mdl_no'] == null) ? '' : row['mdl_no'],
            sap_mtrl_id: row['sap_mtrl_id'],
            namc_cd: row['namc_cd'],
            fam_cd: row['fam_cd'],
            katashiki_cd: row['katashiki_cd'],
            buyoff_cust_nm: row['buyoff_cust_nm'],
            dest_ctry_id: row['dest_ctry_id'],
            hikiate_stat_comb_cd: row['hikiate_stat_comb_cd'],
            base_optn_typ_cd: row['base_optn_typ_cd'],
            net_spec_cd_comb_txt: row['net_spec_cd_comb_txt'],
            pc_spec_cd_comb_txt: row['pc_spec_cd_comb_txt'],
            hundred_pct_optn_flg: row['hundred_pct_optn_flg'],
            compar_pc_spec_cd_comb_txt: row['compar_pc_spec_cd_comb_txt'],
            compar_100_pct_optn_flg: row['compar_100_pct_optn_flg'],
            eff_dt: (row['eff_dt'] == null) ? '' : row['eff_dt'].split('T')[0],
            expr_dt: (row['expr_dt'] == null) ? '' : row['expr_dt'].split('T')[0],
            rmrk_txt: row['rmrk_txt'],
            rep_prod_def_id: row['rep_prod_def_id'],
            optn_cd: (row['optn_cd'] == null) ? '' : row['optn_cd']
          }));
          this.SpinnerService.hide();
          console.log(this.rows);
        }
      }, err => {
        console.log(err);
        this.successMessage = "";
        this.showdata = false;
        console.log(this.showdata);
        this.rows = [];
        if (err == 0) {
          this.errorMessage = "Request timed out. Please try again.";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        } else {
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        }
      });
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }
  errorExport(data) {
    this.exportData = [];
    data.forEach(row => {
      let obj = {
        "Monthly Representative": row.monthlyRep,
        "Comparitive BOM": row.compBom,
        "Model Number": row.mdlNo,
        "Option": row.option,
        "SAP Material ID": row.sapMatId,
        "NAMC": row.namc,
        "Family": row.family,
        "Katashiki": row.katashiki,
        "Customer": row.customer,
        "Dest": row.dest,
        "Hikiate Status": row.hikiate,
        "S/P": row.sp,
        "Net Spec": row.netspec,
        "PC Spec": row.pcSpec,
        "100% Spec": row.hundPerSpec,
        "Comp PC Spec": row.compPcSpec,
        "Comp 100% Spec": row.compHundPerSpec,
        "Extaction Time In": row.extTimeIn,
        "Extraction Time Out": row.extTimeOut,
        "Comments": row.comments,
        "Rep Prod Def Id": row.repProdDefId,
        "Error": row.errorMessage
      }
      this.exportData.push(obj);
    });
    console.log(this.exportData)
    this.exportService.exportAsExcelFile(this.exportData, this.errorFileName, this.errorSheetName);
  }


  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'effDateIn') {
      this.effDateIn = "";
    } else if (name == 'effDateOut') {
      this.effDateOut = "";
    }
  }

  Resetafterdialogmsg(data) {
    this.modelNumber = '';
    this.option = '';
    this.sapMatId = '';
    this.namc = '';
    this.famcd = '';
    this.katashiki = '';
    this.customer = '';
    this.dest = 'ALL';
    this.hikiate = '';
    this.sp = '';
    this.netSpec = '';
    this.pcSpec = '';
    this.compPcSpec = '';
    this.hundPercent = '';
    this.compHundPercent = '';
    this.effDateIn = '';
    this.effDateOut = '';
  }


  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.log(res)
        this.Roledata = res;

      })
      console.log(this.Roledata);
      if (this.Roledata.length != 0) {
        this.Roledata.filter((res) => {
          if (res.function_nm == 'NBPC_9350') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length == 0) {
        let arrayvalue: any; let roleList: any;
        let data = localStorage.getItem('role');
        arrayvalue = data.split(',')
        console.log(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str == '') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        let body = {
          security_grp_nm: roleList
        }
        let userpreviliagedata=localStorage.getItem('userpreviliagedata')

          if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
            this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
            this.userroleAccessfn();
            this.accessEnable = true;
            resolve(true);
          }else{
          console.log(this.roleAccess)
          this.roleAccess = []
          this.errorMessage = "User does not have permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        };
      }
      console.log(this.roleAccess);

    });
  }
  ////get from s3 Grid search data
  async getFileFromS3(fileName) {
    this.SpinnerService.show();
    // return new Promise(async resolve => {
    let a = await this.getS3Info();
    if (a) {
      const bucket = new S3(
        {
          accessKeyId: this.accessKey,
          secretAccessKey: this.secretKey,
          sessionToken: this.sessionToken,
          region: 'us-west-2'
        }
      );
      const params = {
        Bucket: 'search-grids',
        Key: fileName,
      };
      console.log(params);
      bucket.getObject(params, (err: any, data: any) => {
        if (err) {
          console.log('download err:::::::::', err)
          this.SpinnerService.hide();
        } else {
          console.log(data)
          this.SpinnerService.hide();
          let result = data.Body.toString();
          let res = JSON.parse(result);
          console.log("res and reult", res, result)
          if (res.length > 0) {
            this.rows = res.map(row => ({
              monthly_rep_flg: row['monthly_rep_flg'],
              compar_bom_flg: row['compar_bom_flg'],
              mdl_no: (row['mdl_no'] == null) ? '' : row['mdl_no'],
              sap_mtrl_id: row['sap_mtrl_id'],
              namc_cd: row['namc_cd'],
              fam_cd: row['fam_cd'],
              katashiki_cd: row['katashiki_cd'],
              buyoff_cust_nm: row['buyoff_cust_nm'],
              dest_ctry_id: row['dest_ctry_id'],
              hikiate_stat_comb_cd: row['hikiate_stat_comb_cd'],
              base_optn_typ_cd: row['base_optn_typ_cd'],
              net_spec_cd_comb_txt: row['net_spec_cd_comb_txt'],
              pc_spec_cd_comb_txt: row['pc_spec_cd_comb_txt'],
              hundred_pct_optn_flg: row['hundred_pct_optn_flg'],
              compar_pc_spec_cd_comb_txt: row['compar_pc_spec_cd_comb_txt'],
              compar_100_pct_optn_flg: row['compar_100_pct_optn_flg'],
              eff_dt: (row['eff_dt'] == null) ? '' : row['eff_dt'].split('T')[0],
              expr_dt: (row['expr_dt'] == null) ? '' : row['expr_dt'].split('T')[0],
              rmrk_txt: row['rmrk_txt'],
              rep_prod_def_id: row['rep_prod_def_id'],
              optn_cd: (row['optn_cd'] == null) ? '' : row['optn_cd']
            }));
            console.log(this.rows);
          }
        }
      })
    }
    // })
  }

  getS3Info() {
    return new Promise(resolve => {
      this.repVehicleService.fileUpldData().subscribe(data => {
        let result = data.payload[0]
        if (result !== null) {
          const aws = result;
          this.accessKey = aws.AccessKeyId;
          this.secretKey = aws.SecretAccessKey;
          this.sessionToken = aws.SessionToken;
          resolve(true)
        }
        else {
          // this.toastr.error('Data�Storage�Disconnected.');
          resolve(false)
        }
      }, err => {
        // this.toastr.error('Unable�to�get�keys�and�token', 'Failed');
      });
    })
  }

}
