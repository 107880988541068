import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReferenceModule} from '../reference.module';
import { HikiateStatusComponent } from '../hikiate-status/hikiate-status.component';
import { HikiateStatusAddDialogComponent } from '../hikiate-status-add-dialog/hikiate-status-add-dialog.component';
import { HikiateStatusViewDialogComponent } from '../hikiate-status-view-dialog/hikiate-status-view-dialog.component';



@NgModule({
  declarations: [
    HikiateStatusComponent,
    HikiateStatusAddDialogComponent,
    HikiateStatusViewDialogComponent
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class HikiateModule { }
