<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>View <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>

  <mat-dialog-content class="mat-typography" >
    <div class="container">
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Family:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="vFamily" class="inputtext" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Family Description:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="vFamilyDescription" class="inputtext" disabled></div>
          </div>
        </div>
      </div>


      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Series:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="vSeries" class="inputtext" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Generation:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="vGeneration" class="inputtext" disabled></div>
          </div>
        </div>
      </div>

      <!-- row3 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Car Family Flag:</label></div>
            <div class=col-2><input type="checkbox" [(ngModel)]="vCarFamilyFlag" class="inputtext" disabled></div>
          </div>
        </div>
      </div>



      <!-- row4 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Effective In Date:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="vEffectiveInDate" class="inputtext" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Effective Out Date:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="vEffectiveOutDate" class="inputtext" disabled></div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center" >
    <button mat-raised-button mat-dialog-close>Ok</button>
  </mat-dialog-actions>
</ng-container>