<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
<h2 mat-dialog-title cdkDragHandle>Edit <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
</h2>
<div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
  {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>

</div>

<mat-dialog-content class="mat-typography">
  <div class="container">
    <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are mandatory</span>
    <!-- row1 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Currency Code From:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="eCurrencyCodeFrom" class="inputtext" disabled></div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Currency Code To:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="eCurrencyCodeTo" class="inputtext" disabled></div>
        </div>
      </div>
    </div>
    <!-- row2 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Cost Type:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="eCostType" class="inputtext" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Month Selection:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="eMonthSelection" aria-label="Default select example"
              id="aeditExchngRtMnth" class="inputtext" disabled></div>
        </div>
      </div>
    </div>

    <!-- row3 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row ">
          <div class="col-4"> <label>Effective In Date:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="eEffectiveInDate" id="eEffectiveInDate" class="inputtext"
              disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Effective Out Date:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="eEffectiveOutDate" class="inputtext" disabled></div>
        </div>
      </div>

    </div>

    <!-- row3 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row ">
          <div class="col-4"> <label>Exchange Rate<span class="text-danger">*</span>:</label></div>
          <div class=col-8><input type="number" class="inputtext" [(ngModel)]="eExchangeRate"></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Active:</label></div>
          <div class=col-8><input type="checkbox" [(ngModel)]="eActive"></div>
        </div>
      </div>
    </div>
    <br>
    <!-- row5 -->
    <div class="row rowClass">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
          <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-200"
              style="width: -webkit-fill-available;" name="w3review" rows="2" cols="69" [(ngModel)]="eComments">
          </textarea>
          </div>
        </div>
      </div>
    </div>


  </div>

</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button cdkFocusInitial (click)="save()">Save</button>
  <button mat-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">

  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>