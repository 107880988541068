import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReferenceModule} from '../reference.module';
import { PlantUserCodeComponent } from '../plant-user-code/plant-user-code.component';
import { PlantUserCodeDeleteDialogComponent } from '../plant-user-code-delete-dialog/plant-user-code-delete-dialog.component';
import { PlantUserCodeEditDialogComponent } from '../plant-user-code-edit-dialog/plant-user-code-edit-dialog.component';
import { PlantUserCodeAddDialogComponent } from '../plant-user-code-add-dialog/plant-user-code-add-dialog.component';


@NgModule({
  declarations: [
    PlantUserCodeComponent,
    PlantUserCodeDeleteDialogComponent,
    PlantUserCodeEditDialogComponent,
    PlantUserCodeAddDialogComponent
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class PlntUsrCdModule { }
