<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
<h2 mat-dialog-title cdkDragHandle >Add <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
</h2>

<div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
  {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>

<mat-dialog-content class="mat-typography">
  <div class="container">
    <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are mandatory</span>
    <!-- row1 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>NAMC<span class="text-danger">*</span>:</label></div>
          <div class=col-8><select aria-label="Default select example" (change)='getFamily()' class="picker"
              [(ngModel)]="namc">
              <option value=""></option>
              <option *ngFor="let namc of namcLst" [value]="namc.namc_lgl_entity_id">{{namc.namc_cd}}</option>

            </select></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Family<span class="text-danger">*</span>:</label></div>
          <div class=col-8><select aria-label="Default select example" class="picker" [(ngModel)]="family"
              (change)='getKatashikiHps();getHikiate();getPcSpecHpsByFam()'>
              <option value=""></option>
              <option *ngFor="let family of famcdLst" [value]="family.fam_cd">{{family.fam_cd}}</option>

            </select></div>
        </div>
      </div>
    </div>
    <!-- row2 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Katashiki<span class="text-danger">*</span>:</label></div>
          <div class=col-8><select aria-label="Default select example" class="picker" [(ngModel)]="katashiki">
              <option></option>
              <option *ngFor="let katashiki of katashikiLst" [value]="katashiki.katashiki_cd">{{katashiki.katashiki_cd}}
              </option>
            </select></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Dest<span class="text-danger">*</span>:</label></div>
          <div class=col-8><select aria-label="Default select example" class="picker" [(ngModel)]="dest">
              <option value=''></option>
              <option *ngFor="let destination of destLst" [value]="destination.dest_ctry_id">
                {{destination.dest_ctry_id}}</option>
            </select></div>
        </div>
      </div>
    </div>

    <!-- row3 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Hikiate Status:</label></div>
          <div class=col-8><select id="hikiSelect" [(ngModel)]="dummyHikiateLst" class="multiple" multiple
              aria-label="Default select example" [(ngModel)]="hikiate">
              <option *ngFor="let hikiate of hikiateLstFromDB" [value]="hikiate.hikiate_stat_cd">
                {{hikiate.hikiate_stat_cd}}</option>
            </select></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <button class="btn" (click)="moveHikiForward()">>></button><br>
            <button class="btn" (click)="moveHikiBackward()">
              << </button>
          </div>
          <div class=col-8><select class="multiple" multiple [(ngModel)]="dummyHikiateLstToDB"
              aria-label="Default select example">
              <option value={{selectedHikiate}} *ngFor="let selectedHikiate of selectedHikiateLst">{{selectedHikiate}}
              </option>
            </select></div>
        </div>
      </div>
    </div>


    <!-- row4 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>PC Spec<span class="text-danger">*</span>:</label></div>
          <div class=col-8><select id="pcspec" multiple class="multiple" [(ngModel)]="dummyPcSpecLst"
              aria-label="Default select example">
              <option *ngFor="let pcSpec of pcSpecLstFromDB" [value]="pcSpec.pc_spec_cd">
                {{pcSpec.pc_spec_cd}} </option>
            </select></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <button class="btn" (click)="movePcSPecForward()">>></button><br>
            <button class="btn" (click)="movePcSpecBackward()">
              << </button>
          </div>
          <div class=col-8><select class="multiple" [(ngModel)]="dummyPcSpecLstToDB" multiple
              aria-label="Default select example">
              <option value={{selectedPcSpec}} *ngFor="let selectedPcSpec of selectedPcSpecLst">{{selectedPcSpec}}
              </option>
            </select></div>
        </div>
      </div>
    </div>
    <!-- row5 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Time In Date<span class="text-danger">*</span>:</label></div>
          <div class=col-8>
            <mat-form-field class="inputtext" appearance="outline">
              <input matInput [matDatepicker]="inPicker" [(ngModel)]="inDate" >
              <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
              <mat-datepicker #inPicker ></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'inDate')">clear</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Time Out Date:</label></div>
          <div class=col-8>
            <mat-form-field class="inputtext" appearance="outline">
              <input matInput [matDatepicker]="outPicker" [(ngModel)]="outDate">
              <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
              <mat-datepicker #outPicker ></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'outDate')">clear</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <!-- row6 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>VDW Effective In:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="effIn" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>VDW Effective Out:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="effOut" disabled></div>
        </div>
      </div>
    </div>
    <!-- row7 -->
    <div class="row rowClass">
      <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
      <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review" rows="4" cols="50"
          [(ngModel)]="comments">
            </textarea>
      </div>
    </div>

  </div>

</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button cdkFocusInitial (click)="updateHundredSpec()">Save</button>
  <button mat-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">

  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>