export class ProductWithinVersion
{

    modelNumber: string;
    option: string;
    sapMatId: string;
    versionType: string;
    versionQual: string;
    versionRev: string;
    bomEffDate: string;
    costEffDate: string;
    status: string;
    namc: string;
    family: string;
    katashiki: string;
    partNo: string;
    cust: string;
    dest: string;
    hikiate: string;
    sp: string;
    pcSpec: String;
    hundPercentSpec: boolean;
    compPcSpec: string;
    compHundPercentSpec: boolean;
    netSpec: string;
    onHoldStatus: boolean;
    comments: string;
    net_struc_flg: any;
    reuse_struc_flg: any;
    rep_prod_id: any;
    rep_prod_def_id: any;
    versionName: any;
    productType: any;
    buttonName: any;
    mfrdProdId: any;
    prod_stat_typ_cd: any;
    bom_proc_stat_typ_id: any;
    prod_bom_id: any;
    bom_typ_cd: any;
    rep_bom_ver_id:any;
}