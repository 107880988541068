<span *ngIf="accessEnable">
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <div class="alert alert-danger alert-dismissible " *ngIf="errorResetbomMsg != ''" role="alert">
    {{errorResetbomMsg}}
    <u><a (click)="downloadresetBom('bom')" style="cursor: pointer;">Click here to see the IDs</a></u>
    <mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <div class="alert alert-success alert-dismissible " *ngIf="successResetbomMsg != ''" role="alert">
    {{successResetbomMsg}}
    <u><a (click)="downloadresetBom('bom')" style="cursor: pointer;">Click here to see the IDs</a></u>
    <mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <div class="alert alert-danger alert-dismissible " *ngIf="errorResetcostMsg != ''" role="alert">
    {{errorResetbomMsg}}
    <u><a (click)="downloadresetBom('cost')" style="cursor: pointer;">Click here to see the IDs</a></u>
    <mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <div class="alert alert-success alert-dismissible " *ngIf="successResetcostMsg != ''" role="alert">
    {{successResetbomMsg}}
    <u><a (click)="downloadresetBom('cost')" style="cursor: pointer;">Click here to see the IDs</a></u>
    <mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <div class="alert alert-success alert-dismissible " *ngIf="successMessage!=''" role="alert">
    {{successMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>

<mat-card>
  <mat-form-field>
    <mat-label>Online View of Products</mat-label>
    <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="products" name="products">
      <mat-option value="All">All</mat-option>
      <mat-option value="onHold">On Hold</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="verNamePad">
    <mat-label>Version Name</mat-label>
    <mat-select [typeaheadDebounceInterval]="500" #matRef [(ngModel)]="versionNm" name="versionNm" [multiple]="true"
      (selectionChange)="checkedState($event)">
      <span *ngFor="let versionNm of versionNmLst">
        <mat-option *ngIf="versionNm.version_name != ''" [value]="versionNm.rep_bom_ver_id" #matOption
          [disabled]="matOption.selected != true && limitexceed">
          {{versionNm.version_name}}
        </mat-option>
      </span>
    </mat-select>
  </mat-form-field>

  <button mat-raised-button [disabled]="versionNm==''" (click)="generateRepVerSummary()">Generate Rep Version
    Summary</button>
    <button mat-raised-button (click)="generateActProdStatus()">Generate Actual Prod Status</button>
    <mat-form-field>
      <mat-label>Actual Production Priority:</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="actProdPrior" name="actProdPrior"
        (selectionChange)="chngActProdPriority()">
        <mat-option value="Normal">Normal</mat-option>
        <mat-option value="onHold">On Hold</mat-option>
      </mat-select>
    </mat-form-field>
  <br>
  <mat-form-field>
    <mat-label>Month</mat-label>
    <mat-select [(ngModel)]="month" name="month">
      <mat-option *ngFor="let month of monthsArr" [value]="month">{{month}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Year</mat-label>
    <mat-select [(ngModel)]="year" name="year">
      <mat-option [value]="currentYear-2">{{currentYear-2}}</mat-option>
      <mat-option [value]="currentYear-1">{{currentYear-1}}</mat-option>
      <mat-option [value]="currentYear">{{currentYear}}</mat-option>

    </mat-select>
  </mat-form-field>
  <button mat-raised-button [disabled]="(month=='' && year =='')?true:(year=='')?true:(month=='')?true:false"
    (click)="generateMonthlyReport()">Generate Actual Monthly Reports</button>


</mat-card>
<mat-accordion class="example-headers-align">
  <mat-expansion-panel class="search" [expanded]="step === 0" (opened)="setStep(0)" hideToggle>

    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Search</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-form-field>
      <mat-label>Model Number</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="modelNumber" name="modelNumber">
        <mat-option [value]=""></mat-option>
        <span *ngFor="let mdlNo of modelNumberLst">
          <span *ngIf="mdlNo.mdl_no != null">
            <mat-option *ngIf="mdlNo.mdl_no != ''" [value]="mdlNo.mdl_no">{{mdlNo.mdl_no}}</mat-option>
          </span>
        </span>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Option</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="option" name="option">
        <mat-option [value]=""></mat-option>
        <span *ngFor="let optn of optionLst">
          <span *ngIf="optn.optn_cd != null">
            <mat-option *ngIf="optn.optn_cd != ''" [value]="optn.optn_cd">{{optn.optn_cd}}</mat-option>
          </span>
        </span>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>SAP Material ID</mat-label>
      <input matInput placeholder="" [(ngModel)]="sapMatId">
    </mat-form-field>

    <mat-form-field>
      <mat-label>R/A</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="ra" name="ra"
        (selectionChange)='getBomVin();dateFrom();dateTo()'>

        <mat-option value="R">R</mat-option>
        <mat-option value="A">A</mat-option>
      </mat-select>
    </mat-form-field>

      <mat-form-field class="picker">
        <mat-label>BoM Effectivity/VIN I9/Unit Exit From Date</mat-label>
        <input matInput [matDatepicker]="inPicker" [(ngModel)]="bomEffFromDate" (dateChange)="dateFrom()">
        <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
        <mat-datepicker #inPicker></mat-datepicker>
        <mat-icon matDatepickerToggleIcon class="dateclear"
          (click)="clearDate($event,'bomEffFromDate')">clear</mat-icon>
      </mat-form-field>

    <mat-form-field class="picker">
      <mat-label>BoM Effectivity/VIN I9/Unit Exit To Date</mat-label>
      <input matInput [matDatepicker]="outPicker" [(ngModel)]="bomEffToDate" (dateChange)="dateTo()">
      <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
      <mat-datepicker #outPicker></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'bomEffToDate')">clear</mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Rep Version Name </mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="repVerNm" name="repVerNm" [disabled]="Ahidedata">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let repVerNm of repVerNmLst" [value]="repVerNm.version_name">{{repVerNm.version_name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>BoM Effectivity/VIN I9/Unit Exit From Time</mat-label>
      <input matInput placeholder="" [disabled]="hidedata" [(ngModel)]="bomEffFromTime">
    </mat-form-field>

    <mat-form-field>
      <mat-label>BoM Effectivity/VIN I9/Unit Exit To Time</mat-label>
      <input matInput placeholder="" [disabled]="hidedata" [(ngModel)]="bomEffToTime">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Processing Status</mat-label>
      <mat-select #mySel [typeaheadDebounceInterval]="500" [(ngModel)]="procStat" name="procStat" [multiple]="true">
        <div class="select-all">
          <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: allSelected}"
            (change)="toggleAllSelection()">Select All</mat-checkbox>
        </div>
        <mat-option *ngFor="let procStat of procStatLst" [value]="procStat.bom_proc_stat_typ_desc"
          (click)="optionClick()">
          {{procStat.bom_proc_stat_typ_desc}}</mat-option>
      </mat-select>
    </mat-form-field>

      <mat-form-field class="picker">
        <mat-label>VIN Buyoff/Unit Ship From Date</mat-label>
        <input matInput [matDatepicker]="fromPicker" [(ngModel)]="buyOffFromDate">
        <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
        <mat-datepicker #fromPicker></mat-datepicker>
        <mat-icon matDatepickerToggleIcon class="dateclear"
          (click)="clearDate($event,'buyOffFromDate')">clear</mat-icon>
      </mat-form-field>

    <mat-form-field class="picker">
      <mat-label>VIN Buyoff/Unit Ship To Date</mat-label>
      <input matInput [matDatepicker]="toPicker" [(ngModel)]="buyOffToDate">
      <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
      <mat-datepicker #toPicker></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'buyOffToDate')">clear</mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>NAMC</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="namc" name="namc" (selectionChange)="getFamily()">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let namc of namcLst" [value]="namc.namc_lgl_entity_id">{{namc.namc_cd}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Family</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="family" name="family"
        (selectionChange)="onchangecall();">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let family of familyLst" [value]="family.fam_cd">{{family.fam_cd}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>VIN/SN</mat-label>
      <input matInput placeholder="" class="readonly" [(ngModel)]="vinSN" [disabled]="hidedata">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Manual Adjustment Status</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="manAdjStat" name="manAdjStat">
        <mat-option [value]=""></mat-option>
        <mat-option value="APPLIED">APPLIED</mat-option>
        <mat-option value="FAILED">FAILED</mat-option>
        <mat-option value="PROCESSING">PROCESSING</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="enableKatashiki">
      <mat-label>Katashiki/Part</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="katashiki" name="katashiki">
        <mat-option></mat-option>
        <span *ngFor="let katashiki of katashikiLst">
          <mat-option *ngIf="katashiki.katashiki_cd != ''" [value]="katashiki.katashiki_cd">
            {{katashiki.katashiki_cd}}</mat-option>
        </span>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="enablePartnum">
      <mat-label>Katashiki/Part</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="katashiki" name="katashiki">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let data of partnumLst" [value]="data.prod_part_no">
          {{data.prod_part_no}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Approval Status</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="approvalStatus" name="approvalStatus"
        [disabled]="approvalsts">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let status of approvalStatusLst" [value]="status.bom_ver_apprvl_stat_typ">
          {{status.bom_ver_apprvl_stat_typ}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>SAP Production Order Number</mat-label>
      <input matInput placeholder="" [(ngModel)]="sapProdNum">
    </mat-form-field>

    <mat-form-field>
      <mat-label>On-Hold Status</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="onHoldStatus" name="onHoldStatus">
        <mat-option></mat-option>
        <mat-option value="Y">Y</mat-option>
        <mat-option value="N">N</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Processed in SAP Status</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="sapStatus" name="sapStatus">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let sapStatus of sapStatusLst" [value]="sapStatus.sap_proc_stat_typ_desc">
          {{sapStatus.sap_proc_stat_typ_desc}}</mat-option>
      </mat-select>
    </mat-form-field>
    <br>


    <button mat-button style="float: right;" (click)="resetAll('')">Reset</button>
    <button mat-button style="float: right;" (click)="search()">Search</button>

  </mat-expansion-panel>
</mat-accordion>

<div class="d-flex" *ngIf="showItems">
  <button mat-raised-button (click)="IdenDepProd()"> Identify Dependent Products </button>
  <button mat-raised-button (click)="setHoldProc()">Set to Hold Processing </button>
  <button mat-raised-button (click)="modifyVboDate()">Modify VBO Date </button>
  <button mat-raised-button (click)="resetBomProc()">Reset for BoM Processing </button>
  <button mat-raised-button (click)="resetCostProc()">Reset for Cost Processing </button>
</div>
<div class="d-flex" *ngIf="showItems">
  <button mat-raised-button (click)="genBomStructure()">Generate BOM Structure </button>
  <button mat-raised-button (click)="resetSendtoSap()">Reset to Send To SAP </button>
  <button mat-raised-button (click)="sendtoSap()">Send To SAP </button>
  <span class="ms-auto">
    <button mat-raised-button (click)="exportFile()">Export to Excel </button>
  </span>
</div>

<!-- <mat-card> -->
<ngx-datatable #prodProcTable *ngIf="showItems" [ngClass]="{'hideerror': showdata, 'showerror': !showdata }"
  class="material striped" [rows]="rows" [columnMode]="ColumnMode.force" [headerHeight]="45" [footerHeight]="30"
  rowHeight="30" [limit]="limitSelected" [selected]="selected" [selectionType]="SelectionType.checkbox"
  [selectAllRowsOnPage]="false" [displayCheck]="displayCheck" (select)="onSelect($event)" [scrollbarH]="true">
  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
      let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
      <div class="page-count">
        {{selectedCount}} selected / {{rowCount}} total
      </div>
      <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
        (change)="table.onFooterPage($event);">
      </datatable-pager>
      <div class="test">
        <select [(ngModel)]="limitSelected">
          <option>50</option>
          <option>100</option>
          <option>500</option>
        </select>
        <div class="float-right records">View {{(offset * pageSize)+1}} - {{(rowCount>
          (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
          {{(rowCount)}}

          </div>
        </div>
      </ng-template>
    </ngx-datatable-footer>
    <ngx-datatable-column [width]="10" [sortable]="false" [canAutoResize]="false" [draggable]="false"
      [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true">
    </ngx-datatable-column>
    <ngx-datatable-column name="Copy Structure">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value" prop="reuse_struc_flg">
        <input type="checkbox" [checked]="row.copyStruc=='Y'" disabled />
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="SAP Processing Status" prop="sap_proc_stat_typ_nm"></ngx-datatable-column>
    <ngx-datatable-column name="Processing Status" prop="bom_proc_stat_typ_desc"></ngx-datatable-column>
    <ngx-datatable-column name="R/A" prop="bom_typ_cd"></ngx-datatable-column>
    <ngx-datatable-column name="NAMC" prop="namc_lgl_entity_id"></ngx-datatable-column>
    <ngx-datatable-column name="SAP Production Order Number" prop="sap_prod_ord_no"></ngx-datatable-column>
    <ngx-datatable-column class="w-50" name="Model Number" prop="mdl_no"></ngx-datatable-column>
    <ngx-datatable-column name="Option" prop="optn_cd"></ngx-datatable-column>
    <ngx-datatable-column name="SAP Material ID" prop="sap_mtrl_id" [width]="200"></ngx-datatable-column>
    <ngx-datatable-column name="Family" prop="fam_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Katashiki/Part" prop="katashiki_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Dest" prop="dest_ctry_id"></ngx-datatable-column>
    <ngx-datatable-column name="Cust" prop="buyoff_cust_nm"></ngx-datatable-column>
    <ngx-datatable-column name="S/P" prop="base_optn_typ_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Net Spec" prop="net_spec_cd_comb_txt"></ngx-datatable-column>
    <ngx-datatable-column name="PC Spec" prop="pc_spec_cd_comb_txt"></ngx-datatable-column>
    <ngx-datatable-column name="Rep Version Name" prop="prod_bom_desc"></ngx-datatable-column>
    <ngx-datatable-column name="VIN/SN" prop="vin"></ngx-datatable-column>
    <ngx-datatable-column name="On-Hold Status">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value" prop="on_hold_flg">
        <input type="checkbox" [checked]="row.on_hold_flg=='Y'" disabled />
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Prod Def Id" prop="prod_def_id"></ngx-datatable-column>
    <ngx-datatable-column name="Prod Bom Id" prop="prod_bom_id"></ngx-datatable-column>
    <ngx-datatable-column name="Hikiate Status" prop="hikiate_stat_comb_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Include 100% Mandatory Spec">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value" prop="hundred_pct_optn_flg">
        <input type="checkbox" [checked]="row.hundred_pct_optn_flg=='Y'" disabled />
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="BoM Effectivity/VIN I9/Unit Exit Date" prop="bom_eff_dt_ts"></ngx-datatable-column>
    <ngx-datatable-column name="VIN Buyoff/Unit Ship Date" prop="buyoff_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Processing Start Date" prop="procsg_strt_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Processing Suspension Date" prop="procSuspDate"></ngx-datatable-column>
    <ngx-datatable-column name="Processing Completion Date" prop="procsg_cmplt_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Processed to Reports" prop="procToReport"></ngx-datatable-column>
    <ngx-datatable-column name="Processed to SAP" prop="proctosap"></ngx-datatable-column>
    <ngx-datatable-column name="Manual Adjustment Status" prop="ManAdjsts">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-rowIndex="rowIndex">
        <div class="text-left">
          <span id=activity{{rowIndex}} title="Activity Stream" (click)="marpopup(row.manadjpopup)"
            style="text-decoration: underline;">{{value}}</span>
        </div>
      </ng-template></ngx-datatable-column>
    <ngx-datatable-column name="manualpopup" prop="manadjpopup" *ngIf="!hidecolumn"></ngx-datatable-column>
  </ngx-datatable>

<!-- //////////////////////////modal popup/////////////////// -->
<!-- openFailedRecords -->
<div class="modal" id="failedPopup">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Manual Adjustment Status</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="hidepopup()"><span>&times;</span></button>
      </div>
      <div class='modal-body'>
        <div class="offset-md-1 col-md-10">
          <table>
            <tr>
              <th scope="col">Manual Adjustment Status</th>
              <th scope="col">Ruled Id(s)</th>
            </tr>
            <tr>
              <td>{{rulestatus}}</td>
              <td>{{ruleId}}</td>
            </tr>
          </table>
        </div>

        </div>
        <div class="modal-footer ">
          <div class='row w-100'>
            <div class='offset-md-5 col-md-3'>
              <button type="button" class="btn btn-secondary btn-lg" data-dismiss="modal"
                (click)="hidepopup()">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</span>
<div *ngIf="!accessEnable">
  <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>
