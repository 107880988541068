import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { ExchangeRateEditDialogService } from './exchange-rate-edit-dialog.service';


@Component({
  selector: 'app-exchange-rate-edit-dialog',
  templateUrl: './exchange-rate-edit-dialog.component.html',
  styleUrls: ['./exchange-rate-edit-dialog.component.css']
})
export class ExchangeRateEditDialogComponent implements OnInit {

  [x: string]: any;
  //to main page
  message = "Records updated successfully";
  // errorMessage = "";

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //alertMessage
  errorMessage: string = '';
  successMessage: string = '';

  //ngModelValues
  eCurrencyCodeFrom: any;
  eCurrencyCodeTo: any;
  eCostType: any;
  eMonthSelection: any;
  eEffectiveInDate: any;
  eEffectiveOutDate: any;
  eExchangeRate: any;
  eComments: any;
  eActive: any;
  selectdMnth: any;
  aExchngRtExtrctTimeIn: any;
  aExchngRtExtrctTimeOut: any;
  setMnth: any;
  curDate: any;
  curMonth: any;
  setYr: any;
  curYr: any;
  effInDate: any;
  effOutDate: any;
  firstDate: any;
  lastDate: any;
  monthNames: any;
  dateVar: any;
  dsplit: any;
  date: any;
  aeditExchngRtExtrctTimeIn: any;
  aeditExchngRtMnth: any;
  d: any;
  eExchng_rt_id: any
  spinnerMessage = "Please Wait...";



  constructor(public dialogRef: MatDialogRef<ExchangeRateEditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public ExchangeRateEditDialogService: ExchangeRateEditDialogService, private SpinnerService: NgxSpinnerService) { }

  getExchangeRtEffDates() {
    this.selectdMnth = this.eMonthSelection//$.trim($("aExchngRate").val());
    $("aExchngRtExtrctTimeIn").prop('disabled', false);
    $('aExchngRtExtrctTimeOut').prop('disabled', false);

    if (this.selectdMnth != "") {
      this.selectdMnth = parseInt(this.selectdMnth, 10);
      this.setMnth = this.selectdMnth - 1;
      this.curDate = new Date();

      this.curMonth = this.curDate.getMonth();
      this.curYr = this.curDate.getFullYear();
      this.setYr = this.curYr;

      if (this.setMnth < this.curMonth) {
        this.setYr = this.curYr + 1;
      }

      this.firstDate = new Date(this.setYr, this.setMnth, 1).getDate().toString();
      this.lastDate = new Date(this.setYr, this.setMnth + 1, 0).getDate().toString();

      this.selectdMnth = this.selectdMnth.toString();
      this.selectdMnth = this.selectdMnth.length > 1 ? this.selectdMnth : '0' + this.selectdMnth;
      this.firstDate = this.firstDate.length > 1 ? this.firstDate : '0' + this.firstDate;

      this.effInDate = this.setYr + '-' + this.selectdMnth + '-' + this.firstDate;
      this.effOutDate = this.setYr + '-' + this.selectdMnth + '-' + this.lastDate;


      this.eEffectiveInDate = this.effInDate;//$("aExchngRtExtrctTimeIn").val(this.effInDate);
      this.eEffectiveOutDate = this.effOutDate;//  $("aExchngRtExtrctTimeOut").val(this.effOutDate);

    }
    else {
      this.eEffectiveInDate = "";
      this.eEffectiveOutDate = "";
      //  $("aExchngRtExtrctTimeIn").val("");
      // $("aExchngRtExtrctTimeOut").val("");
    }

    $("aExchngRtExtrctTimeIn").prop('disabled', true);
    $('aExchngRtExtrctTimeOut').prop('disabled', true);
    return true;
  }


  ngOnInit(): void {
    if (this.data.active == 'In Active') {
      this.eActive = false
    }
    if (this.data.active == 'Active') { 
      this.eActive = true
    }

    console.log("data inside edit", this.data);
    this.eCurrencyCodeFrom = this.data.currencyCodeFrom;
    this.eCurrencyCodeTo = this.data.currencyCodeTo;
    this.eCostType = this.data.costType;
    this.eEffectiveInDate = this.data.effectiveInDate;
    this.eEffectiveOutDate = this.data.effectiveOutDate;
    this.eExchangeRate = this.data.exchangeRate; 
    this.eComments = this.data.comments;
   
    this.eExchng_rt_id = this.data.exchng_rt_id;
    this.monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUNE", "JULY", "AUG", "SEP", "OCT", "NOV", "DEC"];
    this.dateVar = this.eEffectiveInDate;
    console.log(this.dateVar)
    this.dsplit = this.dateVar.split("-");
    console.log(this.dsplit)
    this.d = new Date(this.dsplit[0], this.dsplit[1] - 1, this.dsplit[2]);
    this.eMonthSelection = this.monthNames[this.d.getMonth()];
    console.log(this.d, this.eMonthSelection)
    // $('aeditExchngRtMnth').val(this.monthNames[this.d.getMonth()]);
  }

  closeAlert() {
    this.errorMessage = "";
  }

  save() {
    let fileName= localStorage.getItem('Filename')
    console.log(this.eExchangeRate);
    if (!this.eExchangeRate) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else if (this.eEffectiveInDate && this.eEffectiveOutDate &&
      this.pipe.transform(this.eEffectiveInDate, 'yyyy-MM-dd') > this.pipe.transform(this.eEffectiveOutDate, 'yyyy-MM-dd')) {
      this.errorMessage = "Effective Out Date should be greater than or equal to Effective In Date";
    }
    else {
      let user = localStorage.getItem('workDayId');
      this.errorMessage = "";
      let cmts;
    if (this.eComments!= undefined) {
      cmts = this.eComments.replaceAll("'", "''");
    } else {
      cmts = this.eComments
    }
      let body = {
        'curExRt': this.eExchangeRate,
        'ActFlg': this.eActive == false ? "Y" : "N",
        'comment': cmts,
        'ExRtid': this.eExchng_rt_id,
        "screenNo":"NBPC_3910",
        "fileName" :fileName
       
      }
      this.SpinnerService.show();
      this.ExchangeRateEditDialogService.editExchangeRate(body).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.response = data.payload;
        if (this.response[0].result == 'success') {
          this.dialogRef.close(this.response[0].result);
        } else if (this.response[0].result == 'AlreadyExists') {
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        this.SpinnerService.hide();
      });
    }

  }

  cancel() {

    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });

  }

}
