<span *ngIf="accessEnable">
<div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
  {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>
<div class="alert alert-danger alert-dismissible " *ngIf="loadebomErrMsg != ''" role="alert">
  {{loadebomErrMsg}}
  <u><a (click)="downloadExcel()" style="cursor: pointer;">Click here to see the failed IDs</a></u>
  <mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>
<div class="alert alert-success alert-dismissible " *ngIf="successMessage!=''" role="alert">
  {{successMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>
<mat-accordion class="example-headers-align">
  <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle (click)="partdescription(partDesc)">

    <mat-expansion-panel-header>
      <mat-panel-title>
        Search
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-form-field>
      <mat-label>Model Number </mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="modelNumber" name="modelNumber">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let modelNumber of modelNumberLst" [value]="modelNumber.mdl_no">{{modelNumber.mdl_no}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Option </mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="optn" name="option">
        <mat-option [value]=""></mat-option>
        <span *ngFor="let option of optionLst">
          <span *ngIf="option.optn_cd != null">
            <mat-option *ngIf="option.optn_cd != ''" [value]="option.optn_cd">{{option.optn_cd}}</mat-option>
          </span>
        </span>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>SAP Material ID</mat-label>
      <input matInput placeholder="" [(ngModel)]="sapMaterialId" maxlength="20"
        oninput="this.value = this.value.toUpperCase()">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Part Number</mat-label>
      <input matInput placeholder="" [(ngModel)]="partNumber" maxlength="10"
        oninput="this.value = this.value.toUpperCase()" (keyup.enter)="partdescription(partDesc)">
    </mat-form-field>

    <mat-form-field>
      <mat-label>NAMC</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="namc" name="namc"
        (selectionChange)='getFamCdByNamc();getKatashikiByFamCd();getCustByKatashiki();getDestByCust()'>
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let namc of namcLst" [value]="namc.namc_lgl_entity_id">{{namc.namc_cd}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Family</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="family" name="family" (selectionChange)='getKatashikiByFamCd()'>
        <mat-option [value]=""></mat-option>
        <mat-option value="ALL">ALL</mat-option>
        <span *ngFor="let family of familyLst">
          <span *ngIf="family.fam_cd != ''">
            <mat-option *ngIf="family.fam_cd != null" [value]="family.fam_cd">{{family.fam_cd}}</mat-option>
          </span>
        </span>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Katashiki</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="katashiki" name="katashiki" (selectionChange)='getCustByKatashiki()'>

        <mat-option value="ALL">ALL</mat-option>
        <mat-option *ngFor="let katashiki of katashikiLst" [value]="katashiki.katashiki_cd">{{katashiki.katashiki_cd}}
        </mat-option>
      </mat-select>
    </mat-form-field>


    <mat-form-field>
      <mat-label>Customer</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="customer" name="customer" (selectionChange)='getDestByCust()'>
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let customer of customerLst" [value]="customer.buyoff_cust_id">{{customer.buyoff_cust_nm}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Dest</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="dest" name="dest" (selectionChange)='getHikiateByDest()'>
        <mat-option [value]=""></mat-option>
        <mat-option value="ALL">ALL</mat-option>
        <mat-option *ngFor="let dest of destLst" [value]="dest.dest_ctry_id">{{dest.dest_ctry_id}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Hikiate Status</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="hikiateStatus" name="hikiateStatus" (selectionChange)='getSpByHiki()'>
        <mat-option [value]=""></mat-option>
        <span *ngFor="let hikiateStatus of hikiateStatusLst">
          <span *ngIf="hikiateStatus.hikiate_stat_comb_cd != null">
            <mat-option *ngIf="hikiateStatus.hikiate_stat_comb_cd != ''" [value]="hikiateStatus.hikiate_stat_comb_cd">
              {{hikiateStatus.hikiate_stat_comb_cd}}</mat-option>
          </span>
        </span>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>S/P</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="sp" name="sp">
        <mat-option [value]=""></mat-option>
        <span *ngFor="let sp of spLst">
          <span *ngIf="sp.base_optn_typ_cd != null">
            <mat-option *ngIf="sp.base_optn_typ_cd != ' '" [value]="sp.base_optn_typ_cd">
              {{sp.base_optn_typ_cd}}</mat-option>
          </span>
        </span>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Net Spec</mat-label>
      <input matInput placeholder="" [(ngModel)]="netSpec">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Pc Spec</mat-label>
      <input matInput placeholder="" [(ngModel)]="pcSpec">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Comp Pc Spec</mat-label>
      <input matInput placeholder="" [(ngModel)]="compPcSpec">
    </mat-form-field>

    <mat-form-field class="picker">
      <mat-label>Extraction Time In</mat-label>
      <input matInput [matDatepicker]="inPicker" [(ngModel)]="extractionTimeIn">
      <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
      <mat-datepicker #inPicker></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'extractionTimeIn')">clear
      </mat-icon>
    </mat-form-field>
    <mat-form-field class="picker">
      <mat-label>Extraction Time Out</mat-label>
      <input matInput [matDatepicker]="outPicker" [(ngModel)]="extractionTimeOut">
      <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
      <mat-datepicker #outPicker></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'extractionTimeOut')">clear
      </mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Product Type</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="prodTyp" name="prodTyp">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let prodTyp of prodTypLst" [value]="prodTyp.prod_typ_desc">{{prodTyp.prod_typ_desc}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Sap Status</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="sapStatus" name="sapStatus">
        <mat-option></mat-option>
        <mat-option value="A">Active</mat-option>
        <mat-option value="I">Inactive</mat-option>
        <mat-option value="D">Deleted</mat-option>
        <mat-option value="R">Duplicate</mat-option>
        <mat-option *ngFor="let sapStatus of sapStatusLst" [value]="sapStatus">{{sapStatus}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Part Description</mat-label>
      <input matInput placeholder="" [(ngModel)]="partDesc" disabled>
    </mat-form-field>



    <button mat-button style="float: right;" (click)="resetAll('initial')">Reset</button>
    <button mat-button style="float: right;" (click)="search()">Search</button>

  </mat-expansion-panel>
</mat-accordion>

<div class="d-flex">
  <button mat-raised-button (click)="openEditDialog()">Edit </button>
  <button mat-raised-button (click)="openDeleteDialog()">Delete </button>

  <button mat-raised-button (click)="exportFile()" class="ms-auto"> Export To Excel </button>
  <button mat-raised-button (click)="getProcStatus()">Load to eBoM </button>
</div>

<!-- <mat-card> -->
<ngx-datatable #myTable class="material striped" [rows]="rows" [columnMode]="ColumnMode.force" [headerHeight]="45"
  [footerHeight]="30" rowHeight="30" [limit]="limitSelected" [selected]="selected"
  [selectionType]="SelectionType.checkbox" [selectAllRowsOnPage]="false" [displayCheck]="displayCheck"
  (activate)="onActivate($event)" (select)="onSelect($event)" [scrollbarH]="true"
  [ngClass]="{'hideerror': showdata, 'showerror': !showdata }">
  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
      let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
      <div class="page-count">
        {{selectedCount}} selected / {{rowCount}} total
      </div>
      <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
        (change)="table.onFooterPage($event)">
      </datatable-pager>
      <div class="test">
        <select [(ngModel)]="limitSelected">
          <option>50</option>
          <option>100</option>
          <option>500</option>
        </select>
        <div class="float-right records">View {{(offset * pageSize)+1}} - {{(rowCount>
          (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
          {{(rowCount)}}

        </div>
      </div>
    </ng-template>
  </ngx-datatable-footer>
  <ngx-datatable-column [width]="10" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false"
    [headerCheckboxable]="true" [checkboxable]="true">
  </ngx-datatable-column>
  <ngx-datatable-column name="Monthly Representative">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value" prop="monthly_rep_flg">
      <input type="checkbox" [checked]="row.monthly_rep_flg=='Y'" disabled />
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Comparitive BOM">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value" prop="compar_bom_flg">
      <input type="checkbox" [checked]="row.compar_bom_flg=='Y'" disabled />
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="NAMC" prop="namc_cd"></ngx-datatable-column>
  <ngx-datatable-column class="w-50" name="Model Number" prop="mdl_no"></ngx-datatable-column>
  <ngx-datatable-column name="Option" prop="optn_cd"></ngx-datatable-column>
  <ngx-datatable-column name="SAP Material ID" prop="sap_mtrl_id" [width]="200"></ngx-datatable-column>
  <ngx-datatable-column name="Family" prop="fam_cd"></ngx-datatable-column>
  <ngx-datatable-column name="Part Number" prop="part_no"></ngx-datatable-column>
  <ngx-datatable-column name="Part Description" prop="part_desc"></ngx-datatable-column>
  <ngx-datatable-column name="Product Type" prop="prod_typ_desc"></ngx-datatable-column>
  <ngx-datatable-column name="Katashiki" prop="katashiki_cd"></ngx-datatable-column>
  <ngx-datatable-column name="Customer" prop="buyoff_cust_nm"></ngx-datatable-column>
  <ngx-datatable-column name="Destination" prop="dest_ctry_id"></ngx-datatable-column>
  <ngx-datatable-column name="Hikiate Status" prop="hikiate_stat_comb_cd"></ngx-datatable-column>
  <ngx-datatable-column name="S/P" prop="base_optn_typ_cd"></ngx-datatable-column>
  <ngx-datatable-column name="Net Spec" prop="net_spec_cd_comb_txt"></ngx-datatable-column>
  <ngx-datatable-column name="PC Spec" prop="pc_spec_cd_comb_txt"></ngx-datatable-column>
  <ngx-datatable-column name="100% Spec">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value" prop="hundred_pct_optn_flg">
      <input type="checkbox" [checked]="row.hundred_pct_optn_flg=='Y'" disabled />
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name=" Comp PC Spec" prop="compar_pc_spec_cd_comb_txt"></ngx-datatable-column>
  <ngx-datatable-column name="Comp 100% Spec">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value" prop="compar_100_pct_optn_flg">
      <input type="checkbox" [checked]="row.compar_100_pct_optn_flg=='Y'" disabled />
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Extaction Time In" prop="eff_dt"></ngx-datatable-column>
  <ngx-datatable-column name="Extraction Time Out" prop="expr_dt"></ngx-datatable-column>
  <ngx-datatable-column name="Comments" prop="rmrk_txt"></ngx-datatable-column>
  <ngx-datatable-column name="Sap Prod Def Id" prop="rep_prod_def_sap_id"></ngx-datatable-column>
  <ngx-datatable-column name="Error Message" prop="err_msg"></ngx-datatable-column>
  <ngx-datatable-column name="Sap Status" prop="stat_flg"></ngx-datatable-column>
</ngx-datatable>
</span>
<div *ngIf="!accessEnable">
  <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>