import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('Ebom_accessToken')
    })
  };
}
@Injectable({
  providedIn: 'root'
})
export class SelectivityPartRuleServiceService {
  [x: string]: any;


  apiUrl = environment.MastergridapiUrl;
  searchdropdown1UrlApi = environment.Mastersearchdropdown1apiUrl;
  searchdropdown2UrlApi = environment.Mastersearchdropdown2apiUrl;
  searchdropdown3UrlApi = environment.Mastersearchdropdown3apiUrl;
  // searchdropdown4UrlApi = environment.Mastersearchdropdown4apiUrl;
  fileUploadUrl = environment.BussinessgridapiUrl + '/AssumeRole'
  namcSpr = 'getNamcSpr'
  familyByNamcSpr = '/getFamilyByNamcSpr'
  selectivity = '/getSelectivityByFamily'
  selectivityRuleVin = 'getSelectivityRuleVin'
  selectivityRuleRep = 'getSelectivityRuleRep'
  completePartRouting = '/getCmpltPrtRtngSprBySlctvty'
  gridData = '/getSelectivityPartRuleGrid'

  constructor(private http: HttpClient) { }

  //namcSpr
  getNamcSpr(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = `${this.searchdropdown1UrlApi}/${this.namcSpr}`+'?screenNo=NBPC_2230'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //familyByNamcSpr
  getFamilyByNamcSpr(namcId): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let familyParams = new HttpParams().set('namcId', namcId);

    let API_URL = this.searchdropdown2UrlApi + this.familyByNamcSpr + '?namcId=' + namcId+'&screenNo=NBPC_2230'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //selectivityByFamily
  getSelectivityByFamily(famCd): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let selectivityParams = new HttpParams().set('famCd', famCd);
    let API_URL = this.searchdropdown2UrlApi + this.selectivity + '?famCd=' + famCd+'&screenNo=NBPC_2230'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }


  //selectivityRuleVin
  getSelectivityRuleVin(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = `${this.searchdropdown2UrlApi}/${this.selectivityRuleVin}`+'?screenNo=NBPC_2230'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }


  //selectivityRuleVin
  getSelectivityRuleRep(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = `${this.searchdropdown2UrlApi}/${this.selectivityRuleRep}`+'?screenNo=NBPC_2230'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //completePartRouting
  getCompletePartRouting(namcId, famCd, slctvTyp): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let completePartRoutingParams = new HttpParams().set('namcId', namcId)
    //   .append('famCd', famCd)
    //   .append('slctvTyp', slctvTyp);
    let API_URL = this.searchdropdown3UrlApi + this.completePartRouting + '?namcId=' + namcId
      + '&famCd=' + famCd + '&slctvTyp=' + slctvTyp+'&screenNo=NBPC_2230'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //grpi
  chkGrpiCd(body): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = environment.BusinessActionRestapiUrl + '/chkGrpiCd'
    return this.http.post(API_URL, body, httpOptions)
      .pipe(catchError(this.error)
      )
  }

  //grid data
  getGridData(gridData): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let gridParams = new HttpParams().set('namcLglEntityId', gridData.namc)
    //   .append('famCd', gridData.family)
    //   .append('grpiCd', gridData.grpi)
    //   .append('slctvTyp', gridData.selectivity)
    //   .append('cmpltRtgCd', gridData.completePartRouting)
    //   .append('parPartNo', gridData.parentPart)
    //   .append('partNo', gridData.partNumber)
    //   .append('slctvRuleVin', gridData.selectivityRuleVin)
    //   .append('slctvRuleRep', gridData.selectivityRuleRep)
    //   .append('rmrkTxt', gridData.comments)
    //   .append('effDt', gridData.selectivityRuleTimeIn)
    //   .append('exprDt', gridData.selectivityRuleTimeOut)
    //   .append('option', gridData.option)
    //   .append('fileName', gridData.fileName)
    let API_URL = this.apiUrl + this.gridData + '?namcLglEntityId=' + gridData.namc + '&famCd=' + gridData.family
      + '&grpiCd=' + gridData.grpi + '&slctvTyp=' + gridData.selectivity + '&cmpltRtgCd=' + gridData.completePartRouting
      + '&parPartNo=' + gridData.parentPart + '&partNo=' + gridData.partNumber + '&slctvRuleVin=' + gridData.selectivityRuleVin
      + '&slctvRuleRep=' + gridData.selectivityRuleRep + '&rmrkTxt=' + gridData.comments 
      + '&effDt=' + gridData.selectivityRuleTimeIn + '&exprDt=' + gridData.selectivityRuleTimeOut
      + '&option=' + gridData.option + '&GfileName=' + gridData.fileName+'&screenNo=NBPC_2230'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }


  fileUpldData(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    return this.http.get(this.fileUploadUrl+'?screenNo=NBPC_2230'+'&fileName=' +fileName, httpOptions)
      .map((response: any) => response).catch(this.error)
  }


  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage:any = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error.status;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }



} 
