<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Add <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <mat-dialog-content class="mat-typography" (click)="partdescription()">
    <div class="container">
      <span class="fst-italic">Field marked with asterisk ' <span class="text-danger">*</span> ' are mandatory</span>

      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Rule Type<span class="text-danger">*</span>:</label></div>
            <div class=col-8><select aria-label="Default select example" class="datedrop inputtext"
                [(ngModel)]="ruleType" (change)='fetchAddVersionNameBpMar();getChngeVerNm();enableDates()'>
                <option value=""></option>
                <option *ngFor="let ruleType of ruleTypeLst" [value]="ruleType.man_adjstmnt_rule_typ_id">
                  {{ruleType.man_adjstmnt_rule_typ_nm}}
                </option>
              </select></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Version Name<span class="text-danger">*</span>:</label></div>
            <div class="col-8"><select aria-label="Default select example" class="datedrop inputtext"
                [(ngModel)]="versionName" (change)='fetchAddNamcBpMar();getChngeVerNm();enableDates();stdenable();getdate()'
                [disabled]="verhidedata">
                <option value=""></option>
                <option value="production">Production</option>
                <option *ngFor="let versionName of versionNameLst" [value]="versionName.version_name">
                  {{versionName.version_name}}</option>
              </select></div>
          </div>
        </div>
      </div>
      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>BOM Effective From<span class="text-danger">*</span>:</label></div>
            <div class=col-8>

              <mat-form-field class="datedrop" appearance="outline">
                <input matInput [matDatepicker]="inPicker" [disabled]="disableDate" [(ngModel)]="addBomEffFrom"
                  (blur)="dateCheck()"  (dateChange)="fetchAddNamcBpMar()">
                <mat-datepicker-toggle [disabled]="disableDate" matSuffix [for]="inPicker"></mat-datepicker-toggle>
                <mat-datepicker [disabled]="disableDate" #inPicker></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'addBomEffFrom')">clear
                </mat-icon>

              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>BOM Effective To<span class="text-danger">*</span>:</label></div>
            <div class=col-8>
              <mat-form-field class="datedrop" appearance="outline">
                <input matInput [matDatepicker]="outPicker" [disabled]="disableDate" [(ngModel)]="addBomEffTo"
                  (blur)="dateCheck()"  (dateChange)="fetchAddNamcBpMar()">
                <mat-datepicker-toggle [disabled]="disableDate" matSuffix [for]="outPicker"></mat-datepicker-toggle>
                <mat-datepicker [disabled]="disableDate" #outPicker></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'addBomEffTo')">clear
                </mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <!-- row3 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>NAMC<span class="text-danger">*</span>:</label></div>
            <div class="col-8"><select aria-label="Default select example" class="datedrop inputtext" [(ngModel)]="namc"
                (change)='fetchAddFamilyMar();getChngnamc();getAddCompletePartRoutingMar()'>
                <option value=""></option>
                <option *ngFor="let namc of namcLst" [value]="namc.namc_lgl_entity_id">{{namc.namc_cd}}</option>
              </select></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Family<span class="text-danger">*</span>:</label></div>
            <div class="col-8"><select aria-label="Default select example" class="datedrop inputtext"
                [(ngModel)]="family" (change)='fetchAddKatashikiMar();fetchAddGrpiMar();' [disabled]="costhidedata">
                <option value=""></option>
                <option *ngFor="let family of famLst" [value]="family.fam_cd">{{family.fam_cd}}</option>

              </select></div>
          </div>
        </div>
      </div>
      <!-- row4 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>S/P:</label></div>
            <div class="col-8"><select aria-label="Default select example" class="datedrop inputtext" [(ngModel)]="sp"
                [disabled]="hidedata" (change)='fetchAddNetSpecMar()'>
                <option value=""></option>
                <option value="S">S</option>
                <option *ngIf="ruleType != '4'" value="P">P</option>
              </select></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Net Spec <span class="text-danger" *ngIf="!nethidedata">*</span>:</label></div>
            <div class="col-8"><select aria-label="Default select example" class="datedrop inputtext"
                [(ngModel)]="netSpec" [disabled]="nethidedata">
                <option *ngFor="let netSpec of netSpecLst" [value]="netSpec.net_spec_cd_comb_txt">
                  {{netSpec.net_spec_cd_comb_txt}}</option>
              </select></div>
          </div>
        </div>
      </div>
      <!-- row5 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Katashiki/Part:</label></div>
            <div class="col-8"><select aria-label="Default select example" class="datedrop inputtext"
                [(ngModel)]="katashiki" (change)='fetchAddGrpiMar();fetchDestData()' [disabled]="costhidedata">
                <option value=""></option>
                <option *ngFor="let katashiki of katasPartLst" [value]="katashiki.coalesce">
                  {{katashiki.coalesce}}</option>
              </select></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>GRPI<span class="text-danger">*</span>:</label></div>
            <div class="col-8">
              <select aria-label="Default select example" class="datedrop inputtext" [(ngModel)]="grpi"
                [disabled]="costhidedata" *ngIf="katashiki.length != 10">
                <option value=""></option>
                <option *ngFor="let grpi of grpiLst" [value]="grpi.grpi_cd">{{grpi.grpi_cd}}</option>
              </select>
              <input type="text" *ngIf="katashiki.length == 10" class="datedrop inputtext"
                oninput="this.value = this.value.toUpperCase()" [(ngModel)]="grpi" />
            </div>
          </div>
        </div>
      </div>
      <!-- row6 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Dest<span class="text-danger" *ngIf="!desthide">*</span>:</label></div>
            <div class="col-8"><select multiple class="multiple" [(ngModel)]="dest" aria-label="Default select example"
                [disabled]="desthide">
                <option value="All">All</option>
                <option value={{dest.dest_ctry_id}} *ngFor="let dest of destLst">{{dest.dest_ctry_id}} </option>
              </select></div>
          </div>
        </div>
      </div>
      <!-- row7 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Part Number<span class="text-danger">*</span>:</label></div>
            <div class="col-8"><input type="text" class="datedrop inputtext" [(ngModel)]="partNumber"
                (keyup.enter)="partdescription()" /></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Part Description:</label></div>
            <div class="col-8"><input type="text" class="datedrop inputtext" [(ngModel)]="partDesc" disabled></div>
          </div>
        </div>
      </div>
      <!-- row8 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Qty Per Parent<span class="text-danger" *ngIf="!qtyhidedata">*</span>:</label>
            </div>
            <div class="col-8"><input type="number" pattern="/^[0-9]+(\.[0-9]{1,2})?$/" class="inputtext" [(ngModel)]="qtyPerParent"
               [maxlength]="15" class="datedrop" [disabled]="qtyhidedata">
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Complete Part Routing<span class="text-danger"
                  *ngIf="!exchidedata">*</span>:</label></div>
            <div class="col-8"><select aria-label="Default select example" class="datedrop"
                [(ngModel)]="completePartRouting" [disabled]="exchidedata">
                <option value=""></option>
                <option *ngFor="let completePartRouting of completePartRoutingLst"
                  [value]="completePartRouting.cmplt_rtg_cd">
                  {{completePartRouting.cmplt_rtg_cd}}</option>

              </select></div>
          </div>
        </div>
      </div>
      <!-- row9 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>ACT Unit Cost<span class="text-danger" *ngIf="!acthidedata">*</span>:</label>
            </div>
            <div class="col-8"><input type="text" class="inputtext" (keypress)="isNumber(event)"
                [(ngModel)]="actUnitCost" [maxLength]="15" [disabled]="acthidedata" /></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>ACT Currency Code<span class="text-danger" *ngIf="!exchidedata || !ACTCurrency">*</span>:</label>
            </div>
            <div class=col-8><select aria-label="Default select example" class="datedrop" [(ngModel)]="actCurrencyCode"
                [disabled]="exchidedata || ACTCurrency">
                <option value="USD">USD</option>
                <option *ngFor="let actCurrencyCode of actCurrencyCodeLst" [value]="actCurrencyCode">{{actCurrencyCode}}
                </option>

              </select></div>
          </div>
        </div>
      </div>
      <!-- row10 -->
      <div class="row rowClass">
        <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
        <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review" rows="4" cols="50"
            [(ngModel)]="comments">
        </textarea>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-button (click)="addmanAdjRule()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">

  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>
