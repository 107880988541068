import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionAddDialogComponent } from '../version-add-dialog/version-add-dialog.component';
import { VersionEditDialogComponent } from '../version-edit-dialog/version-edit-dialog.component';
import { VersionsComponent } from '../versions/versions.component';
import {VersionDeleteDialogComponent} from '../version-delete-dialog/version-delete-dialog.component'
import {ReferenceModule} from '../reference.module';


@NgModule({
  declarations: [
    VersionsComponent,
    VersionAddDialogComponent,
    VersionEditDialogComponent,
    VersionDeleteDialogComponent,
  ],
  imports: [
    CommonModule,
    ReferenceModule,
  ]
})
export class VersionModule { }
