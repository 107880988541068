<span *ngIf="accessEnable">
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>

<div class="alert alert-success alert-dismissible " *ngIf="successMessage!=''" role="alert">
  {{successMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>

<mat-accordion class="example-headers-align">
  <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>

    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Search</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-form-field>
      <mat-label>Approval Status</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="approvalStatus" name="approvalStatus">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let approvalStatus of approvalStatusLst" [value]="approvalStatus.bom_ver_apprvl_stat_typ">
          {{approvalStatus.bom_ver_apprvl_stat_typ}}
        </mat-option>
      </mat-select>
    </mat-form-field>

      <mat-form-field>
        <mat-label>Version Type </mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="versionType" name="versionType"
          (selectionChange)='getVerQualByType()'>
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let versionType of versionTypeLst" [value]="versionType.bom_ver_typ_id">
            {{versionType.bom_ver_typ_cd}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Version Qualifier</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="versionQual" name="versionQual"
          (selectionChange)='getversionRevision()'>
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let versionQual of versionQualLst" [value]="versionQual.bom_ver_qual_id">
            {{versionQual.bom_ver_qual_cd}}</mat-option>
        </mat-select>
      </mat-form-field>

    <mat-form-field>
      <mat-label>Version Revision</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="versionRev" name="versionRev">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let versionRev of versionRevLst" [value]="versionRev.bom_ver_rev_nbr">
          {{versionRev.version_revision}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Priority</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="priority" name="priority">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let priority of priorityLst" [value]="priority.ver_priority_typ_id">
          {{priority.ver_priority_typ_cd}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Volume Count Status</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="volCntStatus" name="volCntStatus">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let volCntStatus of volCntStatusLst" [value]="volCntStatus.vol_cnt_calc_stat_typ_id">
          {{volCntStatus.vol_cnt_calc_stat_typ_nm}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="picker">
      <mat-label>Bom Effectivity Date</mat-label>
      <input matInput [matDatepicker]="inPicker" [(ngModel)]="bomEffDate" >
      <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
      <mat-datepicker #inPicker></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'bomEffDate')">clear</mat-icon>
    </mat-form-field>
    <mat-form-field class="picker">
      <mat-label>Cost Effectivity Date</mat-label>
      <input matInput [matDatepicker]="outPicker" [(ngModel)]="costEffDate">
      <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
      <mat-datepicker #outPicker></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'costEffDate')">clear</mat-icon>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Comments</mat-label>
      <input matInput [(ngModel)]="comments">
    </mat-form-field>



    <button mat-button style="float: right;" (click)="resetAll('initial')">Reset</button>
    <button mat-button style="float: right;" (click)="search()">Search</button>

  </mat-expansion-panel>
</mat-accordion>

<div class="d-flex">
  <button mat-raised-button (click)="openAddDialog()"> Add </button>
  <button mat-raised-button (click)="openEditDialog()">Edit </button>
  <button mat-raised-button (click)="openDeleteDialog()">Delete </button>
  <button mat-raised-button (click)="close()">Close </button>
  <button mat-raised-button (click)="exportFile()" class="ms-auto"> Export To Excel </button>
</div>

<!-- <mat-card> -->
<ngx-datatable #myTable class="material striped" [rows]="rows" [columnMode]="ColumnMode.force" [headerHeight]="45"
  [footerHeight]="30" rowHeight="30" [limit]="limitSelected" [selected]="selected"
  [selectionType]="SelectionType.checkbox" [selectAllRowsOnPage]="false" [displayCheck]="displayCheck"
  (activate)="onActivate($event)" (select)="onSelect($event)" [scrollbarH]="true"
  [ngClass]="{'hideerror': showdata, 'showerror': !showdata }">
  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
      let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
      <div class="page-count">
        {{selectedCount}} selected / {{rowCount}} total
      </div>
      <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
        (change)="table.onFooterPage($event)">
      </datatable-pager>
      <div class="test">
        <select [(ngModel)]="limitSelected">
          <option>50</option>
          <option>100</option>
          <option>500</option>
        </select>
        <div class="float-right records">View {{(offset * pageSize)+1}} - {{(rowCount>
          (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
          {{(rowCount)}}

          </div>
        </div>
      </ng-template>
    </ngx-datatable-footer>
    <ngx-datatable-column [width]="10" [sortable]="false" [canAutoResize]="false" [draggable]="false"
      [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true">
    </ngx-datatable-column>
    <ngx-datatable-column class="w-50" name="Approval Status" prop="bom_ver_apprvl_stat_typ"></ngx-datatable-column>
    <ngx-datatable-column name="Version Type" prop="bom_ver_typ_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Version Qualifier" prop="bom_ver_qual_cd" [width]="200"></ngx-datatable-column>
    <ngx-datatable-column name="Version Revision" prop="version_revision"></ngx-datatable-column>
    <ngx-datatable-column name="Priority" prop="ver_priority_typ_cd"></ngx-datatable-column>
    <ngx-datatable-column name="BoM Effectivity Date" prop="bom_eff_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Cost Effectivity Date" prop="cost_eff_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Effective In Date" prop="eff_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Effective Out Date" prop="expr_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Volume Count Status" prop="vol_cnt_calc_stat_typ_nm"></ngx-datatable-column>
    <ngx-datatable-column name="Volume Last Processed Date" prop="vol_cnt_last_update_ts"></ngx-datatable-column>
    <ngx-datatable-column name="Comments" prop="rmrk_txt"></ngx-datatable-column>
    <ngx-datatable-column name="Volume Processed to Reports" prop="dm_load_ts"></ngx-datatable-column>
  </ngx-datatable>
</span>
<div *ngIf="!accessEnable">
  <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>
