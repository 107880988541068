<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Add<mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>

  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>

  <mat-dialog-content class="mat-typography" >
    <div class="container">
      <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are mandatory</span>
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Family<span class="text-danger">*</span>:</label></div>
            <div class=col-8><select class="picker" [(ngModel)]="fFamily" (change)='getNamcData()'>
                <option value=""></option>
                <option *ngFor="let data of familyArray" [(ngValue)]="data.fam_cd">{{data.fam_cd}}</option>
              </select></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Project:</label></div>
            <div class=col-8><select class="picker" [(ngModel)]="fProject">
                <option value=""></option>
                <option *ngFor="let data of projectArray" [(ngValue)]="data.proj_cd">{{data.proj_cd}}</option>
              </select></div>
          </div>
        </div>
      </div>
      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Model Year<span class="text-danger">*</span>:</label></div>
            <div class=col-8><select class="picker" [(ngModel)]="fModelYear">
                <option value=""></option>
                <option *ngFor="let data of modelArray" [(ngValue)]="data.mdl_yr">{{data.mdl_yr}}</option>
              </select></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Model Change Type:</label></div>
            <div class=col-8><select class="picker" [(ngModel)]="fModelChangeType">
                <option value=""></option>
                <option *ngFor="let data of modelChngTypArray" [(ngValue)]="data.proj_typ_cd">{{data.proj_typ_cd}}
                </option>
              </select></div>
          </div>
        </div>
      </div>
      <!-- row3 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>NAMC<span class="text-danger">*</span>:</label></div>
            <div class=col-8><select class="picker" [(ngModel)]="fNamc">
                <option value=""></option>
                <option *ngFor="let data of namcArray" [(ngValue)]="data.namc_lgl_entity_id">{{data.namc_cd}}</option>
              </select></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>SSN:</label></div>
            <div class=col-8><input type="text" class="inputtext" [maxLength]="2"
                oninput="this.value = this.value.toUpperCase()" [(ngModel)]="fSsn"> </div>
          </div>
        </div>
      </div>
      <!-- row4 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Hikiate Status:</label></div>
            <div class=col-8><select id="hikiSelect" [(ngModel)]="dummyHikiateLst" class="multiple" multiple
                aria-label="Default select example">
                <option value=""></option>
                <option value={{hikiate.hikiate_stat_cd}} *ngFor="let hikiate of hikiateLstFromDB">
                  {{hikiate.hikiate_stat_cd}}</option>
              </select></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <button class="btn" (click)="moveHikiForward()">>></button><br>
              <button class="btn" (click)="moveHikiBackward()">
                << </button>
            </div>
            <div class=col-8><select class="multiple" multiple [(ngModel)]="dummyHikiateLstToDB"
                aria-label="Default select example">
                <option value={{selectedHikiate}} *ngFor="let selectedHikiate of selectedHikiateLst">{{selectedHikiate}}
                </option>
              </select></div>
          </div>
        </div>
      </div>

      <!-- row5 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>Prod Time In:</label></div>
            <div class=col-8>
              <mat-form-field class="picker inputtext" appearance="outline">
                <input matInput [matDatepicker]="inProdPicker" [(ngModel)]="fProdDateIn">
                <mat-datepicker-toggle matSuffix [for]="inProdPicker"></mat-datepicker-toggle>
                <mat-datepicker #inProdPicker></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'fProdDateIn')">clear
                </mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Prod Time Out:</label></div>
            <div class=col-8>
              <mat-form-field class="picker inputtext" appearance="outline">
                <input matInput [matDatepicker]="outProdPicker" [(ngModel)]="fProdDateOut">
                <mat-datepicker-toggle matSuffix [for]="outProdPicker"></mat-datepicker-toggle>
                <mat-datepicker #outProdPicker></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'fProdDateOut')">clear
                </mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <!-- row5 -->

      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>Generation Id:</label></div>
            <div class=col-8><select aria-label="Default select example" class="picker" [(ngModel)]="fGeneration"
                aria-label="Default select example">
                <option value=""></option>
                <option *ngFor="let data of generationIdArray" [(ngValue)]="data.gnrt_id">{{data.gnrt_nm}}</option>
              </select></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Line Off Plant:</label></div>
            <div class=col-8><input type="text" class="inputtext" [maxLength]="5"
                oninput="this.value = this.value.toUpperCase()" [(ngModel)]="fLineOffPlant"> </div>
          </div>
        </div>
      </div>
      <!-- row6 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>Extraction Time In<span class="text-danger">*</span>:</label></div>
            <div class=col-8>
              <mat-form-field class="picker" appearance="outline">
                <input matInput [matDatepicker]="inPicker" [(ngModel)]="fEffDateIn">
                <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
                <mat-datepicker #inPicker></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'fEffDateIn')">clear
                </mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Extraction Time Out:</label></div>
            <div class=col-8>
              <mat-form-field class="picker" appearance="outline">
                <input matInput [matDatepicker]="outPicker" [(ngModel)]="fEffDateOut">
                <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
                <mat-datepicker #outPicker></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'fEffDateOut')">clear
                </mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <!-- row7 -->
      <div class="row rowClass">
        <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
        <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review" rows="4" cols="50"
            [(ngModel)]="fComments">
            </textarea>
        </div>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="center" >
    <button mat-button cdkFocusInitial (click)="updateFamProNamc()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</ng-container>

<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">

  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>