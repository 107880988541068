import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { DialogData } from '../dialog-data/dialog-data';
import { NgxSpinnerService } from "ngx-spinner";
import { RepUnitCopyaddDialogService } from "./rep-unit-copyadd-dialog.service";

@Component({
  selector: 'app-rep-units-copyadd-dialog',
  templateUrl: './rep-units-copyadd-dialog.component.html',
  styleUrls: ['./rep-units-copyadd-dialog.component.css']
})
export class RepUnitsCopyaddDialogComponent implements OnInit {
  [x: string]: any;

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //errormessage
  errorMessage: string = "";

  //ngModel Values
  copyMonthlyRep: any = "";
  copySapMtrlId: any = "";
  copyNamc: any = "";
  copyFamily: any = "";
  copyPart: any = "";
  copyPartDesc: any = "";
  copyRelKatashiki: any = "";
  copyProdType: any = "";
  copyExtTimeIn: any = "";
  copyExtTimeOut: any = "";
  copyComments: any = "";
  copyGrpi: any = "";
  screen = "unit"
  //lst
  namcLst = [];
  famcdLst = [];
  katashikiLst = [];
  prodTypLst = [];
  grpitext: boolean = false;
  //response from backend after insert/update records
  response: any;

  //to main page
  message = "Record Added Successfully";

  spinnerMessage = "Please Wait...";

  constructor(public dialogRef: MatDialogRef<RepUnitsCopyaddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private SpinnerService: NgxSpinnerService,
    private repCopyAddService: RepUnitCopyaddDialogService) { }

  async ngOnInit() {
    this.SpinnerService.show();
    console.log(this.data);
    let a = await this.getNamc();
    let b = await this.getProdTypData();
    let namc_id;
    let prodtyp_id;
    if (a && b) {
      console.log(this.data);

      for (let i = 0; i < this.namcLst.length; i++) {
        if (this.data.namc == this.namcLst[i].namc_cd) {
          namc_id = this.namcLst[i].namc_lgl_entity_id
        }
      }
      for (let i = 0; i < this.prodTypLst.length; i++) {
        if (this.data.prodType == this.prodTypLst[i].prod_typ_desc) {
          prodtyp_id = this.prodTypLst[i].prod_typ_cd
        }
      }
    }
    this.copyMonthlyRep = this.data.monthlyRep;
    this.copySapMtrlId = this.data.sapMatId;
    this.copyNamc = namc_id;
    this.copyFamily = this.data.family;
    this.copyPart = this.data.part;
    this.copyPartDesc = this.data.partDesc;
    this.copyRelKatashiki = this.data.katashiki;
    this.copyProdType = prodtyp_id;
    this.copyExtTimeIn = this.data.extractionTimeIn;
    this.copyExtTimeOut = this.data.extractionTimeOut;
    this.copyComments = this.data.comments;
    this.getgrpi();
    this.copyGrpi = this.data.grpi
    if (localStorage.getItem('partnumbercpypopup')) {
      localStorage.removeItem('partnumbercpypopup')
    }
    this.getFamily();



    this.getKatashikiByFamCd();
  }
  getNamc() {
    return new Promise(res => {
      this.repCopyAddService.getNamc().subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.namcLst = data.payload;
        res(true)
      });
    })

  }
  getProdTypData() {
    return new Promise(res => {
      this.repCopyAddService.getProdType().subscribe(data => {
        console.log(data)
        this.prodTypLst = data.payload;
        res(true)
      });
    })
  }

  getFamily(): void {
    if (this.copyNamc == undefined) {
      this.copyNamc = ""
    }
    if (this.copyNamc != "") {
      this.SpinnerService.show();
      this.repCopyAddService.getFamily(this.copyNamc, this.screen).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.famcdLst = data.payload;
      });
    } else {
      this.famcdLst = []
      this.copyFamily = ""
    }
  }

  getKatashikiByFamCd(): void {
    if (this.copyFamily == undefined) {
      this.copyFamily = ""
    }
    this.SpinnerService.show();
    this.repCopyAddService.getKatashikiByFamCd(this.copyFamily).subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.katashikiLst = data.payload;
    });

  }
  //onChangeMethod
  getFamCdByNamc(): void {
    this.getFamily();
  }

  partdescription(partdata) {
    this.errorMessage = "";
    console.log(this.copyPart, partdata)
    if (this.copyPart == "") {
      console.log('1')
      this.copyPartDesc = ""
    }
    else if (this.copyPart != "") {
      console.log('2')
      if (this.copyPartDesc == "") {
        console.log('3')
        this.repCopyAddService.getPartDesc(this.copyPart).subscribe(data => {
          console.log(data);
          if (data.payload) {
            console.log('4')
            this.copyPartDesc = data.payload[0].part_desc;
            this.errorMessage = "";
            localStorage.setItem("partnumbercpypopup", this.copyPart);

          } else {
            console.log('5')
            this.errorMessage = "Part Number <" + this.copyPart + "> Invalid"
            this.copyPart = ""
            this.copyPartDesc = ""
          }


        });
      }
      else if (this.copyPartDesc != "") {
        console.log('6', this.copyPart != localStorage.getItem('partnumbercpypopup'))
        if (this.copyPart != localStorage.getItem('partnumbercpypopup')) {
          console.log('9')
          this.repCopyAddService.getPartDesc(this.copyPart).subscribe(data => {
            console.log(data)
            if (data.payload) {
              console.log('7')
              this.copyPartDesc = data.payload[0].part_desc;
              this.errorMessage = ""
              localStorage.setItem("partnumbercpypopup", this.copyPart);
            } else {
              console.log('8')
              this.errorMessage = "Part Number <" + this.copyPart + "> Invalid"
              this.copyPart = ""
              this.copyPartDesc = ""
            }
          });
        } else {
          console.log('9')
          this.repCopyAddService.getPartDesc(this.copyPart).subscribe(data => {
            console.log(data)
            if (data.payload) {
              console.log('7')
              this.copyPartDesc = data.payload[0].part_desc;
              this.errorMessage = ""
              localStorage.setItem("partnumbercpypopup", this.copyPart);
            } else {
              console.log('8')
              this.errorMessage = "Part Number <" + this.copyPart + "> Invalid"
              this.copyPart = ""
              this.copyPartDesc = ""
            }
          });
        }
      }
    }

  }

  getgrpi() {
    console.log(this.copyProdType)
    if (this.copyProdType == "AC") {
      this.grpitext = true;
    } else if (this.copyProdType == "A") {
      this.grpitext = true;
    } else {
      this.grpitext = false;
      this.copyGrpi = ""
    }
  }

  //save
  submitRepUnit(): void {

    if (!this.copyNamc || !this.copyFamily || !this.copyPart || !this.copyExtTimeIn || !this.copyProdType) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else if ((this.grpitext == true) && !this.copyGrpi) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else if (this.copyExtTimeIn && this.copyExtTimeOut &&
      this.pipe.transform(this.copyExtTimeIn, 'yyyy-MM-dd') > this.pipe.transform(this.copyExtTimeOut, 'yyyy-MM-dd')) {
      this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";
    }
    else {
      this.errorMessage = "";
      let body = {
        part: this.copyPart
      }
      this.SpinnerService.show();
      this.repCopyAddService.PartNumberValidation(body).subscribe(data => {
        console.log(data);
        this.response = data.payload;
        if (this.response[0].result == 'success') {
          this.AddExistsCheck();
        } else if (this.response[0].result == 'Partnum_Invalid') {
          this.SpinnerService.hide();
          this.errorMessage = 'Part Number Invalid';
        } else if (this.response[0].result == 'Record Already Exists') {
          this.SpinnerService.hide();
          // this.dialogRef.close(this.response[0].result);
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        console.log(err);
        this.SpinnerService.hide();
      });

    }

  }
  AddExistsCheck() {
    let fileName= localStorage.getItem('Filename')
    let body = {
      namc: this.copyNamc,
      family: this.copyFamily,
      part: this.copyPart,
      grpiCd: this.copyGrpi,
      prodtyp: this.copyProdType,
      "screenNo":"NBPC_9355",
      "fileName" :fileName
    }
    this.SpinnerService.show();
    this.repCopyAddService.ExistsRepUnitData(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.CopyAddRepUnit();
      } else if (this.response[0].result == 'Invalid') {
        this.SpinnerService.hide();
        this.errorMessage = 'Part Number Invalid';
      } else if (this.response[0].result == 'Data Already Exists') {
        this.SpinnerService.hide();
        this.errorMessage = 'Record Entered already Exists!';
      }
    }, err => {
      console.log(err);
      this.SpinnerService.hide();
    });
  }
  CopyAddRepUnit() {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    let mnthly_flg;
    if (this.copyExtTimeOut == "") {
      this.copyExtTimeOut = "9999-12-31"
    }
    if (this.copyMonthlyRep == true) {
      mnthly_flg = 'Y'
    }
    else {
      mnthly_flg = 'N'
    }
    this.errorMessage = "";
    let indateFormat = ''; let outdateFormat = '';
    if (this.copyExtTimeIn != "") {
      let d1 = moment(this.copyExtTimeIn).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.copyExtTimeIn = date
    }
    if (this.copyExtTimeOut != "") {
      let d1 = moment(this.copyExtTimeOut).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.copyExtTimeOut = date
    }
    let cmts;
    if (this.copyComments != undefined) {
      cmts = this.copyComments.replaceAll("'", "''");
    } else {
      cmts = this.copyComments
    }
    let body = {
      "monRepFlg": mnthly_flg,
      "sapMtrlId": this.copySapMtrlId,
      "namc": this.copyNamc,
      "family": this.copyFamily,
      "part": this.copyPart,
      "partDesc": this.copyPartDesc,
      "katashiki": this.copyRelKatashiki,
      "prodTyp": this.copyProdType,
      "extTimeIn": indateFormat,
      "extTimeOut": outdateFormat,
      "comment": cmts,
      "grpiCode": this.copyGrpi,
      "screenNo":"NBPC_9355",
      "fileName" :fileName
      
      
    }
    this.SpinnerService.show();
    this.repCopyAddService.addRepUnitData(body).subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.dialogRef.close(this.response[0].result);
      } else {
        this.errorMessage = "Data not Added";
      }
    })
  }

  cancel(): void {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }


  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'copyExtTimeIn') {
      this.copyExtTimeIn = "";
    } else if (name == 'copyExtTimeOut') {
      this.copyExtTimeOut = "";
    }
  }
}
