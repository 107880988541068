import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { PcSpecsExclusionListAddDialogService } from './pc-specs-exclusion-list-add-dialog.service';


@Component({
  selector: 'app-pc-specs-exclusion-list-add-dialog',
  templateUrl: './pc-specs-exclusion-list-add-dialog.component.html',
  styleUrls: ['./pc-specs-exclusion-list-add-dialog.component.css']
})
export class PcSpecsExclusionListAddDialogComponent implements OnInit {

  [x: string]: any;
  //to main page
  message = "Records updated successfully";
  errorMessage = "";
  successMessage = "";

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //ngModelValues
  ePcSpecPosition: any;
  eHikiateStatus: any;
  eEffectiveFrom: any;
  eEffectiveTo: any;
  eExchangeRate: any = "";
  eComments: any = "";
  spinnerMessage = "Please Wait...";

  constructor(public dialogRef: MatDialogRef<PcSpecsExclusionListAddDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public PcSpecsExclusionListAddDialogService: PcSpecsExclusionListAddDialogService,
    private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
  }

  closeAlert() {
    this.errorMessage = "";
  }

  save() {
    let fileName= localStorage.getItem('Filename')
    console.log(this.ePcSpecPosition);
    if (!this.ePcSpecPosition || !this.eEffectiveFrom || !this.eEffectiveTo) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else if (this.eEffectiveFrom && this.eEffectiveTo && this.pipe.transform(this.eEffectiveFrom, 'yyyy-MM-dd') > this.pipe.transform(this.eEffectiveTo, 'yyyy-MM-dd')) {

      this.errorMessage = "Effective To should be greater than or equal to Effective From Date";
    }
    else {
      this.errorMessage = "";
      let indateFormat = ''; let outdateFormat = '';
      if (this.eEffectiveFrom != "") {
        let d1 = moment(this.eEffectiveFrom).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.eEffectiveFrom = date
      }
      if (this.eEffectiveTo != "") {
        let d1 = moment(this.eEffectiveTo).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.eEffectiveTo = date
      }
      let body = {
        'pcSpecPosnCd': this.ePcSpecPosition,
        'effDt': indateFormat,
        'exprDt': outdateFormat,
        "screenNo":"NBPC_2271",
        "fileName" :fileName
      }
      this.SpinnerService.show();
      this.PcSpecsExclusionListAddDialogService.existsCheckaddPcSpecs(body).subscribe(data => {
        this.response = data.payload;
        this.SpinnerService.hide();
        if (this.response[0].result == 'Data Already Exists') {
          this.errorMessage = "Data Already Exists";
          this.SpinnerService.hide();
        } else if (this.response[0].result == 'success') {
          this.AddPcSpec();
        } else if (this.response[0].result == 'Invalid') {
          this.errorMessage = "Data Invalid";
          this.SpinnerService.hide();
        }
      }, err => {
        this.SpinnerService.hide();
      });
    }
  }

  AddPcSpec() {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    let indateFormat = ''; let outdateFormat = '';
    if (this.eEffectiveFrom != "") {
      let d1 = moment(this.eEffectiveFrom).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.eEffectiveFrom = date
    }
    if (this.eEffectiveTo != "") {
      let d1 = moment(this.eEffectiveTo).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.eEffectiveTo = date
      // this.effDateOut = this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')
    }
    let cmts;
    if (this.eComments!= undefined) {
      cmts = this.eComments.replaceAll("'", "''");
    } else {
      cmts = this.eComments
    }
    let body = {
      'pcSpecPosnCd': this.ePcSpecPosition,
      'hikiateStatFlg': this.eHikiateStatus == true ? 'Y' : 'N',
      'effDt': indateFormat,
      'exprDt': outdateFormat,
      'rmrkTxt': cmts,
      "screenNo":"NBPC_2271",
      "fileName" :fileName
    }
    this.SpinnerService.show();
    this.PcSpecsExclusionListAddDialogService.addPcSpecs(body).subscribe(data => {
      this.response = data.payload;
      this.SpinnerService.hide();
      if (this.response[0].result == 'success') {
        this.dialogRef.close(this.response[0].result);
      } else {
        this.errorMessage = this.response[0].result;
      }
    }, err => {
      this.SpinnerService.hide();
    });
  }
  cancel() {

    this.message = "";

    this.dialogRef.close({ event: 'Cancel', data: this.message });

  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'eEffectiveFrom') {
      this.eEffectiveFrom = "";
    } else if (name == 'eEffectiveTo') {
      this.eEffectiveTo = "";
    }
  }

}
