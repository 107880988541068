import { Component, OnInit,Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import { NgxSpinnerService } from "ngx-spinner"; 

@Component({
  selector: 'app-hundered-specifications-view-dialog',
  templateUrl: './hundered-specifications-view-dialog.component.html',
  styleUrls: ['./hundered-specifications-view-dialog.component.css']
})
export class HunderedSpecificationsViewDialogComponent implements OnInit {

  // ngModelValues
  viewnamc:any;
  viewfamily:any;
  viewkatashiki:any;
  viewdestination:any;
  viewhikiate:any;
  viewpcSpec:any;
  viewtimeInDt:any;
  viewtimeOutDt:any;
  viewcomments:any;
  viewvdwEffIn:any;
  viewvdwEffOut:any;
  viewhundPercent:any; 
  spinnerMessage="Please Wait...";

  constructor(private SpinnerService: NgxSpinnerService,@Inject(MAT_DIALOG_DATA) public data: DialogData) { }
  index:any;
  rows = [];
  ngOnInit(): void {
    this.viewnamc= this.data.namc;
    this.viewfamily= this.data.family;
    this.viewkatashiki= this.data.katashiki;
    this.viewdestination= this.data.dest;
    this.viewhikiate= this.data.hikiate;
    this.viewpcSpec= this.data.pcSpec;
    this.viewtimeInDt= this.data.timeInDt;
    this.viewtimeOutDt= this.data.timeOutDt;
    this.viewcomments= this.data.comments;
    this.viewvdwEffIn= this.data.vdwEffIn;
    this.viewvdwEffOut= this.data.vdwEffOut;
    this.viewhundPercent=  this.data.hundPercent;     
  }

}
