import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { GlobalService } from '../global.service';
import { DialogData } from '../dialog-data/dialog-data';
import { MatDialog } from '@angular/material/dialog';
import { ManualJobTriggerService } from "./manual-job-trigger.service";
import { ManualJobTriggerEditDialogComponent } from '../manual-job-trigger-edit-dialog/manual-job-trigger-edit-dialog.component';


@Component({
  selector: 'app-manual-job-trigger',
  templateUrl: './manual-job-trigger.component.html',
  styleUrls: ['./manual-job-trigger.component.css']
})
export class ManualJobTriggerComponent implements OnInit {

  //alertMessage
  errorMessage: string = '';
  successMessage: string = '';
  jobName
  status
  tempstatus
  Description=''
  spinnerMessage = ""
  rows=[]
  temprow=[]
  ColumnMode = ColumnMode;
  selected = [];
  private _SelectionType = SelectionType;
  sameLengthRows: boolean = false;
  roleAccess: any = []
  accessEnable: boolean;
  Roledata: any = [];
  jobNameDropdown
  jobStatusDropdown
  tempjobStatusDropdown=[]
  public get SelectionType() {
    return this._SelectionType;
  }
  public set SelectionType(value) {
    this._SelectionType = value;
  }
  limitSelected: any = 50;
  @ViewChild('myTable') table;

  showelement = false
  showdata: boolean = true;
  step = 0;

  constructor(private SpinnerService: NgxSpinnerService, public globalrole: GlobalService, private dialogData: DialogData, public dialog: MatDialog, public service :ManualJobTriggerService) { }

  ngOnInit(): void {
    this.userroleAccessfn()
    this.SpinnerService.show()
    this.search("initial")
    this.service.manualJobTrigger().subscribe(data=>{
      console.log(data); 
      this.jobNameDropdown= data.payload[0]
      this.tempjobStatusDropdown= data.payload[1]
      for (let i = 0; i < this.tempjobStatusDropdown.length; i++) {
        if(this.tempjobStatusDropdown[i].jobmstatus==='Y') {
          this.tempjobStatusDropdown[i].jobmstatus='Yes'
        } else if(this.tempjobStatusDropdown[i].jobmstatus==='N'){
          this.tempjobStatusDropdown[i].jobmstatus='No'
        }      
      }
      this.jobStatusDropdown=this.tempjobStatusDropdown
      console.log( this.jobNameDropdown,this.jobStatusDropdown);
      this.SpinnerService.hide()    
    }, err => {    
      this.SpinnerService.hide();
  });   

  }
  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  resetAll(data) {
    this.jobName = ''
    this.showelement = false
    this.status = ''
    this.tempstatus=''
    this.Description = ''
    this.closeAlert()
  }
  search(data) {
  if(data!=='initial'){
    this.closeAlert()
    let body
    if(this.jobName==undefined){
      this.jobName=''
    }
    if(this.status==undefined){
      this.tempstatus=''
    }else if(this.status=='YES'){
      // this.status='Y'
      this.tempstatus='Y'
    }else if(this.status=='NO'){
      // this.status='N'
      this.tempstatus='N'
    }
    if(this.Description==undefined){
      this.Description=''
    }
    body={
      jobName: this.jobName,
      jobStatus:this.tempstatus,
      Description:this.Description
    }
    this.SpinnerService.show()
    this.service.manualJobGrid(body).subscribe(data=>{
      console.log(data.payload); 
      this.temprow=data.payload   
      for (let i = 0; i < this.temprow.length; i++) {
        if(this.temprow[i].job_status==='Y') {
          this.temprow[i].job_status='Yes'
        } else if(this.temprow[i].job_status==='N'){
          this.temprow[i].job_status='No'
        }      
      }
      this.rows=this.temprow  
      this.SpinnerService.hide()   
    },err=>{
      this.SpinnerService.hide()
      this.rows=[]
      this.errorMessage = "No Records";
    })
  }  else{
    this.SpinnerService.show()
    const body={
      jobName: '',
      jobStatus:'',
      Description:''
    }
    this.service.manualJobGrid(body).subscribe(data=>{
      console.log(data.payload); 
      this.temprow=data.payload   
      for (let i = 0; i < this.temprow.length; i++) {
        if(this.temprow[i].job_status==='Y') {
          this.temprow[i].job_status='Yes'
        } else if(this.temprow[i].job_status==='N'){
          this.temprow[i].job_status='No'
        }      
      }
      this.rows=this.temprow
      this.SpinnerService.hide()   
    },err=>{
      this.SpinnerService.hide()
      this.rows=[]
      this.errorMessage = "No Records";
    })
  }}

 
  onSelect({ selected }) {
    this.selected = selected;
    if (this.selected.length == selected.length) {
      this.sameLengthRows = true
    }
    if (this.selected.length == 0) {
      this.sameLengthRows = false
    }
    console.log('Select Event', selected, this.selected.length);

    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
  }
  onActivate(event) {
    // console.log('Activate Event', event);
  }
  displayCheck(row) {
    return row.job_status=='Yes'?false:true;
  }

  singleSelectCheck(row: any) {
    return this.selected.indexOf(row) === -1;
  }

  opentriggrtDialog(): void {
    console.log(this.selected, this.selected.length)
    this.errorMessage = "";
    this.successMessage = "";
    console.log(this.roleAccess,"nandhu")      
      if (this.selected.length == 1) {
        this.errorMessage = '';
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(ManualJobTriggerEditDialogComponent, {
          disableClose: true,
          data: this.dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed',result);
          if (result == 'success') {
            this.successMessage = "Records Updated Successfully"
            this.selected = []
          }
          else if (result.data == 'ECS is successfully Triggered') {
            this.successMessage = 'Job has been triggered successfully';
            this.globalrole.goToTop();
            this.selected = []
            this.search('initial')
          }
          else if(result.data == 'ECS is already Running'){
            this.errorMessage = 'Job is already Running';
            this.globalrole.goToTop();
          }
          else if(result.data == 'ETL Job is Running'){
            this.errorMessage = 'ETL Job is Running ';
            this.globalrole.goToTop();
          }
          else if(result.data == 'Something went wrong please contact support people'){
            this.errorMessage = 'Something went wrong please contact support people';
            this.globalrole.goToTop();
          }
        });
      }
      else {
        console.log("inside else");
        
        if (this.sameLengthRows == true) {
          this.errorMessage = "Please select a row to Tigger"
          this.globalrole.goToTop();
        }
        if (this.sameLengthRows == false) {
          this.errorMessage = "Please select a row to Tigger"
          this.globalrole.goToTop();
        }
      }
 
  }
  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.log(res)
        this.Roledata = res;
      })
      console.log(this.Roledata);
      if (this.Roledata.length != 0) {
        this.Roledata.filter((res) => {
          if (res.function_nm == 'NBPC_9994') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true)
      } else if (this.Roledata.length == 0) {
        let arrayvalue: any; let roleList: any;
        let data = localStorage.getItem('role');
        arrayvalue = data.split(',')
        console.log(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str == '') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        let body = {
          security_grp_nm: roleList
        }
        let userpreviliagedata = localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length != 0 && userpreviliagedata != "") {
          this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
          this.userroleAccessfn();
          this.accessEnable = true;
          resolve(true)
        }
        else {
          console.log(this.roleAccess)
          this.roleAccess = [];
          this.errorMessage = "User does not have permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        }
      }
      console.log(this.roleAccess);
    })
  }
  sendToDialog(selected): void {
    selected.forEach(row => {
      console.log(row);
      
      this.dialogData.job = row.job_name;
      this.dialogData.Td = row.td_name
      this.dialogData.paramName = row.parm_nm;
      this.dialogData.jobTime = row.job_timing;
      this.dialogData.processingState = row.parm_val_txt;
      this.dialogData.description = row.parm_desc;
      this.dialogData.updateById = row.update_by_id;
      this.dialogData.updateTs = row.update_ts;
      this.dialogData.createById = row.create_by_id;
      this.dialogData.createTs = row.create_ts;
    });
    console.log(this.dialogData.monthlyRep)
  }
  closeAlert(): void {
    this.errorMessage = '';
    this.successMessage = '';
  }
  getDecs(){
    this.service.manualJobdesc(this.jobName).subscribe((data:any)=>{
      console.log(data.payload); 
    })
  }

}
