import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenerateActProdStatusComponent } from '../generate-act-prod-status/generate-act-prod-status.component';
import { GenerateRepVerSumDialogComponent } from '../generate-rep-ver-sum-dialog/generate-rep-ver-sum-dialog.component';
import { ProductWithinVersionAddDialogComponent } from '../product-within-version-add-dialog/product-within-version-add-dialog.component';
import { ProductWithinVersionEditDialogComponent } from '../product-within-version-edit-dialog/product-within-version-edit-dialog.component';
import { ProductWithinVersionUploadDialogComponent } from '../product-within-version-upload-dialog/product-within-version-upload-dialog.component';
import { ProductWithinVersionViewDialogComponent } from '../product-within-version-view-dialog/product-within-version-view-dialog.component';
import { ProductWithinVersionComponent } from '../product-within-version/product-within-version.component';
import {ReferenceModule} from '../reference.module';


@NgModule({
  declarations: [
    ProductWithinVersionEditDialogComponent,
    GenerateRepVerSumDialogComponent,
    GenerateActProdStatusComponent,
    ProductWithinVersionUploadDialogComponent,
    ProductWithinVersionViewDialogComponent,
    ProductWithinVersionComponent,
    ProductWithinVersionAddDialogComponent,
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class ProdWithinVersionModule { }
