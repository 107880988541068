import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionTypesComponent } from '../version-types/version-types.component';
import { VersionTypesAddDailogComponent } from '../version-types-add-dailog/version-types-add-dailog.component';
import { VersionTypesDeleteDailogComponent } from '../version-types-delete-dailog/version-types-delete-dailog.component';
import {ReferenceModule} from '../reference.module';


@NgModule({
  declarations: [
    VersionTypesComponent,
    VersionTypesAddDailogComponent,
    VersionTypesDeleteDailogComponent,
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class VerTypesModule { }
