import { Component, OnInit, Inject } from '@angular/core';
import { isDate } from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { DialogData } from '../dialog-data/dialog-data';
import { ManualAdjustmentRuleAddDialogService } from './manual-adjustment-rule-add-dialog.service';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-manual-adjustment-rule-add-dialog',
  templateUrl: './manual-adjustment-rule-add-dialog.component.html',
  styleUrls: ['./manual-adjustment-rule-add-dialog.component.css']
})
export class ManualAdjustmentRuleAddDialogComponent implements OnInit {
  [x: string]: any;


  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');
  //to main page
  message = "Records Updated Successfully";
  index: any;
  errorMessage = "";


  //ngmodel
  dest: any = "";
  ruleType: any = "";
  versionName: any = "";
  family: any = "";
  namc: any = "";
  sp: any = "";
  grpi: any = "";
  partNumber: any = "";
  addBomEffFrom: any = "";
  addBomEffTo: any = "";
  comments: any = "";
  katashiki: any = "";
  qtyPerParent: any = "";
  actUnitCost: any = "";
  actCurrencyCode: any = "";
  completePartRouting: any = "";
  netSpec: any = "";
  partDesc: any = "";
  entered = [];

  spinnerMessage = "Please Wait...";

  //list
  ruleTypeLst = [];
  versionNameLst = [];
  namcLst = [];
  spLst = [{ "value": "S" }, { "value": "P" }];
  netSpecLst = [];
  famLst = [];
  katasPartLst = [];
  grpiLst = [];
  destLst = [];
  completePartRoutingLst = [];
  actCurrencyCodeLst = [];
  childPartnumLst = []
  partnumLst = []
  repBomVerId = '0'
  //response after insert in a record
  response: any;

  hidedata: any = true;
  nethidedata: any = true;
  verhidedata: any = true;
  costhidedata: any = false;
  exchidedata: any = false;
  qtyhidedata: any = false;
  disableDate: boolean = false;
  spData: boolean = false;
  costeffdate: any = "";
  constructor(private SpinnerService: NgxSpinnerService,
    private manualAdjustmentRuleAddDialogService: ManualAdjustmentRuleAddDialogService,
    private dialogData: DialogData,
    public dialogRef: MatDialogRef<ManualAdjustmentRuleAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }
  dateCheck() {
    this.errorMessage = ''
    this.fromDialog(this.entered)
    const fromDate = moment(this.addBomEffFrom, 'YYYY-MM-DD', true).isValid()
    const toDate = moment(this.addBomEffTo, 'YYYY-MM-DD', true).isValid()
    if (fromDate !== true || toDate !== true) {
      console.log(fromDate);
      console.log(toDate);
      this.errorMessage = 'Please provide Bom Effective Dates in this format [YYYY-MM-DD]'
    }
    else {
      console.log(fromDate);
      console.log(toDate);
    }

  }
  fromDialog(entered): void {
    entered.forEach(row => {
      this.dialogData.bomEffFrom = row.addBomEffFrom
      this.dialogData.bomEffTo = row.addBomEffTo
    });

  }

  ngOnInit(): void {
    this.getRuletyp();
    this.fetchDestData();
  }
  getRuletyp() {
    this.SpinnerService.show();
    this.manualAdjustmentRuleAddDialogService.getRuleTypeBpMar().subscribe(res => {
      console.log("res from rule typ", res.payload);
      this.ruleTypeLst = res.payload
      this.SpinnerService.hide();
    })
  }

  fetchAddVersionNameBpMar() {
    this.SpinnerService.show();
    this.manualAdjustmentRuleAddDialogService.getAddVersionNameBpMar(this.ruleType).subscribe(res => {
      console.log("res from vername", res.payload);
      this.versionNameLst = res.payload;
      this.namc = "";
      this.versionName = ""
      this.partNumber = ""
      this.addBomEffTo = ""
      this.addBomEffFrom = ""
      this.family = ""
      this.dest = ""
      this.grpi = ""
      this.sp = ""
      this.netSpec = ""
      this.partNumber = ""
      this.partDesc = ""
      this.actUnitCost = ""
      this.actCurrencyCode = ""
      this.completePartRouting = ""
      this.qtyPerParent = ""
      this.katashiki = ""
      this.comments = ""
      this.SpinnerService.hide()
    })
  }

  getdate() {
    console.log("index", this.versionName);

    this.versionNameLst.filter((res) => {
      if (res.version_name == this.versionName) {
        this.costeffdate = (res.cost_eff_dt).split('T')[0]
      }
    })
    console.log(this.costeffdate)
  }
  enableDates() {
    console.log('production details')
    if (this.versionName != 'production') {
      this.disableDate = true
      this.addBomEffFrom = ''
      this.addBomEffTo = ''
    }
    else {
      this.disableDate = false

    }
  }
  stdenable() {
    console.log(this.versionName.substring(0, 3))
    if (this.ruleType == '1' || this.ruleType == '5') {
      if ((this.versionName.substring(0, 3) == 'STD') || (this.versionName.substring(0, 5) == 'ADHOC')) {
        this.acthidedata = true
        this.ACTCurrency = true;
      } else if (this.versionName.substring(0, 3) == 'INV') {
        this.ACTCurrency = true;
        this.acthidedata = false
      } else {
        this.acthidedata = false
        this.ACTCurrency = false;
      }
    }
    else if (this.versionName.substring(0, 3) == 'STD') {
      this.acthidedata = true
      this.exchidedata = true;
    }
   
  }

  fetchAddNamcBpMar() {
    console.log(this.versionName)
    if (this.versionName == "") {
      this.namc = "";
      this.family = "";
      this.sp = ""
      this.netSpec = ""
      this.katashiki = ""
      this.dest = ""
      this.addBomEffFrom = ''
      this.addBomEffTo = ''

    } else {
      for (let i = 0; i < this.versionNameLst.length; i++) {
        if (this.versionName == this.versionNameLst[i].version_name) {
          this.repBomVerId = this.versionNameLst[i].rep_bom_ver_id
        }
      }
      if(this.versionName == 'production'){
        this.repBomVerId = '0'
      }
      let indateFormat = ""; let outdateFormat = ""
      if (this.addBomEffFrom != "") {
        let d1 = moment(this.addBomEffFrom).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.addBomEffFrom = date
      }
      if (this.addBomEffTo != "") {
        let d1 = moment(this.addBomEffTo).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.addBomEffTo = date
      }
      console.log("versionname", this.versionName);
      if (this.repBomVerId != "0" && this.versionName != "") {
        this.SpinnerService.show();
        this.manualAdjustmentRuleAddDialogService.getAddNamcBpMar(this.versionName, this.repBomVerId, indateFormat, outdateFormat).subscribe(res => {
          console.log("res from namc", res.payload);
          this.namcLst = res.payload
          this.SpinnerService.hide();
        })
      } else if (this.repBomVerId == '0' && (outdateFormat != '' && indateFormat != '')) {
        this.SpinnerService.show();
        this.manualAdjustmentRuleAddDialogService.getAddNamcBpMar(this.versionName, this.repBomVerId, indateFormat, outdateFormat).subscribe(res => {
          console.log("res from namc", res.payload);
          this.namcLst = res.payload
          this.SpinnerService.hide();
        })
      }
    }
  }

  fetchAddFamilyMar() {
    if(this.versionName == 'production'){
      this.repBomVerId = '0'
    }
    let indateFormat = ""; let outdateFormat = ""
    if (this.addBomEffFrom != "") {
      let d1 = moment(this.addBomEffFrom).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.addBomEffFrom = date
    }
    if (this.addBomEffTo != "") {
      let d1 = moment(this.addBomEffTo).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.addBomEffTo = date
    }
    this.SpinnerService.show();
    this.manualAdjustmentRuleAddDialogService.getAddFamilyMar(this.repBomVerId, this.namc,indateFormat, outdateFormat).subscribe(res => {
      console.log("res from family", res.payload);
      this.famLst = res.payload
      this.SpinnerService.hide();
    })
  }

  fetchAddKatashikiMar() {
    if (this.family == "") {
      this.sp = ""
    }
    if(this.versionName == 'production'){
      this.repBomVerId = '0'
    }
    if (this.family != "") {
      this.SpinnerService.show();
      this.manualAdjustmentRuleAddDialogService.getAddKatashikiMar(this.repBomVerId, this.namc, this.family).subscribe(res => {
        console.log("res from katashiki", res.payload);
        this.katasPartLst = res.payload
        this.SpinnerService.hide();
      })
    } else {
      this.katasPartLst = []
      this.katashiki = ""
    }
  }

  fetchAddGrpiMar() {
    if (this.family != '') {
      this.SpinnerService.show();
      this.manualAdjustmentRuleAddDialogService.getAddGrpiMar(this.namc, this.family).subscribe(res => {
        console.log("res from grpi", res.payload);
        this.grpiLst = res.payload;
        this.SpinnerService.hide();
      })
    } else {
      this.grpiLst = []
      this.grpi = ''
    }
  }

  fetchDestData() {
    this.manualAdjustmentRuleAddDialogService.getDestData(this.namc, this.family, this.katashiki).subscribe(res => {
      console.log("res from dest", res.payload);
      this.destLst = res.payload
    })
  }

  fetchAddNetSpecMar() {
    if (this.sp == 'P') {
      this.nethidedata = false
    } else {
      this.nethidedata = true
    }
    if(this.versionName == 'production'){
      this.repBomVerId = '0'
    }
    this.manualAdjustmentRuleAddDialogService.getAddNetSpecMar(this.namc, this.family, this.sp, this.repBomVerId).subscribe(res => {
      console.log("res from netspec", res.payload);
      this.netSpecLst = res.payload
    })
  }


  fetchAddPartNoMar() {
    this.manualAdjustmentRuleAddDialogService.getAddPartNoMar(this.namc, this.family, this.grpi).subscribe(res => {
      console.log("res from partnum", res.payload);
      this.partnumLst = res.payload
    })
  }


  fetchAddChildPartNoMar() {
    this.manualAdjustmentRuleAddDialogService.getAddChildPartNoMar(this.namc, this.family, this.grpi).subscribe(res => {
      console.log("res from childpart", res.payload);
      this.childPartnumLst = res.payload
    })
  }


  getAddActCurCodeBpMar() {
    this.manualAdjustmentRuleAddDialogService.getAddActCurCodeBpMar().subscribe(res => {
      console.log("res from act curr code", res.payload);
      this.actCurrencyCodeLst = res.payload
    })
  }

  getAddCompletePartRoutingMar() {
    this.manualAdjustmentRuleAddDialogService.getAddCompletePartRoutingMar(this.namc).subscribe(res => {
      console.log("res from complete part routing", res.payload);
      this.completePartRoutingLst = res.payload
    })
  }


  addmanAdjRule() {
    console.log(this.ruleType, this.versionName, this.addBomEffFrom, this.addBomEffTo, this.namc, this.family, this.grpi, this.dest, this.partNumber);
    console.log(this.pipe.transform(this.addBomEffFrom, 'yyyy-MM-dd') > this.pipe.transform(this.addBomEffTo, 'yyyy-MM-dd'))
    if ((this.sp == 'P') && !this.netSpec) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else if (this.ruleType == "3") {
      if (this.versionName == 'production') {
        console.log('production0');
        if (!this.ruleType || !this.namc || !this.versionName || !this.partNumber
          || !this.addBomEffTo || !this.addBomEffFrom|| !this.actUnitCost ) {
          console.log('production1');
          this.errorMessage = "Please enter all mandatory field";
        }
        else if (this.addBomEffFrom && this.addBomEffTo &&
          this.pipe.transform(this.addBomEffFrom, 'yyyy-MM-dd') > this.pipe.transform(this.addBomEffTo, 'yyyy-MM-dd')) {
          this.errorMessage = "Effective To should be greater than or equal to Effective From";
        }
        else {
          this.insertfunctionStart();
        }
      }
      else {
        console.log('production2');
        if (!this.ruleType || !this.namc || !this.versionName || !this.partNumber  || !this.actUnitCost) {
          this.errorMessage = "Please enter all mandatory field";
          console.log('production4');
        }
        else {
          this.insertfunctionStart();
        }
      }
    }
    else if (this.ruleType == "2" || this.ruleType == '4') {
      if (this.versionName == 'production') {
        console.log('0')
        if (this.namc == '14' || this.namc == '15') {
          if (!this.ruleType || !this.namc || !this.versionName || !this.partNumber
            || !this.addBomEffTo || !this.addBomEffFrom || !this.family || !this.grpi) {
            this.errorMessage = "Please enter all mandatory field";
            console.log('1')
          }
          else if (this.addBomEffFrom && this.addBomEffTo &&
            this.pipe.transform(this.addBomEffFrom, 'yyyy-MM-dd') > this.pipe.transform(this.addBomEffTo, 'yyyy-MM-dd')) {
            this.errorMessage = "Effective To should be greater than or equal to Effective From";
          }
          else {
            this.insertfunctionStart();
          }
        }
        else {
          console.log('1.1')
          console.log(this.ruleType, this.namc, this.versionName, this.partNumber
            , this.family, this.grpi, this.dest)
          if (!this.ruleType || !this.namc || !this.versionName || !this.partNumber
            || !this.family || !this.grpi || !this.dest) {
            this.errorMessage = "Please enter all mandatory field";
            console.log('2')
          } else {
            this.insertfunctionStart();
          }
        }
      }
      else {
        console.log('1.2')
        if (this.namc == '14' || this.namc == '15') {
          console.log('1.3')
          if (!this.ruleType || !this.namc || !this.versionName || !this.partNumber
            || !this.family || !this.grpi) {
            this.errorMessage = "Please enter all mandatory field";
            console.log('3')
          } else {
            this.insertfunctionStart();
          }
        }
        else {
          console.log('1.5')
          console.log(this.ruleType, this.namc, this.versionName, this.partNumber
            , this.family, this.grpi, this.dest)
          if (!this.ruleType || !this.namc || !this.versionName || !this.partNumber
            || !this.family || !this.grpi || !this.dest) {
            this.errorMessage = "Please enter all mandatory field";
            console.log('4')
          } else {
            this.insertfunctionStart();
          }
        }
      }
    } else if (this.ruleType == '5' || this.ruleType == '1') {
      if (this.versionName == 'production') {
        console.log('0')
        if (this.namc == '14' || this.namc == '15') {
          if (!this.ruleType || !this.namc || !this.versionName || !this.partNumber
            || !this.addBomEffTo || !this.addBomEffFrom || !this.family || !this.grpi || !this.qtyPerParent || !this.completePartRouting || !this.actUnitCost || !this.actCurrencyCode) {
            this.errorMessage = "Please enter all mandatory field";
            console.log('1')
          }
          else if (this.addBomEffFrom && this.addBomEffTo &&
            this.pipe.transform(this.addBomEffFrom, 'yyyy-MM-dd') > this.pipe.transform(this.addBomEffTo, 'yyyy-MM-dd')) {
            this.errorMessage = "Effective To should be greater than or equal to Effective From";
          }
          else {
            this.insertfunctionStart();
          }
        }
        else {
          console.log('1.1')
          console.log(this.ruleType, this.namc, this.versionName, this.partNumber
            , this.family, this.grpi, this.dest, this.qtyPerParent, this.completePartRouting, this.actUnitCost, this.actCurrencyCode)
          if (!this.ruleType || !this.namc || !this.versionName || !this.partNumber
            || !this.family || !this.grpi || !this.dest || !this.qtyPerParent || !this.completePartRouting || !this.actUnitCost || !this.actCurrencyCode) {
            this.errorMessage = "Please enter all mandatory field";
            console.log('2')
          } else {
            this.insertfunctionStart();
          }
        }
      }
      else {
        console.log('1.2')
        if (this.namc == '14' || this.namc == '15') {
          console.log('1.3')
          if (!this.ruleType || !this.namc || !this.versionName || !this.partNumber
            || !this.family || !this.grpi) {
            this.errorMessage = "Please enter all mandatory field";
            console.log('3')
          } else {
            this.insertfunctionStart();
          }
        }
        else {
          console.log('1.5')
          console.log(this.ruleType, this.namc, this.versionName, this.partNumber
            , this.family, this.grpi, this.dest)
          if (!this.ruleType || !this.namc || !this.versionName || !this.partNumber
            || !this.family || !this.grpi || !this.dest) {
            this.errorMessage = "Please enter all mandatory field";
            console.log('4')
          } else {
            this.insertfunctionStart();
          }
        }
      }
    }

  }

  insertfunctionStart() {
    console.log('else');
    this.SpinnerService.show();
    if (this.ruleType == '1' || this.ruleType == '3' || this.ruleType == '5') {
      this.checkValPartNo();
    }
    else {
      let body = {
        "famCd": this.family
      }
      this.manualAdjustmentRuleAddDialogService.chkCarFmFlg(body).subscribe(res => {
        console.log("res from chkCarFmFlg", res.payload);
        this.chkCarFmFlgres = res.payload[0].result;
        if (this.chkCarFmFlg == 'success') {
          this.checkValPartNo();
        }
        else {
          if (this.ruleType == '2') {
            if (this.katashiki.length != 10) {
              this.fetchChildPartNoManAdjAddExclude();
            } else {
              this.checkValPartNo();
            }
          }
          else {
            this.fetchChildPartNoManAdjAdd();
          }
        }
      })
    }
  }

  checkValPartNo() {
    let fileName= localStorage.getItem('Filename')
    let body = {
      'childPartNo': this.partNumber,
      "screenNo":"NBPC_10122",
          "fileName" :fileName
    }
    this.manualAdjustmentRuleAddDialogService.chkValPartNo(body).subscribe(res => {
      console.log("res from chkValPartNo", res.payload);
      this.response = res.payload;
      if (this.response[0].result == 'success') {
        if (this.ruleType == '3') {
          this.FetchIfMarRecordExist();
        } else {
          if (this.grpi != '') {
            this.checkGrpiCd();
          }
        }
        // }
      } else {
        this.SpinnerService.hide();
        this.errorMessage = this.response[0].result;
      }
    })
  }


  fetchunitStdPrcAmt() {
    let fileName= localStorage.getItem('Filename')
    let costeffdatefrom = this.costeffdate == '' ? null : this.costeffdate
    let indateFormat = ''; let outdateFormat = '';
    if (this.addBomEffFrom != "") {
      let d1 = moment(this.addBomEffFrom).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.addBomEffFrom = date
    } else {
      indateFormat = null
    }
    if (this.addBomEffTo != "") {
      let d1 = moment(this.addBomEffTo).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.addBomEffTo = date
    } else {
      outdateFormat = null
    }

    let body = {
      "childPartNo": this.partNumber,
      "namcLglEntityId": this.namc,
      "bomEffDtFrom": indateFormat,
      "bomEffDtTo": outdateFormat,
      "costeffDate": costeffdatefrom,
      "screenNo":"NBPC_10122",
          "fileName" :fileName

    }
    this.manualAdjustmentRuleAddDialogService.getUnitStdPrcAmt(body).subscribe(res => {
      console.log("res from getunitstdprcamt", res.payload);
      this.response = res.payload[0].result;
      if (this.response == 'success') {
        this.FetchIfMarRecordExist();
      } else {
        this.SpinnerService.hide();
        this.errorMessage = this.response;
      }
    }, err => {
      this.errorMessage = 'Error';
    })
  }

  CheckValParntPartNm() {

    let body = {
      "parpart": this.katashiki
    }
    this.manualAdjustmentRuleAddDialogService.chkValParntPartNm(body).subscribe(res => {
      console.log("res from chkValParntPartNm", res.payload);
      this.response = res.payload[0].result;
      if (this.response == 'success') {
        if (this.ruleType == '1' || this.ruleType == '5') {
          for (let i = 0; i < this.versionNameLst.length; i++) {
            if (this.versionName == this.versionNameLst[i].version_name) {
              this.repBomVerId = this.versionNameLst[i].rep_bom_ver_id
            }
          }
          if(this.versionName == 'production'){
            this.repBomVerId = '0'
          }
          
          let body = {
            'repBomVerId': this.repBomVerId
          }
          this.manualAdjustmentRuleAddDialogService.versionValChk(body).subscribe(res => {
            console.log("res from versionValChk", res.payload);
            this.response = res.payload[0].result;
            if (this.response == 'success') {
              this.checkGrpiCd();
            } else {
              this.SpinnerService.hide();
              this.errorMessage = this.response;
            }
          })
        } else {
          this.fetchChildPartNoManAdjAddExclude();
        }
      } else {
        this.SpinnerService.hide();
        this.errorMessage = this.response;
      }

    }, err => {
      this.SpinnerService.hide();
      this.errorMessage = 'Failed in ParentPartNumber Validation';
    })

  }

  fetchChildPartNoManAdjAddExclude() {
    let body = {
      "famCd": this.family,
      "namcLglEntityId": this.namc,
      "grpiCd": this.grpi,
      'childPartNo': this.partNumber
    }
    this.manualAdjustmentRuleAddDialogService.getChildPartNoManAdjAddExclude(body).subscribe(res => {
      console.log("res from getChildPartNoManAdjAddExclude", res.payload);
      this.response = res.payload;
      if (this.response[0].result == 'success') {
        this.checkGrpiCd();
      } else {
        this.errorMessage = this.response[0].result;
        this.SpinnerService.hide();
      }
    }, err => {
      this.SpinnerService.hide();
      this.errorMessage = 'Failed in ChildPartNumber ';
    })
  }

  fetchChildPartNoManAdjAdd() {
    let body = {
      "famCd": this.family,
      "namcLglEntityId": this.namc,
      "grpiCd": this.grpi,
      'childPartNo': this.partNumber
    }
    this.manualAdjustmentRuleAddDialogService.getChildPartNoManAdjAdd(body).subscribe(res => {
      console.log("res from getChildPartNoManAdjAdd", res.payload);
      this.response = res.payload;
      if (this.response[0].result == 'success') {
        this.checkGrpiCd();
      }
      else {
        this.SpinnerService.hide();
        this.errorMessage = this.response[0].result
      }
    }, err => {
      this.SpinnerService.hide();
      this.errorMessage = 'Failed in ChildPartNumber';
    })
  }
  FetchIfMarRecordExist() {
    let fileName= localStorage.getItem('Filename')
    let parentpartValue = "";
    let katashikivalue = ""
    console.log(this.katashiki);
    if (this.katashiki.length == '10') {
      parentpartValue = this.katashiki
    } else {
      katashikivalue = this.katashiki
    }
    if(this.versionName == 'production'){
      this.repBomVerId = '0'
    }
    let body = {
      "famCd": this.family,
      "namcLglEntityId": this.namc,
      "grpi": this.grpi,
      "childPartNo": this.partNumber,
      "katashikiCd": katashikivalue,
      "repBomVerId": this.repBomVerId,
      "manAdjstmntRuleTypId": this.ruleType,
      "perVehQty": this.qtyPerParent,
      "baseOptnTypCd": this.sp,
      "destCtryIdCombTxt": this.dest,
      "parentPartNo": parentpartValue,
      "screenNo":"NBPC_10122",
          "fileName" :fileName
    }
    this.manualAdjustmentRuleAddDialogService.checkIfMarRecordExist(body).subscribe(res => {
      console.log("res from checkIfMarRecordExist", res.payload);
      this.response = res.payload;
      if (this.response[0].result == 'success') {
        this.InsertManualAdjustmentRule();
      } else {
        this.SpinnerService.hide();
        this.errorMessage = this.response[0].result
      }
    }, err => {
      this.SpinnerService.hide();
      this.errorMessage = 'Failed in Records Exists Check';
    })
  }
  checkGrpiCd() {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "famCd": this.family,
      "grpiCd": this.grpi,
      "screenNo":"NBPC_10122",
      "fileName" :fileName
    }
    this.manualAdjustmentRuleAddDialogService.chkGrpiCd(body).subscribe(res => {
      console.log("res from chkGrpiCd", res.payload);
      this.response = res.payload[0].result;
      if (this.response == 'success') {
        if (this.qtyPerParent != '') {
          if (this.qtyPerParent == 0) {
            this.errorMessage = "Qty Per Parent cannot be 0";
          }
        }
        if (this.ruleType == '1' || this.ruleType == '5') {

          if (this.ruleType == '1' || this.ruleType == '5') {
            for (let i = 0; i < this.versionNameLst.length; i++) {
              if (this.versionName == this.versionNameLst[i].version_name) {
                this.repBomVerId = this.versionNameLst[i].rep_bom_ver_id
              }
            }
            if (this.repBomVerId == '') {
              this.repBomVerId = '0'
            }
            if(this.versionName == 'production'){
              this.repBomVerId = '0'
            }
            let body = {
              'repBomVerId': this.repBomVerId
            }
            this.manualAdjustmentRuleAddDialogService.versionValChk(body).subscribe(res => {
              console.log("res from versionValChk", res.payload);
              this.response = res.payload[0].result;
              if (this.response == 'success') {
                this.fetchunitStdPrcAmt();
              } else {
                this.SpinnerService.hide();
                this.errorMessage = this.response;
              }
            })
          }
        } else {
          this.FetchIfMarRecordExist();
        }
      } else {
        this.errorMessage = "Grpi code" + this.grpi + " Invalid";
        this.SpinnerService.hide();
      }
    }, err => {
      this.SpinnerService.hide();
      this.errorMessage = 'Data not found';
    })
  }


  InsertManualAdjustmentRule() {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    let parentpartValue = "";
    let katashikivalue = ""
    console.log(this.katashiki);
    if (this.katashiki.length == '10') {
      parentpartValue = this.katashiki
    } else {
      katashikivalue = this.katashiki
    }
    if(this.versionName == 'production'){
      this.repBomVerId = '0'
    }
    console.log(parentpartValue, katashikivalue)
    let indateFormat = ''; let outdateFormat = '';
    if (this.addBomEffFrom != "") {
      let d1 = moment(this.addBomEffFrom).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.addBomEffFrom = date
    }
    if (this.addBomEffTo != "") {
      let d1 = moment(this.addBomEffTo).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.addBomEffTo = date
    }
    let cmts;
    if (this.comments!= undefined) {
      cmts = this.comments.replaceAll("'", "''");
    } else {
      cmts = this.comments
    }
    let body = {
      "baseOptnTypCd": this.sp,
      "bomEffDtFrom": indateFormat,
      "bomEffDtTo": outdateFormat,
      "cmpltRtgCd": this.completePartRouting,
      // "stdCostCrncyCd": this., 
      "actlCostCrncyCd": this.actCurrencyCode,
      "destCtryIdCombTxt": this.dest,
      "famCd": this.family,
      "grpiCd": this.grpi,
      "katashikiCd": katashikivalue,
      // "manAdjstmntRuleStatFlg": this., 
      // "manAdjstmntRuleStatDesc": , 
      "manAdjstmntRuleTypId": this.ruleType,
      "namcLglEntityId": this.namc,
      "netSpecCdCombTxt": this.netSpec,
      "childPartNo": this.partNumber,
      "parentPartNo": parentpartValue,
      'perVehQty': this.qtyPerParent,
      // "prodCnt": this., 
      "repBomVerNm": this.versionName,
      "rmrkTxt": cmts,
      "actlUnitCostAmt": this.actUnitCost,
      // "stdUnitCostAmt": this., 
      'repBomVerId': this.repBomVerId,
      "screenNo":"NBPC_10122",
          "fileName" :fileName
    }
    console.log(parentpartValue, katashikivalue)
    this.manualAdjustmentRuleAddDialogService.addManualAdjustmentRule(body).subscribe(res => {
      console.log("res from addManualAdjustmentRule", res.payload);
      if (this.response[0].result == 'success') {
        this.dialogRef.close(this.response[0].result);
        this.SpinnerService.hide();
      } else {
        this.errorMessage = "Data not Added";
        this.SpinnerService.hide();
      }
    }, err => {
      this.SpinnerService.hide();
      this.errorMessage = 'Failed in adding data';
    })
  }





  partdescription() {
    if (this.partNumber == "") {
      console.log('1')
      this.partDesc = ""
    }

    else if (this.partNumber != "") {

      if (this.partDesc == "") {
        this.manualAdjustmentRuleAddDialogService.getPartDesc(this.partNumber).subscribe(data => {
          console.log(data)
          if (data.payload) {
            this.partDesc = data.payload[0].part_desc;
            localStorage.setItem("partnumberpopup", this.partNumber);
            this.errorMessage = ''
          }
          else {
            this.errorMessage = "Part Number " + this.partNumber + " Invalid";
            this.partNumber = ""
            this.partDesc = ""
          }

        });
      }
      else if (this.partDesc != "") {
        if (this.partNumber != localStorage.getItem('partnumberpopup'))
          this.manualAdjustmentRuleAddDialogService.getPartDesc(this.partNumber).subscribe(data => {
            console.log(data)
            if (data.payload) {
              this.partDesc = data.payload[0].part_desc;
              localStorage.setItem("partnumberpopup", this.partNumber);
              this.errorMessage = ''
            }
            else {
              this.errorMessage = "Part Number " + this.partNumber + " Invalid"
              this.partNumber = ""
              this.partDesc = ""
            }
          });
      }
      else {
        this.errorMessage = ''
      }
    }
  }

  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getChngeVerNm() {
    console.log(this.ruleType)
    if (this.ruleType == "3") {
      this.costhidedata = true
      this.desthide = true
      this.verhidedata = false
      this.exchidedata = true;
      this.ACTCurrency =true
      this.qtyhidedata = true
      this.hidedata = true
      this.acthidedata = false
      console.log(this.spData)
      this.sp = "";
      // this.spLst = [{ "value": "S" }, { "value": "P" }];

    } else if (this.ruleType == "2") {
      this.costhidedata = false;
      this.desthide = false;
      this.exchidedata = true
      this.ACTCurrency =true
      this.qtyhidedata = true
      this.nethidedata = true
      this.verhidedata = false;
      this.acthidedata = true;
      console.log(this.namc)

      console.log(this.spData)
      this.sp = "";
      // this.spLst = [{ "value": "S" }, { "value": "P" }];

    } else if (this.ruleType == "1") {
      this.nethidedata = true;
      this.costhidedata = false;
      this.desthide = false;
      this.exchidedata = false;
      this.hidedata = false;
      this.qtyhidedata = false;
      this.verhidedata = false;
      this.acthidedata = false;
      console.log(this.spData)
      this.sp = "";
      // this.spLst = [{ "value": "S" }, { "value": "P" }];
    } else if (this.ruleType == "5") {
      this.nethidedata = true;
      this.costhidedata = false;
      this.desthide = false;
      this.exchidedata = false;
      this.hidedata = false;
      this.verhidedata = false;
      this.qtyhidedata = false;
      this.acthidedata = false;
      console.log(this.spData)
      this.sp = "";
      // this.spLst = [{ "value": "S" }, { "value": "P" }];

    } else if (this.ruleType == "4") {
      this.costhidedata = false
      this.desthide = false
      this.exchidedata = true
      this.ACTCurrency =true
      this.qtyhidedata = false
      this.nethidedata = true
      this.verhidedata = false
      this.acthidedata = true
      if (this.versionName == 'production') {
        this.hidedata = true;
        this.sp = ''
      }
      if (this.versionName != 'production') {
        console.log("in else");
        this.sp = "S";
        this.hidedata = false;
        // this.spLst = [{ "value": "S" }]

      }


    } else if (this.ruleType == "") {
      this.hidedata = true
      this.exchidedata = false
      this.qtyhidedata = false
      this.nethidedata = true
      this.verhidedata = true
      this.costhidedata = false
      this.desthide = false
      this.sp = "";
      // this.spLst = [{ "value": "S" }, { "value": "P" }];

    }
  }
  getChngnamc() {
    console.log(this.ruleType);
    if (this.ruleType != "3") {
      console.log(this.ruleType);
      if (this.namc == '14' || this.namc == '15') {
        this.hidedata = true
        this.desthide = true;
      } else {
        this.hidedata = false;
        this.desthide = false;
      }
    } else {
      console.log("else")
    }
  }



  closeAlert() {
    this.errorMessage = '';
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'addBomEffFrom') {
      this.addBomEffFrom = "";
    } else if (name == 'addBomEffTo') {
      this.addBomEffTo = "";
    }
  }

}


