import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReferenceModule} from '../reference.module';
import { FamilyComponent } from '../family/family.component';
import { FamilyEditDialogComponent } from '../family-edit-dialog/family-edit-dialog.component';
import { FamilyViewDialogComponent } from '../family-view-dialog/family-view-dialog.component';
import { FamilyMultieditDialogComponent } from '../family-multiedit-dialog/family-multiedit-dialog.component';



@NgModule({
  declarations: [
    FamilyComponent,
    FamilyEditDialogComponent,
    FamilyViewDialogComponent,
    FamilyMultieditDialogComponent
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class FamilyModule { }
