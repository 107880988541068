<span *ngIf="accessEnable">
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <div class="alert alert-success alert-dismissible " *ngIf="successMessage!=''" role="alert">
    {{successMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>

<mat-accordion class="example-headers-align">
  <mat-expansion-panel [expanded]="step === 0" (opened)="(0)" hideToggle>

    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Search</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-form-field>
      <mat-label>Parameter Name</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="parameterName" name="parameterName">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let parameterName of parameterNamerLst" [value]="parameterName.parm_nm">
          {{parameterName.parm_nm}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Parameter Value</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="parameterValue" name="parameterValue">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let parameterValue of parameterValueLst" [value]="parameterValue.parm_val_txt">
          {{parameterValue.parm_val_txt}}</mat-option>
      </mat-select>
    </mat-form-field>


    <button mat-button style="float: right;" (click)="resetAll('initial')">Reset</button>
    <button mat-button style="float: right;" (click)="search()">Search</button>

  </mat-expansion-panel>
</mat-accordion>

<div class="d-flex">
  <button mat-raised-button (click)="openEditDialog()">Edit </button>
  <button mat-raised-button (click)="openViewDialog()">View </button>
</div>

<!-- <mat-card> -->

<ngx-datatable #myTable class="material striped" [rows]="rows" [columnMode]="ColumnMode.force" [headerHeight]="45"
  [footerHeight]="30" rowHeight="30" [limit]="limitSelected" [selected]="selected"
  [selectionType]="SelectionType.single" [selectAllRowsOnPage]="false" [displayCheck]="displayCheck"
  (activate)="onActivate($event)" (select)="onSelect($event)" [selectCheck]="singleSelectCheck"
  [ngClass]="{'hideerror': showdata, 'showerror': !showdata }">
  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
      let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
      <div class="page-count">
        {{selectedCount}} selected / {{rowCount}} total
      </div>
      <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
        (change)="table.onFooterPage($event)">
      </datatable-pager>
      <div class="test">
        <select [(ngModel)]="limitSelected">
          <option>50</option>
          <option>100</option>
          <option>500</option>
        </select>
        <div class="float-right records">View {{(offset * pageSize)+1}} - {{(rowCount>
          (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
          {{(rowCount)}}

          </div>
        </div>
      </ng-template>
    </ngx-datatable-footer>
    <ngx-datatable-column [width]="65" [sortable]="false" [canAutoResize]="false" [draggable]="false"
      [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true" name="Select">
    </ngx-datatable-column>
    <ngx-datatable-column name="Job" prop="parm_nm"></ngx-datatable-column>
    <ngx-datatable-column name="Processing State" prop="parm_val_txt"></ngx-datatable-column>
    <ngx-datatable-column name="Description" prop="parm_desc"></ngx-datatable-column>
    <ngx-datatable-column name="Update By Id" prop="update_by_id"></ngx-datatable-column>
    <ngx-datatable-column name="Update  Ts" prop="update_ts"></ngx-datatable-column>
  </ngx-datatable>
</span>
<div *ngIf="!accessEnable">
  <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>