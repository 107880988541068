<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Actual Production Status <mat-icon class="close-icon" (click)="cancel()">
      close</mat-icon>
  </h2>

  <mat-dialog-content class="mat-typography">
    <div class="container">
      <div class="d-flex">
        <span class="fst-italic" *ngIf="productTyp!='onHold'">All Products are displayed.</span>
        <span class="fst-italic" *ngIf="productTyp=='onHold'">Only on-hold products are displayed.</span>
        <span class="ms-auto">
          <button (click)="exportFile()">Export To Excel</button>
          <button (click)="print()">Print</button>
        </span>
      </div>
      <!-- <mat-card> -->

      <div class="table-container mat-elevation-z8" #prodStat id="prodStat"  *ngIf="showdata">
        <table>
          <tr>
            <th scope="col" *ngFor="let data of displayheaderArray"> {{data}} </th>
          </tr>
          <tr *ngFor="let data of gridList,let i = index" [ngClass]="{'TotalColor': (data[1] == null)}">
            <td *ngFor="let a of data"> {{a}} </td>
          </tr>
        </table>
      </div>
      <div style="text-align: center;" *ngIf="!showdata">
        <h5 style="color: red;margin-top: 25px;">No Records Found</h5>
      </div>
    </div>

  </mat-dialog-content>
</ng-container>
