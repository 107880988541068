import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import { NgxSpinnerService } from "ngx-spinner"; 



@Component({
  selector: 'app-family-project-namc-view-dialog',
  templateUrl: './family-project-namc-view-dialog.component.html',
  styleUrls: ['./family-project-namc-view-dialog.component.css']
})
export class FamilyProjectNamcViewDialogComponent implements OnInit {
 
  //ngModelValues
  fFamily:any;
  fProject:any;
  fSsn:any;
  fNamc:any;
  fHikiate:any;
  fModelYear:any;
  fModelChangeType:any;
  fGeneration:any;
  fLineOffPlant:any;
  fProdDateIn:any;
  fProdDateOut:any;
  fEffDateIn:any;
  fEffDateOut:any;
  fComments:any;
  spinnerMessage="Please Wait...";

  constructor(private SpinnerService: NgxSpinnerService,@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {

    this.fFamily=this.data.family;
    this.fProject=this.data.project;
    this.fSsn=this.data.ssn;
    this.fNamc=this.data.namc;
    this.fHikiate=this.data.hikiate;
    this.fModelYear=this.data.modelYear;
    this.fGeneration=this.data.generation;
    this.fModelChangeType=this.data.modelChangeType;
    this.fLineOffPlant=this.data.lineOffPlant;
    this.fProdDateIn=this.data.prodDateIn;
    this.fProdDateOut=this.data.prodDateOut;
    this.fEffDateIn=this.data.effectiveDateIn;
    this.fEffDateOut=this.data.effectiveDateOut;
    this.fComments=this.data.comments;
  }

}
