import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductWithinVersion } from '../dialog-data/product-within-version';
import { ProdVersionActionsComponent } from '../prod-version-actions/prod-version-actions.component';
import { ProductWithinVersionService } from '../product-within-version/product-within-version.service';
import { ProductWithinVersionAddDialogService } from './product-within-version-add-dialog.service'

@Component({
  selector: 'app-product-within-version-add-dialog',
  templateUrl: './product-within-version-add-dialog.component.html',
  styleUrls: ['./product-within-version-add-dialog.component.css']
})
export class ProductWithinVersionAddDialogComponent implements OnInit {

  //alert
  errorMessage = '';

  //ngModel
  versionType: any = '';
  versionQual: any = '';
  versionRev: any = '';
  bomEffDate: any = '';
  costEffDate: any = '';
  namc: any = '';
  family: any = '';
  katashiki: any = '';
  dest: any = '';
  hikiate: any = '';
  part: any = '';

  //list
  verTypeLst = [];
  verQualifierLst = [];
  namcLst = [];
  famcdLst = [];
  katashikiLst = [];
  destLst = [];
  hikiateLst = [];
  partLst = [];
  versionRevLst: any = [];
  //ngx-datatable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('addVerTable') table;
  limitSelected: any = 50;
  showItems: boolean = false;
  repbomVerId: any = ''
  showdata: boolean = true;

  columns = [
    { name: 'namc', prop: 'namc_cd' }, { name: 'modelNumber', prop: 'mdl_no' },
    { name: 'option', prop: 'optn_cd' }, { name: 'sapMatId', prop: 'sap_mtrl_id' },
    { name: 'family', prop: 'fam_cd' }, { name: 'katashiki', prop: 'katashiki_cd' },
    { name: 'dest', prop: 'dest_ctry_id' }, { name: 'sp', prop: 'base_optn_typ_cd' },
    { name: 'netSpec', prop: 'net_spec_cd_comb_txt' },
    { name: 'pcSpec', prop: 'pc_spec_cd_comb_txt' }, { name: 'compPCSpec', prop: 'compar_pc_spec_cd_comb_txt' },
    { name: 'hikiate', prop: 'hikiate_stat_comb_cd' }, { name: 'partNo', prop: 'prod_part_no' },
    { name: 'hundPercent', prop: 'hundred_pct_optn_flg' }, { name: 'compHundPercent', prop: 'compar_100_pct_optn_flg' },
    { name: 'comments', prop: 'rmrk_txt' }

  ];

  ColumnMode = ColumnMode;

  //to main page
  message = "Record Added Successfully";

  spinnerMessage = "Please wait...";
  response: any;
  repBomVerEffDt: any;
  repBomVerExprDt: any;



  constructor(public dialogRef: MatDialogRef<ProductWithinVersionAddDialogComponent>,
    private prodwithinVerService: ProductWithinVersionAddDialogService, public dialog: MatDialog,
    private dialogData: ProductWithinVersion,
    private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {

    this.getHikiateData();
    this.getDest();
    //to get Katashiki
    this.getKatashikiByFamCd();
    // to get family
    this.getFamilyFpn();
    this.getnamcData()
    this.getverTypData()
  }


  enableDates() {
    if (this.versionQual !== '') {
      // this.
    }
  }

  getGridData() {
    let fileName= localStorage.getItem('Filename')
    this.SpinnerService.show()
    let body = {
      "bomEffDt": this.bomEffDate,
      "namcLglEntityId": this.namc,
      "famCd": this.family,
      "partNo": this.part,
      "katashikiCd": this.katashiki,
      "hikiateStatus": this.hikiate,
      "repBomVerId": this.repbomVerId,
      "destId": this.dest,
      "repBomVerEffDt": this.repBomVerEffDt,
      "repBomVerExprDt": this.repBomVerExprDt,
      "screenNo":"NBPC_10110",
      "fileName" :fileName

    }
    this.prodwithinVerService.getGridDetails(body).subscribe(data => {
      console.log(data)
      this.showdata = true;
      this.rows = data.payload;
      this.SpinnerService.hide()
    }, err => {
      this.showdata = false;
      console.log(this.showdata);
      this.errorMessage = "No records found"
      this.rows = []
      this.SpinnerService.hide()
      console.log("err", err);

    });
  }

  resetAll(): void {
    this.versionType = '';
    this.versionQual = '';
    this.versionRev = '';
    this.bomEffDate = '';
    this.costEffDate = '';
    this.namc = '';
    this.family = '';
    this.katashiki = '';
    this.dest = '';
    this.hikiate = '';
    this.part = '';
    this.errorMessage = '';
    this.showItems = false;
    this.getHikiateData();
    this.getDest();
    //to get Katashiki
    this.getKatashikiByFamCd();
    // to get family
    this.getFamilyFpn();
    this.getnamcData()
    this.getverTypData()
    // this.getGridData();
  }

  getverTypData() {
    //vertype
    this.prodwithinVerService.getVersionType().subscribe(data => {
      console.log(data);
      this.verTypeLst = data.payload;
    });
  }

  getnamcData() {
    this.prodwithinVerService.getNamcFpn().subscribe(data => {
      console.log(data);
      this.namcLst = data.payload;
    });
  }

  getVersionQual() {
    if (this.versionType == undefined) {
      this.versionType = ""
    }
    this.SpinnerService.show();
    this.prodwithinVerService.getQualifier(this.versionType).subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.verQualifierLst = data.payload;
      this.versionQual = ""
      this.versionRev = "";
      this.bomEffDate = "";
      this.costEffDate = "";
      this.repbomVerId = "";
    });
  }

  getVersionRevData() {
    if (this.versionQual == undefined) {
      this.versionQual = ""
    }
    this.SpinnerService.show();
    this.prodwithinVerService.getVersionRev(this.versionQual).subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.versionRevLst = data.payload[0]
      this.versionRev = this.versionRevLst.version_revision;
      this.bomEffDate = this.versionRevLst.bom_eff_dt;
      this.costEffDate = this.versionRevLst.cost_eff_dt;
      this.repbomVerId = this.versionRevLst.rep_bom_ver_id
      this.repBomVerEffDt = this.versionRevLst.eff_dt
      this.repBomVerExprDt = this.versionRevLst.expr_dt

    });
  }

  getFamilyFpn(): void {
    if (this.namc == undefined) {
      this.namc = ""
    }
    this.prodwithinVerService.getFamilyByNamcFpn(this.namc).subscribe(data => {
      console.log(data);
      this.famcdLst = data.payload;
      this.family = ""
      this.katashiki = ""
    });
  }

  getKatashikiByFamCd(): void {
    console.log(this.namc)
    if (this.family == undefined) {
      this.family = ""
    }
    this.prodwithinVerService.getKatashikiByFamCd(this.family).subscribe(data => {
      console.log(data);
      this.katashikiLst = data.payload;
      this.katashiki = ""
    });

  }



  getDest(): void {
    if (this.namc == undefined) {
      this.namc = ""
    }
    if (this.family == undefined) {
      this.family = ""
    }
    if (this.katashiki == undefined) {
      this.katashiki = ""
    }
    // if (this.customer == undefined) {
    //   this.customer = ""
    // }
    this.prodwithinVerService.getDestByCust(this.namc, this.family, this.katashiki).subscribe(data => {
      console.log(data);
      this.destLst = data.payload;
      this.dest = ""
    });
  }

  getHikiateData(): void {

    let hikiObj =
    {
      namc: this.namc,
      family: this.family,
      katashiki: this.katashiki,
      // cust: this.customer,
      dest: this.dest
    }
    this.prodwithinVerService.getHikiate(hikiObj).subscribe(data => {
      console.log(data);
      this.hikiateLst = data.payload;
      this.hikiate = ""
    });
  }





  //ngx-table
  onSelect({ selected }) {
    this.selected = selected
    console.log('Select Event', selected, this.selected);
  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }
  openGrid(): void {
    if (this.versionType && this.versionQual && this.versionRev) {
      this.errorMessage = '';
      this.showItems = true;
      this.selected = [];
      this.getGridData();
    }
    else {
      this.errorMessage = "Please select all mandatory fields"
    }

  }
  closeAlert(): void {
    this.errorMessage = '';
  }

  addVersion(): void {
    console.log("this.selected", this.selected);
    let typversion;
    let qualversion;
    for (let i = 0; i < this.verTypeLst.length; i++) {
      if (this.versionType == this.verTypeLst[i].bom_ver_typ_id) {
        typversion = this.verTypeLst[i].bom_ver_typ_cd
        break;
      }
    }
    for (let i = 0; i < this.verQualifierLst.length; i++) {
      if (this.versionQual == this.verQualifierLst[i].bom_ver_qual_id) {
        qualversion = this.verQualifierLst[i].bom_ver_qual_cd
        break;
      }
    }
    if (this.selected.length < 1) {
      this.errorMessage = "Please select a product to add version";
    }
    else {
      this.errorMessage = '';
      if (this.selected.length > 0) {
        this.errorMessage = '';
        this.sendToDialogData(this.selected, 'addtoversion')
        const dialogRef = this.dialog.open(ProdVersionActionsComponent, {
          disableClose: true,
          data: this.dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('result')
          this.dialogRef.close({'result':result,'vertyp':typversion,'verqual':qualversion,'verrev':this.versionRev})
          this.selected = [];

        });
      }

    }

  }

  sendToDialogData(selected, data): void {

    console.log(selected);

    let arrayvalue1 = [];
    let rep_prod_def_id_lst: any
    for (let i = 0; i < selected.length; i++) {
      arrayvalue1.push(selected[i].rep_prod_def_id);
    }
    console.log(arrayvalue1.length, arrayvalue1);
    let str1 = '';
    for (let i = 0; i < arrayvalue1.length; i++) {
      if (str1 == '') {
        str1 = arrayvalue1[i]
      } else {
        str1 += ',' + arrayvalue1[i];
      }
      rep_prod_def_id_lst = str1;
    }

    selected.forEach(row => {
      this.dialogData.rep_prod_def_id = rep_prod_def_id_lst
      this.dialogData.rep_bom_ver_id = this.repbomVerId
      this.dialogData.buttonName = data

    });
  }

  selectMonthlyprod() {
    console.log("rows", this.rows);
    this.SpinnerService.show();

    if (this.selected.length == 0) {
      for (let i = 0; i < this.rows.length; i++) {
        if (this.rows[i].monthly_rep_flg == 'Y') {
          this.selected.push(this.rows[i]);
        }
      }
      this.selected = [...this.selected]
      this.SpinnerService.hide()
      console.log("selected", this.selected);
    } else {
      this.selected = [];
      console.log("selected", this.selected);
      this.SpinnerService.hide()
    }
  }


}
