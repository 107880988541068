import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferenceModule } from '../reference.module';
import { ProcstatusComponent } from '../procstatus/procstatus.component';
import { ProcstatuseditComponent } from '../procstatusedit/procstatusedit.component';


@NgModule({
    declarations: [
        ProcstatusComponent,
        ProcstatuseditComponent
    ],
    imports: [
        CommonModule,
        ReferenceModule
    ]
})
export class ProcStatusModule { }
