import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogData } from '../dialog-data/dialog-data';
import { ProcstatuseditService } from './procstatusedit.service';


@Component({
  selector: 'app-procstatusedit',
  templateUrl: './procstatusedit.component.html',
  styleUrls: ['./procstatusedit.component.css']
})
export class ProcstatuseditComponent implements OnInit {
  [x: string]: any;
  //to main page
  message = "Records Edited Successfully";
  index: any;
  errorMessage = "";

  //ngModelValues
  eprocName: any;
  eParameterSts: any;
  //respone after inserting a record
  response: any;

  spinnerMessage = "Please Wait";
  constructor(private ProcstatuseditService: ProcstatuseditService, public dialogRef: MatDialogRef<ProcstatuseditService>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    console.log(this.data)
    this.eprocName = this.data.job;
    this.eParameterSts = this.data.processingState;
    // to get systemParamValue
    this.ProcstatuseditService.getStatusValue().subscribe(data => {
      console.log(data);
      this.ProcedureStatusLst = data.payload;
    });

  }
  //closealert
  closeAlert() {
    this.errorMessage = "";
  }
  uploadParm() {
    let user = localStorage.getItem('workDayId');
    let email = localStorage.getItem('emailId');
    let fileName=localStorage.getItem('Filename')
    console.log(this.eParameterSts);

    if (!this.eParameterSts) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else {
      this.errorMessage = "";
      let body = {
        'proc_nm': this.eprocName,
        'status': this.ProcedureStatus,        
        "emailid": email,
        "fileName" :fileName
      }
      this.SpinnerService.show();
      this.ProcstatuseditService.editprocsts(body).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.response = data.payload;
        if (this.response[0].result == 'success') {
          this.dialogRef.close(this.response[0].result);
        } else if (this.response[0].result == 'AlreadyExists') {
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        this.SpinnerService.hide();
      });
    }
  }

  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });

  }

}