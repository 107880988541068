import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot) {
    var accessToken;
    if (window.location.href.includes('/?code')) {
      accessToken = window.location.href.split('?')[1].split('code=')[1].split('&')[0];
    }
    let codefromurl = localStorage.getItem('codeResponse');
    console.log(codefromurl)

    if (codefromurl != null) {
      return true;
    }
    else {
      localStorage.setItem('redirectUrl', window.location.href);
      let redirectUrl = environment.redirectUrl + environment.codeChallenge;
      window.location.href = redirectUrl;
      return true;
    }
  }
}
