import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReferenceModule} from '../reference.module';
import { PcSpecsExclusionListComponent } from '../pc-specs-exclusion-list/pc-specs-exclusion-list.component';
import { PcSpecsExclusionListEditDialogComponent } from '../pc-specs-exclusion-list-edit-dialog/pc-specs-exclusion-list-edit-dialog.component';
import { PcSpecsExclusionListAddDialogComponent } from '../pc-specs-exclusion-list-add-dialog/pc-specs-exclusion-list-add-dialog.component';


@NgModule({
  declarations: [
    PcSpecsExclusionListComponent,
    PcSpecsExclusionListEditDialogComponent,
    PcSpecsExclusionListAddDialogComponent
    
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class PcspecExcLstModule { }
