import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AppMaterialModule} from './app-material/app-material.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {MatDialogModule} from '@angular/material/dialog';
import { NgxSpinnerModule } from "ngx-spinner";  


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AppMaterialModule,
    NgxDatatableModule,
    MatDialogModule,
    NgxSpinnerModule
  ],
  exports:[AppMaterialModule,
    NgxDatatableModule,
    MatDialogModule,
    NgxSpinnerModule]
})
export class ReferenceModule { }
