import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnresolvedSlctvtyComponent } from './unresolved-slctvty/unresolved-slctvty.component';
import { UnresolvedPartSourceComponent } from './unresolved-part-source/unresolved-part-source.component';
import { DuplicateGrpiComponent } from './duplicate-grpi/duplicate-grpi.component';
import { UsageVarianceCalculationComponent } from './usage-variance-calculation/usage-variance-calculation.component';
import { MajorPartsDiscrepancyComponent } from './major-parts-discrepancy/major-parts-discrepancy.component';
import { RecordCountsFromDataSourcesComponent } from './record-counts-from-data-sources/record-counts-from-data-sources.component';
import {CircularParentChildComponent} from './circular-parent-child/circular-parent-child.component';
import { PartsWithoutCostSummaryComponent } from './parts-without-cost-summary/parts-without-cost-summary.component';
import { PartsWithoutCostDetailComponent } from './parts-without-cost-detail/parts-without-cost-detail.component';
import { PcspecToMfgspecComponent } from './pcspec-to-mfgspec/pcspec-to-mfgspec.component';



import {ReferenceModule} from './reference.module';



@NgModule({
  declarations: [
    UnresolvedSlctvtyComponent,
    UnresolvedPartSourceComponent,
    UsageVarianceCalculationComponent,
    MajorPartsDiscrepancyComponent,
    RecordCountsFromDataSourcesComponent,
    CircularParentChildComponent, 
    PartsWithoutCostSummaryComponent,
    PartsWithoutCostDetailComponent,
    PcspecToMfgspecComponent,
    DuplicateGrpiComponent

  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class QualAndExcepModule { }
