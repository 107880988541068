import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManualAdjustmentRuleComponent } from '../manual-adjustment-rule/manual-adjustment-rule.component';
import { ManualAdjustmentRuleAddDialogComponent } from '../manual-adjustment-rule-add-dialog/manual-adjustment-rule-add-dialog.component';
import { ManualAdjustmentRuleDeleteDialogComponent } from '../manual-adjustment-rule-delete-dialog/manual-adjustment-rule-delete-dialog.component';
import { ManualAdjustmentRuleEditDialogComponent } from '../manual-adjustment-rule-edit-dialog/manual-adjustment-rule-edit-dialog.component';
import { ManualAdjustmentRuleViewDialogComponent } from '../manual-adjustment-rule-view-dialog/manual-adjustment-rule-view-dialog.component';
import { ManualAdjustmentRuleUploadDialogComponent } from '../manual-adjustment-rule-upload-dialog/manual-adjustment-rule-upload-dialog.component';
import { ManualAdjustmentRuleCorrectBomsComponent } from '../manual-adjustment-rule-correct-boms/manual-adjustment-rule-correct-boms.component';
import {ReferenceModule} from '../reference.module';




@NgModule({
  declarations: [
    ManualAdjustmentRuleComponent,
    ManualAdjustmentRuleAddDialogComponent,
    ManualAdjustmentRuleDeleteDialogComponent,
    ManualAdjustmentRuleEditDialogComponent,
    ManualAdjustmentRuleViewDialogComponent,
    ManualAdjustmentRuleUploadDialogComponent,
    ManualAdjustmentRuleCorrectBomsComponent
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class ManAdjRuleModule { }
