import { Component, OnInit } from '@angular/core';
import { DialogData } from '../dialog-data/dialog-data';

@Component({
  selector: 'app-extraneous-parts-list-view-dialog',
  templateUrl: './extraneous-parts-list-view-dialog.component.html',
  styleUrls: ['./extraneous-parts-list-view-dialog.component.css']
})
export class ExtraneousPartsListViewDialogComponent implements OnInit {

  vExtraneousPartType:any;
  vExtraneousPartDescription:any;
  vMatchingPartText:any;
  vMatchingPartDescriptionText:any;
  vComponentCode:any;
  vSourceRouting:any;
  vDestRouting:any;
  vEffectiveInDate:any;
  vEffectiveOutDate:any;
  vComments:any;


  constructor(public data: DialogData) { }
  ngOnInit(): void {

    this.vExtraneousPartType=this.data.extraneousPartType;
    this.vExtraneousPartDescription=this.data.extraneousPartDescription;
    this.vMatchingPartText=this.data.matchingPartText;
    this.vMatchingPartDescriptionText=this.data.matchingPartDescriptionText;
    this.vComponentCode=this.data.componentCode;
    this.vSourceRouting=this.data.sourceRouting;
    this.vDestRouting=this.data.destRouting;
    this.vEffectiveInDate=this.data.effectiveInDate;
    this.vEffectiveOutDate=this.data.effectiveOutDate;
    this.vComments=this.data.comments;

  }

}
