import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { DialogData } from '../dialog-data/dialog-data';
import { NgxSpinnerService } from "ngx-spinner";
import { PartSourceRoutingEditDialogService } from './part-source-routing-edit-dialog.service';




@Component({
  selector: 'app-part-source-routing-edit-dialog',
  templateUrl: './part-source-routing-edit-dialog.component.html',
  styleUrls: ['./part-source-routing-edit-dialog.component.css']
})
export class PartSourceRoutingEditDialogComponent implements OnInit {

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  [x: string]: any;
  //to main page
  message = "Records Edited Successfully";
  index: any;
  errorMessage = "";
  spinnerMessage = "Please Wait...";

  //ngModelValues
  pNamc: any;
  pCompletePartRouting: any;
  pPartSource: any = "";
  pEffectiveInDate: any = "";
  pEffectiveOutDate: any;
  pComments: any;
  ppart_src_rtg_id: any = ""
  //response after insert in a record
  response: any;
  namc_id: any;
  partSourceLst: any = [];
  constructor(private editPartSourceRoutService: PartSourceRoutingEditDialogService, private SpinnerService: NgxSpinnerService, public dialogRef: MatDialogRef<PartSourceRoutingEditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {


  }

  async ngOnInit(): Promise<void> {
    this.SpinnerService.show();
    let a = await this.getnamc();
    if (a) {
      console.log(this.data);
      for (let i = 0; i < this.namcLst.length; i++) {
        if (this.data.namc == this.namcLst[i].namc_cd) {
          this.namc_id = this.namcLst[i].namc_lgl_entity_id
        }
      }
    }
    this.pNamc = this.data.namc;
    this.pCompletePartRouting = this.data.completePartRouting;
    this.pPartSource = this.data.partSource;
    this.pEffectiveInDate = this.data.effectiveInDate;
    this.pEffectiveOutDate = this.data.effectiveOutDate;
    this.pComments = this.data.comments;
    this.ppart_src_rtg_id = this.data.part_src_rtg_id;
    this.getpartsource();
  }
  getnamc() {
    //to get namc
    return new Promise(res => {
      this.editPartSourceRoutService.getNamc().subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.namcLst = data.payload;
        res(true);
      });
    });
  }
  //to get partsource
  getpartsource() {
    this.SpinnerService.show();
    this.editPartSourceRoutService.getPartSource().subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.partSourceLst = data.payload;

    });
  }

  //closealert
  closeAlert() {
    this.errorMessage = "";
  }



  updatePartSrcRtng() {
    let fileName= localStorage.getItem('Filename')
    console.log(this.pPartSource, this.pEffectiveOutDate);

    if (!this.pPartSource || !this.pEffectiveOutDate) {

      this.errorMessage = "Please enter all mandatory field";

    }
    else if (this.pEffectiveInDate && this.pEffectiveOutDate &&
      this.pipe.transform(this.pEffectiveInDate, 'yyyy-MM-dd') > this.pipe.transform(this.pEffectiveOutDate, 'yyyy-MM-dd')) {

      this.errorMessage = "Effective Out Date should be greater than or equal to Effective In Date";

    }
    else {
      let user = localStorage.getItem('workDayId');
      this.errorMessage = "";
      let indateFormat = '';
      if (this.pEffectiveOutDate != "") {
        let d1 = moment(this.pEffectiveOutDate).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.pEffectiveOutDate = date
      }
      let cmts;
      if (this.pComments != undefined) {
        cmts = this.pComments.replaceAll("'", "''");
      } else {
        cmts = this.pComments
      }
      let body = {
        'partSr': this.pPartSource,
        'effOutCd': indateFormat,
        'comments': cmts,
        'part_src_rtg_id': this.ppart_src_rtg_id,
        "screenNo":"NBPC_2295",
        "fileName" :fileName
       
      }
      this.SpinnerService.show();
      this.editPartSourceRoutService.editPartSource(body).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.response = data.payload;
        if (this.response[0].result == 'success') {
          this.dialogRef.close(this.response[0].result);
        } else if (this.response[0].result == 'AlreadyExists') {
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        this.SpinnerService.hide();
      });

    }
  }
  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'pEffectiveOutDate') {
      this.pEffectiveOutDate = "";
    }
  }
}

