import { Component, OnInit } from '@angular/core';
import { DialogData } from '../dialog-data/dialog-data';

@Component({
  selector: 'app-family-view-dialog',
  templateUrl: './family-view-dialog.component.html',
  styleUrls: ['./family-view-dialog.component.css']
})
export class FamilyViewDialogComponent implements OnInit {
  [x: string]: any;
  
  vFamily:any;
  vFamilyDescription:any;
  vSeries:any;
  vGeneration:any;
  vCarFamilyFlag:any;
  vEffectiveInDate:any;
  vEffectiveOutDate:any;

  
  constructor(public data: DialogData) { }

  ngOnInit(): void {
    console.log(this.data.carFamilyFlag)
    this.vFamily=this.data.family;
    this.vFamilyDescription=this.data.familyDescription;
    this.vSeries=this.data.series;
    this.vGeneration=this.data.generation;
    this.vCarFamilyFlag=this.data.carFamilyFlag== 'Y' ? true:false;
    this.vEffectiveInDate=this.data.effectiveInDate;
    this.vEffectiveOutDate=this.data.effectiveOutDate;

  }

}
