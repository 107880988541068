<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
<h2 mat-dialog-title cdkDragHandle >Edit <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
</h2>

<mat-dialog-content class="mat-typography">
  <div class="container">


    <!-- row1 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Rule Type:</label></div>
          <div class="col-8"><input type="text" class="inputtext" disabled [(ngModel)]="editRuleType"></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Version Name:</label></div>
          <div class="col-8"><input type="text" class="inputtext" disabled [(ngModel)]="editVersionName"></div>
        </div>
      </div>
    </div>
    <!-- row2 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>BOM Effective From:</label></div>
          <div class="col-8"><input type="text" class="inputtext" disabled [(ngModel)]="editBomEffFrom"></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>BOM Effective To:</label></div>
          <div class="col-8"><input type="text" class="inputtext" disabled [(ngModel)]="editBomEffTo"></div>
        </div>
      </div>
    </div>
    <!-- row3 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>NAMC:</label></div>
          <div class="col-8"><input type="text" class="inputtext" disabled [(ngModel)]="editNamc"></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Family:</label></div>
          <div class="col-8"><input type="text" class="inputtext" disabled [(ngModel)]="editFamily"></div>
        </div>
      </div>
    </div>
    <!-- row4 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>S/P:</label></div>
          <div class="col-8"><input type="text" class="inputtext" disabled [(ngModel)]="editSp"></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Net Spec:</label></div>
          <div class="col-8"><input type="text" class="inputtext" disabled [(ngModel)]="editNetSpec"></div>
        </div>
      </div>
    </div>
    <!-- row5 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Katashiki/Part:</label></div>
          <div class="col-8"><input type="text" class="inputtext" disabled [(ngModel)]="editKatasPart"></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>GRPI:</label></div>
          <div class="col-8"><input type="text" class="inputtext" disabled [(ngModel)]="editGrpi"></div>
        </div>
      </div>
    </div>
    <!-- row6 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Dest:</label></div>
          <div class="col-8"><input type="text" class="inputtext" disabled [(ngModel)]="editDest"></div>
        </div>
      </div>
    </div>
    <!-- row7 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Part Number:</label></div>
          <div class="col-8"><input type="text" class="inputtext" disabled [(ngModel)]="editPartNo"></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Part Description:</label></div>
          <div class="col-8"><input type="text" class="inputtext" disabled [(ngModel)]="editPartDesc"></div>
        </div>
      </div>
    </div>
    <!-- row8 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Qty Per Parent:</label></div>
          <div class="col-8"><input type="text" class="inputtext" disabled [(ngModel)]="editQtyPerPar"></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Complete Part Routing:</label></div>
          <div class="col-8"><input type="text" class="inputtext" disabled [(ngModel)]="editCmpRouting"></div>
        </div>
      </div>
    </div>
    <!-- row9 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>ACT Unit Cost:</label></div>
          <div class="col-8"><input type="text" class="inputtext" disabled [(ngModel)]="editActUnitCst"></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>ACT Currency Code:</label></div>
          <div class="col-8"><input type="text" class="inputtext" disabled [(ngModel)]="editActCurrencyCd"></div>
        </div>
      </div>
    </div>
    <!-- row10 -->
    <div class="row rowClass">
      <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
      <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review" rows="4" cols="50"
          [(ngModel)]="editComments">
        </textarea>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button (click)="manAdjEdit()">Save</button>
  <button mat-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">

  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>