import { Component, OnInit, ViewChild } from '@angular/core';
import { AppMaterialModule } from '../app-material/app-material.module';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PartSourceService } from './part-source.service';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { GlobalService } from '../global.service';



@Component({
  selector: 'app-part-source',
  templateUrl: './part-source.component.html',
  styleUrls: ['./part-source.component.css']
})
export class PartSourceComponent implements OnInit {

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //alertMessage
  errorMessage: string = '';

  //ngModel values for search
  step = 0;
  partSource: any = "";
  partSourceDescription: any = "";
  actualDirectMaterialCost: string;
  standardDirectMaterialCost: string;
  transferCost: any;
  extractionTimeIn: any;
  extractionTimeOut: string;
  spinnerMessage = "Please Wait...";


  //list
  partSourceLst: any;
  partSourceDescriptionLst: any;
  actualDirectMaterialCostLst: any;
  standardDirectMaterialCostLst: any;
  transferCostLst: any;
  extractionTimeInLst: any;
  extractionTimeOutLst: any;

  //mat-dialog
  data: any = {};

  //ngx-datatable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;
  showdata: boolean = true;
  Roledata: any;
  roleAccess: any = [];
  columns = [

    { name: 'partSource', prop: 'part_src_typ_cd' }, { name: 'partSourceDescription', prop: 'part_src_typ_desc' },
    { name: 'actualDirectMaterialCost', prop: 'actl_dir_mtrl_cost_flg' }, { name: 'standardDirectMaterialCost', prop: 'std_dir_mtrl_cost_flg' },
    { name: 'transferCost', prop: 'trnfr_cost_flg' }, { name: 'extractionTimeIn', prop: 'eff_dt' },
    { name: 'extractionTimeOut', prop: 'expr_dt' }

  ];
  ColumnMode = ColumnMode;
  recordcount: any = 0;
  accessEnable: boolean;

  constructor(private partSourceService: PartSourceService, private SpinnerService: NgxSpinnerService, public globalrole: GlobalService, ) { }

  async ngOnInit() {

    this.SpinnerService.show();
    let a = await this.userroleAccessfn();
    if (a) {
      console.log('a', a)
      //grid
      this.getGridData();
    }
    this.onloadfn();
  }

  onloadfn() {
    //part source desc
    this.changePartSourceDescription();

    //to getPartSource
    this.partSourceService.getPartSource().subscribe(data => {
      console.log(data);
      this.partSourceLst = data.payload;

    });


  }
  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  //onchange getPartSourceDescription
  changePartSourceDescription() {
    if (this.partSource == undefined) {
      this.partSource = ""
    }
    let partSource = {
      "partSourceTypeCd": this.partSource,
    }
    this.partSourceService.PartDesc(partSource).subscribe(data => {
      console.log(data);
      this.partSourceDescriptionLst = data.payload;
      this.partSourceDescription = ""
    });
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  //ngx-table
  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }

  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }

  closeAlert(): void {
    this.errorMessage = '';
  }

  resetAll(): void {
    this.selected = [];
    this.partSource = '';
    this.partSourceDescription = '';
    this.errorMessage = "";
    // this.getGridData();
    this.onloadfn();
  }

  onOptionsSelected() {
    console.log("the selected value is ", this.partSource);
  }

  search() {
    this.errorMessage = '';
    this.getGridData();
  }

  getGridData() {
    this.selected = [];
    console.log(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      let data1 = this.partSourceDescription.replaceAll('+', '%2B');
      console.log(data1)
      if (this.partSource == undefined) {
        this.partSource = ""
      }
      if (this.partSourceDescription == undefined) {
        data1 = ""
      }
      let gridData =
      {
        partSource: this.partSource,
        partSourceDescription: data1
      }
      console.log(gridData);
      this.showdata = true;
      console.log(this.showdata);
      this.SpinnerService.show();
      this.partSourceService.getPartSourceGrid(gridData).subscribe(data => {
        console.log(data)
        this.SpinnerService.hide();
        this.recordcount = data.pagination.totalRecords;
        let datarow = data.payload;
        this.rows = datarow.map(row => ({
          part_src_typ_cd: row['part_src_typ_cd'],
          part_src_typ_desc: row['part_src_typ_desc'],
          actl_dir_mtrl_cost_flg: row['actl_dir_mtrl_cost_flg'],
          std_dir_mtrl_cost_flg: row['std_dir_mtrl_cost_flg'],
          trnfr_cost_flg: row['trnfr_cost_flg'],
          eff_dt: (row['eff_dt'] == null) ? '' : row['eff_dt'].split('T')[0],
          expr_dt: (row['expr_dt'] == null) ? '' : row['expr_dt'].split('T')[0],

        }));

        console.log(this.rows)
      }, err => {
        console.log(err);
        this.showdata = false;
        console.log(this.showdata);
        this.rows = [];
        if (err == 0) {
          this.errorMessage = "Request timed out. Please try again.";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        } else {
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        }
      });
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }

  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.log(res)
        this.Roledata = res;
      })
      console.log(this.Roledata);
      if (this.Roledata.length != 0) {
        this.Roledata.filter((res) => {
          if (res.function_nm == 'NBPC_2295') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length == 0) {
        let arrayvalue: any; let roleList: any;
        let data = localStorage.getItem('role');
        arrayvalue = data.split(',')
        console.log(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str == '') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        let body = {
          security_grp_nm: roleList
        }

        let userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
          this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
          this.userroleAccessfn();
          this.accessEnable = true;
          resolve(true);
        }else{
        console.log(this.roleAccess)
        this.roleAccess = []
        this.errorMessage = "User does not have permission to perform 'read' operation";
        this.accessEnable = false;
        this.SpinnerService.hide();
      };
      }
      console.log(this.roleAccess);
    })
  }

}
