import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReferenceModule} from '../reference.module';
import { IsSystemParameterComponent } from '../is-system-parameter/is-system-parameter.component';
import { IsSystemParameterEditDialogComponent } from '../is-system-parameter-edit-dialog/is-system-parameter-edit-dialog.component';
import { IsSystemParameterViewDialogComponent } from '../is-system-parameter-view-dialog/is-system-parameter-view-dialog.component';


@NgModule({
  declarations: [
    IsSystemParameterComponent,
    IsSystemParameterEditDialogComponent,
    IsSystemParameterViewDialogComponent
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class IsSysParmModule { }
