import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProdProcStatusComponent } from '../prod-proc-status/prod-proc-status.component';
import { ModifyVboDateComponent } from '../modify-vbo-date/modify-vbo-date.component';
import {ReferenceModule} from '../reference.module';

@NgModule({
  declarations: [
    ProdProcStatusComponent,
    ModifyVboDateComponent
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class ProdProcStatusModule { }
