import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('Ebom_accessToken')
    })
  };
}

@Injectable({
  providedIn: 'root'
})
export class RepresentativeVehiclesService {
  // [x: string]: any;
  searchdropdownUrlApi = environment.MastersearchdropdownapiUrl;
  mastersearchdropdown2apiUrl = environment.Mastersearchdropdown3apiUrl;
  gridData = '/getRepVehicleGrid';
  modelNumber = '/getModelNumber';
  option = '/getOption';
  namc = 'getNamc';
  family = '/getFamilyByNamc';
  katashiki = '/getKatashiki';
  dest = '/getDestByCust';
  hikiate = '/getHikiateStatus';
  customer = 'getCustomer';
  destByCust = '/getDestByCust';
  custByKatashiki = '/getCustByKatashiki';
  sp = '/getRepVehbasOptTypVald';

  constructor(private http: HttpClient) { }

  //grid data
  getGridData(vehGridData): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = environment.MastergridapiUrl + this.gridData + '?mdlNo=' + vehGridData.mdlNo
      + '&optCd=' + vehGridData.optnCd + '&sapMtrlId=' + vehGridData.sapMtrlId + '&namc=' + vehGridData.namc
      + '&family=' + vehGridData.family + '&katashiki=' + vehGridData.katashiki + '&cust=' + vehGridData.cust
      + '&hikiate=' + vehGridData.hikiate + '&dest=' + vehGridData.dest + '&sp=' + vehGridData.sp + '&netSpec=' + vehGridData.netSpec
      + '&pcSpec=' + vehGridData.pcSpec + '&compPcSpec=' + vehGridData.compPcSpec + '&hunSpec=' + vehGridData.hunSpec
      + '&cmpHunSpec=' + vehGridData.cmpHunSpec + '&extTimeIn=' + vehGridData.extTimeIn + '&extTimeOut=' + vehGridData.extTimeOut
      + '&option=' + vehGridData.option + '&GfileName=' + vehGridData.fileName+'&screenNo=NBPC_9350'+'&fileName=' +fileName

    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //namc
  getNamc(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = `${this.searchdropdownUrlApi}/${this.namc}`+'?screenNo=NBPC_9350'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }
  //model number
  getModelNumber(screen): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let SearchParams = new HttpParams().set('screen', screen)
    let API_URL = this.searchdropdownUrlApi + this.modelNumber + '?screen=' + screen+'&screenNo=NBPC_9350'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //option
  getOption(screen): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let SearchParams = new HttpParams().set('screen', screen)
    let API_URL = this.searchdropdownUrlApi + this.option + '?screen=' + screen+'&screenNo=NBPC_9350'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //family and  famCdByNamc
  getFamily(namc, screen): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let famParams = new HttpParams().set('namcId', namc)
    //   .append('screen', screen);
    let API_URL = this.searchdropdownUrlApi + this.family + '?namcId=' + namc + "&screen=" + screen + "&option=" + 'initial'+'&screenNo=NBPC_9350'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //customer
  getCustomer(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = `${this.searchdropdownUrlApi}/${this.customer}`+'?screenNo=NBPC_9350'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //dest
  getDest(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = `${this.searchdropdownUrlApi}/${this.dest}`+'?screenNo=NBPC_9350'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //hikiate and hikibyDest
  getHikiate(hikiObj): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = this.searchdropdownUrlApi + this.hikiate + '?namcId=' + hikiObj.namc
      + '&famCd=' + hikiObj.family + '&katashikiCd=' + hikiObj.katashiki
      + '&custId=' + hikiObj.cust + '&destId=' + hikiObj.dest+'&screenNo=NBPC_9350'+'&fileName=' +fileName
    // let hikiParams = new HttpParams().set('namcId', hikiObj.namc)
    //   .append('famCd', hikiObj.family)
    //   .append('katashikiCd', hikiObj.katashiki)
    //   .append('custId', hikiObj.cust)
    //   .append('destId', hikiObj.dest);
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )

  }

  //katashikiByFamCd
  getKatashikiByFamCd(famCd): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let katashiliParams = new HttpParams().set("famCd", famCd);
    let API_URL = this.searchdropdownUrlApi + this.katashiki + '?famCd=' + famCd+'&screenNo=NBPC_9350'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //custByKatashiki
  getCustByKatashiki(namcId, famCd, katashikiCd): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = this.searchdropdownUrlApi + this.custByKatashiki + '?namcId=' + namcId
      + "&famCd=" + famCd + '&katashikiCd=' + katashikiCd+'&screenNo=NBPC_9350'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )

  }

  //destByCust
  getDestByCust(namcId, famCd, katashikiCd, custId): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = this.searchdropdownUrlApi + this.destByCust + '?namcId=' + namcId
      + "&famCd=" + famCd + '&katashikiCd=' + katashikiCd + '&custId=' + custId+'&screenNo=NBPC_9350'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)


  }
  //sp
  getSp(namc, family, katashiki, buyOffCust, destCtry, hikiStatComb): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let spParams = new HttpParams().set('namc', namc)
    //   .append('family', family)
    //   .append('katashiki', katashiki)
    //   .append('buyOffCust', buyOffCust)
    //   .append('destCtry', destCtry)
    //   .append('hikiStatComb', hikiStatComb);

    let API_URL = this.mastersearchdropdown2apiUrl + this.sp + '?namc=' + namc
      + "&family=" + family + '&katashiki=' + katashiki + '&buyOffCust=' + buyOffCust
      + "&destCtry=" + destCtry + '&hikiStatComb=' + hikiStatComb+'&screenNo=NBPC_9350'+'&fileName=' +fileName

    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }
  fileUpldData(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    return this.http.get(environment.BussinessgridapiUrl + '/AssumeRole', httpOptions)
      .map((response: any) => response).catch(this.error)
  }


  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage: any = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error.status;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }



}
