import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { DialogData } from '../dialog-data/dialog-data';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { MatDialog } from '@angular/material/dialog';
import { SelectivityPartAddDialogComponent } from '../selectivity-part-add-dialog/selectivity-part-add-dialog.component';
import { SelectivityPartMultieditDialogComponent } from '../selectivity-part-multiedit-dialog/selectivity-part-multiedit-dialog.component';
import { SelectivityPartEditDialogComponent } from '../selectivity-part-edit-dialog/selectivity-part-edit-dialog.component';
import { SelectivityPartViewDialogComponent } from '../selectivity-part-view-dialog/selectivity-part-view-dialog.component';
import { SelectivityPartUploadDialogComponent } from '../selectivity-part-upload-dialog/selectivity-part-upload-dialog.component';
import { SelectivityPartRuleServiceService } from './selectivity-part-rule-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../global.service';

import * as S3 from 'aws-sdk/clients/s3';

@Component({
  selector: 'app-selectivity-part-rule',
  templateUrl: './selectivity-part-rule.component.html',
  styleUrls: ['./selectivity-part-rule.component.css']
})
export class SelectivityPartRuleComponent implements OnInit {
  [x: string]: any;


  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //export to excel
  fileName = 'SelectivityPartRule';
  exportData = [];
  sheetName = [];
  recordcount: any = 0;
  roleAccess: any = []
  Roledata: any = [];
  //alertMessage
  errorMessage: string = '';
  successMessage: string = '';

  //ngModel values for search
  step = 0;
  namc: string = "";
  family: string = "";
  grpi: string = "";
  selectivity: string = "";
  completePartRouting: string = "";
  parentPart: string = "";
  partNumber: string = "";
  selectivityRuleVin: string = "";
  selectivityRuleRep: string = "";
  comments: any = "";
  effDateIn: string = "";
  effDateOut: string = "";
  selectivityRuleTimeIn: any = '';
  selectivityRuleTimeOut: any = '';
  spinnerMessage = "Please Wait...";
  accessKey: any
  secretKey: any
  sessionToken: any
  fileFormat: any

  //list
  namcLst: string[] = [];
  familyLst: string[] = [];
  grpiLst: string[] = [];
  parentPartLst: string[] = [];
  partNumberLst: string[] = [];
  selectivityRuleRepLst: string[] = [];
  selectivityRuleVinLst: string[] = [];
  selectivityLst: string[] = [];
  completePartRoutingLst: string[] = [];

  //ngx-datatable
  rows = [];
  private _SelectionType = SelectionType;
  public get SelectionType() {
    return this._SelectionType;
  }
  public set SelectionType(value) {
    this._SelectionType = value;
  }
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;
  showdata: boolean = true;
  columns = [
    { name: 'namc', prop: 'namc_cd' }, { name: 'family', prop: 'fam_cd' }, { name: 'grpi', prop: 'grpi_cd' }, { name: 'selectivity', prop: 'slctv_typ' },
    { name: 'completePartRouting', prop: 'cmplt_rtg_cd' }, { name: 'parentPart', prop: 'par_part_no' }, { name: 'partNumber', prop: ' part_no' },
    { name: 'selectivityRuleVin', prop: 'vin_slctv_rule_typ_id' }, { name: 'selectivityRuleRep', prop: 'rep_slctv_rule_typ_id' }, { name: 'selectivityTimeIn', prop: 'eff_dt' },
    { name: 'selectivityRuleTimeOut', prop: 'expr_dt' }, { name: 'comments', prop: 'rmrk_txt' }
  ];
  ColumnMode = ColumnMode;


  constructor(private SpinnerService: NgxSpinnerService, public globalrole: GlobalService, 
    private selectivityPartRuleServiceService: SelectivityPartRuleServiceService, public dialog: MatDialog, private exportService: ExportToExcelService,

    private dialogData: DialogData) { }



  async ngOnInit(): Promise<void> {
    this.SpinnerService.show();

    let a = await this.userroleAccessfn();
    if (a) {
      console.log('a', a)
      //grid
      this.getGridData("initial");
    }
    this.onloadfn();
  }

  onloadfn() {
    //to get namc
    this.selectivityPartRuleServiceService.getNamcSpr().subscribe(data => {
      console.log(data);
      this.namcLst = data.payload;
    });

    // to get family
    this.getFamilyByNamcSpr();

    // to get selectivity
    this.selectivityPartRuleServiceService.getSelectivityByFamily(this.family).subscribe(data => {
      console.log(data);
      this.selectivityLst = data.payload;
    });

    // to get selectivityRuleVin
    this.selectivityPartRuleServiceService.getSelectivityRuleVin().subscribe(data => {
      console.log(data);
      this.selectivityRuleVinLst = data.payload;
    });


    // to get selectivityRuleRep
    this.selectivityPartRuleServiceService.getSelectivityRuleRep().subscribe(data => {
      console.log(data);
      this.selectivityRuleRepLst = data.payload;
    });


    // to get completePartRouting
    this.selectivityPartRuleServiceService.getCompletePartRouting(this.namc, this.family, this.selectivity).subscribe(data => {
      console.log(data);
      this.completePartRoutingLst = data.payload;
    });
  }
  //grpi
  checkGrpiCd(data) {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "famCd": this.family,
      "grpiCd": this.grpi,
      "screenNo":"NBPC_2230",
      "fileName" :fileName
    }
    this.selectivityPartRuleServiceService.chkGrpiCd(body).subscribe(res => {
      console.log("res from chkGrpiCd", res.payload);
      if (res.payload[0].result == 'Invalid_GRPI') {
        this.errorMessage = "GRPI <" + this.grpi + "> Invalid"
      } else {
        let indateFormat = ''; let outdateFormat = '';
        if (this.selectivityRuleTimeIn != "") {
          let d1 = moment(this.selectivityRuleTimeIn).format('YYYY-MM-DD')
          let b = d1.split('T')[0]
          let parts = []
          parts = b.split('-');
          let date = new Date(parts[0], parts[1] - 1, parts[2]);
          indateFormat = moment(date).format('YYYY-MM-DD')
          this.selectivityRuleTimeIn = date
        }
        if (this.selectivityRuleTimeOut != "") {
          let d1 = moment(this.selectivityRuleTimeOut).format('YYYY-MM-DD')
          let b = d1.split('T')[0]
          let parts = []
          parts = b.split('-');
          let date = new Date(parts[0], parts[1] - 1, parts[2]);
          outdateFormat = moment(date).format('YYYY-MM-DD')
          this.selectivityRuleTimeOut = date
        }

        let newDate = new Date();
        let date = '_' + newDate.getDate() + (newDate.getMonth() + 1) + newDate.getFullYear() + '_' + newDate.getHours() + newDate.getMinutes() + newDate.getSeconds() + newDate.getMilliseconds();
        let fileName = 'Selectivity-partrule-grid/SlctivityGrdSrc' + date + '.json'
        let gridData
        if (data == 'initial') {
          gridData =
          {
            namc: this.namc,
            family: this.family,
            grpi: this.grpi,
            selectivity: this.selectivity,
            completePartRouting: this.completePartRouting,
            parentPart: this.parentPart,
            partNumber: this.partNumber,
            selectivityRuleVin: this.selectivityRuleVin,
            selectivityRuleRep: this.selectivityRuleRep,
            comments: this.comments,
            selectivityRuleTimeIn: indateFormat,
            selectivityRuleTimeOut: outdateFormat,
            option: data,
            fileName: fileName
          }
        } else {
          gridData =
          {
            namc: "",
            family: "",
            grpi: "",
            selectivity: "",
            completePartRouting: "",
            parentPart: "",
            partNumber: "",
            selectivityRuleVin: "",
            selectivityRuleRep: "",
            comments: "",
            selectivityRuleTimeIn: "",
            selectivityRuleTimeOut: "",
            option: data,
            fileName: fileName
          }
        }
        this.SpinnerService.show();
        this.selectivityPartRuleServiceService.getGridData(gridData).subscribe(data => {
          console.log(data);
          this.showdata = true;
          this.recordcount = data.pagination.totalRecords;
          console.log(this.showdata);
          // this.SpinnerService.hide();
          let datarow = data.payload;
          if (data.payload[0].RecordCnt > 1000) {
            setTimeout(() => {
              this.getFileFromS3(fileName);
            }, 2000);
          }
          else {
            this.SpinnerService.hide();
            this.rows = datarow.map(row => ({
              namc_cd: row['namc_cd'],
              fam_cd: row['fam_cd'],
              grpi_cd: row['grpi_cd'],
              slctv_typ: row['slctv_typ'],
              cmplt_rtg_cd: row['cmplt_rtg_cd'],
              par_part_no: row['par_part_no'],
              part_no: row['part_no'],
              vin_slctv_rule_typ_id: row['vin_slctv_rule_typ_id'],
              rep_slctv_rule_typ_id: row['rep_slctv_rule_typ_id'],
              eff_dt: (row['eff_dt'] == null) ? '' : row['eff_dt'].split('T')[0],
              expr_dt: (row['expr_dt'] == null) ? '' : row['expr_dt'].split('T')[0],
              rmrk_txt: row['rmrk_txt'],
              slctv_part_rule_id: row['slctv_part_rule_id']
            }));
          }
          console.log(this.rows)
        }, err => {
          console.log(err);
          this.showdata = false;
          console.log(this.showdata);
          this.rows = [];
          this.successMessage = "";
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        });
      }
    })

  }

  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  //ngx-table
  onSelect({ selected }) {
    this.selected = selected;
    if (this.selected.length == selected.length) {
      this.sameLengthRows = true
    }
    if (this.selected.length == 0) {
      this.sameLengthRows = false
    }
    console.log('Select Event', selected, this.selected);

    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }

  openAddDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    console.log(this.roleAccess.includes('Create'))
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(SelectivityPartAddDialogComponent, {
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');

        if (result == 'success') {
          this.getGridData("add");
          this.successMessage = "Data Added Successfully"
          // this.getGridData();
          this.selected = []
        }
        else if (result == 'AlreadyExists') {
          this.errorMessage = 'Data Entered already Exists!'
          this.globalrole.goToTop();
        }
        else {
          // th
        }
      });
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }
  }
  openEditDialog(): void {
    console.log(this.selected, this.selected.length)
    this.errorMessage = '';
    this.successMessage = '';
    let count = this.selected.length
    console.log(this.roleAccess.includes('Update'))
    if (this.roleAccess.includes('Update')) {
      if (this.selected.length == 1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(SelectivityPartEditDialogComponent, {
          disableClose: true,
          data: this.dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          if (result == 'success') {
            this.getGridData("add");
            this.successMessage = "Records Updated Successfully"
            // this.getGridData();

            this.selected = []
          }
          else if (result == 'AlreadyExists') {
            this.errorMessage = 'Data Entered already Exists!'
            this.globalrole.goToTop();
          }
          else {
            // th
          }

        });
      }
      else if (this.selected.length > 1) {
        const dialogRef = this.dialog.open(SelectivityPartMultieditDialogComponent, {
          disableClose: true,
          data: this.selected
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log(result)
          console.log('The dialog was closed');

          if (result == 'success') {
            this.getGridData("add");
            this.successMessage = +count + " Records Updated Successfully"
            // this.getGridData();

            this.selected = []
          }
          else if (result == 'AlreadyExists') {
            this.errorMessage = 'Data Entered already Exists!'
            this.globalrole.goToTop();
          }
          else {
            this.errorMessage = result

          }

        });
      }
      else {
        this.errorMessage = "Please select a row to edit";
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
    }
  }
  openViewDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    console.log(this.roleAccess.includes('Read'))
    if (this.roleAccess.includes('Read')) {
      if (this.selected.length == 1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(SelectivityPartViewDialogComponent, {
          disableClose: true,
          data: this.dialogData

        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');

        });
      }
      else {
        if (this.sameLengthRows == true) {
          this.errorMessage = "please select only one row to view"
          this.globalrole.goToTop();
        }
        if (this.sameLengthRows == false) {
          this.errorMessage = "please select a row to view"
          this.globalrole.goToTop();
        }
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }
  openUploadDialog(): void {

    this.successMessage = '';
    this.errorMessage = '';
    console.log(this.roleAccess.includes('Create'))
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(SelectivityPartUploadDialogComponent, {
        disableClose: true,
        // width: '50%'

      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        if (result == 'success') {
          this.getGridData("add");
          this.successMessage = 'Upload Process Started, Uploaded Information will be share via Mail'
        }
        else if(result == 'cancel'){
          this.successMessage = ''
          this.errorMessage = ''
        }
        else {
          this.errorMessage = result
          this.globalrole.goToTop();
        }
      })
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }
  }

  search() {
    if (this.selectivityRuleTimeIn && this.selectivityRuleTimeOut && this.pipe.transform(this.selectivityRuleTimeIn, 'yyyy-MM-dd') > this.pipe.transform(this.selectivityRuleTimeOut, 'yyyy-MM-dd')) {
      this.errorMessage = "Selectivity Rule Time Out should be greater than or equal to Selectivity Rule Time In";
      this.globalrole.goToTop();
    }
    else {
      this.errorMessage = '';
      this.successMessage = '';
      this.getGridData("initial");

    }
  }
  exportFile(): void {
    this.successMessage = '';
    this.errorMessage = '';

    this.rows.forEach(row => {
      let obj = {
        "NAMC": row.namc_cd,
        "Family": row.fam_cd,
        "GRPI": row.grpi_cd,
        "Parent Part": row.par_part_no,
        "Part Number": row.part_no,
        "Selectivity": row.slctv_typ,
        "Complete Part Routing": row.cmplt_rtg_cd,
        "Selectivity Rule Vin": (row['vin_slctv_rule_typ_id'] == null) ? '' : row['vin_slctv_rule_typ_id'].split('-')[0],
        "Selectivity Rule Desc-Vin": (row['vin_slctv_rule_typ_id'] == null) ? '' : row['vin_slctv_rule_typ_id'].split('-')[1],
        "Selectivity Rule Rep": (row['rep_slctv_rule_typ_id'] == null) ? '' : row['rep_slctv_rule_typ_id'].split('-')[0],
        "Selectivity Rule Desc-Rep": (row['rep_slctv_rule_typ_id'] == null) ? '' : row['rep_slctv_rule_typ_id'].split('-')[1],
        "Selectivity Rule Time In": row.eff_dt,
        "Selectivity Rule Time Out": row.expr_dt,
        "Comments": row.rmrk_txt,
      }
      this.exportData.push(obj);
    });

    this.exportService.exportAsExcelFile(this.exportData, this.fileName, this.sheetName);
    this.exportData = []
  }
  closeAlert(): void {
    this.errorMessage = '';
    this.successMessage = '';
  }
  getFamilyByNamcSpr() {
    if (this.namc == undefined) {
      this.namc = ""
    }
    this.selectivityPartRuleServiceService.getFamilyByNamcSpr(this.namc).subscribe(data => {
      console.log(data);
      this.familyLst = data.payload;
      this.family = ""
      this.selectivity = ""
      this.completePartRouting = ""
    });
  }

  getSelectivityByFam() {
    if (this.family == undefined) {
      this.family = ""
    }
    this.selectivityPartRuleServiceService.getSelectivityByFamily(this.family).subscribe(data => {
      console.log(data);
      this.selectivityLst = data.payload;
      this.selectivity = ""
      this.completePartRouting = ""
    });
  }

  getCmpRoutBySelectivity() {
    if (this.namc == undefined) {
      this.namc = ""
    }
    if (this.family == undefined) {
      this.family = ""
    }
    if (this.selectivity == undefined) {
      this.selectivity = ""
    }
    this.selectivityPartRuleServiceService.getCompletePartRouting(this.namc, this.family, this.selectivity).subscribe(data => {
      console.log(data);
      this.completePartRoutingLst = data.payload;
      this.completePartRouting = ""
    });

  }



  sendToDialog(selected): void {
    selected.forEach(row => {
      this.dialogData.namc = row.namc_cd
      this.dialogData.family = row.fam_cd
      this.dialogData.grpi = row.grpi_cd
      this.dialogData.selectivity = row.slctv_typ
      this.dialogData.completePartRouting = row.cmplt_rtg_cd
      this.dialogData.parentPart = row.par_part_no
      this.dialogData.partNumber = row.part_no
      this.dialogData.selectivityRuleVin = row.vin_slctv_rule_typ_id
      this.dialogData.selectivityRuleRep = row.rep_slctv_rule_typ_id
      this.dialogData.selectivityRuleTimeIn = this.pipe.transform(row.eff_dt, 'yyyy-MM-dd');
      this.dialogData.selectivityRuleTimeOut = this.pipe.transform(row.expr_dt, 'yyyy-MM-dd');
      this.dialogData.comments = row.rmrk_txt;
      this.dialogData.slctv_part_rule_id = row.slctv_part_rule_id;
    });
    console.log(this.dialogData.monthlyRep)
  }
  resetAll(data): void {
    this.selected=[]
    this.namc = '';
    this.family = '';
    this.grpi = '';
    this.namc = '';
    this.selectivity = '';
    this.completePartRouting = '';
    this.parentPart = '';
    this.partNumber = '';
    this.selectivityRuleVin = '';
    this.selectivityRuleRep = '';
    this.comments = '';
    this.selectivityRuleTimeIn = '';
    this.selectivityRuleTimeOut = '';
    this.errorMessage = "";
    this.successMessage = '';
    // this.getGridData(data);
    this.onloadfn();
  }


  getGridData(data) {
    this.selected=[]
    console.log(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      if (this.namc == undefined) {
        this.namc = ""
      }
      if (this.family == undefined) {
        this.family = ""
      }
      if (this.selectivity == undefined) {
        this.selectivity = ""
      }
      if (this.completePartRouting == undefined) {
        this.completePartRouting = ""
      }
      if (this.selectivityRuleVin == undefined) {
        this.selectivityRuleVin = ""
      }
      if (this.selectivityRuleRep == undefined) {
        this.selectivityRuleRep = ""
      }

      let indateFormat = ''; let outdateFormat = '';
      if (this.selectivityRuleTimeIn != "") {
        let d1 = moment(this.selectivityRuleTimeIn).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.selectivityRuleTimeIn = date
      }
      if (this.selectivityRuleTimeOut != "") {
        let d1 = moment(this.selectivityRuleTimeOut).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.selectivityRuleTimeOut = date
      }

      if (this.grpi != '') {
        this.checkGrpiCd(data);
      }
      else {

        let newDate = new Date();
        let date = '_' + newDate.getDate() + (newDate.getMonth() + 1) + newDate.getFullYear() + '_' + newDate.getHours() + newDate.getMinutes() + newDate.getSeconds() + newDate.getMilliseconds();
        let fileName = 'Selectivity-partrule-grid/SlctivityGrdSrc' + date + '.json'
        let gridData
        let cmts;
    if (this.comments!= undefined) {
      cmts = this.comments.replaceAll("'", "''");
    } else {
      cmts = this.comments
    }
        if (data == 'initial') {
          gridData =
          {
            namc: this.namc,
            family: this.family,
            grpi: this.grpi,
            selectivity: this.selectivity,
            completePartRouting: this.completePartRouting,
            parentPart: this.parentPart,
            partNumber: this.partNumber,
            selectivityRuleVin: this.selectivityRuleVin,
            selectivityRuleRep: this.selectivityRuleRep,
            comments: cmts,
            selectivityRuleTimeIn: indateFormat,
            selectivityRuleTimeOut: outdateFormat,
            option: data,
            fileName: fileName
          }
        } else {
          gridData =
          {
            namc: "",
            family: "",
            grpi: "",
            selectivity: "",
            completePartRouting: "",
            parentPart: "",
            partNumber: "",
            selectivityRuleVin: "",
            selectivityRuleRep: "",
            comments: "",
            selectivityRuleTimeIn: "",
            selectivityRuleTimeOut: "",
            option: data,
            fileName: fileName
          }
        }
        this.SpinnerService.show();
        this.selectivityPartRuleServiceService.getGridData(gridData).subscribe(data => {
          console.log(data);
          this.showdata = true;
          this.recordcount = data.pagination.totalRecords;
          console.log(this.showdata);
          // this.SpinnerService.hide();
          let datarow = data.payload;
          if (data.payload[0].RecordCnt > 1000) {
            setTimeout(() => {
              this.getFileFromS3(fileName);
            }, 2000);
          }
          else {
            this.SpinnerService.hide();
            this.rows = datarow.map(row => ({
              namc_cd: row['namc_cd'],
              fam_cd: row['fam_cd'],
              grpi_cd: row['grpi_cd'],
              slctv_typ: row['slctv_typ'],
              cmplt_rtg_cd: row['cmplt_rtg_cd'],
              par_part_no: row['par_part_no'],
              part_no: row['part_no'],
              vin_slctv_rule_typ_id: row['vin_slctv_rule_typ_id'],
              rep_slctv_rule_typ_id: row['rep_slctv_rule_typ_id'],
              eff_dt: (row['eff_dt'] == null) ? '' : row['eff_dt'].split('T')[0],
              expr_dt: (row['expr_dt'] == null) ? '' : row['expr_dt'].split('T')[0],
              rmrk_txt: row['rmrk_txt'],
              slctv_part_rule_id: row['slctv_part_rule_id']
            }));
          }
          console.log(this.rows)
        }, err => {
          console.log(err);
          this.showdata = false;
          this.successMessage = "";
          console.log(this.showdata);
          this.rows = [];
          if (err == 0) {
            this.errorMessage = "Request timed out. Please try again.";
            this.SpinnerService.hide();
            this.globalrole.goToTop();
          } else {
            this.errorMessage = "No Records";
            this.SpinnerService.hide();
            this.globalrole.goToTop();
          }
        });
      }
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }

  errorExport(data) {
    this.exportData = [];
    data.forEach(row => {
      let obj = {
        "Selectivity": row.selectivity,
        "Complete Part Routing": row.completePartRouting,
        "Selectivity Rule Vin": row.selectivityRuleVin,
        "Selectivity Rule Rep": row.selectivityRuleRep,
        "Selectivity Rule Time In": row.selectivityRuleTimeIn,
        "Selectivity Rule Time Out": row.selectivityRuleTimeOut,
        "Comments": row.comments,
        "Error": row.errorMessage
      }
      this.exportData.push(obj);
    });
    console.log(this.exportData)
    this.exportService.exportAsExcelFile(this.exportData, this.errorFileName, this.errorSheetName);
  }
  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'selectivityRuleTimeIn') {
      this.selectivityRuleTimeIn = "";
    } else if (name == 'selectivityRuleTimeOut') {
      this.selectivityRuleTimeOut = "";
    }
  }

  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.log(res)
        this.Roledata = res;
      })
      console.log(this.Roledata);
      if (this.Roledata.length != 0) {
        this.Roledata.filter((res) => {
          if (res.function_nm == 'NBPC_2230') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length == 0) {
        let arrayvalue: any; let roleList: any;
        let data = localStorage.getItem('role');
        arrayvalue = data.split(',')
        console.log(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str == '') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        let body = {
          security_grp_nm: roleList
        }

        let userpreviliagedata=localStorage.getItem('userpreviliagedata')

          if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
            this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
            this.userroleAccessfn();
            this.accessEnable = true;
            resolve(true);
          }else{
          console.log(this.roleAccess)
          this.roleAccess = []
          this.errorMessage = "User does not have permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        };
      }
      console.log(this.roleAccess);
    })
  }

  async getFileFromS3(fileName) {
    this.SpinnerService.show();
    // return new Promise(async resolve => {
    let a = await this.getS3Info();
    if (a) {
      const bucket = new S3(
        {
          accessKeyId: this.accessKey,
          secretAccessKey: this.secretKey,
          sessionToken: this.sessionToken,
          region: 'us-west-2'
        }
      );

      const params = {
        Bucket: 'search-grids',
        Key: fileName,
      };
      console.log(params);
      bucket.getObject(params, (err: any, data: any) => {
        if (err) {
          console.log('download err:::::::::', err)
          this.SpinnerService.hide();
          // this.toastrService.error('Unable to download file', 'Failed')
        } else {
          console.log(data)
          this.SpinnerService.hide();
          let result = data.Body.toString();
          let res = JSON.parse(result);
          console.log("res and reult", res, result)
          if (res.length > 0) {
            this.rows = res.map(row => ({
              namc_cd: row['namc_cd'],
              fam_cd: row['fam_cd'],
              grpi_cd: row['grpi_cd'],
              slctv_typ: row['slctv_typ'],
              cmplt_rtg_cd: row['cmplt_rtg_cd'],
              par_part_no: row['par_part_no'],
              part_no: row['part_no'],
              vin_slctv_rule_typ_id: row['vin_slctv_rule_typ_id'],
              rep_slctv_rule_typ_id: row['rep_slctv_rule_typ_id'],
              eff_dt: (row['eff_dt'] == null) ? '' : row['eff_dt'].split('T')[0],
              expr_dt: (row['expr_dt'] == null) ? '' : row['expr_dt'].split('T')[0],
              rmrk_txt: row['rmrk_txt'],
              slctv_part_rule_id: row['slctv_part_rule_id']
            }));
          }
        }
      })
    }
    // })
  }

  getS3Info() {
    return new Promise(resolve => {
      this.selectivityPartRuleServiceService.fileUpldData().subscribe(data => {
        let result = data.payload[0]
        if (result !== null) {
          const aws = result;
          this.accessKey = aws.AccessKeyId;
          this.secretKey = aws.SecretAccessKey;
          this.sessionToken = aws.SessionToken;
          resolve(true)
        }
        else {
          // this.toastr.error('Data�Storage�Disconnected.');

          resolve(false)
        }
      }, err => {
        // this.toastr.error('Unable�to�get�keys�and�token', 'Failed');
      });
    })
  }
}





