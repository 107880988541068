<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 cdkDragHandle mat-dialog-title>Edit <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>

  <mat-dialog-content class="mat-typography">
    <div class="container">
      <a (click)="exportFile()" class="ms-auto datashow">Click here to see all existing records with the same
        combination</a>
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>NAMC:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="editnamc" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Family:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="editfamily" (change)='getHikiate();'
                disabled></div>
          </div>
        </div>
      </div>
      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Katashiki:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="editkatashiki" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Dest:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="editdestination" disabled></div>
          </div>
        </div>
      </div>

      <!-- row3 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Hikiate Status:</label></div>
            <div class=col-8><select id="hikiSelect" [(ngModel)]="dummyHikiateLst" class="multiple" multiple
                aria-label="Default select example">
                <option value={{hikiate.hikiate_stat_cd}} *ngFor="let hikiate of hikiateLstFromDB">
                  {{hikiate.hikiate_stat_cd}}</option>
              </select></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <button class="btn" (click)="moveHikiForward()">>></button><br>
              <button class="btn" (click)="moveHikiBackward()">
                << </button>
            </div>
            <div class=col-8><select class="multiple" multiple [(ngModel)]="dummyHikiateLstToDB"
                aria-label="Default select example">
                <option value={{selectedHikiate}} *ngFor="let selectedHikiate of selectedHikiateLst">{{selectedHikiate}}
                </option>
              </select></div>
          </div>
        </div>
      </div>
      <!-- row4 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>PC Spec:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="editpcSpec" disabled></div>
          </div>
        </div>
      </div>

      <!-- row5 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Time In-Date<span class="text-danger">*</span>:</label></div>
            <div class=col-8>
              <mat-form-field class="picker inputtext" appearance="outline">
                <input matInput [matDatepicker]="inPicker" [(ngModel)]="edittimeInDt">
                <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
                <mat-datepicker #inPicker></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'edittimeInDt')">clear
                </mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Time Out-Date:</label></div>
            <div class=col-8>
              <mat-form-field class="picker inputtext" appearance="outline">
                <input matInput [matDatepicker]="outPicker" [(ngModel)]="edittimeOutDt">
                <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
                <mat-datepicker #outPicker></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'edittimeOutDt')">clear
                </mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <!-- row6 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>VDW Effective In:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="editvdwEffIn" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>VDW Effective Out:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="editvdwEffOut" disabled></div>
          </div>
        </div>
      </div>

      <!-- row7 -->
      <div class="row rowClass">
        <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
        <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review" rows="4" cols="50"
            [(ngModel)]="editcomments">
          </textarea>
        </div>
      </div>


      <!-- row8 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>100% Spec Id:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="editreqd_100pct_pc_spec_optn_id"
                disabled></div>
          </div>
        </div>
      </div>


    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-button cdkFocusInitial (click)="editHundPer()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">

  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>
