

export class VersionDialogData
{

  approvalStat:any;
  versionType:any;
  versionQual:any;
  versionRev:any;
  priority:any;
  priorityId:any;
  volCntStatus:any;
  bomEffDate:any;
  costEffDate:any;
  effInDate:any;
  effOutDate:any;
  comments:any;
  repBomVerId:any;

}