<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>View <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon></h2>

  <mat-dialog-content class="mat-typography" >
    <div class="container">

      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>NAMC:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="vNamc" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Family:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="vFamily" disabled></div>
          </div>
        </div>
      </div>

    <!-- row2 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class ="row">
          <div class="col-4"> <label>GRPI:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="vGrpi" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class ="row">
          <div class="col-4"> <label>Selectivity:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="vSelectivity" disabled></div>
        </div>
      </div>
    </div>

    <!-- row3 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class ="row">
          <div class="col-4"> <label>Part Number:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="vPartNumber" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class ="row">
          <div class="col-4"> <label>Parent Part:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="vParentPart" disabled></div>
        </div>
      </div>
    </div>

     <!-- row4 -->
     <div class="row rowClass">
        <div class="col-md-6">
          <div class ="row ">
            <div class="col-4"> <label>Complete Part Routing:</label></div>
            <div class=col-8><input type="text" class="inputtext" [(ngModel)]="vCompletePartRouting"disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class ="row">
            
          </div>
        </div>
      </div>


    <!-- row5 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class ="row">
          <div class="col-4"> <label>Selectivity Rule Vin:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="vSelectivityRuleVin" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class ="row">
          <div class="col-4"> <label>Selectivity Rule Rep:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="vSelectivityRuleRep" disabled></div>
        </div> 
      </div>
    </div>

    <!-- row6 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class ="row">
          <div class="col-4"> <label>Selectivity Rule Time In:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="vSelectivityRuleTimeIn" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class ="row">
          <div class="col-4"> <label>Selectivity Rule Time Out:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="vSelectivityRuleTimeOut" disabled></div>
        </div>
      </div>
    </div>

    <!-- row7 -->
    <div class="row rowClass">
      <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
      <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" [(ngModel)]="vComments" disabled name="w3review" rows="4" cols="50">
          </textarea>
        </div>
    </div>

  </div>

  </mat-dialog-content>
  <mat-dialog-actions align="center" >
    <button mat-raised-button mat-dialog-close>Ok</button>
  </mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>