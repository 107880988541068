<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Add <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon></h2>

<div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
  {{errorMessage}}<mat-icon class="close-icon"  (click)="closeAlert()">close</mat-icon>
</div>

  <mat-dialog-content class="mat-typography" >
    <div class="container">
      <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are mandatory</span>
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-12">
          <div class="row">
            <div class="col-3"> <label>Version Type<span class="text-danger">*</span>:</label></div>
            <div class=col-9><input type="text" [(ngModel)]="aVerTyp" maxlength="5"></div>
          </div>
        </div>
      </div>
      <div class="row rowClass">
        <div class="col-12">
          <div class ="row">
            <div class="col-3"> <label>Version Type Description<span class="text-danger">*</span>:</label></div>
            <div class=col-9><textarea class="w-100" name="w3review" rows="4" cols="50" [(ngModel)]="aVerTypDesc"
                maxlength="50"></textarea></div>
          </div>
        </div>
      </div>
    
  </div>

  </mat-dialog-content>

  <mat-dialog-actions align="center" >
    <button mat-button cdkFocusInitial (click)="submitVerTyp()">Save</button>
    <button mat-button mat-dialog-close (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>