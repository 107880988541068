export class GLReconPeriod
{
    glReconPeriodNm:any;
  namc:any;
  glReconStatus:any;
  stdVersion:any;
  actVersion:any;
  periodStartDt:any;
  periodEndDt:any;
  comments:any;
}