<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>View <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
</h2>

<mat-dialog-content class="mat-typography" >
  <div class="container">

    <!-- row1 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"><label>Monthly Representative:</label></div>
          <div class="col-2"><input type="checkbox" value="viewMonthlyRep" [(ngModel)]="viewMonthlyRep" disabled></div>
        </div>
      </div>
    </div>
    <!-- row2 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>SAP Material ID:</label></div>
          <div class=col-8><input type="text" class="inputtext" value="viewSapMtrlId" [(ngModel)]="viewSapMtrlId"
              disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>NAMC:</label></div>
          <div class=col-8><input type="text" class="inputtext" value="viewNamc" [(ngModel)]="viewNamc" disabled></div>
        </div>
      </div>
    </div>
    <!-- row3 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Family:</label></div>
          <div class=col-8><input type="text" class="inputtext" value="viewFamily" [(ngModel)]="viewFamily" disabled>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Part:</label></div>
          <div class=col-8><input type="text" class="inputtext" value="viewPart" [(ngModel)]="viewPart" disabled></div>
        </div>
      </div>
    </div>
    <!-- row4 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Part Description:</label></div>
          <div class=col-8><input type="text" class="inputtext" value="viewPartDesc" [(ngModel)]="viewPartDesc"
              disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Related Katashiki:</label></div>
          <div class=col-8><input type="text" class="inputtext" value="viewRelKatashiki" [(ngModel)]="viewRelKatashiki"
              disabled></div>
        </div>
      </div>
    </div>
    <!-- row5 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Product Type:</label></div>
          <div class=col-8><input type="text" class="inputtext" value="viewProdType" [(ngModel)]="viewProdType"
              disabled></div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Grpi Code:</label></div>
          <div class=col-8>
            <input type="text" class="picker" class="inputtext" [(ngModel)]="viewGrpi" disabled>
          </div>
        </div>
      </div>
    </div>
    <!-- row6 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row" style="margin-top: 5px">
          <div class="col-4"> <label>Extraction Time In:</label></div>
          <div class=col-8><input type="text" class="picker" [(ngModel)]="viewExtTimeIn" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row" style="margin-top: 5px">
          <div class="col-4"> <label>Extraction Time Out:</label></div>
          <div class=col-8><input type="text" class="picker" [(ngModel)]="viewExtTimeOut" disabled></div>
        </div>
      </div>
    </div>
    <!-- row7 -->
    <div class="row rowClass">
      <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
      <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review" rows="4" cols="50"
          [(ngModel)]="viewComments" disabled>
        </textarea>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center" >
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
</mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>