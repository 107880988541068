import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('Ebom_accessToken')
    })
  };
}
@Injectable({
  providedIn: 'root'
})
export class PcspecToMfgspecService {

  apiUrl = environment.apiUrl;
  searchdropdownUrlApi = environment.MastersearchdropdownapiUrl;
  namc = 'getNamc'
  gridData = '/getPcSpecToMfgSpecCnvrsnGrd'

  constructor(private http: HttpClient) { }

  //namc
  getNamc(): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = `${this.searchdropdownUrlApi}/${this.namc}`+'?screenNo=NBPC_7910'+'&fileName=' +fileName;
    return this.http.get(API_URL,httpOptions)
      .pipe(
        catchError(this.error)

      )
  }


  //grid data
  getPcSpecToMfgSpecConversionGrid(gridData): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }

    let API_URL = environment.QualandExcpgridapiUrl + this.gridData + '?bomTypCd=' +  gridData.ra
    + '&namcCd=' + gridData.namc + '&vinKatashiki=' + gridData.vinKatashiki + '&strtDt=' + gridData.startDate
    + '&endDt=' + gridData.suspensionDate + '&GfileName=' + gridData.fileName+'&screenNo=NBPC_7910'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(catchError(this.error)
      )
  }

  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage:any = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error.status;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  fileUpldData(): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    return this.http.get(environment.BussinessgridapiUrl + '/AssumeRole'+'?screenNo=NBPC_7910'+'&fileName=' +fileName, httpOptions)
      .map((response: any) => response).catch(this.error)
  }

}
