import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReferenceModule} from '../reference.module';
import { SelectivityPartRuleComponent } from '../selectivity-part-rule/selectivity-part-rule.component';
import {SelectivityPartAddDialogComponent} from '../selectivity-part-add-dialog/selectivity-part-add-dialog.component';
import {SelectivityPartEditDialogComponent} from '../selectivity-part-edit-dialog/selectivity-part-edit-dialog.component';
import {SelectivityPartViewDialogComponent} from '../selectivity-part-view-dialog/selectivity-part-view-dialog.component';
import { SelectivityPartUploadDialogComponent } from '../selectivity-part-upload-dialog/selectivity-part-upload-dialog.component';
import { SelectivityPartMultieditDialogComponent } from '../selectivity-part-multiedit-dialog/selectivity-part-multiedit-dialog.component';


@NgModule({
  declarations: [
    SelectivityPartRuleComponent,
    SelectivityPartAddDialogComponent,
    SelectivityPartEditDialogComponent,
    SelectivityPartViewDialogComponent,
    SelectivityPartUploadDialogComponent,
    SelectivityPartMultieditDialogComponent
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class SlctyPartRuleModule { }
