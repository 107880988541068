import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import 'rxjs/Rx';
import {environment} from 'src/environments/environment';



var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('Ebom_accessToken')
    })
  };
}

@Injectable({
  providedIn: 'root'
})
export class HikiateStatusAddDialogService {

  apiUrl=environment.apiUrl;
  getNamcUrl = '/getNamcHs'
  getFamilyUrl = '/getFamilyByNamcHs'
  // getHikStatusUrl = '/getHikiateStatusHs'

  constructor(private http: HttpClient) { } 


  getNamcdropdown (): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const url= environment.Mastersearchdropdown3apiUrl + this.getNamcUrl+'?screenNo=NBPC_9996'+'&fileName=' +fileName
    return this.http.get(url, httpOptions)
      .map((res: any) => res).catch(this.handleError)
  }

  getFamilydropdown(namcId): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const url= environment.Mastersearchdropdown3apiUrl + this.getFamilyUrl + '?namcId=' + namcId+'&screenNo=NBPC_9996'+'&fileName=' +fileName
    return this.http.get(url, httpOptions)
      .map((res: any) => res).catch(this.handleError)
  }


  // getHikStatusdropdown(): Observable<any> {
  //   const url= environment.Mastersearchdropdown3apiUrl + this.getFamilyUrl
  //   return this.http.get(url, httpOptions)
  //     .map((res: any) => res).catch(this.handleError)
  // }

  //ExitsCheckHikiateStatus
  ExitsCheckHikiateStatus(body): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const url = environment.Master_ActionapiUrl + '/existCheckHikiSt'
    return this.http.post(url, body, httpOptions)
      .map((res: any) => res).catch(this.handleError)
  }

  //addHikiateStatus
  addHikiateStatus(body): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const url = environment.Master_ActionInsertApiUrl + '/addHikiSt'
    return this.http.post(url, body, httpOptions)
      .map((res: any) => res).catch(this.handleError)
  }


 // ===================================== Exception Handler ===========================================
 private handleError(error: HttpErrorResponse) {
  var errorMsg = '';
  if (error.error instanceof ErrorEvent) {
    //A client-side or network error occurred. Handle it accordingly.
    errorMsg = `An error occurred: ${error.error.message}`;
  } else {
    //The backend returned an unsuccessful response code.
    //The response body may contain clues as to what went wrong,
    errorMsg = `Backend returned code ${error}`;
  }
  //return an observable with a user-facing error message
  return throwError(errorMsg);
}
}
 
