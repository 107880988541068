<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Edit <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>

  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>

  <mat-dialog-content class="mat-typography" >
    <div class="container">
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>NAMC:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="majorPartEditNAMC" class="inputtext" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Family:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="majorPartEditFamily" class="inputtext" disabled></div>
          </div>
        </div>
      </div>
      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Katashiki/Part:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="majorPartEditKatashiki" class="inputtext" disabled></div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Major Part Type</label></div>
            <div class=col-8><input type="text" [(ngModel)]="majorPartEditPartType" class="inputtext" disabled></div>
          </div>
        </div>
      </div>
      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Expected Major Part Type Quantity:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="majorPartEditExpPartQty" class="inputtext" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Product Type:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="majorPartEditProdType" class="inputtext" disabled></div>
          </div>
        </div>
      </div>

      <!-- row3 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>Part Code:</label></div>
            <div class=col-8><input type="text" [ngModel]="majorPartEditPartCode" class="inputtext" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">

          </div>
        </div>
      </div>


      <!-- row4 -->
      <div class="row rowClass">
        <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
        <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review" rows="4" cols="50"
            [(ngModel)]="majorPartEditComments">
          </textarea>
        </div>
      </div>

      <!-- row5 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Active:</label></div>
            <div class=col-2><input type="checkbox" [(ngModel)]="majorPartEditActive"></div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="row">
          </div>
        </div>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="center" >
    <button mat-button cdkFocusInitial (click)="updateMajLst()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>