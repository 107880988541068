import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReferenceModule} from '../reference.module';
import { ExtraneousPartsListComponent } from '../extraneous-parts-list/extraneous-parts-list.component';
import { ExtraneousPartsListAddDialogComponent } from '../extraneous-parts-list-add-dialog/extraneous-parts-list-add-dialog.component';
import { ExtraneousPartsListEditDialogComponent } from '../extraneous-parts-list-edit-dialog/extraneous-parts-list-edit-dialog.component';
import { ExtraneousPartsListViewDialogComponent } from '../extraneous-parts-list-view-dialog/extraneous-parts-list-view-dialog.component';



@NgModule({
  declarations: [
    ExtraneousPartsListComponent,
    ExtraneousPartsListAddDialogComponent,
    ExtraneousPartsListEditDialogComponent,
    ExtraneousPartsListViewDialogComponent,
    
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class ExtPartLstModule { }
