import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import { NgxSpinnerService } from "ngx-spinner";
import { PlantUserCodeEditDialogService } from './plant-user-code-edit-dialog.service';

import * as moment from 'moment';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-plant-user-code-edit-dialog',
  templateUrl: './plant-user-code-edit-dialog.component.html',
  styleUrls: ['./plant-user-code-edit-dialog.component.css']
})
export class PlantUserCodeEditDialogComponent implements OnInit {
  spinnerMessage = "Please Wait...";
  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  [x: string]: any;
  //to main page
  message = "Records Edited Successfully";
  index: any;
  errorMessage = "";


  //ngModelValues
  namc: any;
  productType: any;
  userCode: any;
  grpiCode: any;
  family: any;
  effectiveInDate: any;
  effectiveOutDate: any;
  activeFlag: any;
  epsms_data_incl_set_id: any;
  //response after insert in a record
  response: any;
  productType_id: any;
  namc_id: any;
  constructor(private editPlantUserRoutService: PlantUserCodeEditDialogService, private SpinnerService: NgxSpinnerService, public dialogRef: MatDialogRef<PlantUserCodeEditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  async ngOnInit(): Promise<void> {
    this.SpinnerService.show();
    console.log("data", this.data);
    let a = await this.getNamc();
    let b = await this.getProductData();
    let namc_id;
    let productType_id;
    if (a && b) {
      console.log(this.data);

      for (let i = 0; i < this.namcLst.length; i++) {
        if (this.data.namc == this.namcLst[i].namc_cd) {
          namc_id = this.namcLst[i].namc_lgl_entity_id
        }
      }
      for (let i = 0; i < this.productArray.length; i++) {
        if (this.data.namc == this.productArray[i].prod_typ_cd) {
          productType_id = this.productArray[i].prod_typ_cd
        }
      }
    }
    // this.epsms_data_incl_set_id=this.data.epsms_data_incl_set_id
    this.namc = this.data.namc;
    this.namc_id = namc_id
    this.productType = this.data.productType;
    this.productType_id = productType_id
    this.userCode = this.data.userCode;
    this.effectiveInDate = this.data.effectiveInDate;
    this.effectiveOutDate = this.data.effectiveOutDate;
    this.grpiCode = this.data.grpiCode;
    if (this.data.activeFlag == 'Y') {
      this.activeFlag = true
    }
    if (this.data.activeFlag == 'N') {
      this.activeFlag = false
    }

    this.family = this.data.family;

  }

  //closealert
  closeAlert() {
    this.errorMessage = "";
  }
  getNamc() {
    return new Promise(res => {
      this.editPlantUserRoutService.getNamc().subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.namcLst = data.payload;
        res(true)
      });
    })

  }
  getProductData() {
    return new Promise(res => {
      this.editPlantUserRoutService.getProductdropdown().subscribe(response => {
        console.log("res from getproduct:", response);
        this.SpinnerService.hide();
        this.productArray = response.payload
        res(true)
      });
    })
  }

  updatePlantUserCode() {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    console.log(this.effectiveInDate);
    if (!this.effectiveInDate) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else if (this.effectiveInDate && this.effectiveOutDate &&
      this.pipe.transform(this.effectiveInDate, 'yyyy-MM-dd') > this.pipe.transform(this.effectiveOutDate, 'yyyy-MM-dd')) {
      this.errorMessage = "Effective Out Date should be greater than or equal to Effective In Date";
    }
    else {
      if (this.effectiveOutDate == "") {
        this.effectiveOutDate = "9999-12-31"
      }
      // if (this.activeFlag == true) {
      //   this.activeFlag = 'Y'
      // } else if (this.activeFlag == false){
      //   this.activeFlag = 'N' 
      // }
      let indateFormat = ''; let outdateFormat = '';
      if (this.effectiveInDate != "") {
        let d1 = moment(this.effectiveInDate).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.effectiveInDate = date
      }
      if (this.effectiveOutDate != "") {
        let d1 = moment(this.effectiveOutDate).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.effectiveOutDate = date
      }

      this.errorMessage = "";
      let body = {
        'inactv_flg': this.activeFlag == true ? 'Y' : 'N',
        'eff_dt': indateFormat,
        'expr_dt': outdateFormat,
        'namc_lgl_entity_id': this.namc_id,
        'serl_no': this.userCode,
        'prod_typ_cd': this.productType_id,
        'fam_cd': this.family,
        'grpi_cd': this.grpiCode,
        "screenNo":"NBPC_9991",
        "fileName" :fileName
        
        
      }
      this.SpinnerService.show();
      this.editPlantUserRoutService.editPlantUser(body).subscribe(data => {
        this.response = data.payload;
        this.SpinnerService.hide();
        if (this.response[0].result == 'success') {
          this.dialogRef.close(this.response[0].result);
        } else if (this.response[0].result == 'AlreadyExists') {
          // this.dialogRef.close(this.response[0].result);
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        this.SpinnerService.hide();
      });


    }
  }


  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'effectiveInDate') {
      this.effectiveInDate = "";
    } else if (name == 'effectiveOutDate') {
      this.effectiveOutDate = "";
    }
  }
}




