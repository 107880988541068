import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { DialogData } from '../dialog-data/dialog-data';
import { VersionQualifierDeleteDialogService } from './version-qualifier-delete-dialog.service';

@Component({
  selector: 'app-version-qualifier-delete-dialog',
  templateUrl: './version-qualifier-delete-dialog.component.html',
  styleUrls: ['./version-qualifier-delete-dialog.component.css']
})
export class VersionQualifierDeleteDialogComponent implements OnInit {

  //to main page
  message = "Record Deleted Successfully";

  spinnerMessage = "Please Wait...";

  verQualId: any;

  //response from backend after insert/update records
  response: any;

  constructor(public dialogRef: MatDialogRef<VersionQualifierDeleteDialogComponent>,
    private SpinnerService: NgxSpinnerService, @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private verQualDeleteService: VersionQualifierDeleteDialogService) { }

  ngOnInit(): void {
    this.verQualId = this.data.verQualId;

  }

  //save
  deleteVerQual(): void {
    let fileName= localStorage.getItem('Filename')
    let body = {
      'bomverqualid': this.verQualId,
      "screenNo":"NBPC_10105",
      "fileName" :fileName
    }
    this.SpinnerService.show();
    this.verQualDeleteService.deleteVersionQual(body).subscribe(data => {
      this.response = data.payload;
      this.SpinnerService.hide();
      console.log(data);
      this.dialogRef.close(this.response[0].result);
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Representative product version cannot be deleted");
    });
  }

  cancel(): void {
    this.message = "";
    this.dialogRef.close('cancel');
  }


}
