import { Component, OnInit } from '@angular/core';
import { DialogData } from '../dialog-data/dialog-data';

@Component({
  selector: 'app-selectivity-part-view-dialog',
  templateUrl: './selectivity-part-view-dialog.component.html',
  styleUrls: ['./selectivity-part-view-dialog.component.css']
})
export class SelectivityPartViewDialogComponent implements OnInit {

  vNamc:any;
  vFamily:any;
  vGrpi:any;
  vSelectivity:any;
  vPartNumber:any;
  vParentPart:any;
  vCompletePartRouting:any;
  vSelectivityRuleVin:any;
  vSelectivityRuleRep:any;
  vSelectivityRuleTimeIn:any;
  vSelectivityRuleTimeOut:any;
  vComments:any;
  spinnerMessage = "Please Wait...";

  constructor(public data: DialogData) { }

  ngOnInit(): void {
    this.vNamc=this.data.namc;
    this.vFamily=this.data.family;
    this.vGrpi=this.data.grpi;
    this.vSelectivity=this.data.selectivity;
    this.vPartNumber=this.data.partNumber;
    this.vParentPart=this.data.parentPart;
    this.vCompletePartRouting=this.data.completePartRouting;
    this.vSelectivityRuleVin=this.data.selectivityRuleVin;
    this.vSelectivityRuleRep=this.data.selectivityRuleRep;
    this.vSelectivityRuleTimeIn=this.data.selectivityRuleTimeIn;
    this.vSelectivityRuleTimeOut=this.data.selectivityRuleTimeOut;
    this.vComments=this.data.comments;
  }

}

