import { Injectable } from '@angular/core'; // at top

@Injectable({
  providedIn: 'root' // just before your class
})
export class BackendData
{
	// forManualAdjRule
	totCountOfAffProdBom: string="";
	noOfVinOrSerAff: string="";
	noOfBomAff: string="";
}