<span *ngIf="accessEnable">

  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>


<div class="alert alert-success alert-dismissible " *ngIf="successMessage!=''" role="alert">
  {{successMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>

<mat-accordion class="example-headers-align">
  <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>

    <mat-expansion-panel-header>
      <mat-panel-title>
        Search
      </mat-panel-title>

      </mat-expansion-panel-header>
      <mat-form-field>
        <mat-label>NAMC</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="namc" name="namc"
          (selectionChange)="getFamilyByNamcSpr()">
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let namc of namcLst" [value]="namc.namc_lgl_entity_id">{{namc.namc_cd}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Family</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="family" name="family"
          (selectionChange)="getSelectivityByFam()">
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let family of familyLst" [value]="family.fam_cd">{{family.fam_cd}}</mat-option>
        </mat-select>
      </mat-form-field>

    <mat-form-field>
      <mat-label>GRPI</mat-label>
      <input matInput placeholder="" [(ngModel)]="grpi">
    </mat-form-field>

      <mat-form-field>
        <mat-label>Selectivity</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="selectivity" name="selectivity"
          (selectionChange)="getCmpRoutBySelectivity()">
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let selectivity of selectivityLst"
            [value]="selectivity.slctv_typ">{{selectivity.slctv_typ}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    <mat-form-field>
      <mat-label>Complete Part Routing</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="completePartRouting" name="completePartRouting">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let completePartRouting of completePartRoutingLst"
          [value]="completePartRouting.cmplt_rtg_cd">{{completePartRouting.cmplt_rtg_cd}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Parent Part</mat-label>
      <input matInput placeholder="" [(ngModel)]="parentPart">
    </mat-form-field>


    <mat-form-field>
      <mat-label>Part Number</mat-label>
      <input matInput placeholder="" [(ngModel)]="partNumber">
    </mat-form-field>


    <mat-form-field>
      <mat-label>Selectivity Rule Vin</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="selectivityRuleVin" name="selectivityRuleVin">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let selectivityRuleVin of selectivityRuleVinLst"
          [value]="selectivityRuleVin.vin_slctv_rule_typ_id">{{selectivityRuleVin.slctv_rule_nm}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Selectivity Rule Rep </mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="selectivityRuleRep " name="selectivityRuleRep ">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let selectivityRuleRep of selectivityRuleRepLst"
          [value]="selectivityRuleRep.rep_slctv_rule_typ_id ">{{selectivityRuleRep.slctv_rule_nm}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Comments</mat-label>
      <input matInput placeholder="" [(ngModel)]="comments">
    </mat-form-field>

    <mat-form-field class="picker">
      <mat-label>Selectivity Rule Time In</mat-label>
      <input matInput [matDatepicker]="inPicker" [(ngModel)]="selectivityRuleTimeIn">
      <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
      <mat-datepicker #inPicker ></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'selectivityRuleTimeIn')">clear
      </mat-icon>
    </mat-form-field>

    <mat-form-field class="picker">
      <mat-label>Selectivity Rule Time Out</mat-label>
      <input matInput [matDatepicker]="outPicker" [(ngModel)]="selectivityRuleTimeOut" >
      <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
      <mat-datepicker #outPicker ></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear dataeclearadj"
        (click)="clearDate($event,'selectivityRuleTimeOut')">clear</mat-icon>
    </mat-form-field>
    <br>

    <button mat-button style="float: right;" (click)="resetAll('initial')">Reset</button>
    <button mat-button style="float: right;" (click)="search()">Search</button>

  </mat-expansion-panel>
</mat-accordion>

<div class="d-flex">
  <button mat-raised-button (click)="openAddDialog()" color=""> Add </button>
  <button mat-raised-button (click)="openEditDialog()">Edit </button>
  <button mat-raised-button (click)="openViewDialog()">View </button>
  <button mat-raised-button (click)="openUploadDialog()">Upload From Excel </button>
  <button mat-raised-button (click)="exportFile()" class="ms-auto"> Export To Excel </button>
</div>

<!-- <mat-card> -->
<ngx-datatable #myTable class="material striped" [rows]="rows" [columnMode]="ColumnMode.force" [headerHeight]="45"
  [footerHeight]="30" rowHeight="30" [limit]="limitSelected" [selected]="selected"
  [selectionType]="SelectionType.checkbox" [selectAllRowsOnPage]="false" [displayCheck]="displayCheck"
  (activate)="onActivate($event)" (select)="onSelect($event)" [scrollbarH]="true"
  [ngClass]="{'hideerror': showdata, 'showerror': !showdata}">
  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
      let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
      <div class="page-count">
        {{selectedCount}} selected / {{rowCount}} total
      </div>
      <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
        (change)="table.onFooterPage($event)">
      </datatable-pager>
      <div class="test">
        <select [(ngModel)]="limitSelected">
          <option>50</option>
          <option>100</option>
          <option>500</option>
        </select>
        <div class="float-right records">View {{(offset * pageSize)+1}} - {{(rowCount>
          (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
          {{(rowCount)}}

          </div>
        </div>
      </ng-template>
    </ngx-datatable-footer>
    <ngx-datatable-column [width]="10" [sortable]="false" [canAutoResize]="false" [draggable]="false"
      [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true">
    </ngx-datatable-column>
    <ngx-datatable-column name="NAMC" prop="namc_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Family" prop="fam_cd"></ngx-datatable-column>
    <ngx-datatable-column name="GRPI" prop="grpi_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Parent Part" prop="par_part_no"></ngx-datatable-column>
    <ngx-datatable-column name="Part Number" prop="part_no"></ngx-datatable-column>
    <ngx-datatable-column name="Selectivity" prop="slctv_typ"></ngx-datatable-column>
    <ngx-datatable-column name="Complete Part Routing" prop="cmplt_rtg_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Selectivity Rule Vin" prop="vin_slctv_rule_typ_id"></ngx-datatable-column>
    <ngx-datatable-column name="Selectivity Rule Rep" prop="rep_slctv_rule_typ_id"></ngx-datatable-column>
    <ngx-datatable-column name="Selectivity Rule Time In" prop="eff_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Selectivity Rule Time Out" prop="expr_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Comments" prop="rmrk_txt"></ngx-datatable-column>
  </ngx-datatable>
</span>
<div *ngIf="!accessEnable">
  <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>