<span *ngIf="accessEnable">
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <div class="alert alert-success alert-dismissible " *ngIf="successMessage!=''" role="alert">
    {{successMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <mat-accordion class="example-headers-align">
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle (click)="partdescription(partDesc)">

    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Search</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>

      <mat-form-field>
        <mat-label>NAMC</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="namcCd" name="namcCd"
          (selectionChange)='getFamCdByNamc()'>
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let namc of namcLst" [value]="namc.namc_lgl_entity_id">{{namc.namc_cd}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Family</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="famCd" name="family"
          (selectionChange)='getKatashikiByFamCd()'>
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let fam of famLst" [value]="fam.fam_cd">{{fam.fam_cd}}</mat-option>
        </mat-select>
      </mat-form-field>

    <mat-form-field>
      <mat-label>Product Type</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="prodTyp" name="prodtyp">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let prod of prodTypLst" [value]="prod.prod_typ_cd">{{prod.prod_typ_desc}}</mat-option>
      </mat-select>
    </mat-form-field>

      <mat-form-field>
        <mat-label>Related Katashiki</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="katashiki" name="katas">
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let katas of katashikiLst"
            [value]="katas.katashiki_cd">{{katas.katashiki_cd}}</mat-option>
        </mat-select>
      </mat-form-field>

    <mat-form-field class="picker">
      <mat-label>Extraction Time In</mat-label>
      <input matInput [matDatepicker]="inPicker" [(ngModel)]="effDateIn">
      <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
      <mat-datepicker #inPicker></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'effDateIn')">clear</mat-icon>
    </mat-form-field>

    <mat-form-field class="picker">
      <mat-label>Extraction Time Out</mat-label>
      <input matInput [matDatepicker]="outPicker" [(ngModel)]="effDateOut">
      <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
      <mat-datepicker #outPicker></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'effDateOut')">clear</mat-icon>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Comments</mat-label>
      <input matInput placeholder="" [(ngModel)]="comments">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Part</mat-label>
      <input matInput placeholder="" [(ngModel)]="part" oninput="this.value = this.value.toUpperCase()"
        (keyup.enter)="partdescription(partDesc)">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Part Description</mat-label>
      <input matInput placeholder="" [(ngModel)]="partDesc" disabled>
    </mat-form-field>

      <mat-form-field>
        <mat-label>SAP Material ID</mat-label>
        <input matInput placeholder="" [(ngModel)]="sapMtrlId" maxlength="20"
          oninput="this.value = this.value.toUpperCase()">
      </mat-form-field>

    <button mat-button style="float: right;" (click)="resetAll('initial')">Reset</button>
    <button mat-button style="float: right;" (click)="search()">Search</button>
  </mat-expansion-panel>
</mat-accordion>

<div class="d-flex">
  <button mat-raised-button (click)="openAddDialog()">Add</button>
  <button mat-raised-button (click)="openCopyAddDialog()">Copy-Add</button>
  <button mat-raised-button (click)="openEditDialog()">Edit</button>
  <button mat-raised-button (click)="openViewDialog()">View</button>
  <button mat-raised-button (click)="openUploadDialog()">Upload From Excel </button>
  <button mat-raised-button (click)="exportFile()" class="ms-auto">Export To Excel</button>

</div>

<ngx-datatable #myTable class="material striped" [rows]="rows" [columnMode]="ColumnMode.force" [headerHeight]="45"
  [footerHeight]="40" rowHeight="30" [limit]="limitSelected" [selected]="selected"
  [selectionType]="SelectionType.checkbox" [selectAllRowsOnPage]="false" [displayCheck]="displayCheck"
  (activate)="onActivate($event)" (select)="onSelect($event)" [scrollbarH]="true"
  [ngClass]="{'hideerror': showdata, 'showerror': !showdata }">
  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
      let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
      <div class="page-count">
        {{selectedCount}} selected / {{rowCount}} total
      </div>
      <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
        (change)="table.onFooterPage($event)">
      </datatable-pager>
      <div class="test">


        <select [(ngModel)]="limitSelected">
          <option>50</option>
          <option>100</option>
          <option>500</option>
        </select>
        <div class="float-right records">View {{(offset * pageSize)+1}} - {{(rowCount>
          (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
          {{(rowCount)}}

          </div>
        </div>
      </ng-template>
    </ngx-datatable-footer>
    <ngx-datatable-column [width]="10" [sortable]="false" [canAutoResize]="false" [draggable]="false"
      [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true">
    </ngx-datatable-column>
    <ngx-datatable-column name="Monthly Representative" [width]="140">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value" prop="monthly_rep_flg">
        <input type="checkbox" [checked]="row.monthly_rep_flg=='Y'" disabled />
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="NAMC" prop="namc_cd"></ngx-datatable-column>
    <ngx-datatable-column name="SAP Material ID" prop="sap_mtrl_id"></ngx-datatable-column>
    <ngx-datatable-column name="Family" prop="fam_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Part Number" prop="part_no"></ngx-datatable-column>
    <ngx-datatable-column name="Part Description" prop="part_desc"></ngx-datatable-column>
    <ngx-datatable-column name="Product Type" prop="prod_typ_desc"></ngx-datatable-column>
    <ngx-datatable-column name="GRPI" prop="grpi_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Related Katashiki" prop="rel_katashiki"></ngx-datatable-column>
    <ngx-datatable-column name="Extraction Time In" prop="eff_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Extraction Time Out" prop="expr_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Comments" prop="rmrk_txt"></ngx-datatable-column>
    <ngx-datatable-column name="Rep Prod Def Id" prop="rep_prod_def_id"></ngx-datatable-column>
  </ngx-datatable>
</span>
<div *ngIf="!accessEnable">
  <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>
