import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SapDataLoadComponent } from '../sap-data-load/sap-data-load.component';
import { SapDataEditDialogComponent } from '../sap-data-edit-dialog/sap-data-edit-dialog.component';
import { SapDataDeleteDialogComponent } from '../sap-data-delete-dialog/sap-data-delete-dialog.component';
import { SapDataMultieditDialogComponent } from '../sap-data-multiedit-dialog/sap-data-multiedit-dialog.component';
import {ReferenceModule} from '../reference.module';


@NgModule({
  declarations: [
    SapDataLoadComponent,
    SapDataEditDialogComponent,
    SapDataDeleteDialogComponent,
    SapDataMultieditDialogComponent
  ],
  imports: [
    CommonModule,
    ReferenceModule
  ]
})
export class SapDataLoadModule { }
