import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { DialogData } from '../dialog-data/dialog-data';
import { PlantUserCodeDeleteDialogService } from './plant-user-code-delete-dialog.service';

@Component({
  selector: 'app-plant-user-code-delete-dialog',
  templateUrl: './plant-user-code-delete-dialog.component.html',
  styleUrls: ['./plant-user-code-delete-dialog.component.css']
})
export class PlantUserCodeDeleteDialogComponent implements OnInit {

  //to main page
  message = "Records Updated Successfully";
  index: any;
     
 //response after insert in a record
 response:any;
  errorMessage: any
  spinnerMessage = "Please Wait...";
  namc: any;
  productType: any;
  userCode: any;
  grpiCode: any;
  family: any;
  namcLst: any = [];
  productArray: any = [];
  constructor(private dltPlntUserService: PlantUserCodeDeleteDialogService, private SpinnerService: NgxSpinnerService, public dialogRef: MatDialogRef<PlantUserCodeDeleteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  async ngOnInit(): Promise<void> {
    console.log(this.data);
    let a = await this.getNamc();
    let b = await this.getProductData();
    let namc_id;
    let productType_id;
    if (a && b) {
      console.log(this.data);

      for (let i = 0; i < this.namcLst.length; i++) {
        if (this.data.namc == this.namcLst[i].namc_cd) {
          namc_id = this.namcLst[i].namc_lgl_entity_id
        }
      }
      for (let i = 0; i < this.productArray.length; i++) {
        if (this.data.productType == this.productArray[i].prod_typ_desc) {
          productType_id = this.productArray[i].prod_typ_cd
        }
      }
    }
    this.namc = namc_id;
    this.productType = productType_id;
    this.userCode = this.data.userCode;
    this.grpiCode = this.data.grpiCode;
    this.family = this.data.family;
    console.log(this.productType);
  }
  getNamc() {
    return new Promise(res => {
      this.SpinnerService.show();
      this.dltPlntUserService.getNamc().subscribe(data => {
        console.log(data);
        this.namcLst = data.payload;
        this.SpinnerService.hide();
        res(true)
      });
    })

  }
  getProductData() {
    return new Promise(res => {
      this.SpinnerService.show();
      this.dltPlntUserService.getProductdropdown().subscribe(response => {
        console.log("res from getproduct:", response);
        this.productArray = response.payload;
        this.SpinnerService.hide();
        res(true)
      });
    })
  }


  deletePlantUser() {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "serl_no": this.userCode,
      "prod_typ_cd": this.productType,
      "fam_cd": this.family,
      "namc_lgl_entity_id": this.namc,
      "grpi_cd": this.grpiCode,
      "screenNo":"NBPC_9991",
      "fileName" :fileName
    }
    console.log(body);
    this.SpinnerService.show();
    this.dltPlntUserService.dltPlntUserCode(body).subscribe(data => {
      this.response = data.payload;
      this.SpinnerService.hide();
      if (this.response[0].result == 'success') {
        this.dialogRef.close(this.response[0].result);
      } else if (this.response[0].result == 'AlreadyExists') {
        // this.dialogRef.close(this.response[0].result);
        this.errorMessage = 'Data Entered already Exists!';
      }
    }, err => {
      this.SpinnerService.hide();
    });
  }

  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }
}
