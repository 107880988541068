import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { HttpHeaders, HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import 'rxjs/Rx';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('Ebom_accessToken')
    })
  };
}
@Injectable({
  providedIn: 'root'
})
export class PartSourceAdjustmentAddDialogService {
  // headers = new HttpHeaders().set('Content-Type', 'application/json');
  apiUrl = environment.apiUrl;
  Bussinessgridapi = environment.BussinessgridapiUrl
  BussinessQeDropdownapi = environment.Business_Qe_dropdownapiURL;
  addPArtSrcAdjData = '/addPartSrcAdjst';
  versionName = 'getVerNmPsa';
  namc = '/getNamcPsa';
  family = '/getFamPsa';
  katashiki = '/getKatasPsa';

  constructor(private http: HttpClient) { }


  //addPArtSrcAdj
  addPArtSrcAdj(partObj): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
   let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const url = environment.BusinessActionRestapiUrl + this.addPArtSrcAdjData
    return this.http.post(url, partObj, httpOptions)
      .map((res: any) => res).catch(this.handleError)
  }


  //version Name
  getVersionNamePsa(): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
   let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = `${this.BussinessQeDropdownapi}/${this.versionName}`+'?screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //namc
  getNamcPsa(verTypCd, bomEffFrom, bomEffTo): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
   let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = this.BussinessQeDropdownapi + this.namc + '?repBomVerId=' + verTypCd + '&bomEffFrom=' + bomEffFrom + '&bomEffTo=' + bomEffTo+'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)

      )
  }

  //family
  getFamilyPsa(verTypCd, namcId, bomEffFrom, bomEffTo): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
   let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let familyParams = new HttpParams().set('verTypCd', verTypCd)
    //   .append('namcId', namcId)
    let API_URL = environment.Business_Qe_dropdownapiURL + this.family + '?repBomVerId=' + verTypCd
      + '&namcId=' + namcId + '&bomEffFrom=' + bomEffFrom + '&bomEffTo=' + bomEffTo+'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)

      )
  }
  //katashiki
  getKatashikiPsa(verTypCd, namcId, famCd, bomEffFrom, bomEffTo): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
   let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let katashikiParams = new HttpParams().set('verTypCd', verTypCd)
    //   .append('namcId', namcId)
    //   .append('famCd', famCd)
    let API_URL = environment.Business_Qe_dropdownapiURL + this.katashiki + '?repBomVerId=' + verTypCd
      + '&namcId=' + namcId + "&famCd=" + famCd  + '&bomEffFrom=' + bomEffFrom + '&bomEffTo=' + bomEffTo+'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)

      )
  }

  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  // ===================================== Exception Handler ===========================================
  private handleError(error: HttpErrorResponse) {
    var errorMsg = '';
    if (error.error instanceof ErrorEvent) {
      //A client-side or network error occurred. Handle it accordingly.
      errorMsg = `An error occurred: ${error.error.message}`;
    } else {
      //The backend returned an unsuccessful response code.
      //The response body may contain clues as to what went wrong,
      errorMsg = `Backend returned code ${error}`;
    }
    //return an observable with a user-facing error message
    return throwError(errorMsg);
  }

}
