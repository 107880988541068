<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Add <mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </h2>

<div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
  {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>

  <mat-dialog-content class="mat-typography" >
    <div class="container">
      <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are mandatory</span>
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Approval Status:</label></div>
            <div class=col-8>
              <input type="text" [(ngModel)]="verAddApprovalStat" class="inputtext" disabled>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Version Type<span class="text-danger">*</span>:</label></div>
            <div class=col-8><select class="heightadd" [(ngModel)]="verAddVerType"
                (change)="getversionqualiData();getvoluCountData()">
                <option value=""></option>
                <option *ngFor="let data of versionTypArray" [value]="data.bom_ver_typ_id">{{data.bom_ver_typ_cd}}
                </option>
              </select></div>
          </div>
        </div>
      </div>
      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Version Qualifier<span class="text-danger">*</span>:</label></div>
            <div class=col-8><select class="heightadd" [(ngModel)]="verAddVerQual" (change)="getversionReviData()">
                <option value=""></option>
                <option *ngFor="let data of versionQualiArray" [value]="data.bom_ver_qual_id">{{data.bom_ver_qual_cd}}
              </select></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Version Revision:</label></div>
            <div class=col-8>
              <input type="text" [(ngModel)]="verAddVerRev" class="inputtext" disabled>
            </div>
          </div>
        </div>
      </div>

    <!-- row3 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row ">
          <div class="col-4"> <label>Priority<span class="text-danger">*</span>:</label></div>
          <div class=col-8><select class="heightadd" [(ngModel)]="verAddPriority" aria-label="Default select example">
              <option value=""></option>
              <option *ngFor="let data of priorityArray" [value]="data.ver_priority_typ_id">
                {{data.ver_priority_typ_cd}}
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Volume Count Status:</label></div>
          <div class=col-8>
            <select [(ngModel)]="verAddVolCntStatusId" disabled>
              <option *ngFor="let data of voulCountArray" [value]="data.vol_cnt_calc_stat_typ_id">
                {{data.vol_cnt_calc_stat_typ_nm}}

              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <!-- row4 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>BoM Effectivity Date<span class="text-danger">*</span>:</label></div>
          <div class=col-8>
            <mat-form-field class="picker" appearance="outline">
              <input matInput [matDatepicker]="inPicker" [(ngModel)]="verAddBomEffDate">
              <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
              <mat-datepicker #inPicker></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'verAddBomEffDate')">clear</mat-icon>

            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Cost Effectivity Date<span class="text-danger">*</span>:</label></div>
          <div class=col-8>
            <mat-form-field class="picker" appearance="outline">
              <input matInput [matDatepicker]="outPicker" [(ngModel)]="verAddCostEffDate">
              <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
              <mat-datepicker #outPicker ></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'verAddCostEffDate')">clear</mat-icon>

              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

    <!-- row5 -->

    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Effective In Date<span class="text-danger">*</span>:</label></div>
          <div class=col-8>
            <mat-form-field class="picker" appearance="outline">
              <input matInput [matDatepicker]="inEffPicker" [(ngModel)]="verAddEffInDate">
              <mat-datepicker-toggle matSuffix [for]="inEffPicker"></mat-datepicker-toggle>
              <mat-datepicker #inEffPicker ></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'verAddEffInDate')">clear</mat-icon>

            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Effective Out Date<span class="text-danger">*</span>:</label></div>
          <div class=col-8>
            <mat-form-field class="picker" appearance="outline">
              <input matInput [matDatepicker]="outEffPicker" [(ngModel)]="verAddEffOutDate"
                >
              <mat-datepicker-toggle matSuffix [for]="outEffPicker"></mat-datepicker-toggle>
              <mat-datepicker #outEffPicker ></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'verAddEffOutDate')">clear</mat-icon>

              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

    <!-- row13 -->
    <div class="row rowClass">
      <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
      <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8">
        <textarea class="w-100" [(ngModel)]="verAddComments" name="w3review" rows="3" cols="68">
          </textarea>
      </div>
    </div>

  </div>

  </mat-dialog-content>
  <mat-dialog-actions align="center" >
    <button mat-button cdkFocusInitial (click)="submitVersion()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>