import { Component, OnInit } from '@angular/core';
import { DialogData } from '../dialog-data/dialog-data';

@Component({
  selector: 'app-is-system-parameter-view-dialog',
  templateUrl: './is-system-parameter-view-dialog.component.html',
  styleUrls: ['./is-system-parameter-view-dialog.component.css']
})
export class IsSystemParameterViewDialogComponent implements OnInit {

  vParameterName: any;
  vParameterValue: any;
  vParameterDescription: any;
  vCreateTs: any;
  vCreateById: any;
  vUpdateTs: any;
  vUpdateById: any;


  constructor(public data: DialogData) { }
  ngOnInit(): void {
    console.log(this.data);
    this.vParameterName = this.data.job;
    this.vParameterValue = this.data.processingState;
    this.vParameterDescription = this.data.description;
    this.vCreateTs = this.data.createTs;
    this.vCreateById = this.data.createById;
    this.vUpdateTs = this.data.updateTs;
    this.vUpdateById = this.data.updateById;
  }

}