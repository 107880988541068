import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { DialogData } from '../dialog-data/dialog-data';
import { IsSystemParameterEditDialogComponent } from '../is-system-parameter-edit-dialog/is-system-parameter-edit-dialog.component';
import { ProcstatusService } from './procstatus.service';
import { NgxSpinnerService } from "ngx-spinner";

import { GlobalService } from '../global.service';
import { ProcstatuseditComponent } from '../procstatusedit/procstatusedit.component';
@Component({
  selector: 'app-procstatus',
  templateUrl: './procstatus.component.html',
  styleUrls: ['./procstatus.component.css']
})
export class ProcstatusComponent implements OnInit {
  // [x: string]: any;
  recordcount: any = 0;
  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //alertMessage
  errorMessage: string = '';
  successMessage: string = '';

  //ngModel values for search
  step = 0;
  ProcedureName: any = "";
  ProcedureStatus: any = "";
  sameLengthRows: boolean = false;
  spinnerMessage = "Please Wait...";

  //list
  ProcedureNameLst: string[] = [];
  ProcedureStatusLst: string[] = [];

  //ngx-datatable
  rows = [];
  private _SelectionType = SelectionType;
  public get SelectionType() {
    return this._SelectionType;
  }
  public set SelectionType(value) {
    this._SelectionType = value;
  }
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;
  roleAccess: any = []
  Roledata: any = [];

  showdata: boolean = true;
  columns = [
    { name: 'ProcedureName', prop: 'proc_nm' }, { name: 'Status', prop: 'proc_status' }, { name: 'Comments', prop: 'rmrk_txt' }, { name: 'Status', prop: 'email_id' }, { name: 'EmailId', prop: 'parm_desc' },
    { name: 'updateById', prop: 'update_by_id' }, { name: 'updateTs', prop: 'update_ts ' }
  ];
  ColumnMode = ColumnMode;


  constructor(private SpinnerService: NgxSpinnerService, private ProcstatusService: ProcstatusService, public dialog: MatDialog,
    private exportService: ExportToExcelService, private dialogData: DialogData, public globalrole: GlobalService,
    ) {

  }
  async ngOnInit() {

    this.SpinnerService.show();
    //grid
    this.getGridData("initial");
    this.onloadfn();
  }

  onloadfn() {
    // to get systemParamName
    this.ProcstatusService.getProcName().subscribe(data => {
      console.log(data);
      this.ProcedureNameLst = data.payload;
    });


    // to get systemParamValue
    this.ProcstatusService.getStatusValue().subscribe(data => {
      console.log(data);
      this.ProcedureStatusLst = data.payload;
    });

  }

  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  //ngx-table
  onSelect({ selected }) {
    this.selected = selected;
    if (this.selected.length == selected.length) {
      this.sameLengthRows = true
    }
    if (this.selected.length == 0) {
      this.sameLengthRows = false
    }
    console.log('Select Event', selected, this.selected.length);

    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }
  displayCheck(row) {
    return row;
  }

  singleSelectCheck(row: any) {
    return this.selected.indexOf(row) === -1;
  }
  openEditDialog(): void {
    console.log(this.selected, this.selected.length)
    this.errorMessage = "";
    this.successMessage = "";
    if (this.selected.length == 1) {
      this.errorMessage = '';
      this.sendToDialog(this.selected)
      const dialogRef = this.dialog.open(ProcstatuseditComponent, {
        disableClose: true,
        data: this.dialogData
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        if (result == 'success') {
          this.getGridData("add");
          this.successMessage = "Records Updated Successfully"
          // this.getGridData();
          this.selected = []
        }
        else if (result == 'AlreadyExists') {
          this.errorMessage = 'Data Entered already Exists!';
          this.globalrole.goToTop();
        }
        else {
          // this.errorMessage = 'Data not inserted/Valid'
        }
      });
    }

    else {
      if (this.sameLengthRows == true) {
        this.errorMessage = "please select only one row to edit"
        this.globalrole.goToTop();
      }
      if (this.sameLengthRows == false) {
        this.errorMessage = "please select a row to edit"
        this.globalrole.goToTop();
      }
    }
  }

  closeAlert(): void {
    this.errorMessage = '';
    this.successMessage = '';
  }
  sendToDialog(selected): void {
    selected.forEach(row => {
      this.dialogData.job = row.proc_nm;
      this.dialogData.processingState = row.proc_status;
      this.dialogData.updateById = row.update_by_id;
      this.dialogData.updateTs = row.update_ts;

    });
    console.log(this.dialogData.monthlyRep)
  }

  resetAll(data): void {
    this.selected = []
    this.ProcedureName = '';
    this.ProcedureStatus = '';
    this.errorMessage = "";
    this.successMessage = '';
    // this.getGridData(data);
    this.onloadfn();
  }

  search() {
    this.errorMessage = '';
    this.successMessage = '';
    this.getGridData("initial");
  }

  getGridData(data) {
    this.selected = []
    if (this.ProcedureName == undefined) {
      this.ProcedureName = ""
    }
    if (this.ProcedureStatus == undefined) {
      this.ProcedureStatus = ""
    }
    let gridData
    if (data == "initial") {
      gridData =
      {
        procnm: this.ProcedureName,
        procSts: this.ProcedureStatus,
        option: data
      }
    } else {
      gridData =
      {
        procnm: "",
        procSts: "",
        option: data
      }
    }
    this.SpinnerService.show();
    this.ProcstatusService.getprocstatusGrid(gridData).subscribe(data => {
      console.log(data)
      this.showdata = true;
      console.log(this.showdata);
      this.SpinnerService.hide();
      let datarow = data.payload;
      this.rows = datarow.map(row => ({
        proc_nm: row['proc_nm'],
        proc_status: row['proc_status'],
        email_id: row['email_id'],
        rmrk_txt: row['rmrk_txt'],
        update_by_id: row['update_by_id'],
        update_ts: (row['update_ts'] == null) ? '' : row['update_ts'].replace('Z', '').replace('T', ' '),
        create_ts: (row['create_ts'] == null) ? '' : row['create_ts'].replace('Z', '').replace('T', ' '),
        create_by_id: row['create_by_id'],
      }))
      // this.recordcount = data.pagination.totalRecords;
      // this.rows = data.payload;
    }, err => {
      console.log(err);
      this.showdata = false;
      this.successMessage = "";
      console.log(this.showdata);
      this.rows = [];
      if (err == 0) {
        this.errorMessage = "Request timed out. Please try again.";
        this.SpinnerService.hide();
        this.globalrole.goToTop();
      } else {
        this.errorMessage = "No Records";
        this.SpinnerService.hide();
        this.globalrole.goToTop();
      }
    });
  }




}